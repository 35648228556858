var BrcGrid = function (selector, dataSource) {
    this.selector = selector;
    this.dataSource = dataSource; // Notation { type: "", url: "", dataType: "", filter: {} }
    this.kendoGrid = null;
    this.uids = [];
    this.config = null;
    this.defaultOptions = {};
    this.isDefaultState = true;
    this.filterable = false;
    //events
    this.setStateHandler = null;
}

BrcGrid.prototype.init = function (config) {
    var grid = this;
    this.config = config;

    var createCellLinkTemplate = function (hyperlink, entityProperty) {
        var route = hyperlink.Route;
        for (var i in hyperlink.Properties) {
            if (hyperlink.Properties.hasOwnProperty(i)) {
                if (hyperlink.Properties[i].IsTemplate) {
                    route = UrlHelper.addOrReplaceParam(route, hyperlink.Properties[i].Name, "${" + hyperlink.Properties[i].TemplateKey + "}");
                }
            }
        }
        var attributeString = "";
        for (var i in hyperlink.Attributes) {
            if (hyperlink.Attributes.hasOwnProperty(i)) {
                var value = hyperlink.Attributes[i].IsTemplate
                    ? "${" + hyperlink.Attributes[i].TemplateKey + "}"
                    : hyperlink.Attributes[i].Value;
                attributeString += hyperlink.Attributes[i].Name + "=\"" + value + "\" ";
            }
        }
        return " # if ( " + entityProperty + " != null ) { # <a " + attributeString + " href='" + route + "'> #: " + entityProperty + " # </a> # } #";
    }

    var createSchema = function (conf, dataSource) {
        var schemaObj = {
            model: {
                id: config.EntityKey,
                fields: {}
            }
        };
        for (var i in conf.Columns) {
            if (conf.Columns.hasOwnProperty(i)) {
                schemaObj.model.fields[conf.Columns[i].EntityProperty] = {
                    type: conf.Columns[i].ValueType.Key === "Link" || conf.Columns[i].ValueType.Key === "HtmlString" ? "string" : conf.Columns[i].ValueType.Key.toLowerCase()
                }
            }
        }
        if (dataSource.dataType === "json") {
            schemaObj.data = function (data) {
                return data["value"];
            }
            schemaObj.total = function (data) {
                return !!data["odata.count"] ? data["odata.count"] : data["@odata.count"]; //добавил Андрей, для меты
            }
        }
        else {
            schemaObj.data = function (data) {
                return data;
            }
            schemaObj.total = function (data) {
                return data.length;
            }
        }
        return schemaObj;
    };

    var createColumns = function (conf) {
        var columnCollection = [];
        var confColumns = conf.Columns.sort(function (a, b) { return (a.OrderNumber > b.OrderNumber) ? 1 : ((b.OrderNumber > a.OrderNumber) ? -1 : 0); });
        for (var i in confColumns) {
            if (conf.Columns.hasOwnProperty(i)) {
                var column = {
                    field: conf.Columns[i].EntityProperty,                    
                    checkAll: false,
                    title: conf.Columns[i].DisplayName,
                    attributes: {},
                    locked: conf.Columns[i].FrozenInd,
                    headerAttributes: {
                        "class": conf.Columns[i].HeaderTextWrapInd ? "brc-wrap" : "brc-nowrap"
                    }
                };
                if (conf.Columns[i].Width != null) {
                    column.width = conf.Columns[i].Width;
                }
                else if (conf.Columns[i].Width == null && conf.Columns[i].ValueType.Key === "Date") {
                    column.width = 100;
                }
                if (conf.Columns[i].ValueFormat != null) {
                    column.format = "{0:" + conf.Columns[i].ValueFormat + "}";
                }
                if (conf.Columns[i].StylePropertyName != null) {
                    column.attributes.style = ' #= ' + conf.Columns[i].StylePropertyName + ' # ';
                }
                //Выбор дефолтного фильтра для столбца
                if (conf.Columns[i].ValueType.Key === "String" || conf.Columns[i].ValueType.Key === "HtmlString") {
                    column.filterable = {
                        cell: {
                            operator: "contains",
                            suggestionOperator: "contains",
                            ignoreCase: true
                        },
                        ignoreCase: true,
                        multi: true
                    };
                    //if (conf.Columns[i].EntityProperty == "asset_complex_name") {
                    //    column.filterable.dataSource = {
                    //        transport: {
                    //            read: {
                    //                url: window["APPLICATION_CONFIG"].routes.root + "Estate/AssetComplex/GetAssetComplexList",
                    //                dataType: "json"
                    //            }
                    //        }
                    //    }
                    //}
                }
                else if (conf.Columns[i].ValueType.Key === "Link") {
                    column.filterable = {
                        cell: {
                            operator: "contains",
                            suggestionOperator: "contains",
                            ignoreCase: true
                        },
                        ignoreCase: true,
                        multi: true
                    };
                    column.template = '<a href="#:' + column.field + '==null?"":' + column.field + '#" target="_blank">#:' + column.field + '==null?"":' + column.field + '#</a>';
                }
                else if (conf.Columns[i].ValueType.Key === "Boolean") {
                    column.filterable = {
                        mode: "row",
                        cell: {
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind: false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [
                                            { text: "Да", value: "true" },
                                            { text: "Нет", value: "false" }
                                        ]
                                    }),
                                    index: 0,
                                    optionLabel: {
                                        text: "",
                                        value: ""
                                    },
                                    valuePrimitive: true

                                });
                            }
                        }
                    };
                }
                //------
                //Простановка атрибутов для столбца
                if (conf.Columns[i].ValueType.Key === "String") {
                    column.attributes.class = "brc-grid__column brc-grid__column-string";
                    column.attributes.title = "#=data." + conf.Columns[i].EntityProperty + "#";
                }
                else if (conf.Columns[i].ValueType.Key === "HtmlString") { //добавила маша
                    column.attributes.class = "brc-grid__column brc-grid__column-string";
                    column.attributes.title = "";
                    column.template = "#= htmlDecode(data." + conf.Columns[i].EntityProperty + ")#";
                }
                else if (conf.Columns[i].ValueType.Key === "Link") {
                    column.attributes.class = "brc-grid__column brc-grid__column-string";
                    column.attributes.title = "#=data." + conf.Columns[i].EntityProperty + "#";
                    column.template = "<a target='_blank' href=#=!!data." + conf.Columns[i].EntityProperty + "?data." + conf.Columns[i].EntityProperty + ".split('|')[0]:''#>#=!!data." + conf.Columns[i].EntityProperty + "?!!data." + conf.Columns[i].EntityProperty + ".split('|')[1]?data." + conf.Columns[i].EntityProperty + ".split('|')[1]:data." + conf.Columns[i].EntityProperty + ":''#</a>";
                }
                else if (conf.Columns[i].ValueType.Key === "Boolean") {
                    column.attributes.class = "brc-grid__column brc-grid__column-checkbox";
                    column.template = "<span class='k-icon #=!" + conf.Columns[i].EntityProperty + "?'':'k-i-check' #' data-value='#=!" + conf.Columns[i].EntityProperty + "?false:true #' ></span>"; //? true : false
                }
                else if (conf.Columns[i].ValueType.Key === "Date") {
                    column.attributes.class = "brc-grid__column brc-grid__column-date";
                }
                else if (conf.Columns[i].ValueType.Key === "Number") {
                    column.attributes.class = "brc-grid__column brc-grid__column-number";
                } else {
                    column.attributes.class = "brc-grid__column";
                }
                //-------
                if (conf.Columns[i].Hyperlink != null) {
                    column.template = createCellLinkTemplate(conf.Columns[i].Hyperlink, conf.Columns[i].EntityProperty);
                }
                if (conf.Columns[i].Summary.length > 0) {
                    column.footerTemplate = "";
                    var summaries = [];
                    conf.Columns[i].Summary.forEach(function (summary) {
                        summaries.push(summary.ShortText + ": #:" + summary.Value + "#");
                        dataSource.aggregate.push({
                            field: conf.Columns[i].EntityProperty,
                            aggregate: summary.Value
                        })
                    });
                    column.footerTemplate = kendo.format("<span title='{0}'>{0}</span>", summaries.join(', '));

                }
                columnCollection.push(column);

            }
        }
        return columnCollection;
    };

    var createPagingSettings = function (conf) {
        var defaultSizes = [10, 50, 100, 200];
        if (conf.PagingAllButtonInd) {
            defaultSizes.push("all");
        }
        return (conf.PagingInd ? {
            refresh: true,
            messages: {
                refresh: ""
            },
            pageSizes: defaultSizes,
            buttonCount: 5
        } : false);
    };
    var dataSource = {
        type: grid.dataSource.type,
        transport: {
            read: {
                url: grid.dataSource.url,
                dataType: grid.dataSource.dataType,
                data: grid.dataSource.data,
                //добавил Андрей, кастомизируем тип запроса для возможности фильтрации по видимости\архивности справочников в модале выбора
                type: (!grid.dataSource.requestType ? "get" : grid.dataSource.requestType)
            },
            parameterMap: function (data, type) {

                if (type == "read") {
                    //var grid = $("#grid").data("kendoGrid");
                    //var currentFilter = grid.dataSource.filter();
                    //console.debug(currentFilter);
                    if (data.filter) {
                        for (var index in data.filter.filters) {
                            
                                if (data.filter.filters[index].field !== undefined) {
                                    data.filter.filters[index] = {
                                        field: typeof data.filter.filters[index].value == "string" ? "tolower(" + data.filter.filters[index].field + ")" : data.filter.filters[index].field,
                                        operator: data.filter.filters[index].operator,
                                        value: data.filter.filters[index].value !== undefined ? typeof data.filter.filters[index].value == "string" ? data.filter.filters[index].value.toLowerCase() : data.filter.filters[index].value : ""
                                    }
                                }
                                
                            
                        }
                    }
                    var newMap = kendo.data.transports.odata.parameterMap(data);
                    delete newMap.$format; // not currently supported by webapi.
                    return newMap;
                }
            }
        },
        schema: createSchema(config, grid.dataSource),
        //Добавил Андрей, для грида документов
        serverPaging: grid.dataSource.serverPaging == undefined ? true : grid.dataSource.serverPaging,
        serverFiltering: grid.dataSource.serverFiltering == undefined ? true : grid.dataSource.serverFiltering,
        serverSorting: grid.dataSource.serverSorting == undefined ? true : grid.dataSource.serverSorting,
        sort: grid.dataSource.sort,
        //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        aggregate: [],

    }

    if (grid.dataSource.filter) {
        dataSource.filter = grid.dataSource.filter;
    }
    if (config.PagingInd) {
        dataSource.pageSize = 50;
    }
    var currentDate = new Date();
    var currentDateString = currentDate.getDate() + "."
                + (currentDate.getMonth() + 1) + "."
                + currentDate.getFullYear() + " "
                + currentDate.getHours() + "\:"
                + currentDate.getMinutes();
    $(grid.selector).kendoGrid({
        autoBind: (config.autoBind != null || config.autoBind != undefined) ? config.autoBind : true, //отключаем автозапрос данных чтобы избежать повторных запросов
        dataSource: dataSource,
        excel: {
            filterable: true,
            fileName: config.DisplayName ? config.DisplayName + " " + currentDateString + ".xlsx" : "Экспорт " + currentDateString + ".xlsx",
            proxyURL: (grid.dataSource.excelProxyURL != undefined ? grid.dataSource.excelProxyURL : null)
        },
        pdf: {
            fileName: config.Name + ".pdf"
        },
        height: '100%',
        filterable: config.FilteringInd ? {
            mode: "menu, row",
            messages: {
                checkAll: "Выбрать все",
                filter: "Фильтр",
                clear:"Сбросить",
                selectedItemsFormat: "Выбрано записей: {0}"
            },
        } : false,
        selectable: config.SelectableModeName === "None" ? false : (config.SelectableModeName === "Multiple" ? "multiple " : "") + "row",//"multiple row",
        navigatable: true,
        scrollable: true,
        resizable: true,
        sortable: true,
        reorderable: config.ReorderingInd,
        groupable: config.GroupingInd,

        columnMenu: config.ColumnMenuInd,
        pageable: createPagingSettings(config),
        columns: createColumns(config),
        persistSelection: true
    });
    grid.kendoGrid = $(grid.selector).data("kendoGrid");
    grid.kendoGrid.bind("dataBound", function (e) {
        var dataView = this.dataSource.view();
        if (config.StylePropertyName) {
            for (var i = 0; i < dataView.length; i++) {
                var uid = dataView[i].uid;
                $(this.wrapper).find("tr[data-uid=" + uid + "]").attr('style', dataView[i][config.StylePropertyName]);

            }
        }
        $(e.sender.table).find("[data-toggle-window]").click(_selectClickedRow);
        $(e.sender.lockedTable).find("[data-toggle-window]").click(_selectClickedRow);

        function _selectClickedRow(event) {
            e.sender.clearSelection();
            e.sender.select($(event.target).closest("tr"));
        }
        //grid.kendoGrid.autoFitColumn(0)
        //for (var columnItem in config.Columns) {
        //    if (config.Columns.hasOwnProperty(columnItem)) {
        //        if (columnItem.StylePropertyName) {
        //            for (var i = 0; i < dataView.length; i++) {
        //                var uid = dataView[i].uid;
        //                $(this.wrapper).find("tr[data-uid=" + uid + "]").attr('style', dataView[i][config.StylePropertyName]);
        //            }
        //        }
        //    }
        //}
    });
    $(window).resize(function () {
        grid.kendoGrid.resize();
    });
    this.defaultOptions = grid.kendoGrid.getOptions();
    this.setState();

    return grid;
}

BrcGrid.prototype.exportExcel = function () {
    var footerTemplates = [];
    this.kendoGrid.columns.forEach(function (col) {
        footerTemplates.push(col.footerTemplate);
        col.footerTemplate = "";
    })
    this.kendoGrid.saveAsExcel();
    this.kendoGrid.columns.forEach(function (col, index) {
        col.footerTemplate = footerTemplates[index];
    })
}

BrcGrid.prototype.exportPDF = function () {
    this.kendoGrid.saveAsPDF();
}

BrcGrid.prototype.bind = function (event, callback) {
    this.kendoGrid.bind(event, callback);
}

BrcGrid.prototype.unbind = function (event) {
    this.kendoGrid.unbind(event);
}

BrcGrid.prototype.setOptions = function (options) {
    this.kendoGrid.setOptions(options);
    if (this.setStateHandler) {
        this.setStateHandler();
    }
    $(this.selector).trigger("brc.grid.setState");
}

BrcGrid.prototype.saveState = function () {
    localStorage["brc-grid-" + this.config.Name] = kendo.stringify(this.kendoGrid.getOptions());
    this.setState();
}
//BrcGrid.prototype.saveStateNamed = function(name){
//    if (name) {
//        var sort = this.kendoGrid.dataSource.sort();
//        var filter = this.kendoGrid.dataSource.filter();
//        localStorage[name] = kendo.stringify({ sort: sort, filter: filter,  });
//    }
//}
BrcGrid.prototype.mergeOptions = function (opt) {
    var defOptions = this.kendoGrid.getOptions();
    var resultCols = [];

    opt.columns.forEach(function (c) {
        if (fieldExist(c.field)) {
            var col = findDefColumn(c.field);
            col.width = c.width;
            col.hidden = c.hidden;
        }
    })
    if (defOptions.sortable)
        if (opt.dataSource.sort) {
            defOptions.dataSource.sort = [];
            for (var i = 0; i < opt.dataSource.sort.length; i++) {
                for (var k = 0; k < defOptions.columns.length; k++) {
                    if (defOptions.columns[k].field == opt.dataSource.sort[i].field)
                        defOptions.dataSource.sort.push(opt.dataSource.sort[i]);
                }
            }
        }
    if (defOptions.filterable)
        if (opt.dataSource.filter) {
            if (opt.dataSource.filter.filters.length > 0) {
                defOptions.dataSource.filter = spliceFilter(opt.dataSource.filter.filters);
                defOptions.dataSource.filter.forEach(function (f) {
                    var field = findFieldInSchema(f.field);
                    if (!!field && field.type.toLowerCase() === 'date'.toLowerCase()) {
                        f.value = kendo.parseDate(f.value);
                    }
                })
            }
        }
    if (defOptions.groupable)
        if (opt.dataSource.group) {
            defOptions.dataSource.group = [];
            for (var i = 0; i < opt.dataSource.group.length; i++) {
                for (var k = 0; k < defOptions.columns.length; k++) {
                    if (defOptions.columns[k].field == opt.dataSource.group[i].field)
                        defOptions.dataSource.group.push(opt.dataSource.group[i]);
                }
            }
        }
    this.kendoGrid.setOptions(defOptions);

    function spliceFilter(filter) {
        filter.forEach(function (f) {
            if (!fieldExist(f.field) && f.unmanaged === false) {
                filter.splice(i, 1);
            }
        })

        return filter;
    }

    function fieldExist(field) {
        for (var i = 0; i < defOptions.columns.length; i++) {
            if (defOptions.columns[i].field == field)
                return true;
        }
        return false;
    }
    function findDefColumn(field) {
        for (var i = 0; i < defOptions.columns.length; i++) {
            if (defOptions.columns[i].field == field) {
                return defOptions.columns[i];
            }
        }
    }
    function findFieldInSchema(field) {
        for (var opt in defOptions.dataSource.schema.model.fields)
        {
            if (opt == field) {
                return defOptions.dataSource.schema.model.fields[field];
            }
        }
       
    }
}

BrcGrid.prototype.setState = function () {
    var options = localStorage["brc-grid-" + this.config.Name];
    if (options) {
        this.isDefaultState = false;
        this.setOptions(JSON.parse(options));
    }
}

BrcGrid.prototype.setDefaultState = function () {
    this.isDefaultState = true;
    this.setOptions(this.defaultOptions);
}

BrcGrid.prototype.clearState = function () {
    localStorage.removeItem("brc-grid-" + this.config.Name);
    this.setDefaultState();
}

BrcGrid.prototype.getSelectedKeys = function () {
    var self = this;

    var rows = self.kendoGrid.select().filter(function () {
        return $(this).parent().parent().parent().hasClass('k-grid-content');
    });

    var selectedKeys = [];
    rows.each(function (index, row) {
        selectedKeys.push(self.kendoGrid.dataItem(row)[self.config.EntityKey]);
    });

    return selectedKeys;
}

BrcGrid.prototype.filters = function () {
    var self = this;
    var popup;
    var toolbar;
    var _lastFilters = [];
    var _currentCustomFilters = [];
    var _filtersAccepted = false;
    var _selectedFilter;
    var validator;

    var modalTemplate = '<form class="brc-form brc-full-height-form brc-grid-filter__window" ><input type="hidden" name="grid_id" value="' + self.config.Id + '"/><div class="brc-window-layout"><div class="brc-window-layout__header brc-window-layout_header-grid"><div class="brc-window-layout__toolbar"><div class="brc-form-block"><div class="row" ><div class="brc-form-group col-md-10"><label>Наименование</label><input required name="grid_filter_name" value="#:data.grid_filter_name#" type="text" class="k-textbox"/></div><div class="brc-form-group col-md-2"><label>Функция</label><input required name="grid_filter_logic" value="#:data.grid_filter_logic#" type="text" class="brc-combobox" /></div></div></div><div class="brc-form-group brc-grid-filter__window-row row" id="createFilter">{0}</div></div></div><div class="brc-window-layout__content"><div class="brc-window-layout__work-area">' +
    '<div class="row brc-grid-filter__window-row" ><div class="brc-form-group col-md-4"><label>Поле</label></div><div class="brc-form-group col-md-3"><label>Оператор</label></div><div class="brc-form-group col-md-4"><label>Значение</label></div></div>' +
        '<div id="filters"/></div></div><div class="brc-window-layout__footer"><div class="brc-form-action"><button id="okButton" class="brc-action k-button k-primary"> ОК </button><button id="cancelButton" class="brc-action k-button k-default">Отмена</button></div></div></div></form>';
    var headerTemplate = '<div style="width:100%"><input data-text-field="DisplayName" data-value-field="Id" data-role="dropdownlist"  data-bind="source: cols" id="field" type="text" class="brc-combobox"/><input type="hidden" name="grid_filter_id" value="#:data.grid_filter_id#"/><input type="hidden" name="app_user_id" value="#:data.app_user_id#"/></div>';
    var filterTemplate = kendo.template('<div filter class="row brc-grid-filter__window-row" >' +
        '<div class="brc-form-group col-md-4"><label></label><input data-text-field="DisplayName" required data-value-field="Id" data-role="dropdownlist" data-bind="source: cols, events:{change:changeField}"  data-value="#:data.grid_column_id#" data-grid_column_id  name="grid_column_id#:kendo.guid()#" type="text" class="brc-combobox"/></div>' +
        '<div class="brc-form-group col-md-3"><label></label><input required data-value="#:!data.grid_filter_operator?"":data.grid_filter_operator#" name="grid_filter_operator#:kendo.guid()#" id="operator" type="text" data-text-field="name" data-value-field="value" data-grid_filter_operator   data-enabled="false" data-role="dropdownlist"  class="brc-combobox"/></div>' +
        '<div class="brc-form-group col-md-4"><label></label><input value="#:data.value==null?"":data.value#" name="grid_filter_value_text#:kendo.guid()#" filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/></div>' +
        '<div class="brc-form-group col-md-1" ><a href="\\#" class="k-button"><span class="k-icon k-i-close"></span></a></div>' +
        '<input type="hidden" name="grid_filter_id" value="#:data.grid_filter_id#"/><input type="hidden" name="grid_filter_condition_id" id="grid_filter_condition_id" value="#:data.grid_filter_condition_id#"/>' +
        '</div>');
    var popupHtml = '<div class="brc-grid-filter__popup"><div class="brc-grid-filter__popup-header"><div class="brc-toolbar brc-toolbar-right brc-toolbar-border-none"><a href="#" class="k-button" id="openFilter">Добавить</a><a href="#" class="k-button" id="fastFilter">Быстрый фильтр</a><a href="#" class="k-button" id="resetFilter" style="position:absolute;left:10px"><span class="k-icon k-i-filter-clear"></span> Сбросить</a></div></div><div class="brc-grid-filter__popup-content">{0}</div></div>';
    var filterButton = '<div class="brc-grid-filter__popup-button" filterButtonContainer><input type="hidden" name="grid_filter_id" id="grid_filter_id" value="#:grid_filter_id#"/><span class="k-icon k-i-filter filter-inactive"></span><div  class="k-button" filterButton>#:grid_filter_name#</div><a href="\\#" class="k-button edit-button"><span class="k-icon k-i-pencil"></a></span><a href="\\#" class="k-button delete-button"><span class="k-icon k-i-close"></span></a></div>';
    var button = _initToolbar();
    _init();
    self.filterable = true;
    self.getCurrentFilters = function () {
        return _currentCustomFilters;
    }
    function _initToolbar() {
        var button = {
            type: "button",
            text: "Фильтры",
            //icon: "filter",
            id: "filterButton",
            //showText: "overflow",
            //showIcon: "never",

            click: function (e) {
                var self = this;
                //toolbar = {
                //    element: function () {
                //        var btn;
                //        self.items.forEach(function (button) {

                //            if ($(button.element).is("#filterButton"))
                //                btn = $(button.element).parent();

                //        });
                //        return btn;
                //    }()
                //}
                _open();
            },
            //overflowOpen: function (e) {
            //    $(e.sender.element).find("#filterButton").text("Фильтры");
            //}
        };
        return button;
    }
    var filterableColumns = [];
    self.config.Columns.forEach(function (element) {
        if (element.Filterable)
            filterableColumns.push(element);
    })
    var viewModel = kendo.observable({
        cols: new kendo.data.DataSource(
                {
                    data: filterableColumns,
                    schema: {
                        model: {
                            id: "Id",
                            EntityProperty: "EntityProperty"
                        }
                    }
                }
            ),
        textOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Содержит",
            value: "contains"
        },
        {
            name: "Не содержит",
            value: "doesnotcontain"
        },
        {
            name: "Заканчивается",
            value: "endswith"
        },
        {
            name: "Начинается",
            value: "startswith"
        },
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Пусто",
            value: "isnull"
        },
        {
            name: "Не пусто",
            value: "isnotnull"
        },
        //{
        //    name: "Не NULL",
        //    value: "isnotnull"
        //},
        //{
        //    name: "NULL",
        //    value: "isnull"
        //},
            ]
        }),
        numberOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Больше",
            value: "gt"
        },
        {
            name: "Больше или равно",
            value: "gte"
        },
        {
            name: "Меньше",
            value: "lt"
        },
        {
            name: "Меньше или равно",
            value: "lte"
        },
        {
            name: "Определено",
            value: "isnotnull"
        },
        {
            name: "Не определено",
            value: "isnull"
        },
            ]
        }),
        dateOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Больше",
            value: "gt"
        },
        {
            name: "Больше или равно",
            value: "gte"
        },
        {
            name: "Меньше",
            value: "lt"
        },
        {
            name: "Меньше или равно",
            value: "lte"
        },
        {
            name: "Определено",
            value: "isnotnull"
        },
        {
            name: "Не определено",
            value: "isnull"
        },
            ]
        }),
        booleanOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Определено",
            value: "isnotnull"
        },
        {
            name: "Не определено",
            value: "isnull"
        },
            ]
        }),
        changeField: function (e) {
            var _self = this;
            var col = e.sender.dataSource.at(e.sender.select());

            var operator = $(e.sender.element).parents(".row").find("#operator").data("kendoDropDownList");
            operator.listView.element.addClass('brc-combobox-dropdownlist');
            var row = $(e.sender.element).parents(".row");
            if (!col) {
                operator.enable(false);
                e.sender.value(null);
                return;

            }
            if (!!e.sender.value()) {
                operator.setDataSource({ data: (col.ValueType.Key == "String" || col.ValueType.Key == "Link" || col.ValueType.Key == "HtmlString" ? _self.textOperators.options.data : col.ValueType.Key == "Number" ? _self.numberOperators.options.data : col.ValueType.Key == "Date" ? _self.dateOperators.options.data : col.ValueType.Key == "Boolean" ? _self.booleanOperators.options.data : null) })
                operator.enable(true);
                operator.refresh();
            }
            else
                operator.enable(false);


            var data = {
                grid_filter_condition_id: row.find("[name=grid_filter_condition_id]").val(),
                grid_filter_operator: operator.value(),
                grid_filter_value: col.ValueType.key == "Boolean" ? row.find("[filter-value")[0].checked : row.find("[filter-value]").val().toLowerCase(),
                grid_column_id: row.find("[data-grid_column_id]").val(),
                grid_filter_id: row.find("[name=grid_filter_id]").val(),
                field: "tolower("+e.sender.select()+")",
                value: col.ValueType.key == "Boolean" ? row.find("[filter-value")[0].checked : row.find("[filter-value]").val().toLowerCase(),
            }
            var htmlControl = row.find("[filter-value]");
            var container = htmlControl.parents(".brc-form-group");
            var control = kendo.widgetInstance(htmlControl);
            if (!!control) {
                control.destroy();
                container.html('');
                htmlControl = $('<input required="required" name="grid_filter_value_text' + kendo.guid() + '" value="' + data.value + '" filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/>');
                container.append(htmlControl);
            }
            else {
                container.html('');
                htmlControl = $('<input required="required" name="grid_filter_value_text' + kendo.guid() + '" value="' + data.value + '"  filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/>');
                container.append(htmlControl);
            }
            initValueField(col, htmlControl, data);
            operator.trigger("change");

        }
    });
    viewModel.cols.fetch(function () {
        var data = this.data();
    });

    function applyFilter(filterId) {
        if (!filterId) {

            var filters = self.kendoGrid.dataSource.filter();
            if (!!filters) {
                filters = filters.filters.slice();
                for (var i = 0; i < filters.length; i++) {
                    if (filters[i].custom)
                        filters.splice(i--, 1)
                }

                self.kendoGrid.dataSource.filter(filters);
            }
            _selectedFilter = null;
            _currentCustomFilters = [];
            if (!!popup) {
                $(popup.element).find("[filterbuttoncontainer]>.k-i-filter").addClass("filter-inactive");
                $(popup.element).find("[filterbuttoncontainer]>[filterbutton]").attr("toggle-state", false);
            }
            $(popup.element).find("#fastFilter").removeClass("k-primary");
            //toolbar.element.find("#filterButton").html("<span>Фильтры</span>");
            return;
        }
        for (var i = 0; i < self.config.GridFilters.length; i++) {
            if (self.config.GridFilters[i].grid_filter_id == filterId) {
                var filters = [];
                _selectedFilter = self.config.GridFilters[i];
                popup.element.find("[name=grid_filter_id]").each(function (index, elem) {
                    if ($(elem).val().toLowerCase() == filterId.toLowerCase())
                        $(elem).parent().find("k-i-filter").removeClass("filter-inactive");
                })
                self.config.GridFilters[i].FilterConditions.forEach(function (cond) {
                    
                    var column = viewModel.cols.get(parseInt(cond.grid_column_id));
                    filters.push({
                        field: 'tolower(' + column.EntityProperty.toLowerCase() + ')',
                        operator: cond.grid_filter_operator,
                        value: column.ValueType.Key == "String" || column.ValueType.Key == "Link" || column.ValueType.Key == "HtmlString" ? (cond.grid_filter_operator == "contains" ? cond.grid_filter_value.toLowerCase() : cond.grid_filter_value.toLowerCase()) : column.ValueType.Key == "Number" ? kendo.parseFloat(cond.grid_filter_value) : column.ValueType.Key == "Date" ? kendo.parseDate(cond.grid_filter_value, ["dd.MM.yyyy", "dd.MM.yy"]) : column.ValueType.Key == "Boolean" ? cond.grid_filter_value == "True" : null,
                        custom: true,
                        grid_filter_id: self.config.GridFilters[i].grid_filter_id,
                        ignoreCase: true
                    })
                })

                _currentCustomFilters = filters.slice();
                filters = filters.concat(_lastFilters.slice());
                self.kendoGrid.dataSource.filter({ logic: self.config.GridFilters[i].grid_filter_logic, filters: filters, ignoreCase:true });
                _filtersAccepted = true;
                //toolbar.element.find("#filterButton").html("<span class=\"k-icon k-i-filter\"></span>" + self.config.GridFilters[i].grid_filter_name);
            }
        }
    }
    function selectFilter(filterControl) {
        var filterId = filterControl.parent().find("[name=grid_filter_id]").val();
        var state = filterControl.attr("toggle-state");
        $(popup.element).find("#fastFilter").removeClass("k-primary");
        if (state == "true") {
            filterControl.attr("toggle-state", false);
            //filterControl.removeClass("k-primary");
            filterControl.parent().find(".k-i-filter").addClass("filter-inactive");
            var gridFilter = self.kendoGrid.dataSource.filter();
            var filters = [];
            if (!!gridFilter) {
                filters = gridFilter.filters;
            }
            for (var i = 0; i < filters.length; i++) {
                if (!!filters[i].custom) {
                    filters.splice(i--, 1);

                }
            }

            self.kendoGrid.dataSource.filter(filters);
            _filtersAccepted = false;
            _currentCustomFilters = [];
            //toolbar.element.find("#filterButton").html("<span>Фильтры</span>");
            _selectedFilter = null;
            return;
        } else {
            if (!_filtersAccepted) {
                var flt = self.kendoGrid.dataSource.filter();
                if (!!flt)
                    _lastFilters = flt.filters;
                else
                    _lastFilters = [];
            }
            filterControl.parents(".brc-grid-filter__popup-content").find("[filterButton]").each(function (index, elem) {
                $(elem).attr("toggle-state", false);
                $(elem).parent().find(".k-i-filter").addClass("filter-inactive");
            })
            filterControl.attr("toggle-state", true);
            // filterControl.addClass("k-primary");
            filterControl.parent().find(".k-i-filter").removeClass("filter-inactive")
            _filtersAccepted = true;

        }
        applyFilter(filterId);
    }
    function _init() {
        var context = this;

        var buttons = [];
        self.config.GridFilters.forEach(function (filter) {
            var template = kendo.template(filterButton);
            buttons.push(template(filter));
        })
        if (!popup) {
            popup = $(kendo.format(popupHtml, buttons.join(''))).kendoWindow({
                title: "Фильтры",
                width: "500px",
                
            }).data("kendoWindow").center();
            _initPopupActions();
            var gridFilter = self.kendoGrid.dataSource.filter();

        }
        else {
            $(popup.element).find(".brc-grid-filter__popup-content").html('');
            $(popup.element).find(".brc-grid-filter__popup-content").append($(buttons.join('')));
            _initFilterActions();

        }
        if (!!_selectedFilter) {
            applyFilter(_selectedFilter.grid_filter_id);
        }
        function _initPopupActions() {
            popup.element.find("#openFilter").click(function () {
                _openFilter();
            })
            popup.element.find("#fastFilter").click(function () {
                _openFilter(null, true);
            })
            popup.element.find("#resetFilter").click(function () {
                applyFilter();
            })
            _initFilterActions();
        }
        function _initFilterActions() {
            popup.element.find("[filterButton]").click(function (e) {
                selectFilter($(e.target));
            })
            popup.element.find(".edit-button").click(function (e) {
                var _id = parseInt($(e.target).parents("[filterbuttoncontainer]").find("input#grid_filter_id").val());
                _openFilter(_id);
            })
            popup.element.find(".delete-button").click(function (e) {
                var button = $(e.target);
                BrcConfirm.show("Удаление фильтра", "Вы действительно хотите удалить выбранный фильтр?").done(function () {
                    var _id = parseInt(button.parents("[filterbuttoncontainer]").find("input#grid_filter_id").val());
                    if (_id > 0) {
                        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "controlmanagermodule/gridfilter/DeleteGridFilter", { id: _id },
                            function (e) {
                                BrcNotification.success("Выполнено");
                                button.parents("[filterButtonContainer]").remove();
                                if (!!_selectedFilter && _selectedFilter.grid_filter_id == _id)
                                    applyFilter();
                                for (var i = 0; i < self.config.GridFilters.length; i++) {
                                    if (self.config.GridFilters[i].grid_filter_id == _id) {
                                        self.config.GridFilters.splice(i, 1);
                                        return;
                                    }
                                }
                                _init();

                            },
                            function (e) {
                                BrcNotification.error(e.responseJSON);
                            });
                    }
                    else {
                        button.parents("[filterButtonContainer]").remove();
                    }
                })
            })
        }
    }
    function _open() {
        popup.open();
        var gridFilter = self.kendoGrid.dataSource.filter();
        if (!!gridFilter && !!gridFilter.filters) {
            gridFilter.filters.forEach(function (f) {
                $(popup.element).find("[name=grid_filter_id]").each(function (i, e) {
                    if (f.grid_filter_id == $(e).val()) {
                        if ($(e).attr("toggle-state")!="true")
                            selectFilter($(e));
                    }
                })

                //self.config.GridFilters.forEach(function (f1) {

                //})
            })
        }
    }
    function _openFilter(id, fast) {
        var filters = [];
        var filter;
        var filterIndex = -1;
        for (var i = 0; i < self.config.GridFilters.length; i++) {
            if (self.config.GridFilters[i].grid_filter_id == id) {
                filter = self.config.GridFilters[i];
                filterIndex = i;
                break;
            }
        }
        if (!filter)
            filter = {
                grid_filter_id: 0,
                grid_filter_name: "",
                FilterConditions: []
            }

        var wnd = $("<div/>").kendoWindow({
            title: "Настройка фильтров",
            width: "900px",
            height: "500px",
            modal: true,
            close: function (e) {
                e.sender.destroy();
            }
        }).data("kendoWindow");
        wnd.content(kendo.template(kendo.format(modalTemplate, headerTemplate)).call(this, filter));
        wnd.center().open();
        if (fast) {
            $(wnd.element).find("[name=grid_filter_name]").parent().remove();
        }
        $(wnd.element).find("[name=grid_filter_logic]").kendoDropDownList({
            dataSource: {
                data: [{
                    text: "И",
                    id: "and"
                },
                {
                    text: "ИЛИ",
                    id: "or"
                }]
            },
            dataValueField: "id",
            dataTextField: "text"
        })
        validator = $(wnd.element).find("form").kendoValidator({
            errorTemplate: kendo.template('<span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg">#=message#</span>'),
            messages: {
                required: 'Поле не должно быть пустым',
            },
        }).data("kendoValidator");
        var createFilterConteiner = $(wnd.element).find("#createFilter");

        createFilterConteiner.append('<div><button id="add" type="button"  class="brc-action k-button k-primary"> Добавить </button></div>');
        kendo.bind(createFilterConteiner.find("#field"), viewModel);
        // kendo.bind(createFilterConteiner.find("[data-grid_filter_operator]"), viewModel);
        var fieldCmb = createFilterConteiner.find("#field").data("kendoDropDownList");
        fieldCmb.listView.element.addClass('brc-combobox-dropdownlist');
        //  var operatorCmb = createFilterConteiner.find("#operator").data("kendoComboBox");
        createFilterConteiner.find("#add").click(function (e) {
            validator = $(wnd.element).find("form").kendoValidator({
                errorTemplate: kendo.template('<span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg">#=message#</span>'),
                messages: {
                    required: 'Поле не должно быть пустым',
                },
            }).data("kendoValidator");
            var data = {
                field: fieldCmb.select(),
                grid_filter_condition_id: 0,
                filterCount: $(wnd.element).find("[filter]").length,
                grid_filter_id: id,
                value: ""
            }
            initFilter(data);
        })
        setFilters(filter);
        function setFilters(fil) {
            fil.FilterConditions.forEach(function (cond, index) {
                var data = {
                    field: fieldCmb.dataSource.indexOf(fieldCmb.dataSource.get(cond.grid_column_id)),
                    grid_column_id: cond.grid_column_id,
                    grid_filter_condition_id: cond.grid_filter_condition_id,
                    filterCount: index,
                    grid_filter_id: id,
                    grid_filter_operator: cond.grid_filter_operator,
                    grid_filter_logic: cond.grid_filter_logic,
                    value: cond.grid_filter_value
                }
                initFilter(data);
            })
        }
        $(wnd.element).find("#cancelButton").click(function (e) {
            e.preventDefault();
            wnd.close();
        })

        $(wnd.element).find("form").submit(function (e) {
            e.preventDefault();

            var form = $(this);
            var data = buildFilter(form);
            if (!fast) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "controlmanagermodule/gridfilter/savegridfilter", data,
                    function (e) {
                        BrcNotification.success("Выполнено");
                        if (filterIndex != -1)
                            self.config.GridFilters[filterIndex] = e;
                        else
                            self.config.GridFilters.push(e);
                        form.find("#filters").html('');
                        setFilters(e);
                        _init();

                        e.custom = true;
                        popup.element.find(".brc-grid-filter__popup-button").each(function (index, elem) {
                            if ($(elem).find("#grid_filter_id").val() == e.grid_filter_id)
                                selectFilter($(elem).find("[filterbutton]"));
                        })

                        wnd.close();
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON);
                    })
            }
            else {
                var conditions = [];
                data.grid_filter_condition.forEach(function (cond) {
                    var column = viewModel.cols.get(cond.grid_column_id);
                    conditions.push({
                        field: cond.grid_filter_operator == "contains" ? 'tolower(' + column.EntityProperty + ')' : column.EntityProperty,
                        operator: cond.grid_filter_operator,
                        value: column.ValueType.Key == "String" || column.ValueType.Key == "Link" || column.ValueType.Key == "HtmlString" ? (cond.grid_filter_operator == "contains" ? cond.grid_filter_value.toLowerCase() : cond.grid_filter_value.toLowerCase()) : column.ValueType.Key == "Number" ? kendo.parseFloat(cond.grid_filter_value) : column.ValueType.Key == "Date" ? kendo.parseDate(cond.grid_filter_value, ["dd.MM.yyyy", "dd.MM.yy"]) : column.ValueType.Key == "Boolean" ? cond.grid_filter_value_bool : false,
                        custom: true,
                        grid_filter_id: data.grid_filter_id

                    })
                })
                if (conditions.length > 0) {
                    applyFilter();
                    var f = self.kendoGrid.dataSource.filter();
                    if (!!f)
                        conditions = f.filters.concat(conditions);
                    self.kendoGrid.dataSource.filter({ logic: data.grid_filter_logic, filters: conditions });
                    _currentCustomFilters = conditions;
                    $(popup.element).find("#fastFilter").addClass("k-primary");
                    //toolbar.element.find("#filterButton").html("<span class=\"k-icon k-i-filter\"></span>Быстрый фильтр");
                }
                wnd.close();
            }
        });

        function buildFilter(form) {
            var data = {
                grid_filter_id: form.find("[name=grid_filter_id]").val(),
                grid_filter_name: form.find("[name=grid_filter_name]").val(),
                grid_filter_logic: form.find("[name=grid_filter_logic]").val(),
                grid_id: self.config.Id,
                grid_filter_condition: []
            }

            form.find("[filter]").each(function (index, element) {
                var f = {
                    grid_filter_condition_id: $(element).find("[name=grid_filter_condition_id]").val(),
                    grid_filter_operator: $(element).find("[data-grid_filter_operator]").val(),
                    grid_filter_logic: $(element).find("[name=grid_filter_logic]").val(),
                    //[$(element).find("[filter-value]").attr("name")]: $(element).find("[filter-value]").attr("type") == "checkbox" ? $(element).find("[filter-value]")[0].checked : $(element).find("[filter-value]").val(),
                    grid_column_id: $(element).find("[data-grid_column_id]").val(),
                    grid_filter_id: $(element).find("[name=grid_filter_id]").val(),
                    app_user_id: $(element).find("[name=app_user_id]").val()
                };
                var widget = kendo.widgetInstance($(element).find("[filter-value][data-external=true]"));
                f[$(element).find("[filter-value]").attr("filter-value")] = $(element).find("[filter-value]").attr("type") == "checkbox" ? $(element).find("[filter-value]")[0].checked : !!widget ? widget.text() : $(element).find("[filter-value]").val().toLowerCase();


                f.grid_filter_value = $(element).find("[filter-value]").attr("type") == "checkbox" ? $(element).find("[filter-value]")[0].checked : !!widget ? widget.text() : $(element).find("[filter-value]").val().toLowerCase();

                data.grid_filter_condition.push(f);



            })
            return data;
        }
        function initFilter(dataItem) {
            var guid = kendo.guid();
            var column;
            var filter = $(filterTemplate(dataItem));
            filter.find(".k-i-close").parent().click(function (e) {

                var row = $(e.target).parents(".row");
                var _id = parseInt(row.find("input#grid_filter_condition_id").val());
                if (_id > 0) {

                    BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "controlmanagermodule/gridfilter/DeleteFilterCondition", { id: _id },
                        function (e) {
                            BrcNotification.success("Выполнено");
                            row.remove();

                        },
                        function (e) {
                            BrcNotification.error(e.responseJSON);
                        });

                }
                else {
                    row.remove();
                }

            })
            $(wnd.element).find("#filters").append(filter);

            kendo.bind(filter, viewModel);
            var field = 'tolower('+filter.find("[data-grid_column_id]").data("kendoDropDownList")+')';
            var op = filter.find("[data-grid_filter_operator]").data("kendoDropDownList");
            field.listView.element.addClass('brc-combobox-dropdownlist');
            op.listView.element.addClass('brc-combobox-dropdownlist');
            var valueControl = filter.find("[filter-value]");
            op.bind("change", function (e) {
                var valueControl = filter.find("[filter-value]");
                var widget = kendo.widgetInstance(valueControl);
                dataItem.value = valueControl.val();
                column = field.dataItem();
                if (valueControl.attr("data-external") == "true") {
                    var parent = widget.wrapper.parent();
                    valueControl = $('<input required="required" value="' + dataItem.value + '" name="grid_filter_value_text' + kendo.guid() + '" filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/>');
                    widget.destroy();
                    widget.wrapper.parent().empty();
                    parent.append(valueControl);
                }
                dataItem.grid_filter_operator = !!e.sender.value() ? e.sender.value() : e.sender._initial;

                if (dataItem.grid_filter_operator == "isnull" || dataItem.grid_filter_operator == "isnotnull") {
                    var control = kendo.widgetInstance(valueControl);
                    if (!!control) {
                        control.enable(false)
                    }
                    else if (valueControl.attr("type") == "checkbox")
                        valueControl.attr("disabled", true);
                    else
                        valueControl.attr("readonly", true);
                    valueControl.attr("required", false);
                    validator.validateInput(valueControl);
                }
                else {
                    var control = kendo.widgetInstance(valueControl);
                    if (!!control) {
                        control.enable(true)
                    }
                    else if (valueControl.attr("type") == "checkbox")
                        valueControl.removeAttr("disabled");
                    else
                        valueControl.removeAttr("readonly");
                    var col = field.dataSource.at(dataItem.field);

                    if (!!col && col.ValueType.Key != "Boolean")
                        valueControl.attr("required", true);

                }
                if (!!column && !kendo.widgetInstance(valueControl))
                    initValueField(column, valueControl, dataItem);
            })
            if (dataItem.field > -1) {
                field.select(dataItem.field);
                column = viewModel.cols.at(dataItem.field);
            }
            else if (!dataItem.value && dataItem.grid_column_id < 0) {
                field.value(null);
            }
            field.trigger("change");


            column = viewModel.cols.at(field.select());


            if (!dataItem.op && !dataItem.grid_filter_operator) {
                op.select(0);
            }
            else if (dataItem.grid_filter_operator)
                op.value(dataItem.grid_filter_operator);
            op.trigger("change");
        }

        //initControls($(wnd.windowHandler.element));
    }
    function initValueField(column, valueControl, dataItem) {

        if (column.ValueType.Key == "Number") {
            valueControl.removeClass("k-textbox");
            valueControl.attr("name", "grid_filter_value_float" + kendo.guid());
            valueControl.attr("filter-value", "grid_filter_value_float")
            var kendoControl = valueControl.kendoNumericTextBox().data("kendoNumericTextBox");
            kendoControl.value(dataItem.value);
        }
        else if (column.ValueType.Key == "Sting") {
            kendoControl.value(dataItem.value.toLowerCase());
        }
        else if (column.ValueType.Key == "Date") {
            valueControl.removeClass("k-textbox");
            valueControl.attr("name", "grid_filter_value_date" + kendo.guid());
            valueControl.attr("filter-value", "grid_filter_value_date")
            valueControl.attr("data-kendo-control", "kendoDatePicker");
            valueControl.val(dataItem.value);
            initControls(valueControl.parent());
        }
        else if (column.ValueType.Key == "Boolean") {
            var parent = valueControl.parent();
            valueControl.remove();
            var guid = kendo.guid();
            valueControl = $('<input type="checkbox" filter-value="grid_filter_value_bool"  id="value' + guid + '" name="grid_filter_value_bool' + guid + '" class="k-checkbox" ' + (dataItem.value == "True" ? 'checked="True"' : '') + '>' +
                                '<label for="value' + guid + '" class="k-checkbox-label"></label>');
            parent.append(valueControl);
        }
        else {
            if (!!column.GridFilterUrl && !!column.GridFilterValueField && !!column.GridFilterTextField && dataItem.grid_filter_operator == "eq") {
                valueControl.attr("data-external", "true");
                switch (column.GridFilterControlType) {
                    case 'Combobox':
                        valueControl.removeClass("k-textbox k-input");
                        valueControl.addClass("brc-combobox");
                        valueControl.attr("data-dataSource", window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl);
                        valueControl.attr("data-valueField", column.GridFilterValueField);
                        valueControl.attr("data-textField", column.GridFilterTextField);
                        valueControl.attr("data-kendo-control", "brc-combobox");
                        initControls(valueControl.parent());
                        break;
                    case 'Tree':
                        valueControl.removeClass("k-textbox k-input");
                        var dataSource = {
                            //data: JSON.parse(_context.find("#okofData").val())
                            odata: {
                                url: window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl
                            }
                        };

                        var dialog = new BrcDialogSelect(valueControl, dataSource).init({
                            multiple: false,
                            usingTextField: true,
                            dataTextField: column.GridFilterTextField,
                            dataValueField: column.GridFilterValueField,
                            dataParentValueField: column.GridFilterParentValueField,
                            hasChildren: "HasChildren",
                            height: "600px"
                        });
                        break;
                    case 'Multi':
                        valueControl.removeClass("k-textbox k-input");
                        valueControl.addClass("brc-combobox");
                        valueControl.attr("data-dataSource", window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl);
                        valueControl.attr("data-valueField", column.GridFilterValueField);
                        valueControl.attr("data-textField", column.GridFilterTextField);
                        valueControl.attr("data-title", column.DisplayName);
                        valueControl.attr("data-mode", "mixed");
                        valueControl.attr("data-kendo-control", "kendoMultiBox");
                        initControls(valueControl.parent());
                        break;
                    case 'Modal':
                        valueControl.removeClass("k-textbox k-input");
                        valueControl.addClass("brc-combobox");
                        valueControl.attr("data-dataSource", window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl);
                        valueControl.attr("data-valueField", column.GridFilterValueField);
                        valueControl.attr("data-textField", column.GridFilterTextField);
                        valueControl.attr("data-title", column.DisplayName)
                        valueControl.attr("data-mode", "modal");
                        valueControl.attr("data-kendo-control", "kendoMultiBox");
                        initControls(valueControl.parent());
                        break
                }
            }
        }

    }
    return button;
}

BrcGrid.prototype.columnVisibility = function () {
    var self = this;
    //var _toolbar = toolbar;
    var _popup;
    var _columns = [];

    var visibilityLine = '<div class="brc-grid-visibility__popup-row"><input type="checkbox" class="k-checkbox" data-bind="checked: visibility,attr:{id: guid},events:{change:change}" /><label class="k-checkbox-label" data-bind="attr:{for:guid}"><span style="font-weight: 400 !important" data-bind="text:displayName"></span></label></div>';
    var popupTemplate = '<div></div>';
    var button = _extendToolbar();
    _initPopup();
    self.config.Columns.forEach(function (element, index) {

        _columns.push(new kendo.observable({
            name: element.EntityProperty,
            displayName: element.DisplayName,
            visibility: true,
            guid: kendo.guid(),
            change: function (e) {
                if (this.visibility)
                    self.kendoGrid.showColumn(this.name);
                else
                    self.kendoGrid.hideColumn(this.name);
            }
        }));

        var content = $(_popup.element).find("#visibilityContainer");
        var control = $(visibilityLine);
        kendo.bind(control, _columns[index]);
        content.append(control);
    })

    function _initPopup() {
        _popup = $(popupTemplate).kendoWindow({
            title: "Видимость колонок",
            width: "500px",
            height: "400px",


        }).data("kendoWindow").center();
        _popup.content('<div class="brc-window-layout"><div class="brc-window-layout__work-area"><div id="visibilityContainer" class="brc-grid-visibility__popup"></div></div></div>');
    }

    function _showPopup() {
        _popup.open();
    }
    function _extendToolbar() {
        var button = {
            type: "button",
            text: "Видимость колонок",
            //showText: "overflow",
            //showIcon: "toolbar",
            //template: '<a class="k-button k-button-icon k-toolbar-last-visible" href=""><i class="fa fa-eye"></i></a>',
            //overflowTemplate: '<a class="k-button k-button-icon k-toolbar-last-visible" href="">Видимость колонок</a>',
            //icon: "preview",
            id: "columnVisibility",
            click: function (e) {
                _showPopup();
            }
        }
        return button;
    }
    return button;
}
BrcGrid.prototype.deleteFilterById = function (id) {
    var self = this;
    var f = self.kendoGrid.dataSource.filter();
    if (!!f) {
        for (var i = 0; i < f.filters.length; i++) {
            if (f.filters[i].id == id)
                f.filters.splice(i, 1);
        }
    }
    return f;
}
BrcGrid.prototype.replaceFilter = function (filters) {
    var self = this;
    var filter = self.kendoGrid.dataSource.filter();
    if (!!filter) {
        for (var i = 0; i < filter.filters.length; i++) {
            if (filter.filters[i].id == filters.id) {
                filter.filters[i] = filters;
                self.kendoGrid.dataSource.filter(filter);
                return;
            }
        }
        filter.filters = filter.filters.concat(filters);
        self.kendoGrid.dataSource.filter(filter);
    }
    else {
        self.kendoGrid.dataSource.filter(filters);
    }
}

BrcGrid.prototype.findFilterById = function (filters, id) {
    var self = this;
    var filter;
    for (var i = 0; i < filters.length; i++) {
        if (!!filters[i].filters && filters[i].id != id) {
            filter = self.findFilterById(filters[i].filters, id);
        }
        else if (filters[i].id == id)
            filter = filters[i];
    }
    return filter;
}
function htmlDecode(value) {
    if (value != null) {
        return value.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
    }
    else {
        return "";
    }
}