var WindowManager = function () {
    var windows = [];
    var messages = {
        windowNotFound: "Не удалось найти к окно с указанным идентификатором!"
    }
    var defaultOptions = {
        visible: true,
        width: 800,
        height: 'auto',
        title: "",
        modal: false,
        position: {
            top: 10
        },
        async: undefined,
        onbound: undefined,
        onrefresh: undefined,
        onclose: defaultClose,
        onsubmit: undefined,
        content: undefined,
        openImmidiate: false,
        id: undefined,
        modal:false
    };
    function defaultClose(e) {
        deleteWindowByUid(e.sender.wrapper.attr('data-uid'), true);
    }
    function getWindowByUid(uid) {
        for (var i = 0; i < windows.length; i++) {
            if (windows[i].uid === uid) {
                return windows[i];
            }
        }
        return null;
    }
    function getWindowById(id) {
        for (var i = 0; i < windows.length; i++) {
            if (windows[i].options.id === id) {
                return windows[i];
            }
        }
        return null;
    }
    function deleteWindowByUid(uid, destroy) {
        for (var i = 0; i < windows.length; i++) {
            if (windows[i].uid === uid) {
                if (destroy) {
                    windows[i].Destroy();
                    windows.splice(i, 1);
                    return;
                } else {
                    windows[i].windowHandler.unbind("close");
                    windows[i].windowHandler.close();
                    windows[i].windowHandler.bind("close", windows[i].options.onclose);
                    return;
                }
            }
        }
        return null;
    }
    function getCurrentWindow() {
        for (var i = 0; i < windows.length; i++) {
            if (windows[i].windowHandler.wrapper.is(".k-state-focused")) {
                return windows[i];
            }
        }
        return null;
    }
    return {
        addWindow: function (config) {
            var windowConfig = {};
            for (var option in defaultOptions) {
                windowConfig[option] = config && config[option] !== undefined ? config[option] : defaultOptions[option];
            }
            windowConfig.onrefresh = function (e) {
                config.onrefresh.call(this, e);
                brcWindow.windowHandler.center().open();
                BrcPreloader.unload($("body"));
            }
            
            var brcWindow = new BrcWindow($('<div/>')).init(windowConfig);
            windows.push(brcWindow);
            if (windowConfig.async) {
                brcWindow.windowHandler.bind("error", function (e) {
                    BrcPreloader.unload($("body"));
                })
                BrcPreloader.load($("body"),true);
                brcWindow.windowHandler.refresh(windowConfig.async);
            }
            else {
                brcWindow.windowHandler.content(windowConfig.content);
                brcWindow.windowHandler.center().open();
            }
            //if (windowConfig.openImmidiate) {
                //brcWindow.windowHandler.center().open();
            //}
            return brcWindow;
        },
        openWindow: function (window) {
            getWindowByUid(window.uid).windowHandler.open();
            return window;
        },
        openOrAddWindow: function (config) {
            var wnd = getWindowById(config.id);
            if (wnd) {
                return WindowManager.openWindow(wnd);
            }
            else {
                return WindowManager.addWindow(config);
            }
        },
        closeWindow: function (destroy, window) {
            if (window === undefined) {
                var wnd = getCurrentWindow();
                deleteWindowByUid(wnd.uid, true);
            }
            else
                deleteWindowByUid(window.uid, destroy);
        },
        getByUid: function (uid) {
            return getWindowByUid(uid);
        },
        setWindowContent: function (window, content) {
            if (window === undefined)
                getCurrentWindow().windowHandler.content(content).refresh();
            else
                getWindowByUid(window.uid).windowHandler.content(content).refresh();
        },
        setWindowContentAsync: function (window, asyncContent) {
            if (window === undefined)
                getCurrentWindow().windowHandler.refresh(asyncContent);
            else
                getWindowByUid(window.uid).windowHandler.refresh(asyncContent);
        },
        getCurrentWindow: function () {
            for (var i = 0; i < windows.length; i++) {
                if (windows[i].windowHandler.wrapper.is(".k-state-focused")) {
                    return windows[i];
                }
            }
            return null;
        }
    }
}();
