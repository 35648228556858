$(document).ajaxError(function myErrorHandler(event, xhr, ajaxOptions, thrownError) {
    if (xhr.responseJSON && xhr.responseJSON.jsHandleError)
    {
        errorWnd(xhr.responseJSON.message);
        return;
    }
    //else if (xhr.responseJSON && !!xhr.responseJSON["odata.error"].message)
    //{
    //    errorWnd(xhr.responseJSON["odata.error"].message.value);
    //    return;
    //}

    function errorWnd(message){
        $("<div/>").kendoDialog({
            content: message,
            actions: [{
                text: "OK",
                action: function (e) {
                },
                primary: true
            }],
            buttonLayout: "normal"
        }).data("kendoDialog").open();
    }
});
