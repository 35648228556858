function PaymentModal() {
    var _paymentId;
    var _contractPayPlanId;
    var _onOKClose;
    var _window;
    var _context;

    return {
        init: init
    };

    function init(config) {
        _paymentId = config.paymentId;
        _contractPayPlanId = config.contractPayPlanId;
        _onOKClose = config.onOKClose;

        initWindow();
    }

    function initWindow(config) {
        var selector = "#paymentWindow" + _paymentId;

        var windowConfig = {
            title: _paymentId > 0 ? "Платеж" : "Новый платеж",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/PaymentModal",
                data: {
                    id: _paymentId,
                    contractPayPlanId: _contractPayPlanId,
                }
            },
            width: 700,
            height: 400,
            onrefresh: onRefreshWindow,
            id: selector
        }
        _window = WindowManager.openOrAddWindow(windowConfig);
       
    }    
    
    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        var validator = initControls(_context);

        if ((_context.find("#canUpdatePayment").val() === 'false' && _paymentId > 0) || (_context.find("#canCreatePayment").val() === 'false' && _paymentId == 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/SavePayment",
                    $(event.target).serializeArray(),
                    function (data) {
                        BrcNotification.success("Выполнено");
                        _onOKClose();
                        WindowManager.closeWindow(true, _window);
                    },
                    function (data) {
                        if (data.responseJSON && data.responseJSON.message) {
                            BrcNotification.error(data.responseJSON.message);
                        }
                        else {
                            BrcNotification.error("Операция не была выполнена!");
                        }
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _window);
        });
    }
}