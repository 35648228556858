function AssetCostGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;
    var _assetId;
    var _assetFixedId;
    var _mode;
    var _source;
    var _pnsId;
    var _knsId;
    var _waterHoleId;

    return {
        init: init,
        read: read
    };
    function read() {
        _grid.kendoGrid.dataSource.read();
        _grid.kendoGrid.resize();
    }
    function init(config) {
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);
        _assetId = config.assetId;
        _assetFixedId = config.assetFixedId;
        _mode = config.mode;
        _source = config.source;
        _pnsId = config.pnsId;
        _knsId = config.knsId;
        _waterHoleId = config.waterHoleId;
        initGrid();
        initToolbar();
    }

    function initGrid() {
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + (_source === "asset_fixed" ? "odata/ODataRealtyAssetCostOnAssetFixed" : _source === "ns" ? "odata/ODataAssetCostOnNsEquipment" : _source === "waterhole" ? "odata/ODataAssetCostOnWaterHole" : "odata/ODataRealtyAssetCost"),
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportAssetCostGrid?assetId=" + _assetId,
            filter: _assetFixedId > 0 ? { field: "asset_fixed_id", operator: "eq", value: parseInt(_assetFixedId) } : _pnsId > 0 ? { field: "pns_id", operator: "eq", value: parseInt(_pnsId) } : _knsId > 0 ? { field: "kns_id", operator: "eq", value: parseInt(_knsId) } : _waterHoleId > 0 ? { field: "water_hole_id", operator: "eq", value: parseInt(_waterHoleId) } : { field: "asset_id", operator: "eq", value: parseInt(_assetId) }
        };
        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());

        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);
    }
    
    function initToolbar() {

        var items = [];

        if (_mode !== "readonly") {
            items.push(
            {
                type: "button",
                text: "Создать",
                click: onCreate,
                hidden: !hasPermission("canCreateAssetCost")
            },
            {
                type: "button",
                text: "Удалить",
                click: onDelete,
                hidden: !hasPermission("canDeleteAssetCost")
            },
            { type: "separator" }
        );
        }
        

        items.push({
            type: "splitButton",
            text: "Таблица",
            menuButtons: [
                _brcGrid.filters(),
                _brcGrid.columnVisibility(),
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ],
        });

        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");        
    }

    function onCreate() {
        var assetCostModal = new AssetCostModal();
        assetCostModal.init({
            assetCostId: 0,
            assetId: _assetId,
            assetFixedId: _assetFixedId,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
            }
        });
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите стоимость");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetCost/DeleteAssetCostList",
                    { assetCostIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="asset_cost"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var assetCostModal = new AssetCostModal();
            assetCostModal.init({
                assetCostId: this.getAttribute("number"),
                assetId: _assetId,
                mode: _mode === "readonly" ? "readonly" : "editable",
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
};