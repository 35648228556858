function SubjectGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;

    var _tree;
    var _toolbar;
    return {
        init: init
    };

    function init(config) {
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);

        initGrid();
        _toolbar = initToolbar(config.isTree);
        if (config.isTree)
            _tree = _initTree($("#splitter"));

        
        //_toolbar.add({
        //    type: "splitButton",
        //    text: "ФНС",
        //    overflow:"never",
        //    menuButtons: [
        //        { type: "Button", text: "Информация с ФНС", click: function (e) { } },
        //        { type: "Button", text: "Перенести данные", click: function (e) { } },
        //    ],
        //});
        //_toolbar.add({ type: "separator" });
        _toolbar.add({
            type: "brcButtonList",
            text: "1С",
            list: [
                {
                    text: "Просмотреть данные",
                    click: show1C
                },
                {
                    text: "Синхронизировать",
                    click: sync1C,
                    hidden: !hasPermission("canUpdateSubject")
                }]
        });

        _toolbar.add({
            type: "brcButtonList",
            text: "Таблица",            
            list:[ //список кнопок
                _brcGrid.filters(),//стандартная кнопка 
                _brcGrid.columnVisibility(),//стандартная кнопка
                { text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }            
              ]

        })
    }

    function initGrid() {
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGrid",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportSubjectGrid",
        };

        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());

        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);

    }
    function _initTree(selector) {

        return $(selector).kendoGroupedGrid({
            tableViewLink: window["APPLICATION_CONFIG"].routes.root + "Estate/Subject?isTree=false",
            brcListConfig: {
                title: " ",
                isTree: true,
                parentKey: "parent_group_subject_group_id",
                key: "subject_group_id",
                textField: "subject_group_name",
                path: "Субъекты: ",
                pathSelector: $(".brc-card-title")[0],
                confirmation: true,
                virtualNode: {
                    all: {
                        dropTarget: true,

                    },
                    ungrouped: {
                        dropTarget: true
                    }
                },
                dataSource: {
                    type: "odata",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGroup",
                    dataType: "json"
                },
                create: function (e) {
                    var entity = e;
                    // entity.equip_type_cr_date = kendo.toString(new Date(), "dd.MM.yyyy HH:mm:ss");
                    // entity.equip_type_is_visible = true;
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/SubjectGroup/CreateSubjectGroup", entity,
                    function (e) {
                        BrcNotification.success("Выполнено")
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON)
                    });
                },
                destroy: function (e) {
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/SubjectGroup/DeleteSubjectGroup", e,
                    function (e) {
                        BrcNotification.success("Выполнено")
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON)
                    });
                },
                update: function (e) {
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/SubjectGroup/UpdateSubjectGroup", e,
                    function (e) {
                        BrcNotification.success("Выполнено")
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON)
                    });

                },
            },
            brcGridConfig: {
                externalInit: {
                    grid: _grid,
                    toolbar: _toolbar
                },
                manyToMany: {
                    alterUrl: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGroupedGrid",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGrid",
                    excelUrl: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportSubjectGrid",
                    excelAlterUrl: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportSubjectGroupedGrid",
                },
                changeGroup: function (items, newGroup) {
                    var itemsIds = [];
                    var oldGroup = items[0].subject_group_id;
                    items.forEach(function (elem) {
                        itemsIds.push(elem.subj_id);
                    })
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Subject/ChangeSubjectGroup", { subjectIds: itemsIds, oldGroup: oldGroup, newGroup: newGroup },
                       function (d) {
                           BrcNotification.success("Выполнено")
                       }, function (d) {
                           BrcNotification.error(d.responseJSON);
                       });
                },
                addGroup: function (items, newGroup) {
                    var itemsIds = [];
                    items.forEach(function (elem) {
                        itemsIds.push(elem.subj_id);
                    })
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Subject/ChangeSubjectGroup", { subjectIds: itemsIds, newGroup: newGroup },
                       function (d) {
                           BrcNotification.success("Выполнено")
                       }, function (d) {
                           BrcNotification.error(d.responseJSON);
                       });
                },
                deleteFromGroups: function (items) {
                    var itemsIds = [];
                    items.forEach(function (elem) {
                        itemsIds.push(elem.subj_id);
                    })
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Subject/DeleteSubjectGroups", { subjectIds: itemsIds },
                       function (d) {
                           BrcNotification.success("Выполнено")
                       }, function (d) {
                           BrcNotification.error(d.responseJSON);
                       });
                },
                filters: !!_grid.kendoGrid.dataSource.filter() ? _grid.kendoGrid.dataSource.filter().filters : []

            },
            permissions: {
                canChangeGroup: hasPermission("canChangeGroup"),
                canDeleteGroup: hasPermission("canDeleteGroup"),
                canCreateGroup: hasPermission("canCreateGroup"),
                canChangeObject: hasPermission("canUpdateSubject"),

            }
        }).data("kendoGroupedGrid");
    }
    function initToolbar(isTree) {
        var items = [];
        items.push({
            type: "button",
            text: "Создать ЮЛ",
            click: onCreateUl,
            hidden: !hasPermission("canCreateSubject")
        },
        {
            type: "button",
            text: "Создать ФЛ",
            click: onCreateFl,
            hidden: !hasPermission("canCreateSubject")
        },
        {
            type: "button",
            text: "Удалить",
            click: onDelete,
            hidden: !hasPermission("canDeleteSubject")
        });


        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");

        toolbar.add(
            BrcReports.init(_brcGrid.config.EntityName, function (requestParameters) {
                var keys = _brcGrid.getSelectedKeys();
                if (keys.length < 1) {
                    $("<div/>").kendoAlert({
                        messages: {
                            okText: "OK"
                        },
                        buttonLayout: "normal",
                        content: "Не выбраны субъекты",
                        modal: true,
                        title: "Ошибка",
                        width: "300px",
                        close: function (e) {
                            e.sender.destroy();
                        },
                        actions: [{
                            text: "OK",
                            primary: true
                        }]
                    }).data("kendoAlert").open();
                    //  BrcNotification.info("Не выбраны объекты");
                    return false;
                }
                var data = [];
                data.push({ name: "subj_id", value: keys[0] });

                //keys.forEach(function (k) {
                //    requestParameters.forEach(function (p) {
                //        data.push({ name: p.name, value: _brcGrid.kendoGrid.dataSource.get(k)[p.name] });
                //    })

                //})

                return data;
            })
            );

        if (!isTree) {

            toolbar.add({
                type: "button",
                text: '<i class="fa fa-sitemap" title="Дерево"></i>',
                id: "display",
                click: function (e) {
                    //_initTree($("#splitter"));
                    //self.toolbar.hide("#display");
                    location.href = window["APPLICATION_CONFIG"].routes.root + "Estate/Subject?isTree=true";
                }
            })
        }
        return toolbar;
    }

    function onCreate(isPerson) {
        var subjectModal = new SubjectModal();
        if (!!_tree)
            var groupId = _tree.getSelectedKey();
        subjectModal.init({
            subjectId: 0,
            isPerson: isPerson,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
            },
            groupId: groupId
        });
    }
    function onCreateUl() {
        onCreate("False");
    }
    function onCreateFl() {
        onCreate("True");
    }
    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите субъект");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/DeleteSubjectList",
                    { subjectIds: selectedIds },
                    function (data) {
                        if (data.type == "error") {
                            BrcNotification.error(data.message);
                        }
                        else if (data.type == "warning") {
                            BrcNotification.warning(data.message);
                        }
                        else if (data.type == "success") {
                            BrcNotification.success(data.message);
                        }
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="subject"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var subjectModal = new SubjectModal();
            subjectModal.init({
                subjectId: this.getAttribute("number"),
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function show1C() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите субъект");
            return false;
        }

        var subject1CModal = new Subject1cModal();
        subject1CModal.init({
            subjectId: selectedIds[0],
            onOKClose: function () { _grid.kendoGrid.dataSource.read(); }
        });
    }
    function sync1C() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите субъектов");
            return false;
        }

        BrcConfirm.show("Подтверждение синхронизации", "Синхронизировать выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/SyncList1C",
                    { subjIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
};