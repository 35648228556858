var ReadonlyHelper = function (selector, isReadonly) {
    $(selector).find("input,textarea,select, [data-role=itemview]").each(function (index, element) {
    if (element.getAttribute("readonly") == "nocheck")
    return;
        var widget = kendo.widgetInstance($(element));
        if (widget) {
            if ($(widget.wrapper).is("table.k-editor")) {
                $(widget.body).attr("contenteditable", !isReadonly)
            }
            else {
                widget.enable(!isReadonly);
            }
        }
        $(element).prop("readonly", isReadonly);

        if (isReadonly) {
            $(element).addClass("k-state-disabled");
        }
        else {
            $(element).removeClass("k-state-disabled");
        }
    });

    $(selector).find(":checkbox,select").each(function (index, element) {
        if (isReadonly) {
            $(element).attr("disabled", "disabled");
        }
        else {
            $(element).removeAttr("disabled");
        }
    });

    $(selector).find("div[data-role=metaButtonGroup] .k-button").each(function (index, element) {
        if (isReadonly) {
            $(element).addClass("k-state-disabled");
        }
        else {
            $(element).removeClass("k-state-disabled");
        }
    });
}