var SubjectModal = function () {
    var _onOKClose;
    var _subjectId;
    var _objRightGrid;
    var _encumbranceGrid;
    var _assetContractGrid;
    var _subjectCard;
    var _context;
    var _needObjRightGridInit = true;
    var _needEncumbranceGridInit = true;
    var _needAssetContractGridInit = true;
    var self = this;
    var _subjectGroup = null;
    var _isPerson;
    return {
        init: init
    };

    function init(config) {
        _onOKClose = config.onOKClose;
        _subjectId = config.subjectId;
        _isPerson = config.isPerson;
        var selector = "#subjectWindow" + config.subjectId;
        var windowConfig = {
            title: config.subjectId > 0 ? "Субъект" : "Новый субъект",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/SubjectCard",
                data: {
                    id: config.subjectId,
                    isPerson: _isPerson,
                    subjGroup:config.groupId
                }
            },
            width: 900,
            height: 800,
            onrefresh: onRefreshWindow,
            id: selector
        }
            
        _subjectCard = WindowManager.openOrAddWindow(windowConfig);
        //$(self).attr("data-card-uid", _subjectCard.uid);
    }


    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);
        if (_subjectId > 0) {
            if (_context.find("#subj_is_person").val() == "True"){
                _context.parent().find(".k-window-title").html("Физическое лицо: " + _context.find("#subj_fio_surname").val() + " " + _context.find("#subj_fio_name").val() + " " + _context.find("#subj_fio_secondname").val());
            }
            else{
                _context.parent().find(".k-window-title").html("Юридическое лицо: " + _context.find("#subj_name").val());
            }
            
        }
        else {
            _context.parent().find(".k-window-title").html(_isPerson == "True" ? "Новое физическое лицо":"Новое юридическое лицо");
        }
        var validator = initControls(_context);
        BrcMetaModule.init(_context.find("#subjectAdditionallyBlock")[0]);
        var dataSourceSubjGroups = {
            data: JSON.parse(_context.find("#subjGroups").val())
        };

        var groupTreeView = new BrcDialogSelect(_context.find("#subject_groups")[0], dataSourceSubjGroups).init({
            multiple: true,
            dataTextField: "name",
            dataValueField: "id",
            dataChildrenField: "items",
            height: "600px"
        });

        if ((_context.find("#canUpdateSubject").val() === 'false' && _subjectId > 0) || (_context.find("#canCreateSubject").val() === 'false' && _subjectId == 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }
        ReadonlyHelper(".readonly", true);

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/SaveSubject",
                    $(event.target).serializeArray(),
                    function (id) {
                        BrcNotification.success("Выполнено");
                        if (!_onOKClose.call(_subjectCard, id)) {
                            if (_subjectId == 0) {
                                WindowManager.closeWindow(true, _subjectCard);
                                init({
                                    subjectId: id,
                                    onOKClose: _onOKClose
                                });
                            }
                            else {
                                _onOKClose();
                                WindowManager.closeWindow(true, _subjectCard);
                            }
                        }
                    }, function () {
                        BrcNotification.error("Операция не была выполнена!");
                    })
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _subjectCard);
        });

        if (_subjectId > 0) {
            var tabStrip = _context.find("#tabstrip").data("kendoTabStrip");
            tabStrip.bind("select", onTabStripSelect);
        }
    }

    function onTabStripSelect(e) {
        switch (e.item.id) {
            case "objRights":
                if (_needObjRightGridInit) {
                    _needObjRightGridInit = false;

                    var objRightGrid = new ObjRightGrid();
                    objRightGrid.init({
                        selector: _context.find("#objRightGrid"),
                        toolbarSelector: _context.find("#objRightToolbar"),
                        subjId: _subjectId,
                        mode: "subject"
                    });
                }
                break;
            case "encumbrances":
                if (_needEncumbranceGridInit) {
                    _needEncumbranceGridInit = false;

                    var encumbranceGrid = new EncumbranceGrid();
                    encumbranceGrid.init({
                        selector: _context.find("#encumbranceGrid"),
                        toolbarSelector: _context.find("#encumbranceToolbar"),
                        subjId: _subjectId,
                        mode: "subject"
                    });
                }
                break;
            case "assetContracts":
                if (_needAssetContractGridInit) {
                    _needAssetContractGridInit = false;

                    var assetContractGrid = new AssetContractGrid();
                    assetContractGrid.init({
                        selector: _context.find("#assetContractGrid"),
                        toolbarSelector: _context.find("#assetContractToolbar"),
                        subjId: _subjectId,
                        mode: "subject",
                        isModal :true
                    });
                }
                break;
            case "documents":
                {
                    var selector = $(e.contentElement).find("#documentGridWrapper");
                    if (!kendo.widgetInstance($(selector).find(".brc-grid"))) {
                        var guid = kendo.guid();
                        selector.attr("guid", guid);

                        var documents = new DocumentGrid(selector, { entityName: "subj_id", entityId: _subjectId });
                        documents.init();
                    }
                    break;
                }
            case "files":
                {
                    if (!kendo.widgetInstance($(e.contentElement).find("#filesView"))) {
                        var itemView = $(e.contentElement).find("#filesView").kendoItemView({
                            files: true,
                            windowStyle: true,
                            title: "",
                            toolbar: $(e.contentElement).find("#filesToolbar"),
                            dataSource: {
                                filter: { field: "subj_id", operator: "eq", value: parseInt(_subjectId) }

                            },
                            upload: {
                                upload: {
                                    url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                                    data: function () {
                                        return {
                                            subj_id: _subjectId, file_relation_id: 0, doc_file_id: 0
                                        }
                                    },

                                },
                                destroy: {
                                    url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                                    data: function (dataItems) {
                                        var self = this;
                                        var ids = [];
                                        dataItems.forEach(function (element) {
                                            ids.push(element.doc_file_id);
                                        })

                                        return {
                                            ids: ids,
                                            relation: { subj_id: _subjectId, file_relation_id: 0, doc_file_id: 0 }
                                        }
                                    },

                                },
                                copy: {
                                    url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                                    data: function (opt) {
                                        var self = this;
                                        var ids = [];
                                        opt.dataItems.forEach(function (element) {
                                            ids.push(element.doc_file_id);
                                        })
                                        return {
                                            relation: { subj_id: _subjectId, file_relation_id: 0, doc_file_id: 0 },
                                            ids: ids
                                        }
                                    }
                                },
                                move: {
                                    url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                                    data: function (opt) {
                                        var self = this;
                                        var ids = [];
                                        opt.dataItems.forEach(function (element) {
                                            ids.push(element.doc_file_id);
                                        })
                                        return {
                                            relation: { subj_id: _subjectId, file_relation_id: 0, doc_file_id: 0 },
                                            old_relation: opt.moveFrom.options.relation,
                                            ids: ids
                                        }
                                    }
                                },
                                check: {
                                    url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                                    data: function (dataItems) {
                                        var self = this;
                                        var names = [];
                                        dataItems.forEach(function (item) {
                                            names.push(item.doc_file_name);
                                        })
                                        return {
                                            relation: { subj_id: _subjectId, file_relation_id: 0, doc_file_id: 0 },
                                            fileNames: names
                                        }
                                    },
                                    reject: function (error, dataItems) {
                                        for (var i = 0; i < dataItems.length; i++) {
                                            if (dataItems[i].doc_file_name == error.fileName) {
                                                dataItems.splice(i, 1);
                                                break;
                                            }
                                        }
                                        return dataItems;
                                    }
                                },

                            }
                        }).data("kendoItemView");
                        itemView.filesToolbar({ subj_id: _subjectId, file_relation_id: 0, doc_file_id: 0 });
                    }
                }
        }
    }

};