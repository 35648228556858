var GeneralSelectModal = function () {
    var _onOKClose;
    var _grid2;
    var _context;
    var _selector;
    var _gridSettingsName;
    var _controlId;
    var _modal;
    
    return {
        init: init
    };

    function init(config) {
        _context = config.context;
//        _grid = config.grid;
        _onOKClose = config.onOKClose;
        _selector = config.selector;
        _controlId = config.controlId;
        _gridSettingsName = config.gridSettingsName;
        _gridInit = config.initGrid;
        
        initWindow();
        
    }

    function initWindow(config) {
        var selector = _selector;
        var windowConfig = {
            id: selector,
            title: "Выберите запись",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/General/GeneralSelectModal",
                data:
                {
                    controlId: _controlId,
                    gridSettingsName: _gridSettingsName
                }
            },
            width: 1000,
            height: 800,
            onrefresh: onRefreshWindow
        }


        _modal = WindowManager.openOrAddWindow(windowConfig);
    }


    function onRefreshWindow(e) {
        _grid2 = _gridInit();

        console.debug("Grid GeneralSelectModal");
        console.debug(_grid2);
        _context = $(e.sender.element.context);
        var validator = initControls(_context);
        _context.find("#okButton").on("click", function (event) {
            event.preventDefault();
            var selectedIds = _grid2.getSelectedKeys();
            if (!_onOKClose(selectedIds))
                WindowManager.closeWindow(true, _modal);
        });
        _context.find("#cancelButton").on("click", function(event) {
             event.preventDefault();
             WindowManager.closeWindow(true, _modal);
        });
        

        }
    }