var NetworkModal = function () {
    var _assetId;
    var _assetFixedId;
    var _assetComplexId;
    var _onOKClose;
    var _networkCard;
    var _context;
    var _connectManager;
    var _needAssetFixedGridInit = true;
    var _needAssetCostGridInit = true;

    var _needNetworkEdgeGridInit = true;
    var _networkEdgeGrid;
    var _needNetworkVertexConstructionGridInit = true;
    var _networkVertexConstructionGrid;
    var _needNetworkArmatureGridInit = true;
    var _networkArmatureGrid;

    return {
        init: init
    };

    function init(config) {
        _assetId = config.assetId;
        _assetComplexId = config.assetComplexId;
        _onOKClose = config.onOKClose;

        initWindow();
    }

    function initWindow(config) {
        var selector = "#networkWindow" + _assetId;

        var windowConfig = {
            id: selector,
            title: _assetId > 0 ? "Сетевой ОН" : "Новый сетевой ОН",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/Network/NetworkCard",
                data: {
                    id: _assetId,
                    assetComplexId: _assetComplexId
                }
            },
            width: 1000,
            height: 800,
            onrefresh: onRefreshWindow
        }

        _networkCard = WindowManager.openOrAddWindow(windowConfig);
    }


    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);
        var form = _context.find("form");
        var validator = initControls(_context);

        BrcMetaModule.init(_context.find("#networkAdditionallyBlock")[0]);

        var addrConf = {
            dataValueField: "addr_id",
            dataTextField: "addr_print",
            idInput: form.find("#fias_address_addr"),
            helpInput: form.find("#fias_address_addr_js"),
            modeModal: "building",
            canCreate: true,
            selectModal: {
                title: "Выбор адреса"
            }
        }
        var addr = form.find("#fias_address_addr").kendoBrcAddressBox(addrConf);
        form.find("#fias_address_addr").bind("change", function () {
            if (!$(this).val()) {
                form.find("#fias_address_addr_js").val("");
                form.find("#fias_address_addr").attr("addr_id", "0");
            }

        });

        if ((_context.find("#canUpdateNetwork").val() === 'false' && _assetId > 0) || (_context.find("#canCreateNetwork").val() === 'false' && _assetId === 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        if (_assetId > 0) {
            initTabStrip();
        }


        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Network/SaveNetwork",
                    $(event.target).serializeArray(),
                    function (id) {
                        BrcNotification.success("Выполнено");
                        if (_assetId == 0) {
                            _onOKClose();
                            WindowManager.closeWindow(true, _networkCard);
                            init({
                                assetId: id,
                                onOKClose: _onOKClose
                            });
                        }
                        else {
                            _onOKClose();
                            WindowManager.closeWindow(true, _networkCard);
                        }
                    },
                    function (data) {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _networkCard);
        })
    }

    function initTabStrip() {
        var tabStrip = _context.find("#tabstrip").data("kendoTabStrip");
        tabStrip.bind("select", onSelect);

        function onSelect(e) {
            if (e.item.id === "documents") {
                var selector = $(e.contentElement).find("#documentGridWrapper");
                if (!kendo.widgetInstance($(selector).find(".brc-grid"))) {
                    var guid = kendo.guid();
                    selector.attr("guid", guid);

                    var documents = new DocumentGrid(selector, { entityName: "asset_id", entityId: _assetId });
                    documents.init();
                }
            }
            else if (e.item.id === "files") {
                if (!kendo.widgetInstance($(e.contentElement).find("#filesView"))) {
                    var itemView = $(e.contentElement).find("#filesView").kendoItemView({
                        files: true,
                        windowStyle: true,
                        title: "",
                        toolbar: $(e.contentElement).find("#filesToolbar"),
                        dataSource: {
                            filter: { field: "asset_id", operator: "eq", value: parseInt(_assetId) }

                        },
                        upload: {
                            upload: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                                data: function () {
                                    return {
                                        asset_id: _assetId, file_relation_id: 0, doc_file_id: 0
                                    }
                                },

                            },
                            destroy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                                data: function (dataItems) {
                                    var self = this;
                                    var ids = [];
                                    dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })

                                    return {
                                        ids: ids,
                                        relation: { asset_id: _assetId, file_relation_id: 0, doc_file_id: 0 }
                                    }
                                },

                            },
                            copy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { asset_id: _assetId, file_relation_id: 0, doc_file_id: 0 },
                                        ids: ids
                                    }
                                }
                            },
                            move: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { asset_id: _assetId, file_relation_id: 0, doc_file_id: 0 },
                                        old_relation: opt.moveFrom.options.relation,
                                        ids: ids
                                    }
                                }
                            },
                            check: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                                data: function (dataItems) {
                                    var self = this;
                                    var names = [];
                                    dataItems.forEach(function (item) {
                                        names.push(item.doc_file_name);
                                    })
                                    return {
                                        relation: { asset_id: _assetId, file_relation_id: 0, doc_file_id: 0 },
                                        fileNames: names
                                    }
                                },
                                reject: function (error, dataItems) {
                                    for (var i = 0; i < dataItems.length; i++) {
                                        if (dataItems[i].doc_file_name == error.fileName) {
                                            dataItems.splice(i, 1);
                                            break;
                                        }
                                    }
                                    return dataItems;
                                }
                            },

                        }
                    }).data("kendoItemView");
                    itemView.filesToolbar({ asset_id: _assetId, file_relation_id: 0, doc_file_id: 0 });
                }
            }
            else if (e.item.id === "asset_fixed") {
                if (_needAssetFixedGridInit) {
                    _needAssetFixedGridInit = false;
                    var assetFixedGrid = new AssetFixedGrid();
                    assetFixedGrid.init({
                        selector: "#assetFixedGrid",
                        toolbarSelector: "#assetFixedToolbar",
                        assetId: _assetId
                    });
                }
            }
            else if (e.item.id == "asset_cost") {
                if (_needAssetCostGridInit) {
                    _needAssetCostGridInit = false;
                    var assetCostGrid = new AssetCostGrid();
                    assetCostGrid.init({
                        selector: "#assetCostGrid",
                        toolbarSelector: "#assetCostToolbar",
                        assetId: _assetId,
                        mode: "readonly",
                        source: "asset_fixed"
                    });
                }
            }
            else if (e.item.id === "edge") {
                if (_needNetworkEdgeGridInit) {
                    _needNetworkEdgeGridInit = false;

                    _networkEdgeGrid = new NetworkEdgeGrid();
                    _networkEdgeGrid.init({
                        selector: _context.find("#networkEdgeGrid"),
                        toolbarSelector: _context.find("#networkEdgeToolbar"),
                        objId: _assetId,
                        objField: "network_id",
                        mode: "network"
                    });
                } else {
                    _networkEdgeGrid.read();
                }
            }
            else if (e.item.id === "construction") {
                if (_needNetworkVertexConstructionGridInit) {
                    _needNetworkVertexConstructionGridInit = false;

                    _networkVertexConstructionGrid = new NetworkVertexConstructionGrid();
                    _networkVertexConstructionGrid.init({
                        selector: _context.find("#networkVertexConstructionGrid"),
                        toolbarSelector: _context.find("#networkVertexConstructionToolbar"),
                        objId: _assetId,
                        objField: "network_id",
                        mode: "network"
                    });
                } else {
                    _networkVertexConstructionGrid.read();
                }
            }
            else if (e.item.id === "armature") {
                if (_needNetworkArmatureGridInit) {
                    _needNetworkArmatureGridInit = false;

                    _networkArmatureGrid = new NetworkArmatureGrid();
                    _networkArmatureGrid.init({
                        selector: _context.find("#networkArmatureGrid"),
                        toolbarSelector: _context.find("#networkArmatureToolbar"),
                        objId: _assetId,
                        objField: "network_id",
                        mode: "network"
                    });
                } else {
                    _networkArmatureGrid.read();
                }
            }
        }
    }

    function moveToMap(){
        
    }

    function connectNetworkToAssetFixed() {
        _assetFixedSelectModal = new AssetFixedSelectModal();
        _assetFixedSelectModal.init({
            onSelect: function (assetFixedId) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Network/AddAssetFixedToNetwork",
                    { assetId: _assetId, assetFixedId: assetFixedId },
                    function () {
                        BrcNotification.success("Выполнено");
                        _onOKClose();
                        WindowManager.closeWindow(true, _networkCard);
                        init({
                            assetId: _assetId,
                            onOKClose: _onOKClose
                        });
                    },
                    function () {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
            }
        });
        _assetFixedSelectModal.open();
    }

    function disconnectNetworkToAssetFixed() {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Network/DeleteAssetFixedFromNetwork",
                    { assetId: _assetId },
                    function () {
                        BrcNotification.success("Выполнено");
                        _onOKClose();
                        WindowManager.closeWindow(true, _networkCard);
                        init({
                            assetId: _assetId,
                            onOKClose: _onOKClose
                        });
                    },
                    function () {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
    }
};