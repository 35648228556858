var AssetFixedModal = function () {
    var _assetFixedId;
    var _assetId;
    var _onOKClose;
    var _assetFixedCard;
    var _context;
    var _mode;
    var _needAssetCostGridInit = true;

    return {
        init: init
    };

    function init(config) {
        _assetFixedId = config.assetFixedId;
        _assetId = config.assetId;
        _assetFixedId = config.assetFixedId;
        _onOKClose = config.onOKClose;
        //_mode = config.mode;
        _mode = "readonly"

        initWindow();
    }

    function initWindow(config) {
        var selector = "#assetFixedWindow" + _assetFixedId;


        var windowConfig = {
            id: selector,
            title: _assetFixedId > 0 ? "Основное средство" : "Новое основное средство",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/AssetFixed/AssetFixedCard",
                data: {
                    id: _assetFixedId,
                    assetId: _assetId
                }
            },
            width: 700,
            height: 600,
            onrefresh: onRefreshWindow
        }

        _assetFixedCard = WindowManager.openOrAddWindow(windowConfig);
    }


    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        var validator = initControls(_context);

        BrcMetaModule.init(_context.find("#assetFixedAdditionallyBlock")[0]);

        if ((_mode === "readonly") || (_context.find("#canUpdateAssetFixed").val() === 'false' && _assetFixedId > 0) || (_context.find("#canCreateAssetFixed").val() === 'false' && _assetFixedId === 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        ReadonlyHelper(".readonly", true);

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetFixed/SaveAssetFixed",
                    $(event.target).serializeArray(),
                    function (data) {
                        BrcNotification.success("Выполнено");
                        _onOKClose();
                        WindowManager.closeWindow(true, _assetFixedCard);
                    },
                    function (data) {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _assetFixedCard);
        });

        _context.find("#dataFrom1CButton").click(function (event) {
            event.preventDefault();
            alert("Запрос к 1С")
        });

        if (_assetFixedId > 0) {
            initTabStrip();
        }
    }

    function initTabStrip() {
        var tabStrip = _context.find("#tabstrip").data("kendoTabStrip");
        tabStrip.bind("select", onSelect);

        function onSelect(e) {
            if (e.item.id === "asset_cost") {
                if (_needAssetCostGridInit) {
                    _needAssetCostGridInit = false;

                    var assetCostGrid = new AssetCostGrid();
                    assetCostGrid.init({
                        selector: "#assetFixedCostGrid",
                        toolbarSelector: "#assetFixedCostToolbar",
                        assetFixedId: _assetFixedId,
                        mode: "readonly"
                    });
                }
            }
        }
    }
};