var EncumbranceModal = function () {
    var _encumbranceId;
    var _assetId;
    var _rightId;
    var _onOKClose;
    var _encumbranceCard;
    var _context;

    return {
        init: init
    };

    function init(config) {
        _encumbranceId = config.encumbranceId;
        _assetId = config.assetId;
        _rightId = config.rightId;
        _onOKClose = config.onOKClose;

        initWindow();
    }

    function initWindow(config) {
        var selector = "#encumbranceWindow" + _encumbranceId;

        var windowConfig = {
            id: selector,
            title: _encumbranceId > 0 ? "Обременение" : "Новое обременение",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/Encumbrance/EncumbranceCard",
                data: {
                    id: _encumbranceId,
                    assetId: _assetId,
                    rightId: _rightId
                }
            },
            width: 900,
            height: 750,
            onrefresh: onRefreshWindow
        }

        _encumbranceCard = WindowManager.openOrAddWindow(windowConfig);
    }


    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        var validator = initControls(_context);
       
        BrcMetaModule.init(_context.find("#encumbranceAdditionallyBlock")[0]);

        BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/SubjectGridConfig", {},
          function (e) {
              subjGridConf = e;
          },
          function (e) {
              BrcNotification.error(e.responseJSON);
          }, false);
        var subjConf = {
            dataValueField: "subj_id",
            dataTextField: "subj_name",
            mode: "mixed",
            permission: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/subject/CheckPermission",
                rightObject: 25,
                rightType: 2
            },
            selectModal: {
                title: "Выбор субъекта",
                grid: {
                    gridConfig: subjGridConf,
                    openObjectSelector: "[data-toggle-window]",
                    openObjectCallback: function (id) {
                        var modal = new SubjectModal();
                        var config = {
                            subjectId: id,
                            onOKClose: function (e) {
                                return false;
                            }
                        }
                        modal.init(config);
                    }
                },
                search: {
                    searchPlaceholder: "Наименование, ИНН, ОГРН",
                    logic: "or",
                    searchFilters: [
                            {
                                field: "subj_name",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_sname",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_inn",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_ogrn",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            }
                    ]

                },
                createObjectCallback: function () {
                    var self = this;
                    var modal = new SubjectModal();
                    var config = {
                        subjectId: 0,
                        onOKClose: function (e) {
                            self.options.selectGrid.dataSource.filter({ field: self.options.dataValueField, operator: "eq", value: parseInt(e) });
                            WindowManager.closeWindow(true, this);
                            self.options.selectGrid.dataSource.read().then(function () {
                                var obj = self.options.selectGrid.dataSource.get(parseInt(e))
                                self.dataSource.pushCreate(obj);
                                self.select(self.dataSource.data().indexOf(self.dataSource.get(obj[self.options.dataValueField])))
                                $(self.options.input).parents("form").first().data("kendoValidator").validateInput($(self.options.input));
                                self.options.selectWindow.windowHandler.destroy();
                            });
                            return true;
                        }
                    }
                    modal.init(config);
                }

            }
        }
        var cs = _context.find("#subj_id").kendoBrcMultiBox(subjConf);

       if ((_context.find("#canUpdateEncumbrance").val() === 'false' && _encumbranceId > 0) || (_context.find("#canCreateEncumbrance").val() === 'false' && _encumbranceId == 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        if (_encumbranceId > 0) {
            initTabStrip();
        }


        DateRangeHelper.init(_context.find("#encum_startdate"), _context.find("#encum_stopdate"));

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Encumbrance/SaveEncumbrance",
                    $(event.target).serializeArray(),
                    function (id) {
                        BrcNotification.success("Выполнено");
                        if (_encumbranceId == 0) {
                            _onOKClose();
                            WindowManager.closeWindow(true, _encumbranceCard);
                            init({
                                encumbranceId: id,
                                onOKClose: _onOKClose
                            });
                        }
                        else {
                            _onOKClose();
                            WindowManager.closeWindow(true, _encumbranceCard);
                        }

                    },
                    function (data) {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _encumbranceCard);
        });

        _context.find('[data-toggle-window="obj_right"]').click(onClickRight);

        function onClickRight(e) {
            e.preventDefault();

            var objRightModal = new ObjRightModal();
            objRightModal.init({
                objRightId: this.getAttribute("number"),
                assetId: _assetId,
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function initTabStrip() {
        var tabStrip = _context.find("#tabstrip").data("kendoTabStrip");
        tabStrip.bind("select", onSelect);

        function onSelect(e) {
            if (e.item.id == "documents") {
                var selector = $(e.contentElement).find("#documentGridWrapper");
                if (!kendo.widgetInstance($(selector).find(".brc-grid"))) {
                    var guid = kendo.guid();
                    selector.attr("guid", guid);

                    var documents = new DocumentGrid(selector, { entityName: "encum_id", entityId: _encumbranceId });
                    documents.init();
                }
            }
            if (e.item.id == "files") {
                if (!kendo.widgetInstance($(e.contentElement).find("#filesView"))) {
                    var itemView = $(e.contentElement).find("#filesView").kendoItemView({
                        files: true,
                        windowStyle: true,
                        title: "",
                        toolbar: $(e.contentElement).find("#filesToolbar"),
                        dataSource: {
                            filter: { field: "encum_id", operator: "eq", value: parseInt(_encumbranceId) }

                        },
                        upload: {
                            upload: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                                data: function () {
                                    return {
                                        encum_id: _encumbranceId, file_relation_id: 0, doc_file_id: 0
                                    }
                                },

                            },
                            destroy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                                data: function (dataItems) {
                                    var self = this;
                                    var ids = [];
                                    dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })

                                    return {
                                        ids: ids,
                                        relation: { encum_id: _encumbranceId, file_relation_id: 0, doc_file_id: 0 }
                                    }
                                },

                            },
                            copy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { encum_id: _encumbranceId, file_relation_id: 0, doc_file_id: 0 },
                                        ids: ids
                                    }
                                }
                            },
                            move: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { encum_id: _encumbranceId, file_relation_id: 0, doc_file_id: 0 },
                                        old_relation: opt.moveFrom.options.relation,
                                        ids: ids
                                    }
                                }
                            },
                            check: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                                data: function (dataItems) {
                                    var self = this;
                                    var names = [];
                                    dataItems.forEach(function (item) {
                                        names.push(item.doc_file_name);
                                    })
                                    return {
                                        relation: { encum_id: _encumbranceId, file_relation_id: 0, doc_file_id: 0 },
                                        fileNames: names
                                    }
                                },
                                reject: function (error, dataItems) {
                                    for (var i = 0; i < dataItems.length; i++) {
                                        if (dataItems[i].doc_file_name == error.fileName) {
                                            dataItems.splice(i, 1);
                                            break;
                                        }
                                    }
                                    return dataItems;
                                }
                            },

                        }
                    }).data("kendoItemView");
                    itemView.filesToolbar({ encum_id: _encumbranceId, file_relation_id: 0, doc_file_id: 0 });
                }
            }
        }
    }
};