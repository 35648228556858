var AssetContractModal = function () {
    var _onOKClose;
    var _contractAssetGrid;
    var _contractPayGrid;
    var _assetContractId;
    var _assetId;
    var _assetComplexId;
    var _needInitContractAssetsGrid = true;
    var _needInitContractPayGrid = true;
    var _needInitContractPayPlanGrid = true;
    var _parcelSelectModal;
    var _buildingSelectModal;
    var _flatSelectModal;
    var _equipmentSelectModal;
    var _contractPayCardModal;
    var _assetContractCard;
    var _context;
    var _contractPayPlanGrid;
    var _validator;

    return {
        init: init
    };

    function init(config) {
        _onOKClose = config.onOKClose;
        _assetContractId = config.assetContractId;
        _assetId = config.assetId;
        _assetComplexId = config.assetComplexId;

        var selector = "#assetContractWindow" + _assetContractId;

        var windowConfig = {
            id: selector,

            title: _assetContractId > 0 ? "Договор" : "Новый договор",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/AssetContractCard",
                data: {
                    id: _assetContractId,
                    assetId: _assetId,
                    assetComplexId: _assetComplexId,
                    contractGroup: config.groupId
                }
            },
            width: 900,
            height: 800,
            onrefresh: onRefreshWindow
        }

        _assetContractCard = WindowManager.openOrAddWindow(windowConfig);

    }

    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        _validator = initControls(_context);
        BrcMetaModule.init(_context.find("#assetContractAdditionallyBlock")[0]);

        var dataSourceContractGroups = {
            data: JSON.parse(_context.find("#contractGroups").val())
        };

        var groupTreeView = new BrcDialogSelect(_context.find("#contract_group")[0], dataSourceContractGroups).init({
            multiple: false,
            dataTextField: "name",
            dataValueField: "id",
            dataChildrenField: "items",
            height: "600px"
        });

        if (_assetContractId > 0) {
            var tabStrip = _context.find("#tabStrip").data("kendoTabStrip");
            tabStrip.bind("select", onTabStripSelect);
        }
        var subjGridConf;
        BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/SubjectGridConfig", {},
           function (e) {
               subjGridConf = e;
           },
           function (e) {
               BrcNotification.error(e.responseJSON);
           }, false);
        var subjConf = {
            dataValueField: "subj_id",
            dataTextField: "subj_sname",

            mode: "normal",
            permission: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/subject/CheckPermission",
                rightObject: 25,
                rightType: 2
            },
            isVirtual: true,
            virtualUrl: window["APPLICATION_CONFIG"].routes.root + "estate/subject/SubjectIndex",
            selectModal: {
                title: "Выбор субъекта",
                search: {
                    searchPlaceholder: "Наименование, ИНН, ОГРН",
                    logic: "or",
                    searchFilters: [
                            {
                                field: "subj_name",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_sname",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_inn",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_ogrn",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            }
                    ]

                },
                createObjectCallback: function () {
                    var self = this;
                    var modal = new SubjectModal();
                    var config = {
                        subjectId: 0,
                        onOKClose: function (e) {
                            self.options.selectGrid.dataSource.filter({ field: self.options.dataValueField, operator: "eq", value: parseInt(e) });
                            WindowManager.closeWindow(true, this);
                            self.options.selectGrid.dataSource.read().then(function () {
                                var obj = self.options.selectGrid.dataSource.get(parseInt(e))
                                self.dataSource.pushCreate(obj);
                                self.select(self.dataSource.data().indexOf(self.dataSource.get(obj[self.options.dataValueField])))
                                $(self.options.input).parents("form").first().data("kendoValidator").validateInput($(self.options.input));
                                self.options.selectWindow.windowHandler.destroy();
                            });
                            return true;
                        }
                    }
                    modal.init(config);
                },
                grid: {
                    gridConfig: subjGridConf,
                    openObjectSelector: "[data-toggle-window]",
                    openObjectCallback: function (id) {
                        var modal = new SubjectModal();
                        var config = {
                            subjectId: id,
                            onOKClose: function (e) {
                                return false;
                            }
                        }
                        modal.init(config);
                    }
                }
            }
        }
        var cs = _context.find("#customer_subj_id").kendoBrcMultiBox(subjConf);
        var es = _context.find("#executor_subj_id").kendoBrcMultiBox(subjConf);
        var ls = _context.find("#leasing_subj_id").kendoBrcMultiBox(subjConf);

        _context.find("#asset_contract_is_forever").bind("change", function () {
            var widget = kendo.widgetInstance(_context.find("#asset_contract_end_date"));
            if ($(this).prop("checked")) {
                _context.find("#asset_contract_end_date").val("");
                widget.enable(false);
                _context.find("#asset_contract_end_date").attr("disabled", "disabled");
                _context.find("#asset_contract_end_date").addClass("k-state-disabled");
            }
            else {
                widget.enable(true);
                _context.find("#asset_contract_end_date").removeAttr("disabled");
                _context.find("#asset_contract_end_date").removeClass("k-state-disabled");
            }
        });
        _context.find("#asset_contract_is_forever").trigger("change");

        DateRangeHelper.init(_context.find("#asset_contract_beg_date"), _context.find("#asset_contract_end_date"));

        if ((_context.find("#canUpdateAssetContract").val() === 'false' && _assetContractId > 0) || (_context.find("#canCreateAssetContract").val() === 'false' && _assetContractId === 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        ReadonlyHelper(".readonly", true);

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (_validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/SaveAssetContract",
                    $(event.target).serializeArray(),
                    function (id) {
                        BrcNotification.success("Выполнено");
                        if (_assetContractId == 0) {
                            _onOKClose();
                            WindowManager.closeWindow(true, _assetContractCard);
                            init({
                                assetContractId: id,
                                onOKClose: _onOKClose,
                                assetId: _assetId,
                                assetComplexId: _assetComplexId
                            });
                        }
                        else {
                            _onOKClose();
                            WindowManager.closeWindow(true, _assetContractCard);
                        }
                    },
                    function (data) {
                        if (data.responseJSON && data.responseJSON.message) {
                            BrcNotification.error(data.responseJSON.message);
                        }
                        else {
                            BrcNotification.error("Операция не была выполнена!");
                        }
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _assetContractCard);
        });

    }

    function onTabStripSelect(e) {
        if (e.item.id == "contractAssetsTab" && _needInitContractAssetsGrid) {
            _needInitContractAssetsGrid = false;

            initContractAssetGrid();
            initContractAssetGridToolbar();

            _parcelSelectModal = new AssetContractAssetSelectModal();
            _parcelSelectModal.init({
                assetContractId: _assetContractId,
                onSelect: function (assetIds) { addAssetListToAssetContract(assetIds) },
                assetType: "parcel"
            });

            _buildingSelectModal = new AssetContractAssetSelectModal();
            _buildingSelectModal.init({
                assetContractId: _assetContractId,
                onSelect: function (assetIds) { addAssetListToAssetContract(assetIds) },
                assetType: "building"
            });

            _flatSelectModal = new AssetContractAssetSelectModal();
            _flatSelectModal.init({
                assetContractId: _assetContractId,
                onSelect: function (assetIds) { addAssetListToAssetContract(assetIds) },
                assetType: "flat"
            });

            _equipmentSelectModal = new AssetContractAssetSelectModal();
            _equipmentSelectModal.init({
                assetContractId: _assetContractId,
                onSelect: function (assetIds) { addAssetListToAssetContract(assetIds) },
                assetType: "equipment"
            });
        }

        if (e.item.id == "contractPayTab" && _needInitContractPayGrid) {
            _needInitContractPayGrid = false;

            initContractPayPlanTab();

            var contractPayInlineCard = new ContractPayInlineCard();
            contractPayInlineCard.init({
                selector: _context.find("#contractPayInlineCard"),
                onIsPrepayChanged: function (isPrepay) {
                    _contractPayPlanGrid.setIsPrepay(isPrepay);
                }
            });
        }

        if (e.item.id == "contractPayPlanTab") {
            initContractPayPlanTab();
        }

        if (e.item.id == "documents") {
            var selector = $(e.contentElement).find("#documentGridWrapper");
            if (!kendo.widgetInstance($(selector).find(".brc-grid"))) {
                var guid = kendo.guid();
                selector.attr("guid", guid);

                var documents = new DocumentGrid(selector, { entityName: "asset_contract_id", entityId: _assetContractId });
                documents.init();
            }
        }
        if (e.item.id == "files") {
            if (!kendo.widgetInstance($(e.contentElement).find("#filesView"))) {
                var itemView = $(e.contentElement).find("#filesView").kendoItemView({
                    files: true,
                    windowStyle: true,
                    title: "",
                    toolbar: $(e.contentElement).find("#filesToolbar"),
                    dataSource: {
                        filter: { field: "asset_contract_id", operator: "eq", value: parseInt(_assetContractId) }
                           
                        },
                    upload: {
                        upload: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                            data: function () {
                                return {
                                    asset_contract_id: _assetContractId, file_relation_id: 0, doc_file_id: 0
                                }
                            },
                           
                        },
                        destroy: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                            data: function (dataItems) {
                                var self = this;
                                var ids = [];
                                dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                })

                                return {
                                    ids: ids,
                                    relation: { asset_contract_id: _assetContractId, file_relation_id: 0, doc_file_id: 0 }
                                }
                            },
                            
                        },
                        copy: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                            data: function (opt) {
                                var self = this;
                                var ids = [];
                                opt.dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                })
                                return {
                                    relation: { asset_contract_id: _assetContractId, file_relation_id: 0, doc_file_id: 0 },
                                    ids: ids
                                }
                            }
                        },
                        move: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                            data: function (opt) {
                                var self = this;
                                var ids = [];
                                opt.dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                })
                                return {
                                    relation: { asset_contract_id: _assetContractId, file_relation_id: 0, doc_file_id: 0 },
                                    old_relation: opt.moveFrom.options.relation,
                                    ids: ids
                                }
                            }
                        },
                        check: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                            data: function (dataItems) {
                                var self = this;
                                var names = [];
                                dataItems.forEach(function (item) {
                                    names.push(item.doc_file_name);
                                })
                                return {
                                    relation: { asset_contract_id: _assetContractId, file_relation_id: 0, doc_file_id: 0 },
                                    fileNames: names
                                }
                            },
                            reject: function (error, dataItems) {
                                for (var i = 0; i < dataItems.length; i++) {
                                    if (dataItems[i].doc_file_name == error.fileName) {
                                        dataItems.splice(i, 1);
                                        break;
                                    }
                                }
                                return dataItems;
                            }
                        },

                    }
                }).data("kendoItemView");
                itemView.filesToolbar({ asset_contract_id: _assetContractId, file_relation_id: 0, doc_file_id: 0 });
            }
        }
    }

    function initContractPayPlanTab() {
        if (_needInitContractPayPlanGrid) {
            _needInitContractPayPlanGrid = false;

            _contractPayPlanGrid = new ContractPayPlanGrid();
            _contractPayPlanGrid.init({
                selector: _context.find("#contractPayPlanGrid"),
                beforeCalculateCallback: beforeCalculateCallback
            });
        }
    }

    function beforeCalculateCallback(calculateCallback) {
        if (_validator.validate()) {
            BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/SaveAssetContract",
                _context.find("form").serializeArray(),
                function (id) {
                    calculateCallback();
                    _onOKClose();
                },
                function (data) {
                    if (data.responseJSON && data.responseJSON.message) {
                        BrcNotification.error(data.responseJSON.message);
                    }
                    else {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                }
            );
        }
    }

    function initContractPayGrid() {

        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyContractPay",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportContractPayGrid?assetContractId=" + _assetContractId,
            filter: { field: "asset_contract_id", operator: "eq", value: parseInt(_assetContractId) }
        };

        var gridConfig = JSON.parse(_context.find("#contractPayGridConfig").val());
        _contractPayGrid = new BrcGrid(_context.find("#contractPayGrid"), dataSource).init(gridConfig);

        _contractPayGrid.bind("dataBound", onDataBoundContractPay);
    }

    function onDataBoundContractPay(e) {
        $("#contractPayGrid").find('[data-toggle-window="contract_pay"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var config = {
                contractPayId: this.getAttribute("number"),
                onOKClose: function () { _contractPayGrid.kendoGrid.dataSource.read(); _contractPayGrid.kendoGrid.resize(); }
            };
            new ContractPayModal().init(config);
        }
    }

    function initContractPayGridToolbar() {
        var _canCreateContractPay = (_context.find("#canCreateAssetContract").val() === 'true');
        var _canDeleteContractPay = (_context.find("#canDeleteAssetContract").val() === 'true');

        var toolbar = _context.find("#contractPayToolbar").kendoToolBar({
            items: [
                    {
                        type: "button",
                        text: "Создать",
                        click: onCreateContractPay,
                        hidden: !_canCreateContractPay
                    },
                    {
                        type: "button",
                        text: "Удалить",
                        click: onDeleteContractPay,
                        hidden: !_canDeleteContractPay
                    }
                , { type: "separator" },
                { type: "button", text: "Экспорт MS Excel", click: function () { _contractPayGrid.exportExcel(); } }
            ]
        }).data("kendoToolBar");
    }

    function onCreateContractPay() {
        var config = {
            contractPayId: 0,
            assetContractId: _assetContractId,
            onOKClose: function () { _contractPayGrid.kendoGrid.dataSource.read(); _contractPayGrid.kendoGrid.resize(); }
        };
        new ContractPayModal().init(config);
    }

    function onDeleteContractPay() {
        var selectedIds = _contractPayGrid.getSelectedKeys();

        if (!(selectedIds.length > 0)) {
            BrcNotification.error("Выберите условия оплаты");
            return;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные условия оплаты?").done(function () {
            removeContractPayListFromAssetContract(selectedIds);
        });
    }
    //----------------------------------------------
    function initContractAssetGrid() {

        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyContractAsset",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportContractAssetGrid?assetContractId=" + _assetContractId,
            filter: { field: "asset_contract_id", operator: "eq", value: parseInt(_assetContractId) }
        };

        var gridConfig = JSON.parse(_context.find("#contractAssetGridConfig").val());
        _contractAssetGrid = new BrcGrid(_context.find("#contractAssetGrid"), dataSource).init(gridConfig);

        _contractAssetGrid.bind("dataBound", onDataBoundContractAsset);
    }

    function initContractAssetGridToolbar(context) {
        var toolbar = _context.find("#contractAssetToolbar").kendoToolBar({
            items: [
                    {
                        type: "button",
                        text: "Добавить ЗУ",
                        click: onAddParcel
                    },
                    {
                        type: "button",
                        text: "Добавить ОКС",
                        click: onAddBuilding
                    },
                    {
                        type: "button",
                        text: "Добавить помещение",
                        click: onAddFlat
                    },
                    {
                        type: "button",
                        text: "Добавить движимое имущество",
                        click: onAddEquipment
                    },
                    {
                        type: "button",
                        text: "Удалить",
                        click: onDeleteContractAsset
                    }
                , { type: "separator" },
                { type: "button", text: "Экспорт MS Excel", click: function () { _contractAssetGrid.exportExcel(); } }
            ]
        }).data("kendoToolBar");
    }

    function onDataBoundContractAsset() {
        _contractAssetGrid.selector.find('[data-toggle-window="asset"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var assetOnContractId = this.getAttribute("number");
            var assetOnContractType = this.getAttribute("category");

            if (assetOnContractType == "Движимое") {
                var equipmentOnContractModal = new EquipmentModal();
                equipmentOnContractModal.init({
                    equipmentId: assetOnContractId,
                    assetId: assetOnContractId,
                    onOKClose: function () {
                        _contractAssetGrid.kendoGrid.dataSource.read();
                        _contractAssetGrid.kendoGrid.resize();
                    }
                });
            }
            else {
                location.href = window["APPLICATION_CONFIG"].routes.root + "Estate/AssetComplex/Asset/" + assetOnContractId;
            }


        }
    }

    function onAddParcel() {
        _parcelSelectModal.open();
    }

    function onAddBuilding() {
        _buildingSelectModal.open();
    }

    function onAddFlat() {
        _flatSelectModal.open();
    }

    function onAddEquipment() {
        _equipmentSelectModal.open();
    }

    function onDeleteContractAsset() {
        var selectedIds = _contractAssetGrid.getSelectedKeys();

        if (!(selectedIds.length > 0)) {
            BrcNotification.error("Выберите объекты договора");
            return;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные объекты из договора?").done(function () {
            removeAssetListFromAssetContract(selectedIds);
        });
    }

    function addAssetListToAssetContract(assetIds) {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/AddAssetListToAssetContract",
            { assetIds: assetIds, assetContractId: _assetContractId },
            function () {
                BrcNotification.success("Выполнено");
                _contractAssetGrid.kendoGrid.dataSource.read();
            },
            function (data) {
                BrcNotification.error("Произошла ошибка!");
            }
        );
    }

    function removeAssetListFromAssetContract(assetIds) {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/RemoveAssetListFromAssetContract",
            { assetIds: assetIds, assetContractId: _assetContractId },
            function () {
                BrcNotification.success("Выполнено");
                _contractAssetGrid.kendoGrid.dataSource.read();
            },
            function (data) {
                BrcNotification.error("Произошла ошибка!");
            }
        );
    }

    function removeContractPayListFromAssetContract(contractPayIds) {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/DeleteContractPayList",
            { contractPayIdList: contractPayIds },
            function () {
                BrcNotification.success("Выполнено");
                _contractPayGrid.kendoGrid.dataSource.read();
            },
            function (data) {
                BrcNotification.error("Произошла ошибка!");
            }
        );
    }
};