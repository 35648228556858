function UniversalGrid() {
    var _grid;
    var _brcGrid;
    var _objId;
    var _objField;
    var _objName;
    var _selector;
    var _canCreate = false;
    var _canDelete = false;
    var _toolbarSelector;
    var _onCreate;
    var _onDelete;
    var _onDataBound;

    return {
        init: init,
        read: read,
        getSelectedKeys: getSelectedKeys,
        grid: grid
    };

    function init(config) {
        _objId = config.objId;
        _objField = config.objField;
        _objName = config.objName;
        _selector = $(config.selector);
        _canCreate = config.canCreate;
        _canDelete = config.canDelete;
        _toolbarSelector = config.toolbarSelector;
        _onCreate = config.onCreate;
        _onDelete = config.onDelete;
        _onDataBound = config.onDataBound;


        initGrid();
        if (config.initToolbar) {
            initToolbar();
        }
        if (config.onDataBound) {
            _grid.bind("dataBound", _onDataBound);
        }
    }

    function grid() {
        return _grid;
    }
    function getSelectedKeys() {
        return _grid.getSelectedKeys();
    }

    function read() {
        _grid.kendoGrid.dataSource.read();
        _grid.kendoGrid.resize();
    }
    function initGrid() {
        var filter = _objField ? initFilter() : null;
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + ("odata/OData_" + _objName),
            dataType: "json",
            filter: filter
        };
        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());
        gridConfig.autoBind = false;
        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.kendoGrid.dataSource.read();
    }

    function initFilter() {
        var filter = { field: _objField, operator: "eq", value: parseInt(_objId) > 0 ? parseInt(_objId) : _objId, unmanaged: true };
        return filter;
    }

    function initToolbar() {
        var items = [];

        if (_canCreate) {
            items.push({
                type: "button",
                text: "Создать",
                click: _onCreate
            });
        }

        if (_canDelete) {
            items.push({
                type: "button",
                text: "Удалить",
                click: _onDelete
            });
        }

        items.push({
            type: "separator"
        });

        items.push({
            type: "splitButton",
            text: "Таблица",
            overflow: "never",
            menuButtons: [
                _brcGrid.filters(),
                _brcGrid.columnVisibility(),
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ],
        })

        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");

    }

};