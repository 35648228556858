var ObjRightModal = function () {
    var _objRightId;
    var _assetId;
    var _onOKClose;
    var _objRightCard;
    var _context;
    var _needEncumbranceGridInit = true;

    return {
        init: init
    };

    function init(config) {
        _objRightId = config.objRightId;
        _assetId = config.assetId;
        _onOKClose = config.onOKClose;

        initWindow();
    }

    function initWindow(config) {
        var selector = "#objRightWindow" + _objRightId;

        var windowConfig = {
            id: selector,
            title: _objRightId > 0 ? "Право" : "Новое право",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/ObjRight/ObjRightCard",
                data: {
                    id: _objRightId,
                    assetId: _assetId,
                }
            },
            width: 1000,
            height: 700,
            onrefresh: onRefreshWindow
        }

        _objRightCard = WindowManager.openOrAddWindow(windowConfig);
    }


    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        var validator = initControls(_context);

        BrcMetaModule.init(_context.find("#objRightAdditionallyBlock")[0]);
        BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/SubjectGridConfig", {},
           function (e) {
               subjGridConf = e;
           },
           function (e) {
               BrcNotification.error(e.responseJSON);
           }, false);
        var subjConf = {
            dataValueField: "subj_id",
            dataTextField: "subj_name",
            mode: "mixed",
            permission: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/subject/CheckPermission",
                rightObject: 25,
                rightType: 2
            },
            selectModal: {
                title: "Выбор субъекта",
                grid: {
                    gridConfig: subjGridConf,
                    openObjectSelector: "[data-toggle-window]",
                    openObjectCallback: function (id) {
                        var modal = new SubjectModal();
                        var config = {
                            subjectId: id,
                            onOKClose: function (e) {
                                return false;
                            }
                        }
                        modal.init(config);
                    }
                },
                search: {
                    searchPlaceholder: "Наименование, ИНН, ОГРН",
                    logic: "or",
                    searchFilters: [
                            {
                                field: "subj_name",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_sname",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_inn",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            },
                            {
                                field: "subj_ogrn",
                                type: "string",
                                operator: "contains",
                                caseSensitive: false,
                            }
                    ]

                },
                createObjectCallback: function () {
                    var self = this;
                    var modal = new SubjectModal();
                    var config = {
                        subjectId: 0,
                        onOKClose: function (e) {
                            self.options.selectGrid.dataSource.filter({ field: self.options.dataValueField, operator: "eq", value: parseInt(e) });
                            WindowManager.closeWindow(true, this);
                            self.options.selectGrid.dataSource.read().then(function () {
                                var obj = self.options.selectGrid.dataSource.get(parseInt(e))
                                self.dataSource.pushCreate(obj);
                                self.select(self.dataSource.data().indexOf(self.dataSource.get(obj[self.options.dataValueField])))
                                $(self.options.input).parents("form").first().data("kendoValidator").validateInput($(self.options.input));
                                self.options.selectWindow.windowHandler.destroy();
                            });
                            return true;
                        }
                    }
                    modal.init(config);
                }

            }
        }
        var cs = _context.find("#subj_id").kendoBrcMultiBox(subjConf);

        if ((_context.find("#canUpdateObjRight").val() === 'false' && _objRightId > 0) || (_context.find("#canCreateObjRight").val() === 'false' && _objRightId == 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        initRecalculation();

        if (_objRightId > 0) {
            initTabStrip();
        }


        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ObjRight/SaveObjRight",
                    $(event.target).serializeArray(),
                    function (id) {
                        BrcNotification.success("Выполнено");
                        if (_objRightId == 0) {
                            _onOKClose();
                            WindowManager.closeWindow(true, _objRightCard);
                            init({
                                objRightId: id,
                                onOKClose: _onOKClose
                            });
                        }
                        else {
                            _onOKClose();
                            WindowManager.closeWindow(true, _objRightCard);
                        }
                    },
                    function (data) {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _objRightCard);
        })
    }

    function initTabStrip() {
        var tabStrip = _context.find("#tabstrip").data("kendoTabStrip");
        tabStrip.bind("select", onSelect);

        function onSelect(e) {
            if (e.item.id == "documents") {
                var selector = $(e.contentElement).find("#documentGridWrapper");
                if (!kendo.widgetInstance($(selector).find(".brc-grid"))) {
                    var guid = kendo.guid();
                    selector.attr("guid", guid);

                    var documents = new DocumentGrid(selector, { entityName: "right_id", entityId: _objRightId });
                    documents.init();
                }
            }
            else if (e.item.id == "files") {
                if (!kendo.widgetInstance($(e.contentElement).find("#filesView"))) {
                    var itemView = $(e.contentElement).find("#filesView").kendoItemView({
                        files: true,
                        windowStyle: true,
                        title: "",
                        toolbar: $(e.contentElement).find("#filesToolbar"),
                        dataSource: {
                            filter: { field: "right_id", operator: "eq", value: parseInt(_objRightId) }

                        },
                        upload: {
                            upload: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                                data: function () {
                                    return {
                                        right_id: _objRightId, file_relation_id: 0, doc_file_id: 0
                                    }
                                },

                            },
                            destroy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                                data: function (dataItems) {
                                    var self = this;
                                    var ids = [];
                                    dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })

                                    return {
                                        ids: ids,
                                        relation: { right_id: _objRightId, file_relation_id: 0, doc_file_id: 0 }
                                    }
                                },

                            },
                            copy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { right_id: _objRightId, file_relation_id: 0, doc_file_id: 0 },
                                        ids: ids
                                    }
                                }
                            },
                            move: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { right_id: _objRightId, file_relation_id: 0, doc_file_id: 0 },
                                        old_relation: opt.moveFrom.options.relation,
                                        ids: ids
                                    }
                                }
                            },
                            check: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                                data: function (dataItems) {
                                    var self = this;
                                    var names = [];
                                    dataItems.forEach(function (item) {
                                        names.push(item.doc_file_name);
                                    })
                                    return {
                                        relation: { right_id: _objRightId, file_relation_id: 0, doc_file_id: 0 },
                                        fileNames: names
                                    }
                                },
                                reject: function (error, dataItems) {
                                    for (var i = 0; i < dataItems.length; i++) {
                                        if (dataItems[i].doc_file_name == error.fileName) {
                                            dataItems.splice(i, 1);
                                            break;
                                        }
                                    }
                                    return dataItems;
                                }
                            },

                        }
                    }).data("kendoItemView");
                    itemView.filesToolbar({ right_id: _objRightId, file_relation_id: 0, doc_file_id: 0 });
                }
            }
            else if (e.item.id == "encumbrances") {
                if (_needEncumbranceGridInit) {
                    _needEncumbranceGridInit = false;

                    var encumbranceGrid = new EncumbranceGrid();
                    encumbranceGrid.init({
                        selector: _context.find("#encumbranceGrid"),
                        toolbarSelector: _context.find("#encumbranceToolbar"),
                        rightId: _objRightId,
                        assetId: _assetId,
                        mode: "right"
                    });
                }
            }
        }
    }

    function initRecalculation() {
        var proportionCh = _context.find("#right_proportion_ch").data("kendoNumericTextBox");
        var proportionZn = _context.find("#right_proportion_zn").data("kendoNumericTextBox");

        proportionCh.bind("change", recalculate);
        proportionZn.bind("change", recalculate);

        recalculate();

        function recalculate() {
            if (proportionCh.value() != null) {
                _context.find("#right_proportion_zn").attr("required", true);
            }
            else {
                _context.find("#right_proportion_zn").removeAttr("required");
            }

            if (proportionZn.value() != null) {
                _context.find("#right_proportion_ch").attr("required", true);
            }
            else {
                _context.find("#right_proportion_ch").removeAttr("required");
            }

            if (proportionCh.value() != null && proportionZn.value() != null && Number(proportionCh.value()) > Number(proportionZn.value())) {
                BrcNotification.error("Числитель доли не может быть больше знаменателя!");
                proportionCh.value("");
                //proportionZn.value("");
            }
        }
    }
};