function PaymentGrid() {
    var _grid;
    var _selector;
    var _contractPayPlanId;

    return {
        init: init
    };

    function init(config) {
        _selector = $(config.selector);
        _contractPayPlanId = _selector.find("#contractPayPlanId").val();

        initGrid();
        initToolbar();
    }

    function initGrid() {
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyPayment",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportPaymentGrid?contractPayPlanId=" + _contractPayPlanId,
            filter: { field: "contract_pay_plan_id", operator: "eq", value: parseInt(_contractPayPlanId) }
        };

        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());

        _grid = new BrcGrid(_selector.find("#grid"), dataSource).init(gridConfig);
        _grid.bind("dataBound", onDataBound);
    }
    
    function initToolbar() {
        var toolbar = _selector.find("#toolbar").kendoToolBar({
            items: [
                {
                    type: "button",
                    text: "Создать",
                    click: onCreate,
                    hidden: !hasPermission("canCreatePayment")
                },
                {
                    type: "button",
                    text: "Удалить",
                    click: onDelete,
                    hidden: !hasPermission("canDeletePayment")
                },
                { type: "separator" },
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ]
        }).data("kendoToolBar");
    }

    function onCreate() {
        var paymentModal = new PaymentModal();
        paymentModal.init({
            contractPayPlanId: _contractPayPlanId,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
            }
        });
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите платежи");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/DeletePaymentList",
                    { paymentIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="payment"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var paymentModal = new PaymentModal();
            paymentModal.init({
                paymentId: this.getAttribute("number"),
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
}