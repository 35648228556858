var AssetFixedSelectModal = function () 
{
    var _grid;
    var _assetFixedSelectWindow;
    var _needInit = true;

    var _onSelect;
    var _wrapperId;

    return {
        init: init,
        open: open
    };

    function init(config) 
    {
        _onSelect = config.onSelect;
        _wrapperId = kendo.guid();

    }

    function initWindow()
    {
        var wrapper = $("#" + _wrapperId);

        _assetFixedSelectWindow = wrapper.kendoWindow({
            width: "900px",
            height: "500px",
            resizable: true,
            modal: true,
            title: "Выбор основного средства",
            visible: false,
            actions: [
                "Minimize",
                "Maximize",
                "Close"
            ]
        }).data("kendoWindow");
        _assetFixedSelectWindow.center();

        wrapper.find(".brc-button").kendoButton();

        wrapper.find("#okButton").on("click", function (e) {
            e.preventDefault();

            var selectedIds = _grid.getSelectedKeys();

            if (!(selectedIds.length === 1)) {
                BrcNotification.error("Выберите основное средство");
                return;
            }

            _assetFixedSelectWindow.close();
            _onSelect(selectedIds[0]);
        });

        wrapper.find("#cancelButton").click(function (e) {
            e.preventDefault();
            //WindowManager.closeWindow(true, _assetFixedSelectWindow);
            _assetFixedSelectWindow.close();
        })
    }

    function initGrid() {
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyAssetFixed",
            dataType: "json"
        };

        var gridConfig = JSON.parse($("#" + _wrapperId).find("#assetFixedSelectGridConfig").val());
        _grid = new BrcGrid($("#" + _wrapperId + " #assetFixedSelectGrid").selector, dataSource);
        _grid.init(gridConfig);
    }

    function open()
    {
        if (_needInit) {
            _needInit = false;
            BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetFixed/AssetFixedSelectModal",
                null,
                function (data) {
                    $("body").append("<div id=" + _wrapperId + ">" + data + "</div>");
                    initWindow();
                    initGrid();
                    _assetFixedSelectWindow.open();
                },
                function (data) {
                    BrcNotification.error("Произошла ошибка!");
                }
            );
        }
        else {
            _grid.kendoGrid.dataSource.read();
            _assetFixedSelectWindow.open();
        }
    }
}