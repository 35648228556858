(function ($) {
    ui = kendo.ui;
    var styleModificator = "page";
    var _buffer = {
        action: "",
        source: undefined,
        items: []
    }

    function _dispatchViewChangeEvent() {
        var event = document.createEvent('Event');
        event.initEvent('itemViewChanged', true, true);
        document.dispatchEvent(event);
    }

    var _options = {
        name: "ItemView",
        itemTemplate: "<span>#=data#</span>",
        windowStyle: false,
        permissions: {
            canCreateFile: false,
            canDeleteFile: false,
            canUpdateFile: false,
        },
        images: {
            'item-file': window["APPLICATION_CONFIG"].routes.root + 'dist/Content/Images/file.png',
            'item-excel': window["APPLICATION_CONFIG"].routes.root + 'dist/Content/Images/excel.png',
            'item-word': window["APPLICATION_CONFIG"].routes.root + 'dist/Content/Images/doc.png',
            'item-archive': window["APPLICATION_CONFIG"].routes.root + 'dist/Content/Images/rar.png',
            'item-png': window["APPLICATION_CONFIG"].routes.root + 'dist/Content/Images/jpg.png',
        },
        upload: {
            upload: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
            },
            destroy: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
            },
            copy: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
            },
            move: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
            },
            check: {
                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
            },
            autoUpload: true
        }
    }
    var _fileOptions = {
        itemTemplate: "<div class='item-wrapper item-draggable view-item' data-uid='#:data.uid#' title='Тип: #:data.doc_file_ext?data.doc_file_ext:\"\"#\nРазмер: #:data._formatBytes(data.length)#\nДата изменения: # var val = kendo.toString(kendo.parseDate(data.doc_file_ch_date!=null?doc_file_ch_date:data.doc_file_cr_date!=null?data.doc_file_cr_date:undefined),'dd.MM.yyyy');##=val?val:\"\"#'>#if (!!data.hasdocument){#<div class='document-file'><span class='k-icon k-i-document-manager'><span></div>#}#<div class='image-wrapper'>" +
                            "<img src='#:data._image()#' class='item-image'/></div><div class='item-footer-wrapper'><div class='item-footer'><span><a target='_self' href='#:window[\"APPLICATION_CONFIG\"].routes.root+\"estate/file/getFile?fileId=\"+data.doc_file_id#'>#:data.doc_file_name#</a></span></div></div></div>",
        alt1ItemTemplate: "<div class='item-alt-wrapper item-draggable view-item' title='Тип: #:data.doc_file_ext?data.doc_file_ext:\"\"#\nРазмер: #:data._formatBytes(data.length)#\nДата изменения: # var val = kendo.toString(kendo.parseDate(data.doc_file_ch_date!=null?doc_file_ch_date:data.doc_file_cr_date!=null?data.doc_file_cr_date:undefined),'dd.MM.yyyy');##=val?val:\"\"#'  data-uid='#:data.uid#'>#if (!!data.hasdocument){#<div class='document-file'><span class='k-icon k-i-document-manager'><span></div>#}#<div class='image-wrapper'><img src='#:data._image()#' class='item-image'/></div><div class='data-wrapper'><span><a target='_self' href='#:window[\"APPLICATION_CONFIG\"].routes.root+\"estate/file/getFile?fileId=\"+data.doc_file_id#'>#:data.doc_file_name#</a></span></div></div>",
        alt2ItemTemplate: "<tr><td><div class='item-wrapper item-draggable view-item' data-uid='#:data.uid#'><div class='document-file'><div class='image-wrapper'><img src='#:data._image()#' class='item-image'/></div><a target='_self' href='#:window[\"APPLICATION_CONFIG\"].routes.root+\"estate/file/getFile?fileId=\"+data.doc_file_id#'>#:data.doc_file_name#</a></div></div></td><td># var val = kendo.toString(kendo.parseDate(data.doc_file_ch_date!=null?doc_file_ch_date:data.doc_file_cr_date!=null?data.doc_file_cr_date:undefined),'dd.MM.yyyy');##=val?val:\"\"#</td><td>#:data._formatBytes(data.length)#</td></tr>",
        altItemTemplate: "<tr data-uid='#:data.uid#' class='clickable-row'><td><a target='_self' href='#:window[\"APPLICATION_CONFIG\"].routes.root+\"estate/file/getFile?fileId=\"+data.doc_file_id#'>#:data.doc_file_name#</a></td><td># var val = kendo.toString(kendo.parseDate(data.doc_file_ch_date!=null?doc_file_ch_date:data.doc_file_cr_date!=null?data.doc_file_cr_date:undefined),'dd.MM.yyyy');##=val?val:\"\"#</td><td>#:data._formatBytes(data.length)#</td></tr>",
        dataSource: {
            type: "odata",
            transport: {
                read: {
                    dataType: "json",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyEntityFiles"
                }
            },
            schema: {
                data: function (data) {
                    return data["value"];
                },
                total: function (data) {
                    return !!data["odata.count"] ? data["odata.count"] : data["@@odata.count"]; //добавил Андрей, для меты
                }
            },
            page: 1,
            pageSize: 100,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true
        },
    }
    var ItemView = ui.Widget.extend({
        options: _options,
        init: init,
        _dataSource: _dataSource,
        refresh: refresh,
        resolveImage: _resolveImage,
        filesToolbar: _filesToolbarInit,
        getSelected: _getSelected,
        getSelectedDataItems: _getSelectedDataItems,
        getFolderId: _getFolderId,
        disableUpload: _disableUpload,
        replaceFilter: _replaceFilter,
        altStyle: false,
        enable: _enable
    })
    function formatBytes(a, b) { if (0 == a) return "0 байт"; var c = 1e3, d = b || 2, e = ["байт", "КБ", "МБ", "ГБ", "ТБ", "ПБ", "ЕБ", "ЗБ", "ЙБ"], f = Math.floor(Math.log(a) / Math.log(c)); return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f] }
    function init(element, options) {
        var that = this;
        styleModificator = options.windowStyle ? "window" : "page";
        //that.wrapper = $("<div data-role='itemContainer' class='container-wrapper' id='wrapperFiles'></div>");
        //that.container = $("<div class='item-container'></div>");
        //that.wrapper.append(that.container);

        that.wrapper = $("<div data-role='itemContainer' class='container-wrapper' id='wrapperFiles'></div>");
        that.tableWrapper = $("<table class='table table-hover table-sm table-striped'><thead><tr><th>Наименование</th><th>Дата</th><th>Размер</th></tr></thead></table>");
        that.container = $("<tbody></tbody>");
        that.wrapper.append(that.tableWrapper);
        that.tableWrapper.append(that.container);

        $(element).append(that.wrapper);
        $(element).append("<div id='pager'></div>");
        var _opt = {};
        if (options.files) {
            $.extend(true, _opt, _fileOptions, options);
        }
        if (!options.permissions) {
            _loadFilePermissions.call(that);
        }
        ui.Widget.fn.init.call(that, element, _opt);
        that._dataSource();
        //that.template = kendo.template(that.options.itemTemplate);
        that.template = kendo.template(that.options.altItemTemplate);
        initSelectTable();

        document.addEventListener('itemViewChanged', function (e) {
            if (!!that.options.upload.autoUpload)
                that.dataSource.read();
        });
    }
    function refresh() {
        var that = this;
        var view = that.dataSource.view();
        var html = kendo.render(that.template, view);
        that.container.html(html);
        _selectable.call(that);
        if (!!that.options.upload.autoUpload) {
            if (that.options.permissions.canUpdateFile) {
                _draggable.call(that);
                _dropTarget.call(that);
            }

        }
        if (!that.options.permissions.canCreateFile) {
            that.upload.disable();
        }
    }
    function _enable(a, b) {
        var self = this;
        var updateEnabled = a && self.options.permissions.canUpdateFile;
        var deleteEnabled = a && self.options.permissions.canDeleteFile;
        var createEnabled = a && self.options.permissions.canCreateFile;
        if (!!self.toolbar) {
            self.toolbar.enable(self.toolbar.wrapper.find("#cutFiles"), updateEnabled);
            self.toolbar.enable(self.toolbar.wrapper.find("#pasteFiles"), updateEnabled);
            self.toolbar.enable(self.toolbar.wrapper.find("#copyFiles"), updateEnabled);
            self.toolbar.enable(self.toolbar.wrapper.find("#deleteFiles"), deleteEnabled);
        }
        //$(self.toolbar.popup.container).find("a.k-button").each(function (index, item) {
        //    self.toolbar.enable(item, a);
        //});

        if (!!self.upload) {
            self.disableUpload(createEnabled);
        }

    }
    function _dataSource() {
        var that = this;
        $.extend(that.options.dataSource.schema, {
            model: {
                _image: function () {
                    return _resolveImage.call(that, this.doc_file_name)
                },
                _formatBytes: formatBytes
            }
        });
        that.dataSource = kendo.data.DataSource.create(that.options.dataSource);
        that.dataSource.bind("change", function () {
            that.refresh();
        });
        that.dataSource.fetch();
        $(that.element).find("#pager").kendoPager({
            dataSource: that.dataSource,
            refresh: true
        });
    }
    function _resolveImage(name) {
        var that = this;
        var split = name.split('.');
        switch (split[split.length - 1]) {
            case "xls":
            case "xlsx":
                return that.options.images["item-excel"];
            case "png":
            case "jpg":
            case "bmp":
            case "jpeg":
            case "gif":
                return that.options.images["item-png"];
            case "doc":
            case "docx":
                return that.options.images["item-word"];
            case "rar":
            case "zip":
            case "tar":
                return that.options.images["item-archive"];
            default:
                return that.options.images["item-file"];
        }
    }
    function _draggable() {
        var that = this;
        that.container.kendoDraggable({
            hint: function (element) {
                var selected = that.container.find(".view-item.item-selection");
                var hasDocFiles = that.container.find(".view-item.item-selection .document-file").length > 0;
                if (selected.length > 1 && element.closest(".view-item").is(".item-selection")) {
                    var elem = $(element).clone();
                    elem.addClass("item-dragged");
                    //elem.find(".item-footer").hide();
                    elem.removeClass("item-selection");
                    //elem.width("75px");
                    elem.css("position", "relative").append("<div class='items-counter-wrapper'><span class='items-counter'>" + selected.length + "</span></div>").find("img").attr("src", that.options.images["item-file"]).css("width", "75px").css("height", "75px");
                    if (hasDocFiles) {
                        elem.append('<div class="document-file" ><span class="k-icon k-i-document-manager"></span></div>');
                    }
                    elem.wrap('<div></div>');
                    return elem.parent();
                }
                else {
                    _deselectAll.call(that);

                    $(element).addClass("item-selection");
                    var elem = $(element).clone();
                    elem.addClass("item-dragged");
                    elem.removeClass("item-selection");
                    //elem.find("img").width("75px");
                    elem.wrap('<div></div>');
                    elem.css("position", "relative");
                    return elem.parent();
                }
                return container;
            },
            filter: ".item-draggable",
            ignore: ".item-footer, .item-footer *",
            group: "itemView"
        });
    }
    function _deselectAll() {
        var that = this;
        that.element.find('.view-item').each(function () {
            $(this).removeClass("item-selection");
        });
    }
    function _selectable() {
        var that = this;
        var g = $("<div class='selection-div'></div>")
        that.container.append(g);
        var x1;
        var y1;
        var x2;
        var y2;
        var ctrl;
        var start = false;
        that.element.find('.view-item').click(function (e) {
            if (!e.ctrlKey) {
                that.container.find(".view-item").removeClass("item-selection");
                $(e.target).closest(".view-item").addClass("item-selection");
            }
            else
                $(e.target).closest(".view-item").toggleClass("item-selection");
        })
        that.element.find(".item-container").mousedown(function (e) {
            if ($(e.target).is(".view-item *") || $(e.target).is('.item-alt-wrapper')) {
            }
            else {
                e.stopPropagation();
                $("html").css("user-select", "none");
                x1 = e.pageX;
                y1 = e.pageY;
                x2 = e.pageX;
                y2 = e.pageY;
                start = true;
                g.show();
            }
        });
        that.element.mousemove(function (e) {
            if (start) {
                x2 = e.pageX;
                y2 = e.pageY;
                var width = x1 > x2 ? x1 - x2 : x2 - x1;
                var height = y1 > y2 ? y1 - y2 : y2 - y1;
                var x = x1 > x2 ? x2 : x1;
                var y = y1 > y2 ? y2 : y1;
                setRectangle(x, y, width, height);
            }
        })
        function setRectangle(x, y, width, height) {
            g.offset({ top: y, left: x });
            g.width(width);
            g.height(height);
        }
        $(document).mouseup(function (e) {
            if (start) {
                start = false;
                g.hide();
                g.width(0);
                g.height(0);
                g.offset({ top: 0, left: 0 })
                $("html").css("user-select", "initial");
                getIt(e);
            }
        })
        function getIt(event) {
            $(that.container.find(".view-item")).each(function (index, elem) {
                var p = $(elem).offset();
                var xmiddle = p.left + $(elem).width() / 2;
                var ymiddle = p.top + $(elem).height() / 2;
                if (matchPos(xmiddle, ymiddle)) {
                    $(elem).addClass("item-selection");
                }
                else if (!event.ctrlKey)
                    $(elem).removeClass("item-selection");
            });
        }
        function matchPos(xmiddle, ymiddle) {
            if (x1 > x2) {
                myX1 = x2;
                myX2 = x1;
            } else {
                myX1 = x1;
                myX2 = x2;
            }
            if (y1 > y2) {
                myY1 = y2;
                myY2 = y1;
            } else {
                myY1 = y1;
                myY2 = y2;
            }
            if ((xmiddle > myX1) && (xmiddle < myX2)) {
                if ((ymiddle > myY1) && (ymiddle < myY2)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }
    function _filesToolbarInit(relation) {
        var self = this;
        self.options.relation = relation;
        var tbContainer;
        if (!self.options.toolbar) {
            var headerContainer = $($("<div class='brc-" + styleModificator + "-layout__header'>" + (!!self.options.title ? '<div class="brс-' + styleModificator + '-layout__title"><h4 class="brc-card-title" data-role="itemViewHeader">' + self.options.title + '</h4></div>' : ''))).insertBefore(self.wrapper);
            tbContainer = $("<div class='brc-toolbar brc-toolbar-border-none brc-toolbar-right'/>");
            headerContainer.append(tbContainer);
            tbContainer.wrap('<div class="brc-' + styleModificator + '-layout__toolbar"></div>');
        }
        else {
            tbContainer = $(self.options.toolbar);
        }
        self.toolbar = tbContainer.kendoToolBar({
            items: [
                { attributes: { "data-search-wrapper": true }, template: '<div class="brc-search-input"><input class="k-textbox" type="text" /><span class="k-select"><span class="k-icon k-i-search"></span></span> </div>', overflow: "never" },
                { type: "separator" },
                  {
                      type: "button", text: "Список", icon: "list-unordered", showText: "overflow", overflow: "always", toggle: function (e) {
                          var tb = e.sender;

                          self.wrapper = $("<div data-role='itemContainer' class='container-wrapper' id='wrapperFiles'></div>");
                          self.tableWrapper = $("<table class='table table-hover table-sm table-striped'><thead><tr><th>Наименование</th><th>Дата</th><th>Размер</th></tr></thead></table>");
                          self.container = $("<tbody></tbody>");
                          self.wrapper.append(self.tableWrapper);
                          self.tableWrapper.append(self.container);
                          $("#wrapperFiles").replaceWith(self.wrapper);
                          self.template = kendo.template(self.options.altItemTemplate);
                          self.refresh();
                          initSelectTable();
                      },
                      togglable: true,
                      group: "viewType",
                      selected: true
                  },
                  {
                      type: "button", text: "Плитка", icon: "table", showText: "overflow", overflow: "always", toggle: function (e) {
                          var tb = e.sender;

                          self.wrapper = $("<div data-role='itemContainer' class='container-wrapper' id='wrapperFiles'></div>");
                          self.container = $("<div class='item-container'></div>");
                          self.wrapper.append(self.container);
                          $("#wrapperFiles").replaceWith(self.wrapper);

                          self.template = kendo.template(self.options.itemTemplate);
                          self.refresh();
                      },
                      togglable: true,
                      group: "viewType"
                  },
                   { type: "separator" },
                { template: '<input type="file" title="Добавить файл" id="filesUpload" name="files"/>' },
                {
                    type: "button", text: "Удалить", icon: "delete", id: "deleteFiles", showText: "overflow", click: function (e) {
                        _deleteFiles.call(self);
                    },
                    attributes: { title: "Удалить" },
                    enable: self.options.permissions.canDeleteFile,
                },

                {
                    type: "button", text: "Копировать", showText: "overflow", icon: "copy", id: "copyFiles",
                    hidden: !self.options.upload.autoUpload,
                    click: function (e) {
                        _copyItems.call(self);
                    },
                    attributes: { title: "Копировать" },
                    enable: self.options.permissions.canUpdateFile,
                },
                {
                    type: "button", text: "Вырезать", showText: "overflow", icon: "cut", id: "cutFiles",
                    hidden: !self.options.upload.autoUpload,
                    click: function (e) {
                        _cutItems.call(self);
                    },
                    attributes: { title: "Вырезать" },
                    enable: self.options.permissions.canUpdateFile,
                },
                {
                    type: "button", text: "Вставить", showText: "overflow", icon: "paste", id: "pasteFiles",
                    hidden: !self.options.upload.autoUpload,
                    click: function (e) {
                        _pasteItems.call(self);
                    },
                    attributes: { title: "Вставить" },
                    enable: self.options.permissions.canUpdateFile,
                },
                { type: "separator" },
                {
                    type: "buttonGroup", overflow: "always", icon: "sort-asc", buttons: [
                        {
                            text: "По имени", group: "sort", togglable: true, toggle: function (e) {
                                var dir = "asc";
                                if ($(this.wrapper).attr("sort-field") == "doc_file_name") {
                                    if ($(this.wrapper).attr("sort-dir") == "asc")
                                        dir = "desc";
                                    else
                                        dir = "asc";
                                }
                                self.dataSource.sort({ field: "doc_file_name", dir: dir });
                                $(this.wrapper).attr("sort-field", "doc_file_name");
                                $(this.wrapper).attr("sort-dir", dir);
                            }
                        },
                        {
                            text: "По размеру", group: "sort", togglable: true, toggle: function (e) {
                                var dir = "asc";
                                if ($(this.wrapper).attr("sort-field") == "length") {
                                    if ($(this.wrapper).attr("sort-dir") == "asc")
                                        dir = "desc";
                                    else
                                        dir = "asc";
                                }
                                self.dataSource.sort({ field: "length", dir: dir });
                                $(this.wrapper).attr("sort-field", "length");
                                $(this.wrapper).attr("sort-dir", dir);
                            }
                        },
                        {
                            text: "По дате", group: "sort", togglable: true, toggle: function (e) {
                                var dir = "asc";
                                if ($(this.wrapper).attr("sort-field") == "doc_file_cr_date") {
                                    if ($(this.wrapper).attr("sort-dir") == "asc")
                                        dir = "desc";
                                    else
                                        dir = "asc";
                                }
                                self.dataSource.sort({ field: "doc_file_cr_date", dir: dir });
                                $(this.wrapper).attr("sort-field", "doc_file_cr_date");
                                $(this.wrapper).attr("sort-dir", dir);
                            }
                        },
                    ]
                },
            ]
        }).data("kendoToolBar");

        self.toolbar.wrapper.find(".brc-search-input").find('input').on("input change", function (e) {
            if (!!$(this).val()) {
                self.replaceFilter({ id: "searchFilter", field: "tolower(doc_file_name)", operator: "contains", value: $(this).val().toLowerCase() });
            }
            else {
                self.replaceFilter({ id: "searchFilter", field: "doc_file_id", operator: "neq", value: -1 })
            }
        })
        self.upload = self.toolbar.element.find("#filesUpload").kendoUpload({
            error: function (e) {
                var x = e.XMLHttpRequest.response.replace(/\"/g, "");
                var r = /\\u([\d\w]{4})/gi;
                x = x.replace(r, function (match, grp) {
                    return String.fromCharCode(parseInt(grp, 16));
                });
                x = unescape(x);
                BrcNotification.error(x);
            },
            success: function (e) {
                BrcNotification.success("Файл(ы) успешно загружены!");
            },

            async: {
                saveUrl: self.options.upload.upload.url,
                autoUpload: false,
                saveField: 'files',
                batch: false,
            },
            localization: {
                select: '<span class="k-icon k-i-add">',
                invalidMaxFileSize: "Размер файла превышает максимально допустимый!"
            },
            validation: {
                maxFileSize: 300000000,
            },
            progress: function (e) {
            },
            showFileList: false,
            complete: function (e) {
                _dispatchViewChangeEvent();
                //document.dispatchEvent(new Event('itemViewChanged'));
            },
            upload: function (e) {
                e.data = typeof (self.options.upload.upload.data) == 'function' ? self.options.upload.upload.data.call(self) : self.options.upload.upload.data;
            },
            dropZone: $(self.element).find("[data-role=itemContainer]"),
            enabled: self.options.permissions.canCreateFile,
            select: function (e) {
                if (!self.options.permissions.canCreateFile) {
                    e.preventDefault();
                    return;
                }
                var _self = this;
                var files = e.files;
                files["new"] = true;
                var dataItems = [];
                files.forEach(function (element) {
                    _self.clearFile(function (f) {
                        return f[0].name == element.name && f[0].uid != element.uid;
                    })
                    dataItems.push({ doc_file_name: element.name });
                    if (element.hasOwnProperty("validationErrors")) {
                        element.validationErrors.forEach(function (element) {
                            BrcNotification.error(_self.localization[element]);
                        });
                    }
                })
                _checkFiles.call(self, typeof (self.options.upload.check.data) == 'function' ? self.options.upload.check.data.call(self, dataItems) : self.options.upload.check.data, {
                    reject: function (error) {
                        _self.clearFile(function (file) {
                            return file[0].name == error.fileName;
                        });
                    },
                    accept: function (error) {
                    },
                    done: function () {
                        if (!!self.options.upload.autoUpload) {
                            setTimeout(function (e) {
                                self.toolbar.element.find(".k-upload-selected").click(function (e) {
                                });
                                self.toolbar.element.find(".k-upload-selected").click();//("click");
                            });
                        }
                        else {
                            if (!self.options.upload.autoUpload) {
                                var data = [];
                                _self.getFiles().forEach(function (file) {
                                    if (_self.getFiles().indexOf(file.name) < 0)
                                        data.push({
                                            doc_file_name: file.name,
                                            'new': true
                                        });
                                })
                                self.dataSource.data(data);
                            }
                        }
                    }
                });
            }
        }).data("kendoUpload");
        self.upload.wrapper.addClass("upload-button-only");

    }
    function _getFolderId() {
        var self = this;
        if (self.options.hasOwnProperty("folderId")) {
            return self.options.folderId();
        }
        else
            return null;
    }
    function _getSelected() {
        var self = this;
        if ($(".table-sm").length > 0){
            return self.element.find(".clickable-row.info");
        }
        else{
            return self.element.find(".view-item.item-selection");
        }
        
    }
    function _getSelectedDataItems() {
        var self = this;
        var items = [];
        self.getSelected().each(function (index, element) {
            items.push(self.dataSource.getByUid($(element).attr('data-uid')));
        });
        console.debug("_getSelectedDataItems");
        console.debug(items);
        return items;
    }
    function _checkFiles(data, options) {
        var self = this;
        BrcAjax.Post(self.options.upload.check.url, data,
                function (e) {
                    if (e && e.length > 0) {
                        var resolveFilesDeferred = [];
                        e.forEach(function (error) {
                            var d = $.Deferred();
                            resolveFilesDeferred.push(d);
                            BrcConfirm.show("Подтверждение загрузки", kendo.format("Файл {0} уже существует в каталоге или объекте, продолжить с заменой?", error.fileName))
                            .done(function () {
                                if (options.hasOwnProperty("accept"))
                                    options.accept.call(self, error);
                            })
                            .fail(function () {
                                if (options.hasOwnProperty("reject"))
                                    options.reject.call(self, error);
                            }).always(function () {
                                d.resolve();
                            });
                        })
                        $.when.apply($, resolveFilesDeferred).done(function () {
                            options.done.call(self);
                        })
                    }
                    else {
                        setTimeout(function (e) {
                            options.done.call(self);
                        });
                    }
                },
                function (e) {
                    BrcNotification.error(e.responseJSON);
                })
    }
    function _copyItems() {
        var self = this;
        _buffer.items = self.getSelectedDataItems();
        _buffer.source = self;
        _buffer.data = "copy";
        _buffer.action = _copyItemsToObject;
    }
    function _cutItems() {
        var self = this;
        _buffer.items = self.getSelectedDataItems();
        self.getSelected().each(function (index, element) {
            $(element).addClass("item-view-cut");
        })
        _buffer.data = "move";
        _buffer.source = self;
        _buffer.action = _moveItemsToObject;
    }
    function _pasteItems() {
        var self = this;
        var ids = [];
        var names = [];
        var dataItems = [];
        if (_buffer.items.length < 1)
            return;
        _buffer.items.forEach(function (item) {
            ids.push(item.doc_file_id);
            names.push(item.doc_file_name);
            dataItems.push(item);
        })
        _checkFiles.call(self, typeof (self.options.upload.check.data) == 'function' ? self.options.upload.check.data.call(self, dataItems) : self.options.upload.check.data, {
            reject: function (error) {
                dataItems = self.options.upload.check.reject.call(self, error, dataItems);
            },
            done: function () {
                if (dataItems.length > 0) {
                    _buffer.action.call(self, typeof (self.options.upload[_buffer.data].data) == 'function' ? self.options.upload[_buffer.data].data.call(self, { dataItems: dataItems, moveFrom: _buffer.source }) : self.options.upload[_buffer.data].data);
                }
            }
        })
    }
    function _deleteFiles() {
        var self = this;
        var items = self.getSelectedDataItems();
        if (!items.length > 0)
            return;
        BrcConfirm.show("Подтверждение удаления", "Вы действительно хотите удалить выбранные объекты?").done(function () {
           
            items.forEach(function (file) {
                if (file.new) {
                    self.upload.clearFile(function (f) {
                        var result = f[0].name == file.doc_file_name;
                        if (result)
                            self.dataSource.remove(self.dataSource.getByUid(file.uid));
                        return result;
                    })
                }
            })
            if (!self.getSelectedDataItems().length > 0)
                return;
            var data = typeof (self.options.upload.destroy.data) == 'function' ? self.options.upload.destroy.data.call(self, self.getSelectedDataItems()) : self.options.upload.destroy.data;
            BrcAjax.Post(self.options.upload.destroy.url, data,
            function (e) {
                self.dataSource.read();
                _dispatchViewChangeEvent();
                BrcNotification.success("Выполнено");
            },
            function (e) {
                BrcNotification.error(e.responseJSON);
            });
        });
    }
    function _copyItemsToObject(data, source) {
        var self = this;
        if (!source)
            source = _buffer.source;
        BrcAjax.Post(self.options.upload.copy.url, data,
                        function (e) {
                            _dispatchViewChangeEvent();
                            BrcNotification.success("Выполнено");
                        },
                        function (e) {
                            BrcNotification.error(e.responseJSON);
                        });
    }
    function _moveItemsToObject(data, source) {
        var self = this;
        if (!source)
            source = _buffer.source;
        _buffer.items = [];
        _buffer.action = {};
        BrcAjax.Post(self.options.upload.move.url, data,
                       function (e) {
                           _dispatchViewChangeEvent();
                           BrcNotification.success("Выполнено");
                       },
                       function (e) {
                           BrcNotification.error(e.responseJSON);
                       })
    }
    function _dropTarget() {
        var self = this;
        if (!self.container.data("kendoDropTarget"))
            self.container.kendoDropTarget({
                group: "itemView",
                drop: function (e) {
                    if (e.sender.element[0] == e.draggable.element[0])
                        return;
                    var srcItemView = $(e.draggable.element).parents("[data-role=itemview]").data("kendoItemView");
                    var items = $(e.draggable.element).find(".view-item.item-selection");
                    var dataItems = []
                    var ids = [];
                    var data = [];
                    if (items.length < 1) {
                        items = $(e.draggable.currentTarget);
                    }
                    items.each(function (index, elem) {
                        var item = srcItemView.dataSource.getByUid($(elem).attr("data-uid"));
                        ids.push(item.doc_file_id);
                        data.push(item.doc_file_name);
                        dataItems.push(item);
                    })
                    var wnd = _actionWindow(e);
                    wnd.element.find("#copyButton").click(function (e) {
                        e.preventDefault();
                        _checkFiles.call(self, typeof (self.options.upload.check.data) == 'function' ? self.options.upload.check.data.call(self, dataItems) : self.options.upload.check.data, {
                            reject: function (error) {
                                dataItems = self.options.upload.check.reject.call(self, error, dataItems);
                            },
                            done: function () {
                                if (dataItems.length > 0) {
                                    _copyItemsToObject.call(self, typeof (self.options.upload.copy.data) == 'function' ? self.options.upload.copy.data.call(self, { dataItems: dataItems }) : self.options.upload.copy.data, srcItemView);
                                }
                            }
                        });
                        wnd.close();
                    });
                    wnd.element.find("#moveButton").click(function (e) {
                        e.preventDefault();
                        _checkFiles.call(self, typeof (self.options.upload.check.data) == 'function' ? self.options.upload.check.data.call(self, dataItems) : self.options.upload.check.data, {
                            reject: function (error) {
                                dataItems = self.options.upload.check.reject.call(self, error, dataItems);
                            },
                            done: function () {
                                if (dataItems.length > 0) {
                                    _moveItemsToObject.call(self, typeof (self.options.upload.move.data) == 'function' ? self.options.upload.move.data.call(self, { moveFrom: srcItemView, dataItems: dataItems }) : self.options.upload.move.data, srcItemView);
                                }
                            }
                        });
                        wnd.close();
                    });
                    wnd.element.find("#cancelButton").click(function (e) {
                        e.preventDefault();
                        wnd.close();
                    });
                }
            });
        function _actionWindow(e) {
            var windowTemplate = "<div><div class='brc-window-layout'><div class='brc-window-layout__content'><div class='brc-window-layout__work-area'>Выберите действие</div></div><div class='brc-window-layout__footer'><div class='brc-form-action'><button class='brc-action k-button' id='copyButton'>Копировать</button><button class='brc-action k-button' id='moveButton'>Переместить</button><button id='cancelButton' class='brc-action k-button'>Отмена</button></div></div></div></div>";
            var wnd = $(windowTemplate).kendoWindow({
                modal: true,
                close: function (e) {
                    e.sender.destroy();
                }
            }).data("kendoWindow").center().open();
            return wnd;
        }
    }
    function _disableUpload(state) {
        var self = this;
        if (!!self.upload) {
            var dropTarget = self.container.data("kendoDropTarget");
            if (state && self.options.permissions.canCreateFile) {
                self.upload.enable();
                self.toolbar.enable("#paste", true);
                if (!!dropTarget)
                    dropTarget.setOptions({ group: "itemView" })
            }
            else {
                self.upload.disable();
                self.toolbar.enable("#paste", false);
                if (!!dropTarget)
                    dropTarget.setOptions({ group: kendo.guid() })
            }
        }
    }
    function _loadFilePermissions() {
        var self = this;
        BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "estate/file/permissions", null,
            function (e) {
                self.options.permissions = e;
            }, function () {
            }, false);
    }
    function _replaceFilter(filters) {
        var self = this;
        var filter = self.dataSource.filter();
        if (!!filter) {
            for (var i = 0; i < filter.filters.length; i++) {
                if (filter.filters[i].id == filters.id) {
                    filter.filters[i] = filters;
                    self.dataSource.filter(filter);
                    return;
                }
            }
            filter.filters = filter.filters.concat(filters);
            self.dataSource.filter(filter);
        }
        else {
            self.dataSource.filter(filters);
        }
    }

    function initSelectTable() {
        $('.table-sm').on('click', '.clickable-row', function (event) {
            if ($(this).hasClass('info')) {
                $(this).removeClass('info');
            } else {
                $(this).addClass('info');//.siblings().removeClass('info');
            }
        });
    }
    ui.plugin(ItemView);
})(jQuery);