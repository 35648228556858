function ContractPayPlanGrid() {
    var _grid;
    var _selector;
    var _contractPayId;
    var _contractPayIsPrepay;
    var _beforeCalculateCallback;

    return {
        init: init,
        setIsPrepay: setIsPrepay
    };

    function init(config) {
        _selector = $(config.selector);
        _contractPayId = _selector.find("#contractPayId").val();
        _beforeCalculateCallback = config.beforeCalculateCallback;

        setIsPrepay(_selector.find("#contractPayIsPrepay").val());

        _selector.find("#contract_pay_is_prepay_out").change(function () {
            if (this.checked) {
                ReadonlyHelper(_selector.find("#contract_pay_prepay_fact_date").parent(), false);
            }
            else {
                _selector.find("#contract_pay_prepay_fact_date").data("kendoDatePicker").value(null);
                ReadonlyHelper(_selector.find("#contract_pay_prepay_fact_date").parent(), true);
            }
        });
        _selector.find("#contract_pay_is_prepay_out").trigger("change");

        initGrid();
        initToolbar();
    }

    function initGrid() {
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyContractPayPlan",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportContractPayPlanGrid?contractPayId=" + _contractPayId,
            filter: { field: "contract_pay_id", operator: "eq", value: parseInt(_contractPayId) }
        };

        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());

        _grid = new BrcGrid(_selector.find("#grid"), dataSource).init(gridConfig);
        _grid.bind("dataBound", onDataBound);
    }
    
    function initToolbar() {
        var toolbar = _selector.find("#toolbar").kendoToolBar({
            items: [
                {
                    type: "button",
                    text: "Создать",
                    click: onCreate,
                    hidden: !hasPermission("canCreateContractPayPlan")
                },
                {
                    type: "button",
                    text: "Удалить",
                    click: onDelete,
                    hidden: !hasPermission("canDeleteContractPayPlan")
                },
                {
                    type: "button",
                    text: "Рассчитать",
                    click: onCalculateContractPayPlanClick,
                    hidden: !hasPermission("canCalculateContractPayPlan")
                },
                { type: "separator" },
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ]
        }).data("kendoToolBar");
    }

    function onCreate() {
        var contractPayPlanModal = new ContractPayPlanModal();
        contractPayPlanModal.init({
            contractPayPlanId: 0,
            contractPayId: _contractPayId,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
            }
        });
    }

    function onCalculateContractPayPlanClick() {
        BrcConfirm.show("Подтверждение рассчета", "Будет выполнено: сохранение договора, сохранение условия оплаты, сохранение выплаты аванса, удаление начислений, удаление оплат. Продолжить?")
            .done(function () {
                _beforeCalculateCallback(calculateContractPayPlan);
            });
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите начисление");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/DeleteContractPayPlanList",
                    { contractPayPlanIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="contract_pay_plan"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var contractPayPlanModal = new ContractPayPlanModal();
            contractPayPlanModal.init({
                contractPayPlanId: this.getAttribute("number"),
                contractPayId: _contractPayId,
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }

    function setIsPrepay(isPrepay) {
        _contractPayIsPrepay = isPrepay;

        if (!_contractPayIsPrepay) {
            _selector.find("#contract_pay_is_prepay_out").prop("checked", false);
            _selector.find("#contract_pay_prepay_fact_date").data("kendoDatePicker").value(null);
        }
        ReadonlyHelper(_selector.find("#prepayBlock"), !_contractPayIsPrepay);
        if (_contractPayIsPrepay) {
            ReadonlyHelper(_selector.find("#contract_pay_prepay_fact_date").parent(), true);
        }
    }

    function calculateContractPayPlan() {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/CalculateContractPayPlan",
            { contractPayId: _contractPayId },
            function () {
                BrcNotification.success("Выполнено");
                _grid.kendoGrid.dataSource.read();
            },
            function (data) {
                if (data.responseJSON && data.responseJSON.message) {
                    BrcNotification.error(data.responseJSON.message);
                }
                else {
                    BrcNotification.error("Операция не была выполнена!");
                }
            }
        );
    }
}