(function ($) {
    var ui = kendo.ui;
    var _options = {
        name: "FileManager",
        windowTemplate: '<div class="brc-window-layout"><div class="brc-window-layout__content"><div class="brc-window-layout__work-area"><div class="brc-window-layout__splitter splitter-no-border" id="fileManagerSplitter"><div id="fileManagerTreeView"></div><div><div class="brc-window-layout"><div class="brc-window-layout__content"><div class="pane-wrapper"><div class="item-view-wrapper" id="fileManagerItemView"></div></div></div></div></div></div></div><div class="brc-window-layout__footer"><div class="brc-form-action"><button id="buttonSaveChanges" class="brc-action k-button k-primary" type="submit"><span class="k-icon k-i-check"></span> ОК </button><button id="buttonCancel" class="brc-action k-button k-default"><span class="k-icon k-i-cancel"></span> Отмена </button></div></div></div></div></div>'
    }
    var FileManager = ui.GroupedGrid.extend({
        options: _options,
        init: init
    });
    var itemView;
    var treeView;
    var splitter;
    var wnd;
    function init(element, options) {
        var that = this;
        wnd = $(element).kendoWindow({
            height: "600px",
            width: "900px",
            title: "Менеджер файлов",
            close: function (e) {
                e.sender.destroy();
            }
        }).data("kendoWindow");
       
        wnd.content($(_options.windowTemplate));
        var windowSelector = $(wnd.element);
        splitter=windowSelector.find("#fileManagerSplitter").kendoSplitter({
            //panes: [{ size: "25%" }, { size: "45%" }, { size: "30%" }]
            panes: [{ size: "25%" }, { size: "75%" }]
        }).data("kendoSplitter");
        itemView = windowSelector.find("#fileManagerItemView").kendoItemView({
            windowStyle: true,
            title:"Файлы",
            itemTemplate: "<div class='item-wrapper'><div class='image-wrapper'>" +
                "<img src='#:data._image()#' class='item-image'/></div><div class='item-footer-wrapper'><div class='item-footer'><span>#:data.doc_file_name#</span></div></div></div>", dataSource: {
                    type: "odata", transport: { read: { dataType: "json", url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyFiles" } }, schema: {
                        data: function (data) {
                            return data["value"];
                        },
                        total: function (data) {
                            return !!data["odata.count"] ? data["odata.count"] : data["@@odata.count"]; //добавил Андрей, для меты
                        }
                    }, page: 1, pageSize: 100, serverPaging: true, serverFiltering: true, serverSorting: true
                }
        }).data("kendoItemView");
        treeView = windowSelector.find("#fileManagerTreeView").kendoBrcTreeView({
            isTree: true,
            parentKey: "parent_folder_doc_folder_id",
            key: "doc_folder_id",
            textField: "doc_folder_name",
            pathSelector: "#path",
            title: "Каталоги",
            windowStyle: true,
            virtualNode: {
                all: {
                    dropTarget: true,
                    text: "Все",
                    filters: [{}]
                },
                ungroupped: {
                    dropTarget: true,
                    text: "Не в каталоге",
                    filters: [{}]
                }
            },
            dataSource: {
                type: "odata",
                url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyFolder",
                dataType: "json"
            },
            create: function (e) {
                var entity = e;
                return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/File/CreateFolder", entity,
                function (e) {
                    BrcNotification.success("Выполнено")
                },
                function (e) {
                    BrcNotification.error(e.responseJSON)
                });
            },
            destroy: function (e) {
                var data = e;
                var req = $.Deferred();
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/File/DeleteFolder", data,
                function (e) {
                    BrcNotification.success("Выполнено");
                    req.resolve();
                },
                function (e) {
                    if (e.responseJSON.docs || e.responseJSON.files && (e.responseJSON.hasOwnProperty("docs") || e.responseJSON.hasOwnProperty("files"))) {

                        BrcConfirm.show("Подтверждение удаление каталога", "Вы действительно хотите удалить выбранный каталог? Будут удалены все документы и файлы в каталоге")
                        .done(function () {
                            BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/File/DeleteFolder", { folder: data, force: true },
                            function (e) {
                                BrcNotification.success("Выполнено");
                                req.resolve();
                            },
                            function (e) {
                                BrcNotification.error(e.responseJSON);
                                req.reject();
                            });
                        })
                    }
                    else {
                        BrcNotification.error(e.responseJSON);
                        req.reject();
                    }
                });
                return req;
            },
            update: function (e) {
                return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/File/UpdateFolder", e,
                function (e) {
                    BrcNotification.success("Выполнено")
                },
                function (e) {
                    BrcNotification.error(e.responseJSON)
                });
            },
            staticListSelect: function (sender, e) {
                var self = this;
                self.mainList.select($())
                var dataItem = sender.dataItem(e.node);
                var filters = [];
                var filter = {};
                if (dataItem.id == "ungroupped")
                    filter = { field: "doc_folder_id", operator: "eq", value: null };
                //else
                //    itemView.dataSource.filter({});
                itemView.dataSource.filter(filter);
                //brcGrid.kendoGrid.dataSource.filter(filter);
            },
            select: function (sender, e) {
                var self = this;
                self.staticList.select($())
                var dataItem = sender.dataItem(e.node);
                var filters = [];
                //var filter = { field: "doc_folder_id", operator: "eq", value: dataItem.doc_folder_id };
                //brcGrid.kendoGrid.dataSource.filter(filter);
                filter = {
                    logic: "or",
                    filters: [
                    { field: "doc_folder_id", operator: "eq", value: dataItem.doc_folder_id },
                { field: "file_folder_id", operator: "eq", value: dataItem.doc_folder_id },
                    ]
                };
                itemView.dataSource.filter(filter);

                var path = "Каталог: " + self.getCurrentPath(e.node, "");
                self.setPath(path, $(wnd.element).find("#path"));
                //else
                //    $("[uid=" + self.gridPaneUid + "]").find(".brc-card-title").text(path)
            }
        })
        
        wnd.bind("resize", function (e) {
            splitter.resize();
        })
        wnd.center().open();
    }
    ui.plugin(FileManager);
})(jQuery);