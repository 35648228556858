var AssetCostModal = function () {
    var _assetCostId;
    var _assetId;
    var _assetFixedId;
    var _onOKClose;
    var _assetCostCard;
    var _context;
    var _mode;

    return {
        init: init
    };

    function init(config) {
        _assetCostId = config.assetCostId;
        _assetId = config.assetId;
        _assetFixedId = config.assetFixedId;
        _onOKClose = config.onOKClose;
        _mode = config.mode;

        initWindow();
    }

    function initWindow(config) {
        var selector = "#assetCostWindow" + _assetCostId;


        var windowConfig = {
            id: selector,
            title: _assetCostId > 0 ? "Стоимость" : "Новая стоимость",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/AssetCost/AssetCostCard",
                data: {
                    id: _assetCostId,
                    assetId: _assetId,
                    assetFixedId: _assetFixedId
                }
            },
            width: 700,
            height: 400,
            onrefresh: onRefreshWindow
        }
        
        _assetCostCard = WindowManager.openOrAddWindow(windowConfig);
    }


    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        var validator = initControls(_context);
        initRecalculation();

        BrcMetaModule.init(_context.find("#assetCostAdditionallyBlock")[0]);

        if ((_mode === "readonly") || (_context.find("#canUpdateAssetCost").val() === 'false' && _assetCostId > 0) || (_context.find("#canCreateAssetCost").val() === 'false' && _assetCostId == 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetCost/SaveAssetCost",
                    $(event.target).serializeArray(),
                    function (data) {
                        BrcNotification.success("Выполнено");
                        _onOKClose();
                        WindowManager.closeWindow(true, _assetCostCard);
                    },
                    function (data) {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _assetCostCard);
        })
    }

    function initRecalculation() {
        var costBalanceCostBox = _context.find("#asset_cost_balance_cost").data("kendoNumericTextBox");
        var costWearBox = _context.find("#asset_cost_wear_cost").data("kendoNumericTextBox");
        var costRestCostBox = _context.find("#asset_cost_rest_cost").data("kendoNumericTextBox");

        costBalanceCostBox.bind("change", recalculate);
        costWearBox.bind("change", recalculate);

        recalculate();

        function recalculate() {
            var result = costBalanceCostBox.value() - costWearBox.value();
            if (costBalanceCostBox.value() != null && costWearBox.value() != null && result < 0) {
                BrcNotification.error("Амортизация не может быть больше балансовой стоимости!");
                costWearBox.value("");
                costRestCostBox.value("");
            }
            else if (result>=0) {
                costRestCostBox.value(result);
            }
        }
    }
};