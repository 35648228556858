(function ($) {
    var ui = kendo.ui;

    var _options = {
        name:"BrcSelectWindow"
    }
    var treeModeTemplate = '<div class="brc-window-layout"><div class="brc-window-layout__header"><div class="brc-window-layout__title">'+
        '<h4 class="brc-card-title" id="path"></h4></div></div>'+
        '<div class="brc-window-layout__content"><div class="brc-window-layout__work-area"><div class="brc-window-layout__splitter splitter-no-border" id="splitter">'+
        '<div id="list"></div><div><div class="pane-wrapper"><div class="brc-window-layout__content">'+
        '<div class="brc-window-layout__header"><div class="brc-window-layout__title"><h4 class="brc-card-title"></h4></div>'+
        '<div class="brc-window-layout__toolbar"><div class="brc-toolbar brc-toolbar-border-none brc-toolbar-right" id="documentsToolbar"></div></div></div>' +
        '<div class="brc-grid-wrapper" id="itemsGridWrapper"><div class="brc-grid" id="itemsGrid"></div></div></div></div></div></div></div></div><div class="brc-window-layout__footer"></div></div>';
    var BrcSelectWindow = ui.Window.extend({
        init: _init,
        initTreeMode: _initTreeMode,
        initGrid: _initGrid,
        options:_options
    });

    function _init(element, options) {
        var that = this;
        treeModeTemplate = $(treeModeTemplate);
        if (options.treeMode) {
            that.initTreeMode(options);
            that.initGrid(options);
            element = treeModeTemplate;
        }      
       
        ui.Window.fn.init.call(that, element, options);
        that.center().open();
    }

    function _initTreeMode(options) {
        var self = this;
        treeModeTemplate.find("#splitter").kendoSplitter({
            panes: [{ size: "25%" }, { size: "75%" }]
        });
        treeModeTemplate.find("#list").kendoBrcTreeView(options.treeConfig);
    }

    function _initGrid(options) {
        var self = this;
        treeModeTemplate.find("#itemsGrid").kendoBrcInlineGrid(options.gridConfig);
    }
    ui.plugin(BrcSelectWindow);
})(jQuery);