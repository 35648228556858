var BrcDialogSelect = function (selector, dataSource, defaultValues) {
    this.selector = selector;
    this.kendoControl = null;
    this.dataSource = dataSource;
    this.config = {};
    this.dialogSelector = null;
    this.kendoDialog = null;
    this.treeViewSelector = null;
    this.kendoTreeView = null;
    this.checkboxAllSelector = null;
    this.value = null;
    if (defaultValues) {
        this.value = defaultValues;
    } else {
        this.value = $(this.selector).val();
    }
    this.kendoTreeViewNodesCount = 0;
}

BrcDialogSelect.prototype.init = function (config) {
    var self = this;
    var defaultOptions = {
        multiple: false,
        dataTextField: "text",
        dataValueField: "id",
        dataParentValueField: null,
        hasChildren: false,
        dataChildrenField: "items",
        title: "Выбор значения",
        width: "600px",
        height: "800px",
        disableTopLevelCheckbox: false,
        searchPlaceholder: "Поиск...",
        selectAllPlaceholder: "Выбрать все",
        controlPlaceholder: "",
        dialogPageBlock: true,
        usingTextField: false,
        treeViewItemTemplate: ""
    };
    for (var option in defaultOptions) {
        if (defaultOptions.hasOwnProperty(option))
            this.config[option] = config && config[option] !== undefined ? config[option] : defaultOptions[option];
    }
    //Инициализация самого контрола

    if (this.config.multiple) {
        this.kendoControl = $(this.selector).kendoMultiSelect({
            dataTextField: this.config.dataTextField,
            dataValueField: this.config.dataValueField,
            placeholder: this.config.controlPlaceholder
        }).data("kendoMultiSelect");
        $(this.kendoControl.wrapper).find(".k-multiselect-wrap").append("<span class='k-select'><span class='k-icon k-i-search'></span></span>");
        $(this.kendoControl.wrapper).find(".k-multiselect-wrap > .k-select").on("click", function () {
            var listbox = $(self.kendoControl.wrapper).find("input[role=listbox]");
            if (listbox.attr("aria-disabled") !== "true") {
                self.kendoControl.open();
            }
        });
    } else {
        this.kendoControl = $(this.selector).kendoComboBox({
            dataTextField: this.config.dataTextField,
            dataValueField: this.config.dataValueField,
            placeholder: this.config.controlPlaceholder
        }).data("kendoComboBox");
        this.kendoControl.bind("change", function () {
            this.wrapper.find("input").attr("title", this.text());
        });

        var arrow = $(this.kendoControl.wrapper).find(".k-select > .k-i-arrow-60-down");
        arrow.removeClass("k-i-arrow-60-down");
        arrow.addClass("k-i-search");

        $(this.kendoControl.wrapper).click(function (e) {
            var element = $(this).find("input[role=combobox]");

            if (e.target === element[0] && element.attr("aria-disabled") !== "true") {
                e.preventDefault();
                self.kendoControl.open();
            }
        });
    }

    //----------------
    //Приватные методы
    //Метод снимающий галки со всех чекбоксов в treeview, кроме выбранного, если такой указан
    function uncheckAllNodes(nodes, checkedNode) {
        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].checked && (!checkedNode || nodes[i].uid !== checkedNode.uid)) {
                nodes[i].set("checked", false);
            }

            if (nodes[i].hasChildren) {
                uncheckAllNodes(nodes[i].children.view(), checkedNode);
            }
        }
    }
    //Метод, добавляющий данные в контрол из выбраных нод
    function insertControl(checkedNodes) {
        self.kendoControl.dataSource.data([]);
        var multiData = self.kendoControl.dataSource.data();
        if (checkedNodes.length > 0) {
            var value;
            if (self.config.multiple) {
                value = self.kendoControl.value().slice();
                for (var i = 0; i < checkedNodes.length; i++) {
                    var obj = {};
                    obj[self.config.dataTextField] = checkedNodes[i][self.config.dataTextField];
                    obj[self.config.dataValueField] = checkedNodes[i][self.config.dataValueField];
                    multiData.push(obj);
                    value.push(checkedNodes[i][self.config.dataValueField].toString());
                }
            } else {
                value = self.kendoControl.value();
                for (var i = 0; i < checkedNodes.length; i++) {
                    var obj = {};
                    obj[self.config.dataTextField] = checkedNodes[i][self.config.dataTextField];
                    obj[self.config.dataValueField] = checkedNodes[i][self.config.dataValueField];
                    multiData.push(obj);
                    value = checkedNodes[i][self.config.dataValueField].toString();
                }
            }
            self.value = value;

            self.kendoControl.dataSource.data(multiData);
            self.kendoControl.dataSource.filter({});
            self.kendoControl.value(value);
            self.kendoControl.trigger("change");
        }
    }
    //Метод, преобразующий дерево json к treeView формату, используя проперти из конфига
    var getPluginFormatDataSource = function (dataSource, config) {
        var result = {};
        var getFormatData = function (data) {
            var result = [];
            for (var i in data) {
                if (data.hasOwnProperty(i)) {
                    var item = {};
                    item[config.dataValueField] = data[i][config.dataValueField];
                    item[config.dataTextField] = data[i][config.dataTextField];
                    if (data[i][config.dataChildrenField]) {
                        item["items"] = getFormatData(data[i][config.dataChildrenField]);
                    }
                    result.push(item);
                }
            }
            return result;
        }
        if (dataSource.odata) {
            result = {
                serverSorting: true,
                serverPaging: true,

                transport: {
                    read: {
                        url: dataSource.odata.url,
                        type: dataSource.odata.type ? dataSource.odata.type : "get",
                        dataType: "json"
                    },
                    parameterMap: function (a, type) {
                        if (config.dataParentValueField) {
                            if (type == "read" && !!a[config.dataValueField]) {
                                return {
                                    $filter: kendo.format("{0} eq {1}", config.dataParentValueField, a[config.dataValueField]),
                                    $orderby: config.dataValueField,
                                    $inlinecount: "allpages"
                                }
                            } else if (type == "read") {
                                return {
                                    $filter: kendo.format("{0} eq {1}", config.dataParentValueField, null),
                                    $orderby: config.dataValueField,
                                    $inlinecount: "allpages"
                                }
                            }
                        }
                    }
                },
                schema: {
                    model: {
                        hasChildren: config.hasChildren,
                        id: config.dataValueField,
                        parentId: config.dataParentValueField
                    },
                    total: function (data) {
                        return !!data ? (!!data["odata.count"] ? data["odata.count"] : data["@odata.count"]) : 0;
                    },
                    data: "value"
                }
            };
        }
        else if (dataSource.data) {
            result.data = getFormatData(dataSource.data);
        }
        else if (dataSource.url) {
            $.ajax({
                url: dataSource.url,
                type: dataSource.odata?(dataSource.odata.type ? dataSource.odata.type : "get"):"get",
                async: false,
                success: function (data) {
                    result.data = getFormatData(data);
                },
                error: function () {
                    BrcNotification.error("Не удалось получить данные");
                    throw new Error("Не удалось получить данные с сервера");
                }
            });
        } else {
            throw new Error("Not supported dataSource configuration");
        }
        //debugger;
        if (dataSource.data || dataSource.url) {
            result.schema = {
                model: {
                    id: config.dataValueField,
                    children: config.dataChildrenField
                }
            };
        }
        return new kendo.data.HierarchicalDataSource(result);
    };
    //Метод возвращает выбранные ноды
    function getCheckedNodes(nodes, checkedNodes) {
        var node;
        for (var i = 0; i < nodes.length; i++) {
            node = nodes[i];
            if (node.checked) {
                var checkedNodeObj = {};
                checkedNodeObj[config.dataTextField] = node[config.dataTextField];
                checkedNodeObj[config.dataValueField] = node[config.dataValueField];
                checkedNodes.push(checkedNodeObj);
            }
            if (node.hasChildren) {
                getCheckedNodes(node.children.view(), checkedNodes);
            }
        }
    }
    //Метод, возвращающий общее колличество нод
    function getAllNodesCount(nodes) {
        var total = 0;
        for (var i = 0; i < nodes.length; ++i) {
            var el = nodes[i];
            if (el.hasChildren) {
                total += getAllNodesCount(el.items);
            }
            total++;
        }
        return total;
    }
    //Метод, выбирающий значение в дереве
    function checkValue(value) {
        if (value !== "") {
            var dataItem = self.kendoTreeView.dataSource.get(value);
            if (dataItem) {
                dataItem.set("checked", true);
                $(self.kendoTreeView.findByUid(dataItem.uid)).parentsUntil('.k-treeview').filter('.k-item').each(function (index, element) {
                    self.kendoTreeView.expand($(this));
                });
            }
        }
    }
    //Метод, проставляющий значения в дерево из контрола
    function setTreeViewValues() {
        var value = self.kendoControl.value();
        uncheckAllNodes(self.kendoTreeView.dataSource.view());
        if (self.config.multiple) {
            for (var i in value) {
                if (value.hasOwnProperty(i)) {
                    checkValue(value[i]);
                }
            }
        } else {
            checkValue(value);
        }
    }
    //Метод проставляющий чекбокс выбора всех элементов в зависимости от выбранных элементов
    function checkOrUncheckSelectAllCheckbox() {
        var checkedNodes = [];
        getCheckedNodes(self.kendoTreeView.dataSource.view(), checkedNodes);
        var selectAllNode = $(self.dialogSelector).find(self.checkboxAllSelector);
        if (self.kendoTreeViewNodesCount === checkedNodes.length && !selectAllNode.prop("checked")) {
            selectAllNode.prop("checked", true);
        }
        if (self.kendoTreeViewNodesCount > checkedNodes.length && selectAllNode.prop("checked")) {
            selectAllNode.prop("checked", false);
        }
    }
    //----------------
    //Создание врапперов и идентификаторов для элементов
    var dialogId = kendo.guid();
    $(this.kendoControl.wrapper).addClass("brc-dialog-select__control");
    var windowGuid = kendo.guid();
    $(this.kendoControl.wrapper).attr("data-brc-dialog-select-control-guid", windowGuid);
    var treeViewId = "treeview-" + dialogId;
    var dialogSelectAllId = "dialogSelectAll-" + kendo.guid();
    var innerTemplate = "<div class='brc-dialog-select'>" + (!this.dataSource.odata ? "<div class='k-textbox k-space-right brc-dialog-select__search-wrapper'><input id='dialogSelectSearch' type='text'  placeholder='" + this.config.searchPlaceholder + "'/><span class='k-icon k-i-search'></span></div>" : "") +
                (this.config.multiple && !this.dataSource.odata ? "<div class='brc-dialog-select__select-all-wrapper'><input data-role='checkbox' type='checkbox' class='k-checkbox' id='" + dialogSelectAllId + "'><label class='k-checkbox-label' for='" + dialogSelectAllId + "'>" + this.config.selectAllPlaceholder + "</label></div>" : "") +
                "<div class='brc-dialog-select__tree-view' id='" + treeViewId + "'></div></div>";
    var actionTemplate = "<div class='brc-form-action'>" +
                        "<button id=\"buttonSetValue\" class=\"brc-action k-button k-primary\"> OK </button>" +
                        "<button id=\"buttonCancel\" class=\"brc-action k-button k-default\"> Отмена </button>" +
                        "</div>";
    $("body").append("<div id='" + dialogId + "'><div class='brc-window-layout'><div class='brc-window-layout__content'><div class='brc-window-layout__work-area'>" + innerTemplate + "</div></div><div class='brc-window-layout__footer'>" + actionTemplate + "</div></div></div>");
    this.dialogSelector = "#" + dialogId;
    this.treeViewSelector = "#" + treeViewId;
    this.checkboxAllSelector = "#" + dialogSelectAllId;
    //----------------
    //Инициализация диалога и навешивание на кнопку ОК обработчика, который сетит данные в контрол
    this.kendoDialog = $(this.dialogSelector).kendoWindow({
        visible: false,
        width: this.config.width,
        height: this.config.height,
        title: this.config.title,
        actions: ["Maximize", "Close"],
        animation: false,
        modal: this.config.dialogPageBlock,
        close: function (e) //добавил Андрей, включаем валидацию и т.к. фокус на контрол не возвращается вызываем валидацию при закрытии окна селекта
        {
            $(self.selector).attr("data-no-validate", false);
            $(self.selector).parents('form').data("kendoValidator").validate();
        }
    }).data("kendoWindow");
    $(this.kendoDialog.wrapper).attr("data-brc-dialog-select-window-guid", windowGuid);
    //----------------
    //Инициализация дерева в диалоге и подписка на события кнопок
    var pluginFormatDataSource = getPluginFormatDataSource(this.dataSource, this.config);
    this.kendoTreeView = $(this.treeViewSelector).kendoTreeView({
        checkboxes: {
            checkChildren: false
        },
        check: function (e) {
            if (!self.config.multiple) {
                var checkedNode = self.kendoTreeView.dataItem(e.node);
                if (checkedNode.get("checked")) {
                    uncheckAllNodes(self.kendoTreeView.dataSource.view(), checkedNode);
                }
            } else {
                checkOrUncheckSelectAllCheckbox();
            }
        },
        loadOnDemand: this.dataSource.odata ? true : false,
        dataTextField: this.config.dataTextField,
        dataSource: pluginFormatDataSource,
        dataBound: function (e) {

        },
        template: this.config.treeViewItemTemplate
    }).data("kendoTreeView");


    this.kendoTreeView.one("dataBound", function (e) {
        if (self.config.disableTopLevelCheckbox) {
            $(this.wrapper).find("ul > li[role=treeitem]").find(".k-checkbox-wrapper").css("display", "none"); //$(".brc-dialog-select__tree-view > ul > [role=treeitem]").find(".k-checkbox-wrapper").css("display", "none")
        }
        if (self.dataSource.odata) {
            initValuesControl();
        }
    });

    $(this.dialogSelector).find("#buttonSetValue").on("click", function () {
        var checkedNodes = [];
        getCheckedNodes(self.kendoTreeView.dataSource.view(), checkedNodes);
        insertControl(checkedNodes);
        self.kendoDialog.close();
        $(self.selector).parents('form').data("kendoValidator").validate();
    });
    $(this.dialogSelector).find("#buttonCancel").on("click", function () {
        self.kendoDialog.close();
    });
    //----------------

    var getOdataServiceItemById = function (id) {
        var result = self.kendoTreeView.dataSource.get(id);
        if (!result) {
            $.ajax({
                type: self.dataSource.odata.type ? self.dataSource.odata.type : "get",
                url: self.config.usingTextField ? self.dataSource.odata.url + "?$filter=" + self.config.dataTextField + " eq '" + id + "'" : self.dataSource.odata.url + "?$filter=" + self.config.dataValueField + " eq " + id,
                async: false,
                success: function(data) {
                    result = data.value[0];
                }
            });
        }
        return result;
    }

    //Подсчет общего колличества элементов в дереве
    this.kendoTreeViewNodesCount = getAllNodesCount(this.kendoTreeView.dataSource.view());
    //----------------
    //Инициализация данных в контроле из dataSource при инициализации компонента
    function initValuesControl() {
        if (self.value) {
            var checkedNodes = [];
            if ($.isArray(self.value)) {
                for (var i in self.value) {
                    if (self.value.hasOwnProperty(i)) {
                        //self.kendoTreeView.dataSource.filter({ field: self.kendoTreeView.dataSource.options.schema.model.id, operator: "eq", value: self.value[i] });
                        checkedNodes.push(self.dataSource.odata ? getOdataServiceItemById(self.value[i]) : self.kendoTreeView.dataSource.get(self.value[i]));
                    }
                }
            } else {
                checkedNodes.push(self.dataSource.odata ? getOdataServiceItemById(self.value) : self.kendoTreeView.dataSource.get(self.value));
            }
            insertControl(checkedNodes);

            function getExpandParentNodePath(obj, array) {
                if (self.config.dataParentValueField) {
                    var parentNode = getOdataServiceItemById(obj[self.config.dataParentValueField]);
                    if (parentNode) {
                        array.unshift(parentNode[self.config.dataValueField]);
                        var item = self.kendoTreeView.dataSource.get(parentNode[self.config.dataValueField]);
                        if (!item) {
                            getExpandParentNodePath(parentNode, array);
                        }
                    }
                }
            }

            for (var i in checkedNodes) {
                if (checkedNodes.hasOwnProperty(i)) {
                    var expandPath = [];
                    var item = self.kendoTreeView.dataSource.get(checkedNodes[i][self.config.dataValueField]);
                    if (!item) {
                        getExpandParentNodePath(checkedNodes[i], expandPath);
                    }
                    if (expandPath.length > 0) {
                        self.kendoTreeView.expandPath(expandPath, function () {
                            initValuesControl();
                        });
                    }
                }
            }
        }
    }

    //Проставляем данные в дерево
    if (!this.dataSource.odata) {
        initValuesControl();
        setTreeViewValues(); //- Пока убрал, вернуть
    }
    //----------------

    //Переопределение открытия диалога, вместо открытия стандартного выбора
    this.kendoControl.open = function () {
        $(self.selector).attr("data-no-validate", true);//Добавил Андрей, чтобы контрол не валидировался при открытии селекта (потере фокуса с контрола)
        setTreeViewValues();
        self.kendoDialog.center().open();
        self.kendoControl.trigger("open");
        return false;
    }
    //Отменяем события родителей при клике на multiselect, для фикса бага с toFront модала, с которого вызван диалог
    this.kendoControl.wrapper.on("mousedown", function (e) {
        if (self.config.multiple) {
            e.stopPropagation();
        }
    });
    //Привязка данных из контрола
    this.kendoControl.bind("change", function (e) {
        setTreeViewValues();
        self.value = $(self.selector).val();
        checkOrUncheckSelectAllCheckbox();
    });
    //При  клике на диалоге, устанавливаем его вверх
    this.kendoDialog.wrapper.on("mousedown", function (e) {
        self.kendoDialog.toFront();
    });
    //----------------
    //Кнопка выбрать все
    $(this.dialogSelector).find(self.checkboxAllSelector).on("change", function () {
        function checkUncheckAllNodes(nodes, checked) {
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].set("checked", checked);

                if (nodes[i].hasChildren) {
                    checkUncheckAllNodes(nodes[i].children.view(), checked);
                }
            }
        }
        self.kendoTreeView.expand(".k-item");
        var isAllChecked = $(this).prop("checked");
        checkUncheckAllNodes(self.kendoTreeView.dataSource.view(), isAllChecked);
    });
    //----------------
    //Поиск по дереву
    if (!this.dataSource.odata) {
        $(this.dialogSelector).find("#dialogSelectSearch").on("keyup", function () {
            if (!self.dataSource.odata) {
                $(self.treeViewSelector).find('li.k-item').show();
                $(self.treeViewSelector).find('span.k-in > span.highlight').each(function () {
                    $(this).parent().text($(this).parent().text());
                });
                if ($.trim($(this).val()) === '') {
                    $(self.dialogSelector).find(".brc-dialog-select__select-all-wrapper").css("visibility", "visible");
                    $(self.treeViewSelector).find('span.k-in').each(function (index) {
                        $(this).html($(this).text());
                    });
                    return;
                } else {
                    $(self.dialogSelector).find(".brc-dialog-select__select-all-wrapper").css("visibility", "hidden");
                }
                var term = this.value.toUpperCase();
                var tlen = term.length;
                $(self.treeViewSelector).find('span.k-in').each(function (index) {
                    var text = $(this).text();
                    var html = '';
                    var q = 0;
                    var p;
                    while ((p = text.toUpperCase().indexOf(term, q)) >= 0) {
                        html += text.substring(q, p) + '<span class="brc-dialog-select__search-highlight">' + text.substr(p, tlen) + '</span>';
                        q = p + tlen;
                    }
                    if (q > 0) {
                        html += text.substring(q);
                        $(this).html(html);

                        $(this).parentsUntil('.k-treeview').filter('.k-item').each(function (index, element) {
                            self.kendoTreeView.expand($(this));
                            $(this).data('SearchTerm', term);
                        });
                    } else {
                        html = text;
                        $(this).html(html);
                    }
                });
                $(self.treeViewSelector).find('li.k-item:not(:has(".brc-dialog-select__search-highlight"))').hide();
                self.kendoTreeView.expand(".k-item");
            }
        });
    }
    //----------------

    return self;
}