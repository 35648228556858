var BrcMultiCombobox = function (selector, dataSourceUrl) {
    this.selector = selector;
    this.dataSourceUrl = dataSourceUrl;
}

BrcMultiCombobox.prototype.init = function (_placeholder, _dataTextField, _dataValueField, _defaultValue, _cleaningButton, _headerTemplate, _template) {
    var multicombobox = this;

    $(multicombobox.selector).wrap("<div class='brc-multicombobox-wrapper'></div>");

    $(multicombobox.selector).kendoMultiSelect({
        //placeholder: _placeholder,
        dataTextField: _dataTextField,
        dataValueField: _dataValueField,

        headerTemplate: _headerTemplate,
        template: _template,
        filter: "contains",
        autoBind: true,
        dataSource: {
            type: "odata",
            serverFiltering: true,
            transport: {
                read: {
                    url: multicombobox.dataSourceUrl,
                    dataType: "json"
                }
            },
            schema: {
                data: function (data) {
                    return data["value"];
                },
                total: function (data) {
                    return data["odata.count"];
                }
            }
        },
        value: _defaultValue
    });

    if (_cleaningButton != undefined && _cleaningButton == "true") {
        var element = $(multicombobox.selector);
        $("<button id='clean-button' type = 'button' class = 'cell brc-clean-button'></button>").insertAfter(element.parent());

        currButton = $(multicombobox.selector).parent().siblings("#clean-button").kendoButton({
            icon: "close",
            click: function () {
                $(multicombobox.selector).data("kendoMultiSelect").value("");
            }
        });
        element.parent().addClass("cell");
    }

    return multicombobox;
}