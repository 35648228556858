function AssetInventGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;
    var _assetId;
    var _mode;
    var _inventPlanId;
    var _parcelSelectModal;
    var _buildingSelectModal;
    var _flatSelectModal;
    var _equipmentSelectModal;

    return {
        init: init
    };

    function init(config) {
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);
        _inventPlanId = config.inventPlanId;
        _assetId = config.assetId;
        _mode = config.mode;

        initGrid();
        initToolbar();

        _parcelSelectModal = new InventPlanAssetSelectModal();
        _parcelSelectModal.init({
            inventPlanId: _inventPlanId,
            onSelect: function (assetIds) { addAssetListToInventPlan(assetIds); },
            assetType: "parcel"
        });

        _buildingSelectModal = new InventPlanAssetSelectModal();
        _buildingSelectModal.init({
            inventPlanId: _inventPlanId,
            onSelect: function (assetIds) { addAssetListToInventPlan(assetIds); },
            assetType: "building"
        });

        _flatSelectModal = new InventPlanAssetSelectModal();
        _flatSelectModal.init({
            inventPlanId: _inventPlanId,
            onSelect: function (assetIds) { addAssetListToInventPlan(assetIds); },
            assetType: "flat"
        });

        _equipmentSelectModal = new InventPlanAssetSelectModal();
        _equipmentSelectModal.init({
            inventPlanId: _inventPlanId,
            onSelect: function (assetIds) { addAssetListToInventPlan(assetIds); },
            assetType: "equipment"
        });
    }

    function initGrid() {
        switch (_mode) {
            case "inventPlan":
                var dataSource = {
                    type: "odata",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyAssetInvent",
                    dataType: "json",
                    excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportAssetInventGrid?inventPlanId=" + _inventPlanId,
                    filter: { field: "invent_plan_id", operator: "eq", value: parseInt(_inventPlanId) }
                };
                break;
            case "parcel":
            case "building":
            case "flat":
            case "equipment":
                var dataSource = {
                    type: "odata",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyAssetInvent",
                    dataType: "json",
                    excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportAssetInventGrid?assetId=" + _assetId,
                    filter: { field: "asset_id", operator: "eq", value: parseInt(_assetId) }
                };
                break;
        }

        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());

        //_grid = new BrcGrid(_selector.find("#grid"), dataSource).init(gridConfig);
        //_grid.bind("dataBound", onDataBound);

        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);
    }
    
    function initToolbar() {
        var items = [];

        switch (_mode) {
            case "inventPlan":
                items.push({
                    type: "button",
                    text: "Добавить ЗУ",
                    click: onAddParcel,
                    hidden: !hasPermission("canCreateAssetInvent")
                },
                    {
                        type: "button",
                        text: "Добавить ОКС",
                        click: onAddBuilding,
                        hidden: !hasPermission("canCreateAssetInvent")
                    },
                    {
                        type: "button",
                        text: "Добавить помещение",
                        click: onAddFlat,
                        hidden: !hasPermission("canCreateAssetInvent")
                    },
                    {
                        type: "button",
                        text: "Добавить движимое",
                        click: onAddEquipment,
                        hidden: !hasPermission("canCreateAssetInvent")
                    },
                    {
                        type: "button",
                        text: "Удалить акт",
                        click: onDeleteAssetInvent,
                        hidden: !hasPermission("canDeleteAssetInvent")
                    },
                    { type: "separator" });
                break;
            case "parcel":
            case "building":
            case "flat":
            case "equipment":
                items.push({
                        type: "button",
                        text: "Создать",
                        click: onCreate,
                        hidden: !hasPermission("canCreateAssetInvent")
                    },
                    {
                        type: "button",
                        text: "Удалить",
                        click: onDelete,
                        hidden: !hasPermission("canDeleteAssetInvent")
                    },
                    { type: "separator" });
                break;
        }
        items.push({
            type: "splitButton",
            text: "Таблица",
            menuButtons: [
                _brcGrid.filters(),
                _brcGrid.columnVisibility(),
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ],
        });

        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");
    }

    function onCreate() {
        var assetInventModal = new AssetInventModal();
        assetInventModal.init({
            assetInventId: 0,
            assetId: _assetId,
            onOKClose: function () { _grid.kendoGrid.dataSource.read(); }
        });
    }

    function onAddParcel() {
        _parcelSelectModal.open();
    }

    function onAddBuilding() {
        _buildingSelectModal.open();
    }

    function onAddFlat() {
        _flatSelectModal.open();
    }

    function onAddEquipment() {
        _equipmentSelectModal.open();
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите инвентаризацию");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/InventPlan/DeleteAssetInventList",
                    { assetInventIds: selectedIds },
                    function (data) {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function (data) {
                        BrcNotification.error("Произошла ошибка!");
                    }
                )
            });
    }

    function onDeleteAssetInvent() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length !== 1) {
            BrcNotification.error("Выберите один акт инвентаризации");
            return;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить акт инвентаризации?").done(function () {
            deleteAssetInvent(selectedIds[0]);
        });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="asset_invent"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var assetInventModal = new AssetInventModal();
            assetInventModal.init({
                assetInventId: this.getAttribute("number"),
                assetId: 0,
                onOKClose: function () { _grid.kendoGrid.dataSource.read(); }
            });
        }
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }

    function addAssetListToInventPlan(assetIds) {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/InventPlan/AddAssetListToInventPlan",
            { assetIds: assetIds, inventPlanId: _inventPlanId },
            function () {
                _grid.kendoGrid.dataSource.read();
                BrcNotification.success("Выполнено");
            },
            function () {
                BrcNotification.error("Произошла ошибка!");
            }
        );
    }

    function deleteAssetInvent(id) {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/InventPlan/DeleteAssetInvent",
            { "id": id },
            function () {
                BrcNotification.success("Выполнено");
                _grid.kendoGrid.dataSource.read();
            },
            function (xhr) {
                if (xhr.responseJSON.type == "error") {
                    BrcNotification.error(xhr.responseJSON.message);
                }
                else {
                    BrcNotification.error("Произошла ошибка!");
                }
            }
        );
    }
};