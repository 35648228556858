function ContractPayInlineCard() {
    var _contractPayId;
    var _assetContractId;
    var _selector;
    var _sumNumeric;
    var _sumPrepayNumeric;
    var _percentPrepayNumeric;
    var _isPrepayCheckBox;
    var _onIsPrepayChanged;
    var _sum;

    return {
        init: init
    };

    function init(config) {
        _selector = $(config.selector);
        _onIsPrepayChanged = config.onIsPrepayChanged;
        _contractPayId = _selector.find("#contract_pay_id").val();

        //Предполагается, что компонент помещен внутрь формы окна, где есть вызов initControls

        if ((_selector.find("#canUpdateContractPay").val() === 'false' && _contractPayId > 0) || (_selector.find("#canCreateContractPay").val() === 'false' && _contractPayId == 0)) {
            ReadonlyHelper(_selector, true);
        }

        _isPrepayCheckBox = _selector.find("#contract_pay_is_prepay");
        _percentPrepayNumeric = kendo.widgetInstance(_selector.find("#contract_pay_prepay_percent"));
        _sumPrepayNumeric = kendo.widgetInstance(_selector.find("#contract_pay_prepay_summ"));
        _sumNumeric = kendo.widgetInstance(_selector.find("#contract_pay_summ"));

        _sumNumeric.bind("change", function () {
            _sum = _sumNumeric.value();

            if (_sum === null || _sum == 0) {
                _sumPrepayNumeric.max(0);
                _percentPrepayNumeric.max(0);
            }
            else {
                _sumPrepayNumeric.max(_sum);
                _percentPrepayNumeric.max(100);
            }

            if (_sumPrepayNumeric.value() && _sumPrepayNumeric.value() > _sum) {
                _sumPrepayNumeric.value(_sum);
            }
            _sumPrepayNumeric.trigger("change");
        });
        _sumNumeric.trigger("change");

        _sumPrepayNumeric.bind("change", function () {
            var sumPrepay = _sumPrepayNumeric.value();
            if (sumPrepay === null) {
                _percentPrepayNumeric.value('');
            }
            else {
                var percent = sumPrepay / _sum * 100;
                _percentPrepayNumeric.value(percent || 0);
            }
        });

        _percentPrepayNumeric.bind("change", function () {
            var percent = _percentPrepayNumeric.value();
            if (percent === null) {
                _sumPrepayNumeric.value('');
            }
            else {
                var sumPrepay = percent * _sum / 100;
                _sumPrepayNumeric.value(sumPrepay);
            }
        });

        _isPrepayCheckBox.bind("change", function () {
            if ($(this).prop("checked")) {
                _percentPrepayNumeric.enable(true);
                $(_percentPrepayNumeric).prop("readonly", false)

                _sumPrepayNumeric.enable(true);
                $(_sumPrepayNumeric).prop("readonly", false)
            }
            else {
                _percentPrepayNumeric.value('');
                _percentPrepayNumeric.enable(false);
                $(_percentPrepayNumeric).prop("readonly", true)

                _sumPrepayNumeric.value('');
                _sumPrepayNumeric.enable(false);
                $(_sumPrepayNumeric).prop("readonly", true)
            }
            _onIsPrepayChanged($(this).prop("checked"));
        });

        if (!_isPrepayCheckBox.prop("readonly")) {
            _isPrepayCheckBox.trigger("change");
        }
    }
}