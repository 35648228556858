var BrcReports = function () {
    return {
        init: _init,
        _exportFormats: [
        { text: 'Pdf', value: 1 },
        { text: 'Xps', value: 2 },
        { text: 'Таблица HTML', value: 3 },
        { text: 'Текст HTML', value: 4 },
        { text: 'Блок HTML', value: 5 },
        { text: 'Rtf', value: 6 },
        { text: 'Rtf таблица', value: 7 },
        { text: 'Rtf рамка', value: 8 },
        { text: 'Rtf Word', value: 9 },
        { text: 'Rtf табулированный текст', value: 10 },
        { text: 'Текст', value: 11 },
        { text: 'Таблица Excel 2003', value: 13 },
        { text: 'Excel 2007', value: 14 },
        { text: 'Word 2007', value: 15 },
        { text: 'Xml', value: 16 },
        { text: 'Csv', value: 17 },
        { text: 'Dif', value: 18 },
        { text: 'Sylk', value: 19 },
        { text: 'Gif', value: 21 },
        { text: 'Bmp', value: 22 },
        { text: 'Png', value: 23 },
        { text: 'Tiff', value: 24 },
        { text: 'Jpeg', value: 25 },
        { text: 'Pcx', value: 26 },
        { text: 'Emf', value: 27 },
        { text: 'Svg', value: 28 },
        { text: 'Svgz', value: 29 },
        { text: 'Mht', value: 30 },
        { text: 'Dbf', value: 31 },
        { text: 'Html', value: 32 },
        { text: 'Ods', value: 33 },
        { text: 'Odt', value: 34 },
        { text: 'Power Point 2007', value: 35 },
        { text: 'Html5', value: 36 },
        { text: 'Data', value: 37 },
        { text: 'Document', value: 1000 }
        ]
    }
    function _init(owner, paramCallback) {
        var button;
        var available = false;
        BrcAjax.Get(window['APPLICATION_CONFIG'].routes.root + "RealtyBase/CheckModule?mod=16", {},
            function (e) {
                available = e;
            },
            function () {

            }, false);
        if (!available)
            return {};
        BrcAjax.Get(window['APPLICATION_CONFIG'].routes.root + "estate/ReportTEmplate/GetReportsOfOwner", { ownerName: owner },
            function (e) {               
                var buttons = [];
                e.forEach(function (template) {
                    var brcReport = new BrcReport(template, paramCallback);
                    brcReport.init();
                    
                    buttons.push({
                        text: template.report_template_name,
                        
                        click: function () {
                            brcReport.open();
                            //if (elem.report_template_is_embedded) {
                            //    var params = paramCallback.call(elem);
                            //    if (params === false) {
                            //        return;
                            //    }
                            //    else if (elem.ParametersCount>0 && (params.length<1)  ) {
                            //        BrcNotification.info("Необходимое число параметров: "+elem.ParametersCount+", передано "+params.length);
                            //        return;
                            //    }
                            //    var form = document.createElement("form");
                            //    form.action = window["APPLICATION_CONFIG"].routes.root + "estate/ReportTemplate/ReportViewer?reportId=" + elem.report_template_id;
                            //    form.target = "_blank";
                            //    form.method = "POST";
                            //    params.forEach(function (p) {
                            //        var input = document.createElement("input");
                            //        input.type = "hidden";
                            //        input.name = p.name;
                            //        input.value = p.value;
                            //        form.appendChild(input);
                            //    })
                            //    document.body.appendChild(form);
                                
                            //    form.submit();
                                
                            //}
                            //else {
                            //    window.open(window["APPLICATION_CONFIG"].routes.root + "estate/ReportTemplate/ReportViewer?reportId=" + elem.report_template_id, '_blank');
                            //    window.focus();
                            //}
                        }
                    })
                })
                button={
                    type: "brcButtonList",
                    text: "Отчеты",
                    list: buttons
                };
                
            },
            function (e) {
                //BrcNotification.error("Ошибка при загрузке списка отчетов");
            },false)
        return button;
    }
}();
var BrcReport = function (template, paramCallback) {
    this.reportTemplate = template;
    this.paramCallback = paramCallback;
};
BrcReport.prototype.init = function () {
    var self = this;
    this.window = $("<div/>").kendoReportVariables({
        reportTemplate: self.reportTemplate,
        additionalParameters: self.paramCallback
    }).data("kendoReportVariables");
};

BrcReport.prototype.open = function () {
    var self = this;

    self.window.dataSource.read().done(function () {
        var neededParameters = [];
        self.window.dataSource.view().forEach(function(p){
            if (!p.needRequest)
                neededParameters.push(p);
        })
        if (!!self.window._addParameters.call(self.window,neededParameters))
            self.window.center().open();
    });
};

(function ($) {
    var ui = kendo.ui;
    
    var _options = {
        reportId: null,
        name: "ReportVariables",
        dataSource: {
            transport: {
                read: {
                    url: window["APPLICATION_CONFIG"].routes.root + "estate/ReportTemplate/GetVariables",
                    data: null
                }
            }
        },
        //close: function (e) {
        //    /'e.sender.destroy();
        //},

        open: function (e) {
            var self = this;
        },
        
    }
    var ReportVariables = ui.Window.extend({
        init: _init,
        options: _options,
        dataSource: _dataSource,
        refresh: refresh,
        _parametersCount: 0,
        _addParameters:_addParameters
    })
    function _addParameters(requestParameters) {
        var self = this;
        if (!!self.options.additionalParameters) {
            var parameters = self.options.additionalParameters.call(self.options.reportTemplate, requestParameters);
            if (!!parameters && parameters.length > 0) {
                var form = $(self.element).find("form");
                parameters.forEach(function (p) {
                    form.append('<input type="hidden" name="' + p.name + '" value="' + p.value + '"/>')
                })
            }
            else if (!parameters)
                return false;
        }
        if (self._parametersCount < 1) {
            form.submit();
            self.close();
            return false;
        }
        return true;
    }
    function _init(element, options) {
        var that = this;
        options.width = "500px";
        ui.Window.fn.init.call(this, element, options);
        that.dataSource();
    }
    function refresh() {
        var that = this;
        var view = that.dataSource.view();
        
        var action = that.options.reportTemplate.report_template_is_auto_load ? window["APPLICATION_CONFIG"].routes.root + "estate/ReportTemplate/DownloadReport?reportId=" + that.options.reportTemplate.report_template_id :
                        window["APPLICATION_CONFIG"].routes.root + "estate/ReportTemplate/ReportViewer?reportId=" + that.options.reportTemplate.report_template_id;
        var target = that.options.reportTemplate.report_template_is_auto_load ?"_self":"_blank";
        var html = "<form target='"+target+"' method='POST' action='" + action + "' class='brc-form brc-full-height-form'><div class='brc-window-layout'><div class='brc-window-layout__content'><div class='brc-window-layout__work-area'><strong style='font-size:16px;'>Выберите формат выгрузки</strong>";
        html += kendo.render(_getTemplate(), view);
        html += "</div></div><div class='brc-window-layout__footer'> <div class='brc-form-action'>"+(that.options.reportTemplate.report_template_is_auto_load?"<input data-role='dropdownlist' data-text-field='text' data-value-field='value' data-bind='source:exportFormats' name='exportFormat'/>":"")+"<button id='saveButton' class='brc-action k-button k-primary' type='submit'><span class='k-icon k-i-check'></span> OK </button><button id='cancelButton' class='brc-action k-button k-default'><span class='k-icon k-i-cancel'></span> Отмена </button></div></div></div></form>";
        
        that.content(html);
        var form = $(that.element).find("form");
        that._parametersCount = form.find("input").length;
        form.find("#cancelButton").click(function (e) {
            e.preventDefault();
            that.close();
        })
        var model = kendo.observable({
            exportFormats: BrcReports._exportFormats
        });
        
        kendo.bind(form,model);
        initControls(form);        
    }
    //<div class="brc-form-group"><label for="exportFormat">Формат экспорта</label></div>
    function _getTemplate(item) {
        return kendo.template(
            '#if (data.needRequest){'+
            'switch (data.type) {'+
            'case "Int32":#'+
             '<div class="brc-form-group"><label for="#:data.name#">#:data.alias#</label><input data-decimals="0" data-role="numerictextbox" name="#=data.name#" #if(!data.nullable){#data-val-required#}# value="#:data.value#"/></div>' +
             '#break;#'+
             '#case "String":#'+
             '<div class="brc-form-group"><label for="#:data.name#">#:data.alias#</label><input class="k-textbox" type="text" name="#=data.name#" #if(!data.nullable){#data-val-required#}# value="#=kendo.format(data.value, "dd.MM.yyyy")#"/></div>' +
              '#break;#' +
              '#case "DateTime":#'+
            '    <div class="brc-form-group"><label for="#:data.name#">#:data.alias#</label><input  #if(!data.nullable){#data-val-required#}# data-placeholder="день.месяц.год" data-date-input="true" data-format="dd.MM.yyyy" data-role="brcdatepicker" name="#=data.name#" #if(data.nullable){#"required"#}# value="#:data.value#"/></div>' +
             '#break;#' +
             '#case "Boolean":#'+
            '<div class="brc-form-group"><label for="#:data.name#">#:data.alias#</label><input class="k-textbox" type="text" data-role="numerictextbox" name="#=data.name#" #if(!data.nullable){#data-val-required#}# value="#:data.value#"/></div>' +
             '#break;#' +
        '#}}#');
    }
    
    function _dataSource() {
        var that = this;
        $.extend(that.options.dataSource.transport.read, {
            data: {
                reportId: that.options.reportTemplate.report_template_id
            }
        });
        that.dataSource = kendo.data.DataSource.create(that.options.dataSource);
        that.dataSource.bind("change", function () {
            that.refresh();
        });

        that.dataSource.fetch();        
    }
    ui.plugin(ReportVariables);

})(jQuery);
(function ($) {
    var ui = kendo.ui;

    var BrcDatePicker = ui.DatePicker.extend({
        options:{
            name: "BrcDatePicker",
            mode:"date"
        },
        init: function (element, options) {
            var self = this;
            $(element).on("change", function () {
                var input = $(this);
                var control = input.data("kendoBrcDatePicker");
                var widget;
                if (self.options.dateInput)
                    widget = input.data("kendoDateInput");
                else
                    widget = control;

                if (widget && widget.value() === null && input.val()) {
                    control.value("");
                }
            });
            options.placeholder = $(element).attr("data-placeholder");
            ui.DatePicker.fn.init.call(this, element, options);
           
            if (self.value() === null)
                $(element).val("");
        }
    })

    ui.plugin(BrcDatePicker);

})(jQuery);
(function ($) {
    var ui = kendo.ui;

    var BrcDateTimePicker = ui.DateTimePicker.extend({
        options: {
            name: "BrcDateTimePicker",
            format: "dd.MM.yy HH:mm",
            parseFormats: ['dd.MM.yy HH:mm:ss', 'dd.MM.yyyy HH:mm:ss', 'dd.MM.yyyy HH:mm'],
        },
        init: function (element, options) {
            var self = this;
            options.value=$(element).val();
            $(element).on("change", function () {
                var input = $(this);
                var control = input.data("kendoBrcDateTimePicker");
                var widget;
                if (self.options.dateInput)
                    widget = input.data("kendoDateInput");
                else
                    widget = control;

                if (widget && widget.value() === null && input.val()) {
                    control.value("");
                }
            });
            //options.placeholder = $(element).attr("data-placeholder");
            ui.DateTimePicker.fn.init.call(this, element, options);
           
            if (self.value() === null)
                $(element).val("");
        }
    })

    ui.plugin(BrcDateTimePicker);

})(jQuery);
(function ($) {
    var ui = kendo.ui;

    var BrcTimePicker = ui.TimePicker.extend({
        options: {
            name: "BrcTimePicker",
            format: "HH:mm:ss",
            parseFormats:  ['dd.MM.yy HH:mm:ss', 'dd.MM.yyyy HH:mm:ss', 'dd.MM.yyyy HH:mm','HH:mm:ss'],
        },
        init: function (element, options) {
            var self = this;
            options.value = $(element).val();
            $(element).on("change", function () {
                var input = $(this);
                var control = input.data("kendoBrcTimePicker");
                var widget;
                if (self.options.dateInput)
                    widget = input.data("kendoDateInput");
                else
                    widget = control;

                if (widget && widget.value() === null && input.val()) {
                    control.value("");
                }

            });
            //options.placeholder = $(element).attr("data-placeholder");
            ui.TimePicker.fn.init.call(this, element, options);

            if (self.value() === null)
                $(element).val("");
        }
    })

    ui.plugin(BrcTimePicker);

})(jQuery);

(function ($) {
    var ui = kendo.ui;
    var _options = {
        name: "BrcReportSchedule",
        url: window["APPLICATION_CONFIG"].routes.root + "estate/reportTemplate/ReportScheduleCard",
        close: function (e) {
            e.sender.destroy();
        },
        refresh: _refresh,
        modal:true,
        report_id:0,
        report_schedule_id: 0,
        refreshCallBack:null
    }
    var BrcReportSchedule = ui.Window.extend({
        init: _init,
        options: _options,
        _initWindow: _initWindow,
        refresh:refresh
    })
    function _refresh(options) {
        var self = this;
        self._initWindow();
        self.center().open();
    }
    function _init(element, options) {
        var that = this;
        ui.Window.fn.init.call(this, element, options);
        that.refresh();
    }
    function refresh() {
        var self = this;
        ui.Window.fn.refresh.call(self, { data: { id: self.options.report_schedule_id, report_id:self.options.report_id }, url: self.options.url });        
    }
    function _initWindow(e) {
        var self = this;
        var form = $(self.element).find("form");
        initControls(form);
        form.find("[name=report_schedule_period]").kendoDropDownList({
            dataSource: {
                data: [
                    { text: "Неделя", value: "Week" },
                    { text: "Месяц", value: "Month" }
                ]
            },
            dataTextField: "text",
            dataValueField:"value"
        })
        var subjectDataSource = {
            odata: {
                type: "get",
                url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGrid",
                dataType: "json",
            }
        }
        var subjectSelect = new BrcDialogSelect(form.find("[name=subj_id]"), subjectDataSource);
        subjectSelect.init({
            multiple: true,
            dataTextField: "subj_name",
            dataValueField: "subj_id",
            treeViewItemTemplate: "#:kendo.format('{0} [{1}]', item.subj_name, (item.subj_email?item.subj_email:'нет email'))#",
            dataParentValueField: "",
            hasChildren: false,
            dataChildrenField: null,
            title: "Выбор субъектов",
            width: "600px",
            height: "800px",
            disableTopLevelCheckbox: false,
            searchPlaceholder: "Поиск...",
            selectAllPlaceholder: "Выбрать все",
            controlPlaceholder: "",
            dialogPageBlock: true,
            usingTextField: true
        })
        var formatDropDown =form.find("[name=report_schedule_default_format]").kendoDropDownList({
            dataSource: {
                data:BrcReports._exportFormats,
            },
            dataTextField: "text",
            dataValueField:"value"
        })
        form.find("[name=report_schedule_interval]").data("kendoBrcTimePicker").bind("change", function (e) {
            var val = e.sender.value();
            var date = new Date(0, 0, 0, val.getHours(), val.getMinutes(), val.getSeconds());
            if (date < new Date(0, 0, 0, 0, 5, 0))
                e.sender.value("00:05:00");
        });
        form.submit(function (e) {
            e.preventDefault();
            var data = $(this).serializeArray();
            var daysResult = 0;
            $(this).find(".k-checkbox[data-val]").each(function (i, c) {
                daysResult += c.checked ? parseInt($(c).attr("data-val")) : 0;
            })
            data.push({ name: "report_schedule_days", value: daysResult });
            BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root+"estate/ReportTemplate/SaveReportSchedule", data,
                function (e) {
                    BrcNotification.success("Выполнено");
                    self.options.report_schedule_id = e.report_schedule_id;
                    self.refresh();
                    if (!!self.options.refreshCallBack)
                        self.options.refreshCallBack.call(self);
                },
                function (e) {
                    BrcNotification.error(e.responseJSON);
                })
        });
        form.find("#cancelButton").click(function (e) {
            e.preventDefault();
            self.close();
        });
    }
    ui.plugin(BrcReportSchedule)
})(jQuery);