(function ($) {
    ui = kendo.ui,
    Widget = ui.ComboBox
    var ComboGrid = Widget.extend({

        options: {
            name: "ComboGrid",
            //selector: null,
            //textField: null,
            //valueField: null,
            //selectGrid: null,
            //subjectGridConfig: null,
            //selectWindow: null,
            template: '<div class="brc-window-layout brc-select-modal"><div class="brc-window-layout__header brc-window-layout_header-grid"><form class="form-inline brc-select-modal__form" ><div class="input-group brc-select-modal__form-search" ><span class="k-combobox k-widget  brc-select-modal__search"><span class="k-dropdown-wrap  brc-select-modal__wrapper"><input type="text" id="searchString" name="searchString"  class="k-input brc-select-modal__input " autocomplete="off" placeholder="Наименование, ИНН, ОГРН"><span class="k-select" unselectable="on" title="clear" role="button" tabindex="-1"><span class="k-icon k-i-close" ></span></span></span></span></div><div class="input-group brc-select-modal_form-create"><button class="k-button brc-action" type="button" id="createSubj">Создать</button></div></form></div><div class="brc-window-layout__content"><div class="brc-window-layout__work-area"><div class="brc-grid-wrapper"><div class="brc-grid" id="subjectSelectGrid"></div></div></div></div><div class="brc-window-layout__footer"><div class="brc-form-action"><button id="okButton" class="brc-action k-button k-primary"> OK </button><button id="cancelButton" class="brc-action k-button k-default">Отмена</button></div></div></div>',
            //input: null,
            //_input: null,
            //guid: kendo.guid(),
            //canCreate:true
        },
        init: function (element, options) {
            var that = this;
            Widget.fn.init.call(that, element, options);
            $(that.wrapper).addClass("brc-combobox");
            var openGridButton = $('<span unselectable="on" class="k-icon k-i-search brc-extend-button" title="table" role="button" tabindex="-1"></span>');
            $(that.wrapper).find(".k-dropdown-wrap").append(openGridButton);

            //var that = this;
            //BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "estate/subject/CheckPermission",
            //    {obj:25, type:2},
            //    function (e) {
            //        that.options.canCreate = e;
            //    })
            ////this.guid = kendo.guid();
            //if (!element) {
            //    throw "element cannot be null";
            //}
            //if (!options.textField) {
            //    throw "textField cannot be null";
            //}
            //if (!options.valueField) {
            //    throw "valueField cannot be null";
            //}
            //BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "Estate/Subject/SubjectGridConfig", {},
            //function (e) {
            //    options.subjectGridConfig = e;
            //},
            //function (e) {
            //    BrcNotification.error(e.responseJSON);
            //}, false);
            //kendo.ui.Widget.fn.init.call(that, element, options);

            //that.options.input = $(element)[0];
            //$(that.options.input).wrap("<span class='k-combobox k-widget  brc-select-control'></span>");
            //$(that.options.input).wrap("<span class='k-dropdown-wrap  brc-select-control__wrapper'></span>");
            //that.options.wrapper = $(that.options.input).parent();
            //that.options._input = $('<input type="hidden"  name="' + that.options.input.name + '" value=""/>').appendTo(that.options.wrapper);
            //var clearButton = $('<span class="k-select brc-select-control__clear" unselectable="on" title="clear" role="button" tabindex="-1"><span class="k-icon k-i-close" ></span></span>').appendTo(that.options.wrapper);
            //var searchButton = $('<span class="k-select" unselectable="on" tabindex="-1" title="open"><span class="k-icon k-i-search"  ></span></span>').appendTo(that.options.wrapper);
            //var showButton = $('<span class="k-select" unselectable="on" tabindex="-1"><span class="fa fa-eye" ></span></span>').appendTo(that.options.wrapper);
            ////$(that.options.input).attr("id", that.options.guid);
            //$(that.options.input).attr("name", that.options.guid);

            //$(that.options.input).removeClass('k-textbox');
            //$(that.options.input).addClass('k-input');
            //$(that.options.input).addClass("brc-subject-select__input");

            //$(that.options.input).click(function (e) {
            //    if (!($(that.options.input).is(".k-state-disabled") || $(that.options.input).attr("readonly") != null || $(that.options.input).attr("disabled") != null))
            //    that.open();
            //});
            //$(that.options.input).on("input change keyup", function (e) {
            //    e.preventDefault();
            //})
            //searchButton.click(function (e) {
            //    if (!($(that.options.input).is(".k-state-disabled") || $(that.options.input).attr("readonly") != null || $(that.options.input).attr("disabled") != null))
            //    that.open();
            //})
            //showButton.click(function (e) {
            //    //that.open();
            //    e.preventDefault();
            //        var id = parseInt($(that.options._input).val());
            //        if (id>0)
            //        {
            //            var modal = new SubjectModal();
            //            var config = {
            //                subjectId: id,
            //                onOKClose: function (e) {
            //                    return false;
            //                }
            //            }
            //            modal.init(config);
            //        }
            //})
            //clearButton.find(".k-i-close").click(function (e) {
            //    if (!($(that.options.input).is(".k-state-disabled") || $(that.options.input).attr("readonly") != null || $(that.options.input).attr("disabled") != null)) {
            //        e.stopPropagation();
            //        $(that.options.input).val('');
            //        $(that.options._input).val('');
            //    }
            //})
            ////openButton.click(function (e) {
            ////    e.preventDefault();
            ////    var id = parseInt($(that.options._input).val());
            ////    if (id>0)
            ////    {
            ////        var modal = new SubjectModal();
            ////        var config = {
            ////            subjectId: id,
            ////            onOKClose: function (e) {
            ////                return false;
            ////            }
            ////        }
            ////        modal.init(config);
            ////    }
            ////})

            //var val = $(that.options.input).val();
            //BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGrid?$filter=" + that.options.valueField + " eq " + (!!val ? val : "null"), {}, function (e) {
            //    if (e.value.length > 0) {
            //        $(that.options._input).val(e.value[0][that.options.valueField]);
            //        $(that.options.input).val(e.value[0][that.options.textField]);
            //    }
            //    else
            //        $(that.options.input).val(null);
            //});

        },
        enable: function (a, b) {
            var self = this.options;
            if (!a) {
                $(self.wrapper).addClass("k-state-disabled");
                $(self.input).addClass("k-state-disabled");
            }
            else {
                $(self.wrapper).removeClass("k-state-disabled");
                $(self.input).removeClass("k-state-disabled");
            }
        },

        //open: function () {
        //    var self = this.options;

        //    var subjSelModalCfg = {
        //        openImmidiate: true,
        //        height: 600,
        //        width: 900,
        //        title: "Выбор субъекта",
        //        content: self.template,
        //        onrefresh: refreshWindow,
        //        modal: true
        //    }

        //    self.selectWindow = WindowManager.addWindow(subjSelModalCfg);
        //    refreshWindow.call(self.selectWindow.windowHandler);

        //    function refreshWindow(e) {
        //        var container = $(this.element);
        //        var form = container.find("form");
        //        var string = form.find("#searchString");//$(self.elem).val();
        //        var dataSource = {
        //            type: "odata",
        //            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGrid",
        //            dataType: "json",
        //            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportSubjectGrid",
        //        };
        //        self.selectGrid = new BrcGrid(container.find("#subjectSelectGrid"), dataSource);
        //        self.selectGrid.init(self.subjectGridConfig);
        //        self.selectGrid.bind("dataBound", function (e) {

        //            self.selectGrid.selector.find('[data-toggle-window="subject"]').click(onClick);

        //            function onClick(e) {
        //                e.preventDefault();

        //                var subjectModal = new SubjectModal();
        //                subjectModal.init({
        //                    subjectId: this.getAttribute("number"),
        //                    onOKClose: function () {
        //                        self.selectGrid.kendoGrid.dataSource.read();
        //                        self.selectGrid.kendoGrid.resize();
        //                    }
        //                });
        //            }

        //        })

        //        form.submit(function (e) {
        //            e.preventDefault();
        //            search(string.val());
        //        })
        //        form.find("#searchString").on("keyup input", function (e) {

        //            search(string.val());
        //        })
        //        form.find("[title=clear]").click(function (e) {
        //            string.val(null);
        //            search(string.val());
        //        })
        //        if (!!self.canCreate) {
        //            form.find("#createSubj").click(function (e) {
        //                e.preventDefault();
        //                var modal = new SubjectModal();
        //                var config = {
        //                    subjectId: 0,
        //                    onOKClose: function (e) {
        //                        self.selectGrid.kendoGrid.dataSource.filter({ field: self.valueField, operator: "eq", value: parseInt(e) });
        //                        WindowManager.closeWindow(true, this);
        //                        self.selectGrid.kendoGrid.dataSource.read().then(function () {
        //                            var obj = self.selectGrid.kendoGrid.dataSource.get(parseInt(e))
        //                            $(self._input).val(obj[self.valueField]);
        //                            $(self.input).val(obj[self.textField]);
        //                            $(self.input).parents("form").data("kendoValidator").validateInput($(self.input));
        //                            self.selectWindow.windowHandler.destroy();
        //                        });
        //                        return true;
        //                    }
        //                }
        //                modal.init(config);
        //            });

        //        }
        //        else {
        //            form.find("#createSubj").addClass("k-state-disabled");
        //        }
        //        container.find("#okButton").click(function (e) {
        //            var val = self.selectGrid.kendoGrid.dataItem(self.selectGrid.kendoGrid.select()[0]);

        //            if (!!val) {
        //                $(self._input).val(val[self.valueField]);
        //                $(self.input).val(val[self.textField]);
        //            }
        //            else {
        //                $(self.input).val(null);
        //                $(self._input).val(null);
        //            }
        //            $(self.input).parents("form").data("kendoValidator").validateInput($(self.input));
        //            self.selectWindow.windowHandler.destroy();

        //        })
        //        container.find("#cancelButton").click(function (e) {
        //            $(self.input).parents("form").data("kendoValidator").validateInput($(self.input));
        //            self.selectWindow.windowHandler.destroy();
        //        })
        //    }
        //    function search(searchString) {

        //        self.selectGrid.kendoGrid.dataSource.filter({
        //            logic: "or", filters: [
        //                { field: "tolower(subj_name)", operator: "contains", value: searchString.toLowerCase() },
        //              { field: "tolower(subj_sname)", operator: "contains", value: searchString.toLowerCase() },
        //            { field: "tolower(subj_inn)", operator: "contains", value: searchString.toLowerCase() },
        //            { field: "tolower(subj_ogrn)", operator: "contains", value: searchString.toLowerCase() }]
        //        })

        //    }
        //}
    });
    ui.plugin(ComboGrid);
})(jQuery);
