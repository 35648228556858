var BrcMetaModule = function () {
    //Data Service
    var needInit = true;
    var brcMetaModuleService = function () {
        return {
            getMethod: function (url, doneCb, failCb) {
                $.ajax({
                    url: url,
                    type: 'get',
                    success: function (data) {
                        if (doneCb) {
                            doneCb(data);
                        }
                    },
                    error: function () {
                        if (failCb) {
                            failCb();
                        }
                    }
                });
            }
        };
    }();
    //Init
    var initMetaControls = function (wrapper) {
        initControls($(wrapper).find('[data-role="brc-meta-module-form"]'));
    };
    var initMetaValidation = function (wrapper) {
        var validator = $(wrapper).find('[data-role="brc-meta-module-form"]').kendoValidator({
            errorTemplate: kendo.template('<span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg">#=message#</span>'),
            validate: function(e) {
                var self = e.sender;
                $(wrapper).find('[data-role="brc-meta-module-form"]').find('[data-kendo-control]').each(function(counter, item) {
                    self.validateInput($(this));
                });
            }
        }).data("kendoValidator");
        $(wrapper).closest("form").on("submit", function (e) {
            if (!validator.validate()) {
                e.preventDefault();
            }
        });
    };
    var handleAddMetaPropertiesButton = function (wrapper) {
        $(wrapper).find('[data-role="brc-meta-module-form"] #addMetaPropertiesButton').on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass("k-state-disabled")) {
                return;
            }
            var formElement = $(this).closest("[data-role='brc-meta-module-form']");
            var href = this.href;
            $(this).css("visibility", "hidden");
            brcMetaModuleService.getMethod(href,
                function (data) {
                    formElement.html($(data).wrap("<div></div>").parent().find("[data-role='brc-meta-module-form']").html());
                    initMetaControls(wrapper);
                    initMetaValidation(wrapper);
                    handleHideMetaPropertiesButton(wrapper);
                },
                function () {
                    BrcNotification.error("Произошла внутренняя ошибка сервера");
                });
        });
    };
    var handleHideMetaPropertiesButton = function(wrapper) {
        $(wrapper).find('[data-role="brc-meta-module-form"] #hideMetaPropertiesButton').on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass("k-state-disabled")) {
                return;
            }
            var formElement = $(this).closest("[data-role='brc-meta-module-form']");
            var href = this.href;
            $(this).css("visibility", "hidden");
            brcMetaModuleService.getMethod(href,
                function(data) {
                    formElement.html($(data).wrap("<div></div>").parent().find("[data-role='brc-meta-module-form']").html());
                    handleAddMetaPropertiesButton(wrapper);
                },
                function() {
                    BrcNotification.error("Произошла внутренняя ошибка сервера");
                });
        });
    };
    var handleDeleteMetaPropertiesButton = function (wrapper) {
        $(wrapper).find('[data-role="brc-meta-module-form"] #deleteMetaPropertiesButton').on("click", function (e) {
            e.preventDefault();
            if ($(this).hasClass("k-state-disabled")) {
                return;
            }
            var self = this;
            if (!$("#metaFormConfirmation")[0]) {
                $("body").append("<div id='metaFormConfirmation'></div>");
            }
            var confirm = $("#metaFormConfirmation").kendoConfirm({
                messages: {
                    okText: "OK",
                    cancel: "Отмена"
                },
                width: 300,
                buttonLayout: "normal",
                title: "Удаление",
                close: function (e) {
                    e.sender.destroy();
                },
                content: "Вы действительно хотите удалить все свойства?"
            }).data("kendoConfirm");
            confirm.open();
            confirm.result.done(function() {
                var formElement = $(self).closest("[data-role='brc-meta-module-form']");
                var href = self.href;
                $(self).css("visibility", "hidden");
                brcMetaModuleService.getMethod(href,
                    function (data) {
                        formElement.html($(data).wrap("<div></div>").parent().find("[data-role='brc-meta-module-form']").html());
                        handleAddMetaPropertiesButton(wrapper);
                    },
                    function () {
                        BrcNotification.error("Произошла внутренняя ошибка сервера");
                    });
            }).fail(function() {
            });
            //kendo.confirm("Вы действительно хотите удалить все свойства?").then(function () {
                
            //});
        });
    };
    return {
        init: function (wrapper,_needInit ) {
            if (_needInit!=undefined && _needInit!=null )
            needInit = _needInit;
            if (needInit)
                initMetaControls(wrapper);
            initMetaValidation(wrapper);
            handleAddMetaPropertiesButton(wrapper);
            handleDeleteMetaPropertiesButton(wrapper);
        }
    };
}();