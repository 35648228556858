function EquipmentGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;
    var _assetId;
    var _subjId;
    var _assetComplexId;
    var _mode;
    var _showAll = true;
    var _excelReportUrl = "Estate/Report/ExcelReportEquipmentGrid";
    var _displayName;
    var _modeKind;

    var _moveManager;
    //~~~~~~~~~~~~~~~~~~~~~~

    var _config;
    var _treeViewLink;
    var _toolbar;
    var _tree;
    //~~~~~~~~~~~~~~~~~~~~~~
    return {
        init: init,
        _create: onCreate
    };

    function init(config) {
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);
        _assetId = config.assetId;
        _assetComplexId = config.assetComplexId;
        _subjId = config.subjId;
        _mode = config.mode;
        _modeKind = config.modeKind;

        _config = config;
        initGrid(_showAll);
        _grid.kendoGrid.dataSource.transport.update = function (e) {
            BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Equipment/SaveEquipment", { _equipment: JSON.parse(kendo.stringify(e.data)) },
                function (d) {
                    e.success(d);
                }, function (d) {
                    e.error(d)
                });
        }
        if (_toolbarSelector.length > 0) {
            _toolbar = _toolbarSelector.kendoToolBar({

            }).data("kendoToolBar");
            initToolbar(config.isTree);

        }


        _toolbar.add({

            type: "brcButtonList",
            text: "Таблица",
            list: [
            _brcGrid.filters(),
            _brcGrid.columnVisibility(),
            { text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ],
        })
        
        var gridFilter = _grid.kendoGrid.dataSource.filter();
        var kindFilter = {
            id: "equipKindFilter", logic: "or", filters: [
                { id: "equipKindFilter1", field: "equip_kind_enum", operator: "eq", value: 1 },
                { id: "equipKindFilter2", field: "equip_kind_enum", operator: "eq", value: 2 },
                { id: "equipKindFilter3", field: "equip_kind_enum", operator: "eq", value: 3 },
                { id: "equipKindFilter4", field: "equip_kind_enum", operator: "isnull" }]
        };
        if (!!gridFilter) {
            gridFilter.filters = gridFilter.filters.concat(kindFilter)
        }
        else {
            var f = { logic: "and", filters: [kindFilter] }
            _grid.kendoGrid.dataSource.filter(f)
        }
        $(document).ready(function () {
            _toolbar.resize();
        })
        //filter1(, "equipKindFilter")
        return _grid;
        
    }

    function initGrid(showAll) {
        var filter = initFilter();

        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyEquipment",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + _excelReportUrl,
            filter: filter
        };

        if (!_config.gridConfig)
            var gridConfig = JSON.parse(_selector.find("#gridConfig").val());
        else
            var gridConfig = _config.gridConfig;

        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);

        _displayName = gridConfig.DisplayName;
        _grid.kendoGrid.dataSource.transport.update = function (e) {
            BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Equipment/SaveEquipment", { _equipment: JSON.parse(kendo.stringify(e.data)) },
                function (d) {
                    e.success(d);
                }, function (d) {
                    e.error(d)
                });
        }

        return _grid;
    }
    function _initTree(selector) {

        var g = $(selector).kendoGroupedGrid({
            tableViewLink: _config.tableViewLink,
            brcListConfig: {
                title: " ",
                isTree: true,
                parentKey: "parent_equip_type_id",
                key: "equip_type_id",
                textField: "equip_type_name",
                path: "Движимое имущество: ",
                pathSelector: $(".brc-card-title")[0],
                virtualNode: {
                    all: {
                        dropTarget: false,
                        //message: "Группа номенклатуры является обязательным полем"
                    },
                },
                dataSource: {
                    type: "odata",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyEquipmentType/FilterVisibleAndArch",
                    dataType: "json",
                    reqType:"post",
                   
                },
                create: function (e) {
                    delete e.index;
                    delete e.value;
                    delete e.expanded;
                    delete e.selected;
                    var entity = e;
                    entity.equip_type_cr_date = new Date();
                    entity.equip_type_is_visible = true;
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyEquipmentType", kendo.stringify(entity),
                   function (e) { BrcNotification.success("Выполнено") },
                    function (e) { BrcNotification.error(e.responseJSON["odata.error"].message.value) }, false, { contentType: "application/json" });
                },
                destroy: function (e) {
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyEquipmentType/DeleteEntity?key=" + e.equip_type_id, e,
                    function (e) {
                        BrcNotification.success("Выполнено")
                    })
                    .fail(function (e) {
                        BrcNotification.error(e.responseJSON)
                    });
                },
                update: function (e) {
                    delete e.index;
                    delete e.value;
                    delete e.expanded;
                    delete e.selected;
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyEquipmentType/UpdateEntity?key=" + e.equip_type_id,
                         kendo.stringify(e),
                    function (e) {
                        BrcNotification.success("Выполнено");
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON["odata.error"].message.value);
                    },
                    true,
                    { contentType: "application/json" });


                },
            },
            brcGridConfig: {
                externalInit: {
                    grid: _grid,
                    toolbar: _toolbar
                },
                changeGroup: function (items, newGroup) {
                    var itemsIds = [];
                    items.forEach(function (elem) {
                        itemsIds.push(elem.asset_id);
                    })
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Equipment/ChangeGroup", { equipmentIds: itemsIds, newGroup: newGroup },
                       function (d) {
                           BrcNotification.success("Выполнено")
                       }, function (d) {
                           BrcNotification.error(d.error.message.value);
                       });
                },
                filters: !!_grid.kendoGrid.dataSource.filter() ? _grid.kendoGrid.dataSource.filter().filters : [],

            },
            permissions: {
                canChangeGroup: hasPermission("canChangeGroup"),
                canDeleteGroup: hasPermission("canDeleteGroup"),
                canCreateGroup: hasPermission("canCreateGroup"),
                canChangeObject: hasPermission("canUpdateEquipment"),
            }
        }).data("kendoGroupedGrid");
        return g;
    }
    function initFilter() {
        switch (_mode) {
            case "list":
                var filter = {};
                _excelReportUrl = "Estate/Report/ExcelReportEquipmentGrid?kind=" + _modeKind;
                break;
            case "assetComplex":
                if (_showAll) {
                    var filter = {
                        logic: "and",
                        filters: [
                            //{ field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                            { field: "asset_complex_id", operator: "eq", value: parseInt(_assetComplexId), unmanaged: true }
                        ]
                    };
                }
                else {
                    var filter = {
                        logic: "and",
                        filters: [
                           // { field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                            { field: "asset_complex_id", operator: "eq", value: parseInt(_assetComplexId), unmanaged: true },
                            { field: "parcel_asset_id", operator: "eq", value: null, unmanaged: true },
                            { field: "bld_asset_id", operator: "eq", value: null, unmanaged: true },
                            { field: "flat_asset_id", operator: "eq", value: null, unmanaged: true }
                        ]
                    };

                }
                _excelReportUrl = "Estate/Report/ExcelReportEquipmentGrid?assetComplexId=" + _assetComplexId + "&showAll=" + _showAll + "&kind=" + _modeKind;
                break;
            case "parcel":
                if (_showAll) {
                    var filter = {
                        logic: "and",
                        filters: [
                            //{ field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                            { field: "parcel_asset_id", operator: "eq", value: parseInt(_assetId), unmanaged: true }
                        ]
                    };
                }
                else {
                    var filter = {
                        logic: "and",
                        filters: [
                            //{ field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                            { field: "parcel_asset_id", operator: "eq", value: parseInt(_assetId), unmanaged: true },
                            { field: "bld_asset_id", operator: "eq", value: null, unmanaged: true },
                            { field: "flat_asset_id", operator: "eq", value: null, unmanaged: true }
                        ]
                    };
                }
                _excelReportUrl = "Estate/Report/ExcelReportEquipmentGrid?parcel_asset_id=" + _assetId + "&showAll=" + _showAll + "&kind=" + _modeKind;
                break;
            case "building":
                if (_showAll) {
                    var filter = {
                        logic: "and",
                        filters: [
                           // { field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                            { field: "bld_asset_id", operator: "eq", value: parseInt(_assetId), unmanaged: true }
                        ]
                    };
                }
                else {
                    var filter = {
                        logic: "and",
                        filters: [
                           // { field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                            { field: "bld_asset_id", operator: "eq", value: parseInt(_assetId), unmanaged: true },
                            { field: "flat_asset_id", operator: "eq", value: null, unmanaged: true }
                        ]
                    };
                }
                _excelReportUrl = "Estate/Report/ExcelReportEquipmentGrid?bld_asset_id=" + _assetId + "&showAll=" + _showAll + "&kind=" + _modeKind;
                break;
            case "flat":
                var filter = {
                    logic: "and",
                    filters: [
                       // { field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                        { field: "flat_asset_id", operator: "eq", value: parseInt(_assetId), unmanaged: true }
                    ]
                };
                _excelReportUrl = "Estate/Report/ExcelReportEquipmentGrid?flat_asset_id=" + _assetId + "&showAll=" + _showAll + "&kind=" + _modeKind;
                break;
            case "invest_project":
                var filter = {
                    logic: "and",
                    filters: [
                        //{ field: "equip_kind_enum", operator: "eq", value: parseInt(_modeKind) },
                        { field: "parcel_asset_id", operator: "eq", value: parseInt(_assetId), unmanaged: true }
                    ]
                };
                _excelReportUrl = "Estate/Report/ExcelReportEquipmentGrid?parcel_asset_id=" + _assetId + "&showAll=" + _showAll + "&kind=" + _modeKind;
                break;
        }
        return filter;
    }

    function initToolbar(isTree) {
        var items = [];

        if (_mode != "invest_project") {


            if (_mode != "list") {
                if (_mode != "flat") {
                    _toolbar.add({
                        type: "button",
                        id: "btnViewParentAssetMode",
                        text: "Все записи",
                        click: onShowAll,
                        enable: false
                    });
                }

                if (hasPermission("canCreateEquipment")) {
                    _toolbar.add({
                        type: "brcButtonList",
                        text: "Создать",
                        list: [
                            {
                                text: "Движимое имущество", click: function (e) {
                                    onCreate.call(this, 1);
                                }
                            },
                            {
                                text: "Транспорт", click: function (e) {
                                    onCreate.call(this, 2);
                                }
                            },
                            {
                                text: "Особо ценное имущество", click: function (e) {
                                    onCreate.call(this, 3);
                                }
                            }
                        ],
                    });
                }
                if (hasPermission("canDeleteEquipment")) {
                    _toolbar.add({
                        type: "button",
                        text: "Удалить",
                        click: onDelete
                    });
                }
            }

        }

        
        if (isTree)
            _tree = _initTree($("#splitter"));

        _toolbar.add({
            type: "buttonGroup",
            buttons: [
                {
                    type: "button", togglable: true, selected: true, equipId: 1, text: "Движимое имущество", id: "equip", toggle: function (e) {
                        var gridFilters = _grid.kendoGrid.dataSource.filter();
                        var f2 = _grid.findFilterById(gridFilters.filters, "equipKindFilter1");
                        f2.operator = e.checked ? "eq" : "isnull";
                        _grid.kendoGrid.dataSource.filter(gridFilters);
                    }
                },
                {
                    type: "button", togglable: true, selected: true, equipId: 2, text: "Транспорт", id: "equip", toggle: function (e) {
                        var gridFilters = _grid.kendoGrid.dataSource.filter();
                        var f2 = _grid.findFilterById(gridFilters.filters, "equipKindFilter2");
                        f2.operator = e.checked ? "eq" : "isnull";
                        _grid.kendoGrid.dataSource.filter(gridFilters);
                    }
                },
                {
                    type: "button", togglable: true, selected: true, equipId: 3, text: "Особо ценное имущество", id: "equip", toggle: function (e) {
                        var gridFilters = _grid.kendoGrid.dataSource.filter();
                        var f2 = _grid.findFilterById(gridFilters.filters, "equipKindFilter3");
                        f2.operator = e.checked ? "eq" : "isnull";
                        _grid.kendoGrid.dataSource.filter(gridFilters);
                    }
                },
            ]
        })

        if (_mode != "invest_project") {
            //_toolbar.add({ type: "separator" });

           

            if (_mode != "list" && (hasPermission("canMoveEquipmentToAssetComplex") || hasPermission("canMoveEquipmentToRealty"))) {
                _toolbar.add({
                    type: "button",
                    text: "Включить в инвентаризацию",
                    click: onAddToInventPlan,
                    hidden: !hasPermission("canCreateAssetInvent")
                });

                if (_mode != "list" && (hasPermission("canMoveEquipmentToAssetComplex") || hasPermission("canMoveEquipmentToRealty"))) {
                    _toolbar.add({
                        type: "brcButtonList",
                        text: "Переместить",
                        list: [
                            {
                                text: "в ЗУ",
                                click: onMoveToParcel,
                                hidden: !hasPermission("canMoveEquipmentToRealty")
                            },
                            {
                                text: "в ОКС",
                                click: onMoveToBuilding,
                                hidden: !hasPermission("canMoveEquipmentToRealty")
                            },
                            {
                                text: "в помещение",
                                click: onMoveToFlat,
                                hidden: !hasPermission("canMoveEquipmentToRealty")
                            },
                            {
                                text: "в ИК",
                                click: onRemoveFromRealty,
                                hidden: !hasPermission("canMoveEquipmentToAssetComplex")
                            },
                             {
                                text: "в другой ИК",
                                click: onMoveToAnotherAssetComplex,
                                hidden: !hasPermission("canMoveEquipmentToAssetComplex")
                            }]
                    });
                }
                

            }
        }
        _toolbar.add({
            type: "button",
            text: "На карту",
            click: navToMapFromGrid
        });

        _toolbar.add({
            type: "brcButtonList",
            text: "1С",
            list: [
                {
                    text: "Просмотреть данные",
                    click: show1C
                },
                {
                    text: "Синхронизировать",
                    click: sync1C,
                    hidden: !hasPermission("canUpdateEquipment")
                }]
        });

        _toolbar.add(
            BrcReports.init(_brcGrid.config.EntityName, function (requestParameters) {
                var keys = _brcGrid.getSelectedKeys();
                if (keys.length < 1) {
                    $("<div/>").kendoAlert({
                        messages: {
                            okText: "OK"
                        },
                        buttonLayout: "normal",
                        content: "Не выбраны субъекты",
                        modal: true,
                        title: "Ошибка",
                        width: "300px",
                        close: function (e) {
                            e.sender.destroy();
                        },
                        actions: [{
                            text: "OK",
                            primary: true
                        }]
                    }).data("kendoAlert").open();
                    //  BrcNotification.info("Не выбраны объекты");
                    return false;
                }
                var data = [];
                data.push({ name: "equip_id", value: keys[0] });

                //keys.forEach(function (k) {
                //    requestParameters.forEach(function (p) {
                //        data.push({ name: p.name, value: _brcGrid.kendoGrid.dataSource.get(k)[p.name] });
                //    })

                //})

                return data;
            })
            );

        if (!isTree) {

            _toolbar.add({
                type: "button",
                text: '<i class="fa fa-sitemap" title="Дерево"></i>',
                id: "display",
                click: function (e) {
                    location.href = _config.treeViewLink
                }
            })
        }

        //return toolbar;
    }

    function onShowAll() {
        if (!_showAll) {
            _showAll = true;
            $("#btnViewParentAssetMode").text("Только для данного объекта");
        }
        else {
            _showAll = false;
            $("#btnViewParentAssetMode").text("Все записи");
        }
        var filter = initFilter();
        if (_config.isTree && !!_tree)
            _tree.options.brcGridConfig.filters = [filter];
        _grid.kendoGrid.dataSource.filter(filter);

        var currentDate = new Date();
        var currentDateString = currentDate.getDate() + "."
                    + (currentDate.getMonth() + 1) + "."
                    + currentDate.getFullYear() + " "
                    + currentDate.getHours() + "\:"
                    + currentDate.getMinutes();


        _grid.kendoGrid.options.excel.proxyURL = window["APPLICATION_CONFIG"].routes.root + _excelReportUrl;
        _grid.dataSource.excelProxyURL = window["APPLICATION_CONFIG"].routes.root + _excelReportUrl;

        //_grid.kendoGrid.options.excel.forceProxy = true;

        _grid.kendoGrid.dataSource.read();
    }

    function onCreate(equipKindEnum) {
        var equipmentModal = new EquipmentModal();
        if (!!_tree)
            var groupId = _tree.getSelectedKey();
        var selectedKind = equipKindEnum;//=_toolbar._getItem(_toolbar.getSelectedFromGroup("equipKind")).toolbar.options.equipId;
        equipmentModal.init({
            equipmentId: 0,
            assetId: _assetId,
            assetComplexId: _assetComplexId,
            modeKind: selectedKind,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
            },
            groupId: groupId
        });
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите движимое имущество");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Equipment/DeleteEquipmentList",
                    { equipmentIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="equipment"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var equipmentModal = new EquipmentModal();
            equipmentModal.init({
                equipmentId: this.getAttribute("number"),
                assetId: _assetId,
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }


    function show1C() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите движимое имущество");
            return false;
        }

        var equipment1CModal = new FixedAsset1cModal();
        equipment1CModal.init({
            assetId: selectedIds[0],
            mode: "equipment",
            onOKClose: function () { _grid.kendoGrid.dataSource.read(); }
        });
    }
    function sync1C() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите движимое имущество");
            return false;
        }

        BrcConfirm.show("Подтверждение синхронизации", "Синхронизировать выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetComplex/SyncList1C",
                    { assetIds: selectedIds, mode: "equipment" },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function navToMapFromGrid() {
        selectedIds = _grid.getSelectedKeys();

        if (!selectedIds.length) {
            BrcNotification.error("Выберите движимое имущество");
            return false;
        }

        BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "Equipment/CheckContour/" + selectedIds[0],
            null,
            function (data) {
                if (data.startsWith("parcel:")) {
                    location.href = window["APPLICATION_CONFIG"].routes.root + "Map/Main?view_menu=false&obj_name=parcel&obj_id=" + data.substring(0, data.indexOf(";")).replace("parcel:", "") + "&asset_complex_id=" + data.substring(data.indexOf(";") + 1).replace("asset_complex:", "");
                }
                if (data.startsWith("building:")) {
                    location.href = window["APPLICATION_CONFIG"].routes.root + "Map/Main?view_menu=false&obj_name=building&obj_id=" + data.substring(0, data.indexOf(";")).replace("building:", "") + "&asset_complex_id=" + data.substring(data.indexOf(";") + 1).replace("asset_complex:", "");
                }
            },
            function () {
                BrcNotification.error("Для объекта не задан контур");
            }
        );
    }

    function onAddToInventPlan() {
        var addToInventPlanManager = new AddToInventPlanManager();
        addToInventPlanManager.addAsset({
            selectedIds: _grid.getSelectedKeys(),
            mode: "equipment"
        });
    };

    function onMoveToParcel() {
        if (!_moveManager) {
            _moveManager = new MoveManager();
        }
        _moveManager.moveEquipment({
            assetComplexId: _assetComplexId,
            selectedIds: _grid.getSelectedKeys(),
            equipmentRealtyType: "parcel",
            onMoved: function () {
                _grid.kendoGrid.dataSource.read();
            }
        });
    }

    function onMoveToBuilding() {
        if (!_moveManager) {
            _moveManager = new MoveManager();
        }
        _moveManager.moveEquipment({
            assetComplexId: _assetComplexId,
            selectedIds: _grid.getSelectedKeys(),
            equipmentRealtyType: "building",
            onMoved: function () {
                _grid.kendoGrid.dataSource.read();
            }
        });
    }

    function onMoveToFlat() {
        if (!_moveManager) {
            _moveManager = new MoveManager();
        }
        _moveManager.moveEquipment({
            assetComplexId: _assetComplexId,
            selectedIds: _grid.getSelectedKeys(),
            equipmentRealtyType: "flat",
            onMoved: function () {
                _grid.kendoGrid.dataSource.read();
            }
        });
    }

    function onRemoveFromRealty() {
        if (!_moveManager) {
            _moveManager = new MoveManager();
        }
        _moveManager.removeEquipmentFromRealty({
            selectedIds: _grid.getSelectedKeys(),
            onMoved: function () {
                _grid.kendoGrid.dataSource.read();
            }
        });
    }

    function onMoveToAnotherAssetComplex() {
        BrcConfirm.show("Подтверждение перемещения", "При перемещении в другой комплекс будут разорваны связи с ЗУ, ОКС и помещениями. Переместить выбранное движимое имущество?")
           .done(function () {
               if (!_moveManager) {
                   _moveManager = new MoveManager();
               }
               _moveManager.moveEquipment({
                   assetComplexId: _assetComplexId,
                   selectedIds: _grid.getSelectedKeys(),
                   equipmentRealtyType: "assetcomplex",
                   onMoved: function () {
                       _grid.kendoGrid.dataSource.read();
                   }
               });
           });
        
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
};