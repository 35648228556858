(function ($) {
    var _options = {
        name: "SimpleLinkEditor",
        named:false
    }
    var SimpleLinkEditor = kendo.ui.Widget.extend({
        options: _options,
        init: _init,
        enable:_enable
    })
    function _init(element, options) {
        var that = this;
        kendo.ui.Widget.fn.init.call(that, element, options);

        var _elem = $(element);
        //_elem.css("display:none");
        _elem.wrap('<span style="position:relative"></span>');
       // _elem.css("color", "transparent")
        var link = $('<a target="_blank" href="#" style="position:absolute;top:0;left:0;text-indent:.571em"></a>');
        link.insertAfter(_elem);
        if (that.options.named) {
            _elem.attr("placeholder", "ссылка|название");
        }
        _elem.addClass("k-input k-textbox");
        _elem.focusin(function (e) {
            link.hide();
            _elem.css("text-indent", "0");
        })
        setLink(_elem.val());
        _elem.css("text-indent", "-9999px");
        _elem.focusout(function (e) {
            setLink(_elem.val());
            _elem.css("text-indent", "-9999px");
            link.show();
        })
        _elem.on("change input keyup", function (e) {
            setLink(_elem.val());
        })

        function setLink(_val) {
            if (that.options.named) {
                val = _val.split('|');
                link.attr("href", val[0]);
                link.text(!!val[1] ? val[1] : val[0]);
            }
            else {
                link.attr("href", _val);
                link.text(_val);
            }
            
        }
    }
    function _enable(val){
        var self = this;
        if (val === true) {
            $(self.element).attr("readonly", false);
            $(self.element).removeClass("k-state-disabled");
        }
        else {
            $(self.element).attr("readonly", true);
            $(self.element).addClass("k-state-disabled");
        }
}
    kendo.ui.plugin(SimpleLinkEditor);
})(jQuery);