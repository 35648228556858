var BrcPreloader = (function() {
    return {
        load: function (element, full) {
            var blockOverlay = document.createElement('div');
            blockOverlay.className = "brc-preloader-overlay-wrapper";

            var preloader = document.createElement('div');
            preloader.className = "brc-preloader k-loading-image";
            blockOverlay.appendChild(preloader);
            $(element)[0].appendChild(blockOverlay);
            if (full) //добавил Андрей, блокирует весь контент и открытые окна тоже.
            {
                $(blockOverlay).css("position", "absolute").css("top", 0).css("width", "100%").css("height", "100%").css("z-index", "99999999999");
            }
        },
        unload: function(element) {
            $(element).find(".brc-preloader-overlay-wrapper").remove();
        }
    }
})();