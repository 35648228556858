var InitLayoutManager = function () {

    var initSidebarNav = function () {
        var navPanelBar = $("[data-toggle=sidebar-nav]").kendoPanelBar({
            activate: function () {
                if ($("[data-toggle='sidebar-nav'] a.k-state-selected").offset()) {
                    $(".slimscroll").slimScroll({ scrollTo: $("[data-toggle='sidebar-nav'] a.k-state-selected").offset().top - $(".slimScrollDiv").offset().top });
                }
            }
        });

        var navKendoPanelBar = navPanelBar.data("kendoPanelBar");
        var linkCollection = $("[data-toggle=sidebar-nav]").find("[role=menuitem] a");
        for (var i in linkCollection) {
            if (linkCollection.hasOwnProperty(i)) {
                if (linkCollection[i].href === location.href) {
                    var listCollection = $(linkCollection[i]).parents("li[role=menuitem]");
                    navKendoPanelBar.expand(listCollection);
                    navKendoPanelBar.select(listCollection.find(".k-header").closest("li[role=menuitem]"));
                    navKendoPanelBar.select($(linkCollection[i]).parent());
                }
            }
        }
        linkCollection.on("click", function (e) {
            if ($(this).parent().attr("aria-expanded")) {
                var linkTextWrapper = $(this).find("[data-role=link-position]")[0];
                if (linkTextWrapper && e.target === linkTextWrapper) {
                    var href = this.href;
                    if (href)
                        window.location = href;
                }
            }
        });
        $("[data-toggle=sidebar-nav]").trigger('sidebar.nav.ready');
    }
    //var initSidebarTabstrip = function () {
    //    $("[data-toggle=sidebar-tabstrip]").kendoTabStrip();
    //}

    var initSidebarToggler = function () {
        $("[data-toggle=sidebar-show]").on("click", function () {
            var splitter = $('[data-toggle="sidebar-splitter"]').data("kendoSplitter");
            splitter.toggle("#sidebarWrapperSplitterPane", $("#sidebarWrapperSplitterPane").width() <= 0);
        });

    }

    var initSidebarSplitter = function () {
        $('[data-toggle="sidebar-splitter"]').kendoSplitter({
            panes: [
                { collapsible: true, size: $('.sidebar-wrapper').width() },
                { collapsible: false }
            ],
            resize: function (e) {
                var event = document.createEvent('Event');
                event.initEvent('LayoutSplitterResize', true, true);                            
                document.dispatchEvent(event);
            },
            collapse: function () { localStorage.sideBarSplCollapse1 = true },
            expand: function () { localStorage.sideBarSplCollapse1 = false }
        });
        var spl = $('[data-toggle="sidebar-splitter"]').data('kendoSplitter');
        var isSplCollapse = localStorage.sideBarSplCollapse1;
        if (isSplCollapse && JSON.parse(isSplCollapse)) spl.collapse(".k-pane:first");
    }

    var initSlimScrollSidebar = function () {
        $(".slimscroll").slimScroll({
            height: '100%'
        });
    }

    var initThemeSelectList = function () {
        $("[data-role='theme-select-list']").kendoComboBox({
            change: function (e) {
                var value = this.value();
                var values = $('[data-role="theme-select-list"]').find('option').map(function (x, y) { return y.value; });
                if (value === "" || !$.inArray(value, values)) {
                    value = $('[data-role="theme-select-list"]').attr("data-default-theme");
                }
                var date = new Date(new Date().getTime());
                date.setFullYear(date.getFullYear() + 1);
                document.cookie = "currentTheme=" + value + "; path=/; expires=" + date.toUTCString();
                location.reload();
            }
        });
    }

    var setKendoCulture = function () {
        kendo.culture("ru-RU");
    }

    var setActiveTab = function () {
        if ($(".actTab").length > 0) {
            var tabToActivate = $(".actTab");
            $("#tabstrip").kendoTabStrip().data("kendoTabStrip").activateTab(tabToActivate);
        }
    }

    return {
        init: function () {
            setKendoCulture();
            initSidebarNav();
            //initSidebarTabstrip();
            initSidebarToggler();
            initSlimScrollSidebar();
            initSidebarSplitter();
            initThemeSelectList();
            setActiveTab();
        }
    };

}();
$(function () {
    InitLayoutManager.init();
    $("#div-logo-nav").show();
    // $("#div-footer-nav").show();
});