(function ($) {
    var ui = kendo.ui;
    var _options = {
        name: "BrcInlineGrid",

        configuration: {
            data: {},
            url: "",
        },
        editable: {
            mode: "incell"
        },
        treeView: null,

    }


    var BrcInlineGrid = ui.Grid.extend({
        init: _init,
        options: _options,
        findFilterById: _findFilterById,
        replaceFilter: _replaceFilter,
        deleteFilterById: _deleteFilterById,
        getConfiguration: _getConfiguration,
        parseConfiguration: _parseConfiguration,
        createSchema: _createSchema,
        replaceFilter:_replaceFilter,
        getSelectedKeys:_getSelectedKeys,
        groupActionsToolbar: _groupActionsToolbar,
        _getGrid:function(){
            return this;
        },
        _parseValidationAttributes: _parseValidationAttributes,
        initFilters: _initFilters,
        initColumnVisibility: _initColumnVisibility,
        exportExcel: _exportExcel,
        exportPDF: _exportPDF
})

function _init(element, options) {
    var that = this;
    that.treeView = options.treeView;
    that._usingCM = !!options.configuration && !!options.configuration.url;
    if (that._usingCM) {
        that.getConfiguration(options);
    }
    ui.Grid.fn.init.call(that, element, options);
    //ui.Grid.fn._editable.call(that);
    $(window).resize(function () {
        that.resize();
    });
    _checkAndSetStyles.call(that);
}
function _checkAndSetStyles() {
    var self = this;
    if (self._usingCM) {
        self.bind("dataBound", function () {
            var dataView = self.dataSource.view();
            var conf = self.options.configuration.loadedConfig;
            if (conf.StylePropertyName) {
                for (var i = 0; i < dataView.length; i++) {
                    var uid = dataView[i].uid;
                    $(self.wrapper).find("tr[data-uid=" + uid + "]").attr('style', dataView[i][conf.StylePropertyName]);

                }
            }
        });
    }
}
function _parseConfiguration(config, options) {
    var self = this;
    var o = options;
    options.columnEditableOptions = {};

    if (!!o.configuration && !!o.configuration.columns) {
        o.configuration.columns.forEach(function (c) {
            options.columnEditableOptions[c.name] = {};
            options.columnEditableOptions[c.name].options = c.options;
            if (!!c.options.dataSource) {
                options.columnEditableOptions[c.name].options.dataSource = new kendo.data.DataSource(c.options.dataSource);
                options.columnEditableOptions[c.name].options.dataSource.fetch();
            }
            if (!!c.editor) 
                options.columnEditableOptions[c.name].editor = c.editor;
            //if (c.options.hasOwnProperty("editable"))
            //    options.columnEditableOptions[c.name].options.editable = c.options.editable;
            
        });
    }
   
    o.columns = _createColumns.call(self, config, options);
    o.pageable = _createPagingSettings.call(self, config);
    o.reorderable = config.ReorderingInd;
    o.groupable = config.GroupingInd;
    o.navigatable = true;
    o.scrollable = true;
    o.resizable = true;
    o.sortable = true;
    o.columnMenu = config.ColumnMenuInd;
    o.selectable = config.SelectableModeName === "None" ? false : (config.SelectableModeName === "Multiple" ? "multiple " : "") + "row";//"multiple row",
    o.filterable = config.FilteringInd ? { mode: "row" } : false;
    o.height = '100%';
    o.pdf = { fileName: config.Name + ".pdf" };
    var currentDate = new Date();
    var currentDateString = currentDate.getDate() + "."
                + (currentDate.getMonth() + 1) + "."
                + currentDate.getFullYear() + " "
                + currentDate.getHours() + "\:"
                + currentDate.getMinutes();
    o.excel = {
        filterable: true,
        fileName: config.DisplayName ? config.DisplayName + " " + currentDateString + ".xlsx" : "Экспорт " + currentDateString + ".xlsx",
        proxyURL: (self.options.dataSource.excelProxyURL != undefined ? self.options.dataSource.excelProxyURL : null)
    };
    o.dataSource.schema = self.createSchema(config,options);
    if (config.PagingInd) {
        o.dataSource.pageSize = 50;
    }

}
function _getConfiguration(options) {
    var self = this;
    var c = options.configuration;
    BrcAjax.Get(c.url, c.data, function (e) {
        options.configuration.loadedConfig = e;
        self.parseConfiguration(e, options);
    },
    function (e) {
        BrcNotification.error(e.responseJSON);
    }, false)
}
function _createColumns(conf, options) {
    var self = this;
    var o = options;
    
    var columnCollection = [];
    columnCollection.push(
        {
            editable: false,
            width: "20px",
            template: "<div></div>"
            //headerTemplate: '<input id="headerSelector_main" type="checkbox" class="k-input k-checkbox toggleAllSelectionCheckbox" /><label for="headerSelector_main" class="k-checkbox-label"></label>', title: '', field: "", width: 20,
            //template: '<input id="rowSelector_#=uid#" type="checkbox" class="k-input k-checkbox selectionCheckbox" /><label for="rowSelector_#=uid#" class="k-checkbox-label"></label>'
        })
    if (options.editable.mode == "inline") {
        columnCollection.push(
            { command: ["edit"], title: "&nbsp;", width: "172px" }
        )
    }
    var confColumns = conf.Columns.sort(function (a, b) { return (a.OrderNumber > b.OrderNumber) ? 1 : ((b.OrderNumber > a.OrderNumber) ? -1 : 0); });
    for (var i in confColumns) {
        if (conf.Columns.hasOwnProperty(i)) {

            var column = {
                field: conf.Columns[i].EntityProperty,
                title: conf.Columns[i].DisplayName,
                attributes: {},
                locked: conf.Columns[i].FrozenInd,
                headerAttributes: {
                    "class": conf.Columns[i].HeaderTextWrapInd ? "brc-wrap" : "brc-nowrap"
                },
                
            };
            if (o.columnEditableOptions.hasOwnProperty(conf.Columns[i].EntityProperty)) {
                if (!!o.columnEditableOptions[conf.Columns[i].EntityProperty].editor && o.columnEditableOptions[conf.Columns[i].EntityProperty].editor.name != "customBoolEditor") {
                    var gridColumnConfig = conf.Columns[i];
                    var colEditableOpt = o.columnEditableOptions[gridColumnConfig.EntityProperty];
                    column.editor = function (container, options) {
                        var input = $("<input/>");
                        self._parseValidationAttributes(input, colEditableOpt.options.validation);
                        //input.kendoValidator(colEditableOpt.validation);
                        input.attr("name", options.field);
                        input.appendTo(container);
                        var editorOptions = colEditableOpt;
                        input[editorOptions.editor](editorOptions.options);
                        var errorMessage = $('<span class="k-invalid-msg" data-for="' + options.field + '"></span>');
                        container.append(errorMessage);
                        kendo.ui.Validator.fn._decorateMessageContainer(errorMessage, options.field);
                        
                    }
                    column.template = function (d) {
                        //console.debug("1");
                        if (o.columnEditableOptions[gridColumnConfig.EntityProperty].options.dataSource != undefined) {
                            //console.debug("2");
                            if (o.columnEditableOptions[gridColumnConfig.EntityProperty].options.dataSource.data().length > 0) {
                                var val = o.columnEditableOptions[gridColumnConfig.EntityProperty].options.dataSource.get(d[colEditableOpt.options.dataValueField]);
                                if (!!val)
                                    return kendo.template(colEditableOpt.options.template)(val);
                                return "";
                            }
                            else {
                                return d[colEditableOpt.options.textField];
                            }                                
                        }
                    }
                }
                else if (!!o.columnEditableOptions[conf.Columns[i].EntityProperty].editor) {
                    column.editor = function (container, options) {
                        var guid = kendo.guid();
                        $('<input class="k-checkbox" id="' + guid + '" type="checkbox" name="' + options.field + '" data-type="boolean" data-bind="checked:' + options.field + '">').appendTo(container);
                        $('<label class="k-checkbox-label" for="' + guid + '">&#8203;</label>').appendTo(container);
                    }
                }
               
            }
            if (conf.Columns[i].Width != null) {
                column.width = conf.Columns[i].Width;
            }
            else if (conf.Columns[i].Width == null && conf.Columns[i].ValueType.Key === "Date") {
                column.width = 100;
            }
            if (conf.Columns[i].ValueFormat != null) {
                column.format = "{0:" + conf.Columns[i].ValueFormat + "}";
            }
            if (conf.Columns[i].StylePropertyName != null) {
                column.attributes.style = ' #= ' + conf.Columns[i].StylePropertyName + ' # ';
            }
            //Выбор дефолтного фильтра для столбца
            if (conf.Columns[i].ValueType.Key === "String" || conf.Columns[i].ValueType.Key === "HtmlString") {
                column.filterable = {
                    cell: {
                        operator: "contains",
                        suggestionOperator: "contains"
                    }
                };
            }
            else if (conf.Columns[i].ValueType.Key === "Link") {
                column.filterable = {
                    cell: {
                        operator: "contains",
                        suggestionOperator: "contains"
                    }
                };
                column.template = '<a href="#:' + column.field + '==null?"":' + column.field + '#" target="_blank">#:' + column.field + '==null?"":' + column.field + '#</a>';
            }
            else if (conf.Columns[i].ValueType.Key === "Boolean") {
                column.filterable = {
                    mode: "row",
                    cell: {
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind: false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [
                                        { text: "Да", value: "true" },
                                        { text: "Нет", value: "false" }
                                    ]
                                }),
                                index: 0,
                                optionLabel: {
                                    text: "",
                                    value: ""
                                },
                                valuePrimitive: true

                            });
                        }
                    }
                };
            }
            //------
            //Простановка атрибутов для столбца
            if (conf.Columns[i].ValueType.Key === "String") {
                column.attributes.class = "brc-grid__column brc-grid__column-string";
                column.attributes.title = "#=data." + conf.Columns[i].EntityProperty + "#";
            }
            else if (conf.Columns[i].ValueType.Key === "HtmlString") { //добавила маша
                column.attributes.class = "brc-grid__column brc-grid__column-string";
                column.attributes.title = "";
                column.template = "#= htmlDecode(data." + conf.Columns[i].EntityProperty + ")#";
            }
            else if (conf.Columns[i].ValueType.Key === "Link") {
                column.attributes.class = "brc-grid__column brc-grid__column-string";
                column.attributes.title = "#=data." + conf.Columns[i].EntityProperty + "#";
                column.template = "<a target='_blank' href=#=!!data." + conf.Columns[i].EntityProperty + "?data." + conf.Columns[i].EntityProperty + ".split('|')[0]:''#>#=!!data." + conf.Columns[i].EntityProperty + "?!!data." + conf.Columns[i].EntityProperty + ".split('|')[1]?data." + conf.Columns[i].EntityProperty + ".split('|')[1]:data." + conf.Columns[i].EntityProperty + ":''#</a>";
            }
            else if (conf.Columns[i].ValueType.Key === "Boolean") {
                column.attributes.class = "brc-grid__column brc-grid__column-checkbox";
                column.template = "<span class='k-icon #=!" + conf.Columns[i].EntityProperty + "?'':'k-i-check' #' data-value='#=!" + conf.Columns[i].EntityProperty + "?false:true #' ></span>"; //? true : false
            }
            else if (conf.Columns[i].ValueType.Key === "Date") {
                column.attributes.class = "brc-grid__column brc-grid__column-date";
            }
            else if (conf.Columns[i].ValueType.Key === "Number") {
                column.attributes.class = "brc-grid__column brc-grid__column-number";
            } else {
                column.attributes.class = "brc-grid__column";
            }
            //-------
            if (conf.Columns[i].Hyperlink != null) {
                column.template = _createCellLinkTemplate(conf.Columns[i].Hyperlink, conf.Columns[i].EntityProperty);
            }
            if (conf.Columns[i].Summary.length > 0) {
                column.footerTemplate = "";
                var summaries = [];
                conf.Columns[i].Summary.forEach(function (summary) {
                    summaries.push(summary.ShortText + ": #:" + summary.Value + "#");
                    dataSource.aggregate.push({
                        field: conf.Columns[i].EntityProperty,
                        aggregate: summary.Value
                    })
                });
                column.footerTemplate = kendo.format("<span title='{0}'>{0}</span>", summaries.join(', '));

            }
            columnCollection.push(column);

        }
    }
    return columnCollection;
};
function _createPagingSettings(conf) {
    var defaultSizes = [10, 50, 100, 200];
    if (conf.PagingAllButtonInd) {
        defaultSizes.push("all");
    }
    return (conf.PagingInd ? {
        refresh: true,
        messages: {
            refresh: ""
        },
        pageSizes: defaultSizes,
        buttonCount: 5
    } : false);
};
function _createCellLinkTemplate(hyperlink, entityProperty) {
    var route = hyperlink.Route;
    for (var i in hyperlink.Properties) {
        if (hyperlink.Properties.hasOwnProperty(i)) {
            if (hyperlink.Properties[i].IsTemplate) {
                route = UrlHelper.addOrReplaceParam(route, hyperlink.Properties[i].Name, "${" + hyperlink.Properties[i].TemplateKey + "}");
            }
        }
    }
    var attributeString = "";
    for (var i in hyperlink.Attributes) {
        if (hyperlink.Attributes.hasOwnProperty(i)) {
            var value = hyperlink.Attributes[i].IsTemplate
                ? "${" + hyperlink.Attributes[i].TemplateKey + "}"
                : hyperlink.Attributes[i].Value;
            attributeString += hyperlink.Attributes[i].Name + "=\"" + value + "\" ";
        }
    }
    return " # if ( " + entityProperty + " != null ) { # <a " + attributeString + " href='" + route + "'> #: " + entityProperty + " # </a> # } #";
}
function _createSchema(conf, options) {
    var self = this;
    var o = options;
    var columnEditableOptions = {};
    
    var schema = {
        model: {
            id: conf.EntityKey,
            fields: {}
        },
        total: function (data) {
            return !!data["odata.count"] ? data["odata.count"] : data["@odata.count"];
        },
        data: function (data) {
            if (data.hasOwnProperty("value"))
                return data["value"];
            else
                return data;
        }
    };
    for (var i in conf.Columns) {
        if (conf.Columns.hasOwnProperty(i)) {
            var colOptions = o.columnEditableOptions[conf.Columns[i].EntityProperty];
            schema.model.fields[conf.Columns[i].EntityProperty] = {
                type: conf.Columns[i].ValueType.Key === "Link" || conf.Columns[i].ValueType.Key === "HtmlString" ? "string" : conf.Columns[i].ValueType.Key.toLowerCase(),
                editable: (!!colOptions && colOptions.options.hasOwnProperty("editable") ? colOptions.options.editable : true),
                validation: (!!colOptions && colOptions.options.hasOwnProperty("validation") ? colOptions.options.validation : {}),
                defaultValue: (!!colOptions && colOptions.options.hasOwnProperty("defaultValue") ? colOptions.options.defaultValue : null),
            }
        }
    }
    return schema;
};
function _replaceFilter(filter) {
    var self = this;
    var selfFilter = self.dataSource.filter();
    if (!!selfFilter && !!selfFilter.filters) {
        for(var f in selfFilter.filters)
            if (selfFilter.filters[f].id == filter.id) {
                selfFilter.filters[f] = filter;
                return selfFilter;
            }
        selfFilter.filters.push(filter);
        return selfFilter;
    }
    return selfFilter;
}
function _getSelectedKeys() {
    var self = this;
    var rows = self.select().filter(function () {
        return $(this).parent().parent().parent().hasClass('k-grid-content');
    });

    var selectedKeys = [];
    rows.each(function (index, row) {
        selectedKeys.push(self.dataItem(row)[self.options.configuration.loadedConfig.EntityKey]);
    });

    return selectedKeys;
}
function _groupActionsToolbar(toolbar,getGrid) {
    var self = this;
   // self.treeView = treeView;
    self.groupActionsToolbar = toolbar;
    self._getGrid = getGrid?getGrid:self._getGrid;
    if (self._getGrid().groupActionsToolbar.options.items.length > 0)
        self._getGrid().groupActionsToolbar.add({
            type: "separator"
        })
    var items = {};
    items =
        {
            type: "buttonGroup",
            buttons: [
                {
                    type: "button",
                    text: "Вырезать",
                    id: "cut",
                    enable: self._getGrid().options.permissions.canChangeObject,
                    click: function (e) {
                        _cut.call(self._getGrid());
                    }
                },

            ]
        };

    //self._getGrid().groupActionsToolbar.add({
    //})
    if (!!self._getGrid().options.groupConfig.manyToMany || self._getGrid().options.groupConfig.copyButton) {
        items.buttons.push({
            type: "button",
            text: "Копировать",
            id: "copy",
            enable: self._getGrid().options.permissions.canChangeObject,
            click: function (e) {
                _cut.call(self._getGrid(), true);
            }
        })
    }
    items.buttons.push({
        type: "button",
        text: "Вставить",
        id: "paste",
        enable: self._getGrid().options.permissions.canChangeObject,
        click: function (e) {
            _paste.call(self._getGrid());
        }
    })
    if (!!self._getGrid().options.groupConfig.manyToMany) {
        items.buttons.push({
            type: "button",
            text: "Удалить из групп",
            id: "deleteFromGroup",
            enable: self._getGrid().options.permissions.canChangeObject,
            click: function (e) {
                BrcConfirm.show('', "Действие приведет к удалению выбранных объектов из всех групп, продолжить?").done(function (e) {
                    _deleteFromGroup.call(self._getGrid());
                })

            }
        })
    }
    if (self._getGrid().options.tableViewLink)
        items.buttons.push({
            type: "button",
            text: '<i class="fa fa-table" title="Таблица"></i>',
            id: "table",
            click: function (e) {

                location.href = self._getGrid().options.tableViewLink;
            }
        })
    self._getGrid().groupActionsToolbar.add(items)
    //self._getGrid().groupActionsToolbar.add({
    //    type: "separator"
    //})
    //self._getGrid().groupActionsToolbar.resize();
    function _deleteFromGroup() {
        self._getGrid = this;
        var items = [];
        self._getGrid().getSelectedKeys().forEach(function (item) {
            var dataItem = self._getGrid().kendoGrid.dataSource.get(item);
            items.push(dataItem);
        });
        if (items.length > 0) {
            self._getGrid().options.groupConfig.deleteFromGroups(items)
                               .done(function (e) {
                                   self._getGrid().dataSource.pushDestroy(items);
                               }).fail(function (e) {

                               }).always(function () {
                                   BrcPreloader.unload($(self._getGrid().wrapper));


                               });
        }

    }
    // return tb;
    function _cut(copy) {
        var self = this;
        self._getGrid().buffer = [];
        self._getGrid().getSelectedKeys().forEach(function (item) {
            var dataItem = self._getGrid().dataSource.get(item);
            self._getGrid().buffer.push(dataItem);
        });
        if (!!copy)
            self._getGrid().buffer["copy"] = true;
        else
            self._getGrid().buffer["copy"] = false;
    }
    function _paste() {
        var self = this;

        if (self._getGrid().hasOwnProperty("buffer") && self._getGrid().buffer.length > 0) {
            BrcPreloader.load($(self._getGrid().wrapper));
            var items = [];
            var selectedFolder = self._getGrid().treeView.mainList.dataItem(self._getGrid().treeView.mainList.select());
            if (!!selectedFolder) {
                self._getGrid().buffer.forEach(function (dataItem) {
                    var flag = false;
                    var currItems = self._getGrid().dataSource.view();
                    currItems.forEach(function (element) {
                        if (element.id == dataItem.id)
                            flag = true;
                    })
                    if (flag)
                        return;

                    items.push(dataItem.toJSON());
                    dataItem[self._getGrid().treeView.options.key] = selectedFolder[self._getGrid().treeView.options.key];

                });
                if (items.length > 0) {
                    if (!self._getGrid().buffer.copy) {
                        self._getGrid().options.groupConfig.changeGroup(items, selectedFolder[self._getGrid().treeView.options.key])
                            .done(function (e) {
                                self._getGrid().dataSource.pushCreate(items);
                                self._getGrid().dataSource.read();
                            }).fail(function (e) {

                            }).always(function () {
                                BrcPreloader.unload($(self._getGrid().wrapper));
                            });
                    }
                    else {
                        self._getGrid().options.groupConfig.addGroup(items, selectedFolder[self._getGrid().treeView.options.key])
                           .done(function (e) {
                               self._getGrid().dataSource.pushCreate(items);
                               self._getGrid().dataSource.read();
                           }).fail(function (e) {

                           }).always(function () {
                               BrcPreloader.unload($(self._getGrid().wrapper));


                           });
                    }
                }
                else {
                    BrcNotification.info("Нет записей доступных для вставки");
                    BrcPreloader.unload($(self._getGrid().wrapper));
                }
            }
            else {
                var selectedFolder = self._getGrid().treeView.staticList.dataItem(self._getGrid().treeView.staticList.select());
                switch (selectedFolder.id) {
                    case "all":
                        if (!!self._getGrid().treeView.options.virtualNode && !self._getGrid().treeView.options.virtualNode.all.dropTarget) {
                            BrcPreloader.unload($(self._getGrid().wrapper));
                            BrcNotification.info(self._getGrid().treeView.options.virtualNode.all.message)
                            return;
                        }
                        break;
                    case "ungrouped":
                        if (!!self._getGrid().treeView.options.virtualNode && !self._getGrid().treeView.options.virtualNode.ungrouped.dropTarget) {
                            BrcPreloader.unload($(self._getGrid().wrapper));
                            BrcNotification.info(self._getGrid().treeView.options.virtualNode.ungrouped.message)
                            return;
                        }
                        break;
                }
                if (!!selectedFolder) {
                    var items = [];
                    self._getGrid().buffer.forEach(function (dataItem) {
                        var flag = false;
                        var currItems = self._getGrid().dataSource.view();
                        currItems.forEach(function (element) {
                            if (element.id == dataItem.id && element[self._getGrid().treeView.options.linkedItemKey]==null)
                                flag = true;
                        })
                        if (flag)
                            return;

                        items.push(dataItem.toJSON());
                        dataItem[self._getGrid().treeView.options.key] = null;

                    });
                    if (items.length > 0) {
                        if (!self._getGrid().buffer.copy) {
                            self._getGrid().options.groupConfig.changeGroup(items, null)
                           .done(function (e) {
                               self._getGrid().dataSource.pushCreate(items);
                               self._getGrid().dataSource.read();
                           }).fail(function (e) {

                           }).always(function () {
                               BrcPreloader.unload($(self._getGrid().wrapper));
                           });
                        }
                        else {
                            BrcNotification.info("")
                        }
                    }
                    else {
                        BrcNotification.info("Нет выбранных записей");
                        BrcPreloader.unload($(self._getGrid().wrapper));
                    }
                }
                else {
                    BrcNotification.info("Необходимо выбрать группу назначения")
                }
            }
        }
        self._getGrid().buffer = [];
    }
}
function _parseValidationAttributes(element, opt) {
    if (!!opt)
    {
        for(var i in opt) {
            var rule=opt[i];
            $(element).attr(i, "");
            $(element).attr("data-" + i + "-msg", rule.message ? rule.message : "");
        }
    }
}

function _initFilters(getGrid) {
    var self = this;
    var popup;
    var toolbar;
    var _lastFilters = [];
    var _currentCustomFilters = [];
    var _filtersAccepted = false;
    var _selectedFilter;
    var validator;
    self._getGrid = getGrid ? getGrid : self._getGrid;
    var modalTemplate = '<form class="brc-form brc-full-height-form brc-grid-filter__window" ><input type="hidden" name="grid_id" value="' + self._getGrid().options.configuration.loadedConfig.Id + '"/><div class="brc-window-layout"><div class="brc-window-layout__header brc-window-layout_header-grid"><div class="brc-window-layout__toolbar"><div class="brc-form-block"><div class="row" ><div class="brc-form-group col-md-10"><label>Наименование</label><input required name="grid_filter_name" value="#:data.grid_filter_name#" type="text" class="k-textbox"/></div><div class="brc-form-group col-md-2"><label>Функция</label><input required name="grid_filter_logic" value="#:data.grid_filter_logic#" type="text" class="brc-combobox" /></div></div></div><div class="brc-form-group brc-grid-filter__window-row row" id="createFilter">{0}</div></div></div><div class="brc-window-layout__content"><div class="brc-window-layout__work-area">' +
    '<div class="row brc-grid-filter__window-row" ><div class="brc-form-group col-md-4"><label>Поле</label></div><div class="brc-form-group col-md-3"><label>Оператор</label></div><div class="brc-form-group col-md-4"><label>Значение</label></div></div>' +
        '<div id="filters"/></div></div><div class="brc-window-layout__footer"><div class="brc-form-action"><button id="okButton" class="brc-action k-button k-primary"> ОК </button><button id="cancelButton" class="brc-action k-button k-default">Отмена</button></div></div></div></form>';
    var headerTemplate = '<div style="width:100%"><input data-text-field="DisplayName" data-value-field="Id" data-role="dropdownlist"  data-bind="source: cols" id="field" type="text" class="brc-combobox"/><input type="hidden" name="grid_filter_id" value="#:data.grid_filter_id#"/><input type="hidden" name="app_user_id" value="#:data.app_user_id#"/></div>';
    var filterTemplate = kendo.template('<div filter class="row brc-grid-filter__window-row" >' +
        '<div class="brc-form-group col-md-4"><label></label><input data-text-field="DisplayName" required data-value-field="Id" data-role="dropdownlist" data-bind="source: cols, events:{change:changeField}"  data-value="#:data.grid_column_id#" data-grid_column_id  name="grid_column_id#:kendo.guid()#" type="text" class="brc-combobox"/></div>' +
        '<div class="brc-form-group col-md-3"><label></label><input required data-value="#:!data.grid_filter_operator?"":data.grid_filter_operator#" name="grid_filter_operator#:kendo.guid()#" id="operator" type="text" data-text-field="name" data-value-field="value" data-grid_filter_operator   data-enabled="false" data-role="dropdownlist"  class="brc-combobox"/></div>' +
        '<div class="brc-form-group col-md-4"><label></label><input value="#:data.value==null?"":data.value#" name="grid_filter_value_text#:kendo.guid()#" filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/></div>' +
        '<div class="brc-form-group col-md-1" ><a href="\\#" class="k-button"><span class="k-icon k-i-close"></span></a></div>' +
        '<input type="hidden" name="grid_filter_id" value="#:data.grid_filter_id#"/><input type="hidden" name="grid_filter_condition_id" id="grid_filter_condition_id" value="#:data.grid_filter_condition_id#"/>' +
        '</div>');
    var popupHtml = '<div class="brc-grid-filter__popup"><div class="brc-grid-filter__popup-header"><div class="brc-toolbar brc-toolbar-right brc-toolbar-border-none"><a href="#" class="k-button" id="openFilter">Добавить</a><a href="#" class="k-button" id="fastFilter">Быстрый фильтр</a><a href="#" class="k-button" id="resetFilter" style="position:absolute;left:10px"><span class="k-icon k-i-filter-clear"></span> Сбросить</a></div></div><div class="brc-grid-filter__popup-content">{0}</div></div>';
    var filterButton = '<div class="brc-grid-filter__popup-button" filterButtonContainer><input type="hidden" name="grid_filter_id" id="grid_filter_id" value="#:grid_filter_id#"/><span class="k-icon k-i-filter filter-inactive"></span><div  class="k-button" filterButton>#:grid_filter_name#</div><a href="\\#" class="k-button edit-button"><span class="k-icon k-i-pencil"></a></span><a href="\\#" class="k-button delete-button"><span class="k-icon k-i-close"></span></a></div>';
    var button = _initToolbar();
    _init();
    self._getGrid().filterable = true;
    self._getGrid().getCurrentFilters = function () {
        return _currentCustomFilters;
    }
    function _initToolbar() {


        var button = {
            type: "button",
            text: "Фильтры",
            id: "filterButton",
            click: function (e) {
                var self = this;
               _open();
            },
            };
        return button;
    }
    var filterableColumns = [];
    self._getGrid().options.configuration.loadedConfig.Columns.forEach(function (element) {
        if (element.Filterable)
            filterableColumns.push(element);
    })
    var viewModel = kendo.observable({
        cols: new kendo.data.DataSource(
                {
                    data: filterableColumns,
                    schema: {
                        model: {
                            id: "Id",
                            EntityProperty: "EntityProperty"
                        }
                    }
                }
            ),
        textOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Содержит",
            value: "contains"
        },
        {
            name: "Не содержит",
            value: "doesnotcontain"
        },
        {
            name: "Заканчивается",
            value: "endswith"
        },
        {
            name: "Начинается",
            value: "startswith"
        },
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Пусто",
            value: "isnull"
        },
        {
            name: "Не пусто",
            value: "isnotnull"
        },
        //{
        //    name: "Не NULL",
        //    value: "isnotnull"
        //},
        //{
        //    name: "NULL",
        //    value: "isnull"
        //},
            ]
        }),
        numberOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Больше",
            value: "gt"
        },
        {
            name: "Больше или равно",
            value: "gte"
        },
        {
            name: "Меньше",
            value: "lt"
        },
        {
            name: "Меньше или равно",
            value: "lte"
        },
        {
            name: "Определено",
            value: "isnotnull"
        },
        {
            name: "Не определено",
            value: "isnull"
        },
            ]
        }),
        dateOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Больше",
            value: "gt"
        },
        {
            name: "Больше или равно",
            value: "gte"
        },
        {
            name: "Меньше",
            value: "lt"
        },
        {
            name: "Меньше или равно",
            value: "lte"
        },
        {
            name: "Определено",
            value: "isnotnull"
        },
        {
            name: "Не определено",
            value: "isnull"
        },
            ]
        }),
        booleanOperators: new kendo.data.DataSource({
            data: [
        {
            name: "Равно",
            value: "eq"
        },
        {
            name: "Не равно",
            value: "neq"
        },
        {
            name: "Определено",
            value: "isnotnull"
        },
        {
            name: "Не определено",
            value: "isnull"
        },
            ]
        }),
        changeField: function (e) {
            var _self = this;
            var col = e.sender.dataSource.at(e.sender.select());

            var operator = $(e.sender.element).parents(".row").find("#operator").data("kendoDropDownList");
            operator.listView.element.addClass('brc-combobox-dropdownlist');
            var row = $(e.sender.element).parents(".row");
            if (!col) {
                operator.enable(false);
                e.sender.value(null);
                return;

            }
            if (!!e.sender.value()) {
                operator.setDataSource({ data: (col.ValueType.Key == "String" || col.ValueType.Key == "Link" || col.ValueType.Key == "HtmlString" ? _self.textOperators.options.data : col.ValueType.Key == "Number" ? _self.numberOperators.options.data : col.ValueType.Key == "Date" ? _self.dateOperators.options.data : col.ValueType.Key == "Boolean" ? _self.booleanOperators.options.data : null) })
                operator.enable(true);
                operator.refresh();
            }
            else
                operator.enable(false);


            var data = {
                grid_filter_condition_id: row.find("[name=grid_filter_condition_id]").val(),
                grid_filter_operator: operator.value(),
                grid_filter_value: col.ValueType.key == "Boolean" ? row.find("[filter-value")[0].checked : row.find("[filter-value]").val(),
                grid_column_id: row.find("[data-grid_column_id]").val(),
                grid_filter_id: row.find("[name=grid_filter_id]").val(),
                field: e.sender.select(),
                value: col.ValueType.key == "Boolean" ? row.find("[filter-value")[0].checked : row.find("[filter-value]").val(),
            }
            var htmlControl = row.find("[filter-value]");
            var container = htmlControl.parents(".brc-form-group");
            var control = kendo.widgetInstance(htmlControl);
            if (!!control) {
                control.destroy();
                container.html('');
                htmlControl = $('<input required="required" name="grid_filter_value_text' + kendo.guid() + '" value="' + data.value + '" filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/>');
                container.append(htmlControl);
            }
            else {
                container.html('');
                htmlControl = $('<input required="required" name="grid_filter_value_text' + kendo.guid() + '" value="' + data.value + '"  filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/>');
                container.append(htmlControl);
            }
            initValueField(col, htmlControl, data);
            operator.trigger("change");

        }
    });
    viewModel.cols.fetch(function () {
        var data = this.data();
    });

    function applyFilter(filterId) {
        if (!filterId) {

            var filters = self._getGrid().dataSource.filter();
            if (!!filters) {
                filters = filters.filters.slice();
                for (var i = 0; i < filters.length; i++) {
                    if (filters[i].custom)
                        filters.splice(i--, 1)
                }

                self._getGrid().dataSource.filter(filters);
            }
            _selectedFilter = null;
            _currentCustomFilters = [];
            if (!!popup) {
                $(popup.element).find("[filterbuttoncontainer]>.k-i-filter").addClass("filter-inactive");
                $(popup.element).find("[filterbuttoncontainer]>[filterbutton]").attr("toggle-state", false);
            }
            $(popup.element).find("#fastFilter").removeClass("k-primary");
            //toolbar.element.find("#filterButton").html("<span>Фильтры</span>");
            return;
        }
        for (var i = 0; i < self._getGrid().options.configuration.loadedConfig.GridFilters.length; i++) {
            if (self._getGrid().options.configuration.loadedConfig.GridFilters[i].grid_filter_id == filterId) {
                var filters = [];
                _selectedFilter = self._getGrid().options.configuration.loadedConfig.GridFilters[i];
                popup.element.find("[name=grid_filter_id]").each(function (index, elem) {
                    if ($(elem).val() == filterId)
                        $(elem).parent().find("k-i-filter").removeClass("filter-inactive");
                })
                self._getGrid().options.configuration.loadedConfig.GridFilters[i].FilterConditions.forEach(function (cond) {
                    var column = viewModel.cols.get(parseInt(cond.grid_column_id));
                    filters.push({
                        field: cond.grid_filter_operator == "contains" ? 'tolower(' + column.EntityProperty + ')' : column.EntityProperty,
                        operator: cond.grid_filter_operator,
                        value: column.ValueType.Key == "String" || column.ValueType.Key == "Link" || column.ValueType.Key == "HtmlString" ? (cond.grid_filter_operator == "contains" ? cond.grid_filter_value.toLowerCase() : cond.grid_filter_value) : column.ValueType.Key == "Number" ? kendo.parseFloat(cond.grid_filter_value) : column.ValueType.Key == "Date" ? kendo.parseDate(cond.grid_filter_value, ["dd.MM.yyyy", "dd.MM.yy"]) : column.ValueType.Key == "Boolean" ? cond.grid_filter_value == "True" : null,
                        custom: true
                    })
                })

                _currentCustomFilters = filters.slice();
                filters = filters.concat(_lastFilters.slice());

                self._getGrid().dataSource.filter({ logic: self._getGrid().options.configuration.loadedConfig.GridFilters[i].grid_filter_logic, filters: filters });
                _filtersAccepted = true;
                //toolbar.element.find("#filterButton").html("<span class=\"k-icon k-i-filter\"></span>" + self._getGrid().options.configuration.loadedConfig.GridFilters[i].grid_filter_name);
            }
        }
    }
    function selectFilter(filterControl) {
        var filterId = filterControl.parent().find("[name=grid_filter_id]").val();
        var state = filterControl.attr("toggle-state");
        $(popup.element).find("#fastFilter").removeClass("k-primary");
        if (state == "true") {
            filterControl.attr("toggle-state", false);
            //filterControl.removeClass("k-primary");
            filterControl.parent().find(".k-i-filter").addClass("filter-inactive");
            var gridFilter = self._getGrid().dataSource.filter();
            var filters = [];
            if (!!gridFilter) {
                filters = gridFilter.filters;
            }
            for (var i = 0; i < filters.length; i++) {
                if (!!filters[i].custom) {
                    filters.splice(i--, 1);

                }
            }

            self._getGrid().dataSource.filter(filters);
            _filtersAccepted = false;
            _currentCustomFilters = [];
            //toolbar.element.find("#filterButton").html("<span>Фильтры</span>");
            _selectedFilter = null;
            return;
        } else {
            if (!_filtersAccepted) {
                var flt = self._getGrid().dataSource.filter();
                if (!!flt)
                    _lastFilters = flt.filters;
                else
                    _lastFilters = [];
            }
            filterControl.parents(".brc-grid-filter__popup-content").find("[filterButton]").each(function (index, elem) {
                $(elem).attr("toggle-state", false);
                $(elem).parent().find(".k-i-filter").addClass("filter-inactive");
            })
            filterControl.attr("toggle-state", true);
            // filterControl.addClass("k-primary");
            filterControl.parent().find(".k-i-filter").removeClass("filter-inactive")
            _filtersAccepted = true;

        }
        applyFilter(filterId);
    }
    function _init() {
        var context = this;

        var buttons = [];
        self._getGrid().options.configuration.loadedConfig.GridFilters.forEach(function (filter) {
            var template = kendo.template(filterButton);
            buttons.push(template(filter));
        })
        if (!popup) {
            popup = $(kendo.format(popupHtml, buttons.join(''))).kendoWindow({
                title: "Фильтры",
                width: "500px"
            }).data("kendoWindow").center();
            _initPopupActions();

        }
        else {
            $(popup.element).find(".brc-grid-filter__popup-content").html('');
            $(popup.element).find(".brc-grid-filter__popup-content").append($(buttons.join('')));
            _initFilterActions();
        }
        if (!!_selectedFilter) {
            applyFilter(_selectedFilter.grid_filter_id);
        }
        function _initPopupActions() {
            popup.element.find("#openFilter").click(function () {
                _openFilter();
            })
            popup.element.find("#fastFilter").click(function () {
                _openFilter(null, true);
            })
            popup.element.find("#resetFilter").click(function () {
                applyFilter();
            })
            _initFilterActions();
        }
        function _initFilterActions() {
            popup.element.find("[filterButton]").click(function (e) {
                selectFilter($(e.target));
            })
            popup.element.find(".edit-button").click(function (e) {
                var _id = parseInt($(e.target).parents("[filterbuttoncontainer]").find("input#grid_filter_id").val());
                _openFilter(_id);
            })
            popup.element.find(".delete-button").click(function (e) {
                var button = $(e.target);
                BrcConfirm.show("Удаление фильтра", "Вы действительно хотите удалить выбранный фильтр?").done(function () {
                    var _id = parseInt(button.parents("[filterbuttoncontainer]").find("input#grid_filter_id").val());
                    if (_id > 0) {
                        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "controlmanagermodule/gridfilter/DeleteGridFilter", { id: _id },
                            function (e) {
                                BrcNotification.success("Выполнено");
                                button.parents("[filterButtonContainer]").remove();
                                if (!!_selectedFilter && _selectedFilter.grid_filter_id == _id)
                                    applyFilter();
                                for (var i = 0; i < self._getGrid().options.configuration.loadedConfig.GridFilters.length; i++) {
                                    if (self._getGrid().options.configuration.loadedConfig.GridFilters[i].grid_filter_id == _id) {
                                        self._getGrid().options.configuration.loadedConfig.GridFilters.splice(i, 1);
                                        return;
                                    }
                                }
                                _init();

                            },
                            function (e) {
                                BrcNotification.error(e.responseJSON);
                            });
                    }
                    else {
                        button.parents("[filterButtonContainer]").remove();
                    }
                })
            })
        }
    }
    function _open() {
        popup.open();
    }
    function _openFilter(id, fast) {
        var filters = [];
        var filter;
        var filterIndex = -1;
        for (var i = 0; i < self._getGrid().options.configuration.loadedConfig.GridFilters.length; i++) {
            if (self._getGrid().options.configuration.loadedConfig.GridFilters[i].grid_filter_id == id) {
                filter = self._getGrid().options.configuration.loadedConfig.GridFilters[i];
                filterIndex = i;
                break;
            }
        }
        if (!filter)
            filter = {
                grid_filter_id: 0,
                grid_filter_name: "",
                FilterConditions: []
            }

        var wnd = $("<div/>").kendoWindow({
            title: "Настройка фильтров",
            width: "900px",
            height: "500px",
            modal: true,
            close: function (e) {
                e.sender.destroy();
            }
        }).data("kendoWindow");
        wnd.content(kendo.template(kendo.format(modalTemplate, headerTemplate)).call(this, filter));
        wnd.center().open();
        if (fast) {
            $(wnd.element).find("[name=grid_filter_name]").parent().remove();
        }
        $(wnd.element).find("[name=grid_filter_logic]").kendoDropDownList({
            dataSource: {
                data: [{
                    text: "И",
                    id: "and"
                },
                {
                    text: "ИЛИ",
                    id: "or"
                }]
            },
            dataValueField: "id",
            dataTextField: "text"
        })
        validator = $(wnd.element).find("form").kendoValidator({
            errorTemplate: kendo.template('<span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg">#=message#</span>'),
            messages: {
                required: 'Поле не должно быть пустым',
            },
        }).data("kendoValidator");
        var createFilterConteiner = $(wnd.element).find("#createFilter");

        createFilterConteiner.append('<div><button id="add" type="button"  class="brc-action k-button k-primary"> Добавить </button></div>');
        kendo.bind(createFilterConteiner.find("#field"), viewModel);
        // kendo.bind(createFilterConteiner.find("[data-grid_filter_operator]"), viewModel);
        var fieldCmb = createFilterConteiner.find("#field").data("kendoDropDownList");
        fieldCmb.listView.element.addClass('brc-combobox-dropdownlist');
        //  var operatorCmb = createFilterConteiner.find("#operator").data("kendoComboBox");
        createFilterConteiner.find("#add").click(function (e) {
            validator = $(wnd.element).find("form").kendoValidator({
                errorTemplate: kendo.template('<span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg">#=message#</span>'),
                messages: {
                    required: 'Поле не должно быть пустым',
                },
            }).data("kendoValidator");
            var data = {
                field: fieldCmb.select(),
                grid_filter_condition_id: 0,
                filterCount: $(wnd.element).find("[filter]").length,
                grid_filter_id: id,
                value: ""
            }
            initFilter(data);
        })
        setFilters(filter);
        function setFilters(fil) {
            fil.FilterConditions.forEach(function (cond, index) {
                var data = {
                    field: fieldCmb.dataSource.indexOf(fieldCmb.dataSource.get(cond.grid_column_id)),
                    grid_column_id: cond.grid_column_id,
                    grid_filter_condition_id: cond.grid_filter_condition_id,
                    filterCount: index,
                    grid_filter_id: id,
                    grid_filter_operator: cond.grid_filter_operator,
                    grid_filter_logic: cond.grid_filter_logic,
                    value: cond.grid_filter_value
                }
                initFilter(data);
            })
        }
        $(wnd.element).find("#cancelButton").click(function (e) {
            e.preventDefault();
            wnd.close();
        })

        $(wnd.element).find("form").submit(function (e) {
            e.preventDefault();

            var form = $(this);
            var data = buildFilter(form);
            if (!fast) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "controlmanagermodule/gridfilter/savegridfilter", data,
                    function (e) {
                        BrcNotification.success("Выполнено");
                        if (filterIndex != -1)
                            self._getGrid().options.configuration.loadedConfig.GridFilters[filterIndex] = e;
                        else
                            self._getGrid().options.configuration.loadedConfig.GridFilters.push(e);
                        form.find("#filters").html('');
                        setFilters(e);
                        _init();

                        e.custom = true;
                        popup.element.find(".brc-grid-filter__popup-button").each(function (index, elem) {
                            if ($(elem).find("#grid_filter_id").val() == e.grid_filter_id)
                                selectFilter($(elem).find("[filterbutton]"));
                        })

                        wnd.close();
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON);
                    })
            }
            else {
                var conditions = [];
                data.grid_filter_condition.forEach(function (cond) {
                    var column = viewModel.cols.get(cond.grid_column_id);
                    conditions.push({
                        field: cond.grid_filter_operator == "contains" ? 'tolower(' + column.EntityProperty + ')' : column.EntityProperty,
                        operator: cond.grid_filter_operator,
                        value: column.ValueType.Key == "String" || column.ValueType.Key == "Link" || column.ValueType.Key == "HtmlString" ? (cond.grid_filter_operator == "contains" ? cond.grid_filter_value.toLowerCase() : cond.grid_filter_value) : column.ValueType.Key == "Number" ? kendo.parseFloat(cond.grid_filter_value) : column.ValueType.Key == "Date" ? kendo.parseDate(cond.grid_filter_value, ["dd.MM.yyyy", "dd.MM.yy"]) : column.ValueType.Key == "Boolean" ? cond.grid_filter_value_bool : false,
                        custom: true
                    })
                })
                if (conditions.length > 0) {
                    applyFilter();
                    var f = self._getGrid().dataSource.filter();
                    if (!!f)
                        conditions = f.filters.concat(conditions);
                    self._getGrid().dataSource.filter({ logic: data.grid_filter_logic, filters: conditions });
                    _currentCustomFilters = conditions;
                    $(popup.element).find("#fastFilter").addClass("k-primary");
                    //toolbar.element.find("#filterButton").html("<span class=\"k-icon k-i-filter\"></span>Быстрый фильтр");
                }
                wnd.close();
            }
        });

        function buildFilter(form) {
            var data = {
                grid_filter_id: form.find("[name=grid_filter_id]").val(),
                grid_filter_name: form.find("[name=grid_filter_name]").val(),
                grid_filter_logic: form.find("[name=grid_filter_logic]").val(),
                grid_id: self._getGrid().options.configuration.loadedConfig.Id,
                grid_filter_condition: []
            }

            form.find("[filter]").each(function (index, element) {
                var f = {
                    grid_filter_condition_id: $(element).find("[name=grid_filter_condition_id]").val(),
                    grid_filter_operator: $(element).find("[data-grid_filter_operator]").val(),
                    grid_filter_logic: $(element).find("[name=grid_filter_logic]").val(),
                    //[$(element).find("[filter-value]").attr("name")]: $(element).find("[filter-value]").attr("type") == "checkbox" ? $(element).find("[filter-value]")[0].checked : $(element).find("[filter-value]").val(),
                    grid_column_id: $(element).find("[data-grid_column_id]").val(),
                    grid_filter_id: $(element).find("[name=grid_filter_id]").val(),
                    app_user_id: $(element).find("[name=app_user_id]").val()
                };
                var widget = kendo.widgetInstance($(element).find("[filter-value][data-external=true]"));
                f[$(element).find("[filter-value]").attr("filter-value")] = $(element).find("[filter-value]").attr("type") == "checkbox" ? $(element).find("[filter-value]")[0].checked : !!widget ? widget.text() : $(element).find("[filter-value]").val();


                f.grid_filter_value = $(element).find("[filter-value]").attr("type") == "checkbox" ? $(element).find("[filter-value]")[0].checked : !!widget ? widget.text() : $(element).find("[filter-value]").val();

                data.grid_filter_condition.push(f);



            })
            return data;
        }
        function initFilter(dataItem) {
            var guid = kendo.guid();
            var column;
            var filter = $(filterTemplate(dataItem));
            filter.find(".k-i-close").parent().click(function (e) {

                var row = $(e.target).parents(".row");
                var _id = parseInt(row.find("input#grid_filter_condition_id").val());
                if (_id > 0) {

                    BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "controlmanagermodule/gridfilter/DeleteFilterCondition", { id: _id },
                        function (e) {
                            BrcNotification.success("Выполнено");
                            row.remove();

                        },
                        function (e) {
                            BrcNotification.error(e.responseJSON);
                        });

                }
                else {
                    row.remove();
                }

            })
            $(wnd.element).find("#filters").append(filter);

            kendo.bind(filter, viewModel);
            var field = filter.find("[data-grid_column_id]").data("kendoDropDownList");
            var op = filter.find("[data-grid_filter_operator]").data("kendoDropDownList");
            field.listView.element.addClass('brc-combobox-dropdownlist');
            op.listView.element.addClass('brc-combobox-dropdownlist');
            var valueControl = filter.find("[filter-value]");
            op.bind("change", function (e) {
                var valueControl = filter.find("[filter-value]");
                var widget = kendo.widgetInstance(valueControl);
                dataItem.value = valueControl.val();
                column = field.dataItem();
                if (valueControl.attr("data-external") == "true") {
                    var parent = widget.wrapper.parent();
                    valueControl = $('<input required="required" value="' + dataItem.value + '" name="grid_filter_value_text' + kendo.guid() + '" filter-value="grid_filter_value_text" id="value" type="text" class="k-textbox"/>');
                    widget.destroy();
                    widget.wrapper.parent().empty();
                    parent.append(valueControl);
                }
                dataItem.grid_filter_operator = !!e.sender.value() ? e.sender.value() : e.sender._initial;

                if (dataItem.grid_filter_operator == "isnull" || dataItem.grid_filter_operator == "isnotnull") {
                    var control = kendo.widgetInstance(valueControl);
                    if (!!control) {
                        control.enable(false)
                    }
                    else if (valueControl.attr("type") == "checkbox")
                        valueControl.attr("disabled", true);
                    else
                        valueControl.attr("readonly", true);
                    valueControl.attr("required", false);
                    validator.validateInput(valueControl);
                }
                else {
                    var control = kendo.widgetInstance(valueControl);
                    if (!!control) {
                        control.enable(true)
                    }
                    else if (valueControl.attr("type") == "checkbox"){
                        valueControl.removeAttr("disabled");
                    }
                    else
                        valueControl.removeAttr("readonly");
                    var col = field.dataSource.at(dataItem.field);

                    if (!!col && col.ValueType.Key != "Boolean")
                        valueControl.attr("required", true);

                }
                if (!!column && !kendo.widgetInstance(valueControl))
                    initValueField(column, valueControl, dataItem);
            })
            if (dataItem.field > -1) {
                field.select(dataItem.field);
                column = viewModel.cols.at(dataItem.field);
            }
            else if (!dataItem.value && dataItem.grid_column_id < 0) {
                field.value(null);
            }
            field.trigger("change");


            column = viewModel.cols.at(field.select());


            if (!dataItem.op && !dataItem.grid_filter_operator) {
                op.select(0);
            }
            else if (dataItem.grid_filter_operator)
                op.value(dataItem.grid_filter_operator);
            op.trigger("change");
        }

        

        //initControls($(wnd.windowHandler.element));
    }
    function initValueField(column, valueControl, dataItem) {
        if (column.ValueType.Key == "Number") {
            valueControl.removeClass("k-textbox");
            valueControl.attr("name", "grid_filter_value_float" + kendo.guid());
            valueControl.attr("filter-value", "grid_filter_value_float")
            var kendoControl = valueControl.kendoNumericTextBox().data("kendoNumericTextBox");
            kendoControl.value(dataItem.value);
        }

        else if (column.ValueType.Key == "Date") {
            valueControl.removeClass("k-textbox");
            valueControl.attr("name", "grid_filter_value_date" + kendo.guid());
            valueControl.attr("filter-value", "grid_filter_value_date")
            valueControl.attr("data-kendo-control", "kendoDatePicker");
            valueControl.val(dataItem.value);
            initControls(valueControl.parent());
        }
        else if (column.ValueType.Key == "Boolean") {
            var parent = valueControl.parent();
            valueControl.remove();
            var guid = kendo.guid();
            valueControl = $('<input type="checkbox" filter-value="grid_filter_value_bool"  id="value' + guid + '" name="grid_filter_value_bool' + guid + '" class="k-checkbox" ' + (dataItem.value == "True" ? 'checked="True"' : '') + '>' +
                                '<label for="value' + guid + '" class="k-checkbox-label"></label>');
            parent.append(valueControl);
        }
        else {
            if (!!column.GridFilterUrl && !!column.GridFilterValueField && !!column.GridFilterTextField && dataItem.grid_filter_operator == "eq") {
                valueControl.attr("data-external", "true");
                switch (column.GridFilterControlType) {
                    case 'Combobox':
                        valueControl.removeClass("k-textbox k-input");
                        valueControl.addClass("brc-combobox");
                        valueControl.attr("data-dataSource", window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl);
                        valueControl.attr("data-valueField", column.GridFilterValueField);
                        valueControl.attr("data-textField", column.GridFilterTextField);
                        valueControl.attr("data-kendo-control", "brc-combobox");
                        initControls(valueControl.parent());
                        break;
                    case 'Tree':
                        valueControl.removeClass("k-textbox k-input");
                        var dataSource = {
                            //data: JSON.parse(_context.find("#okofData").val())
                            odata: {
                                url: window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl
                            }
                        };

                        var dialog = new BrcDialogSelect(valueControl, dataSource).init({
                            multiple: false,
                            usingTextField: true,
                            dataTextField: column.GridFilterTextField,
                            dataValueField: column.GridFilterValueField,
                            dataParentValueField: column.GridFilterParentValueField,
                            hasChildren: "HasChildren",
                            height: "600px"
                        });
                        break;
                    case 'Multi':
                        valueControl.removeClass("k-textbox k-input");
                        valueControl.addClass("brc-combobox");
                        valueControl.attr("data-dataSource", window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl);
                        valueControl.attr("data-valueField", column.GridFilterValueField);
                        valueControl.attr("data-textField", column.GridFilterTextField);
                        valueControl.attr("data-title", column.DisplayName);
                        valueControl.attr("data-mode", "mixed");
                        valueControl.attr("data-kendo-control", "kendoMultiBox");
                        initControls(valueControl.parent());
                        break;
                    case 'Modal':
                        valueControl.removeClass("k-textbox k-input");
                        valueControl.addClass("brc-combobox");
                        valueControl.attr("data-dataSource", window["APPLICATION_CONFIG"].routes.root + column.GridFilterUrl);
                        valueControl.attr("data-valueField", column.GridFilterValueField);
                        valueControl.attr("data-textField", column.GridFilterTextField);
                        valueControl.attr("data-title", column.DisplayName)
                        valueControl.attr("data-mode", "modal");
                        valueControl.attr("data-kendo-control", "kendoMultiBox");
                        initControls(valueControl.parent());
                        break
                }
            }
        }

    }
    return button;
}

function _deleteFilterById(id) {
    var self = this;
    var f = self.kendoGrid.dataSource.filter();
    if (!!f) {
        for (var i = 0; i < f.filters.length; i++) {
            if (f.filters[i].id == id)
                f.filters.splice(i, 1);
        }
    }
    return f;
}
function _replaceFilter(filters) {
    var self = this;
    var filter = (self.kendoGrid != undefined ? self.kendoGrid.dataSource.filter() : self.dataSource.filter());
    if (!!filter) {
        for (var i = 0; i < filter.filters.length; i++) {
            if (filter.filters[i].id == filters.id) {
                filter.filters[i] = filters;
                self.kendoGrid != undefined ? self.kendoGrid.dataSource.filter(filter) : self.dataSource.filter(filter);
                return;
            }
        }
        filter.filters = filter.filters.concat(filters);
        self.kendoGrid != undefined ? self.kendoGrid.dataSource.filter(filter) : self.dataSource.filter(filter);
    }
    else {
        self.kendoGrid != undefined ? self.kendoGrid.dataSource.filter(filters) : self.dataSource.filter(filters);
    }
}

function _findFilterById(filters, id) {
    var self = this;
    var filter;
    for (var i = 0; i < filters.length; i++) {
        if (!!filters[i].filters && filters[i].id != id) {
            filter = self.findFilterById(filters[i].filters, id);
        }
        else if (filters[i].id == id) {
            filter = filters[i];
            break;
        }
    }
    return filter;
}

function _initColumnVisibility(getGrid) {
    var self = this;
    //var _toolbar = toolbar;
    var _popup;
    var _columns = [];
    self._getGrid = getGrid ? getGrid : self._getGrid;
    var visibilityLine = '<div class="brc-grid-visibility__popup-row"><input type="checkbox" class="k-checkbox" data-bind="checked: visibility,attr:{id: guid},events:{change:change}" /><label class="k-checkbox-label" data-bind="attr:{for:guid}"><span style="font-weight: 400 !important" data-bind="text:displayName"></span></label></div>';
    var popupTemplate = '<div></div>';
    var button = _extendToolbar();
    _initPopup();
    self._getGrid().options.configuration.loadedConfig.Columns.forEach(function (element, index) {

        _columns.push(new kendo.observable({
            name: element.EntityProperty,
            displayName: element.DisplayName,
            visibility: true,
            guid: kendo.guid(),
            change: function (e) {
                if (this.visibility)
                    self._getGrid().showColumn(this.name);
                else
                    self._getGrid().hideColumn(this.name);
            }
        }));

        var content = $(_popup.element).find("#visibilityContainer");
        var control = $(visibilityLine);
        kendo.bind(control, _columns[index]);
        content.append(control);
    })

    function _initPopup() {
        _popup = $(popupTemplate).kendoWindow({
            title: "Видимость колонок",
            width: "500px",
            height: "400px",


        }).data("kendoWindow").center();
        _popup.content('<div class="brc-window-layout"><div class="brc-window-layout__work-area"><div id="visibilityContainer" class="brc-grid-visibility__popup"></div></div></div>');
    }

    function _showPopup() {
        _popup.open();
    }
    function _extendToolbar() {
        var button = {
            type: "button",
            text: "Видимость колонок",
            
            id: "columnVisibility",
            click: function (e) {
                _showPopup();
            }
        }
        return button;
    }
    return button;
}
function _exportExcel() {
    var footerTemplates = [];
    this.columns.forEach(function (col) {
        footerTemplates.push(col.footerTemplate);
        col.footerTemplate = "";
    })
    this.saveAsExcel();
    this.columns.forEach(function (col, index) {
        col.footerTemplate = footerTemplates[index];
    })
}

function _exportPDF() {
    this.kendoGrid.saveAsPDF();
}

function sleep(delay) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
}
ui.plugin(BrcInlineGrid);
})(jQuery);