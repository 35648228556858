(function ($) {
    ui = kendo.ui,
    Widget = ui.BrcMultiBox;
    var _mode;
    var _id;
    var BrcAddressBox = ui.BrcMultiBox.extend({
        options: {
            idInput: null,
            helpInput: null,
            helpInputText: null,
            modeModal: null,
            name: "BrcAddressBox",
            selectModal: {
                title: "Выбор адреса",
                dataSource: null,
                refreshWindow: null,
                createObjectCallback: null,
            },
        },
        selectModal: function () {
            var self = this;
            _mode = self.options.modeModal;
            _id = self.options.idInput.attr("addr_id");

            var addrSearchWindowConfig = {
                title: "Выбор адреса",
                async: {
                    url: window["APPLICATION_CONFIG"].routes.root + "Address/FIAS/SearchAddressModal?id=" + _id + "&mode=" + _mode + "&data=" + self.options.helpInput.val()
                },
                width: 600,
                height: 630,
                onrefresh: refreshWindow
            }
            self.options.selectWindow = WindowManager.addWindow(addrSearchWindowConfig);
            refreshWindow.call(self.options.selectWindow.windowHandler);
            function refreshWindow(e) {
                var container = $(this.element);

                initControls(container);
                selectAddressValue(container, container);

                var validator = container.find("form").data("kendoValidator");
                container.find("#okButton").click(function (e) {
                    e.preventDefault();
                    if (validator.validate()) {
                        var resValue = collectValueToJS(container, 0);
                        self.value(resValue);
                        self.text(container.find("#addr_obj_prn").val());
                        self.options.helpInput.val(resValue);
                        if (self.options.helpInputText !== null) {
                            self.options.helpInputText.val(container.find("#addr_obj_prn").val());
                        }
                        self.options.selectWindow.windowHandler.destroy();
                    }
                });
                container.find("#cancelButton").click(function (e) {
                    if (!!validator)
                        validator.validateInput($(self.options.input));
                    self.options.selectWindow.windowHandler.destroy();
                });

                //подставляем текущее значение адреса
                var currentAddrElemId = container.find("#currentAddrElemId").val();
                //if (_mode == "parcel") {
                //    container.find(".searchAddressHome").bind("change", function () {
                //        searchCountAddressObj(container, 0, false);
                //    });
                //}
                if (_id > 0 && currentAddrElemId != undefined && currentAddrElemId != null && currentAddrElemId > 0) {
                    searchCountAddressObj(container, currentAddrElemId, true);

                    //if (_mode == "building" || _mode == "flat") {

                        if (container.find("#addr_obj_building_nmb").hasClass("k-textbox")) {
                            container.find("#addr_obj_building_nmb").replaceWith('<input class="brc-combobox" data-minLength="1" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasBuildingNmbList?addrElemId=' + currentAddrElemId + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_building_nmb" name="addr_obj_building_nmb" type="text" />');
                            initControls(container.find("#addr_obj_building_nmb").parent());
                        }

                        selectHomeValue(container.find("#addr_obj_building_nmb").parent(), container, currentAddrElemId);
                        
                    //} //if (_mode == "building" || _mode == "flat") {
                }

                container.find(".searchAddressHome").bind("change", function () {
                    searchCountAddressObj(container, 0, true);
                });
            }
        }
    });
    ui.plugin(BrcAddressBox);

    function selectAddressValue(elem, container) {
        elem.find(".searchAddressLevel[name=addr_elem_id]").bind("change", function () {
            var currentValue = $(this).val();
            var currentLevel = Number($(this).attr("level"));

            if (currentValue != undefined && currentValue != null && currentValue != "" && isNumeric(currentValue)) {

                initAddressValue(currentLevel, currentValue, container);    //определяем, кого будем разблокировать
                if (currentLevel > 1) {
                    //if (_mode == "building" || _mode == "flat") {
                        blockHomeControls(container);           //блокируем домики
                        initHomeValue(currentValue, container); //ищем домики и заполняем дропдаун
                    //}
                    if (_mode == "parcel") {
                        initParcelValue(currentValue, container);
                    }
                    searchCountAddressObj(container, currentValue, false); //ищем адрес в ФИАС
                }
            }
            else if (currentValue == undefined || currentValue == null || currentValue == "") {

                if (currentLevel == 1) {
                    container.find("input.searchAddressLevel[name=addr_elem_id]").each(function (item) {
                        var level = Number($(this).attr("level"));

                        if (level > currentLevel) {
                            destroyWidget($(this));
                            $(this).closest(".k-widget.brc-combobox").replaceWith('<input class="k-textbox searchAddressLevel k-state-disabled" level="' + level + '" id="addr_elem_id-' + level + '" name="addr_elem_id" type="text" disabled="disabled"  />');
                        }
                    });

                    //if (_mode == "building" || _mode == "flat") {
                        //блокируем домики
                        blockHomeControls(container);
                    //}
                    //else 
                    if (_mode == "parcel") {
                        blockParcelControls(container);
                    }
                    //очищаем сообщение и поле с полным адресом
                    container.find("#message").replaceWith("<span id='message'></span>");
                    container.find("#addr_obj_prn").val("");
                }
                else {
                    //if (_mode == "building" || _mode == "flat") {
                        //блокируем домики
                        blockHomeControls(container);
                    //}

                    //ищем ближайшее верхнее значение
                    var upLevel = 0;
                    var upValue = 0;
                    container.find("input.searchAddressLevel[name=addr_elem_id]").each(function (item) {
                        var tempValue = $(this).val();
                        if (tempValue != undefined && tempValue != null && tempValue != "" && isNumeric(tempValue)) {
                            var level = Number($(this).attr("level"));
                            if (level < currentLevel && level > upLevel) {
                                upLevel = level;
                                upValue = tempValue;
                            }
                        }
                    });
                    if (upLevel == 1 && _mode == "parcel") {
                        blockParcelControls(container);
                    }


                    initAddressValue(upLevel, upValue, container);
                    //if (_mode == "building" || _mode == "flat") {
                        selectHomeValue(container.find("#addr_obj_building_nmb").parent(), container, upValue);
                    //}

                    //ищем адрес в ФИАС
                    searchCountAddressObj(container, upValue, false);
                }
            }
        });
    }

    function initAddressValue(currentLevel, currentValue, container) {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "address/fias/GetFiasActiveAddressElemDepend",
            {
                parentAddrElemId: currentValue
            },
            function (data) {
                container.find("input.searchAddressLevel[name=addr_elem_id]").each(function (item) {
                    var level = Number($(this).attr("level"));
                    if (level > currentLevel) {
                        destroyWidget($(this));

                        if ($.inArray(level, data) < 0) {
                            $(this).closest(".k-widget.brc-combobox").replaceWith('<input class="k-textbox searchAddressLevel k-state-disabled" level="' + level + '" id="addr_elem_id-' + level + '" name="addr_elem_id" type="text" disabled="disabled"  />');
                            initControls(container.find("#addr_elem_id-" + level).parent());
                        }
                        else {

                            if (container.find("#addr_elem_id-" + level).hasClass("k-textbox")) {
                                container.find("#addr_elem_id-" + level).replaceWith('<input class="brc-combobox searchAddressLevel" level="' + level + '" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasActiveAddressElemList?elemLevelId=' + level + '&parentAddrElemId=' + currentValue + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_id" id="addr_elem_id-' + level + '" name="addr_elem_id" type="text" />');
                            }
                            else {
                                container.find("#addr_elem_id-" + level).closest(".k-widget.brc-combobox").replaceWith('<input class="brc-combobox searchAddressLevel" level="' + level + '" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasActiveAddressElemList?elemLevelId=' + level + '&parentAddrElemId=' + currentValue + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_id" id="addr_elem_id-' + level + '" name="addr_elem_id" type="text" />');
                            }
                            selectAddressValue(container.find("#addr_elem_id-" + level).parent(), container);
                            initControls(container.find("#addr_elem_id-" + level).parent());
                        }
                    }
                });
            },
            function (data) {
                if (data.responseJSON && data.responseJSON.message) {
                    BrcNotification.error(data.responseJSON.message);
                }
                else {
                    BrcNotification.error("Произошла ошибка!");
                }
            }
        );
    }

    function initHomeValue(parentValue, container) {
        destroyWidget(container.find("#addr_obj_building_nmb"));

        if (container.find("#addr_obj_building_nmb").hasClass("k-textbox")) {
            container.find("#addr_obj_building_nmb").replaceWith('<input class="brc-combobox" data-minLength="1" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasBuildingNmbList?addrElemId=' + parentValue + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_building_nmb" name="addr_obj_building_nmb" type="text" />');
        }
        else {
            container.find("#addr_obj_building_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="brc-combobox" data-minLength="1"  data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasBuildingNmbList?addrElemId=' + parentValue + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_building_nmb" name="addr_obj_building_nmb" type="text" />');
        }
        selectHomeValue(container.find("#addr_obj_building_nmb").parent(), container, parentValue);
        initControls(container.find("#addr_obj_building_nmb").parent());
    }

    function selectHomeValue(elem, container, addrElemId) {
        elem.find("#addr_obj_building_nmb").bind("change", function () {
            var currentValue = $(this).val();
            if (currentValue != undefined && currentValue != null && currentValue != "") {
                //подтягиваем корпуса и строения
                destroyWidget(container.find("#addr_obj_corps_nmb"));
                if (container.find("#addr_obj_corps_nmb").hasClass("k-textbox")) {
                    container.find("#addr_obj_corps_nmb").replaceWith('<input class="brc-combobox searchAddressHome"  data-minLength="1" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasCorpsNmbList?addrElemId=' + addrElemId + '&buildingNmb=' + currentValue + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_corps_nmb" name="addr_obj_corps_nmb" type="text" />');
                }
                else {
                    container.find("#addr_obj_corps_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="brc-combobox searchAddressHome" data-minLength="1"  data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasCorpsNmbList?addrElemId=' + addrElemId + '&buildingNmb=' + currentValue + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_corps_nmb" name="addr_obj_corps_nmb" type="text" />');
                }
                initControls(container.find("#addr_obj_corps_nmb").parent());

                destroyWidget(container.find("#addr_stroy_type_id"));
                if (container.find("#addr_stroy_type_id").hasClass("k-textbox")) {
                    container.find("#addr_stroy_type_id").replaceWith('<input class="brc-combobox searchAddressHome" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataAddrStroyType" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_stroy_type_name" data-val="true" data-valueField="addr_stroy_type_id" id="addr_stroy_type_id" name="addr_stroy_type_id" type="text" />');
                }
                else {
                    container.find("#addr_stroy_type_id").closest(".k-widget.brc-combobox").replaceWith('<input class="brc-combobox searchAddressHome" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataAddrStroyType" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_stroy_type_name" data-val="true" data-valueField="addr_stroy_type_id" id="addr_stroy_type_id" name="addr_stroy_type_id" type="text" />');
                }
                initControls(container.find("#addr_stroy_type_id").parent());


                container.find("#addr_obj_stroy_nmb").removeAttr("disabled").removeClass("k-state-disabled").addClass("searchAddressHome");
                
                //ищем адрес в ФИАС
                searchCountAddressObj(container, addrElemId,true);

                container.find(".searchAddressHome").bind("change", function () {
                    searchCountAddressObj(container, addrElemId,false);
                });
            }
            else {
                //блокируем корпуса, строения и помещения
                blockCorpsControls(container);
                if (_mode == "flat") {
                    blockFlatControls(container);
                }
                //ищем адрес в ФИАС
                searchCountAddressObj(container, addrElemId, false);
            }
        });
    }

    function blockHomeControls(container) {
        //блокируем контрол домика
        destroyWidget(container.find("#addr_obj_building_nmb"));
        container.find("#addr_obj_building_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="k-textbox k-state-disabled" id="addr_obj_building_nmb" name="addr_obj_building_nmb" type="text" disabled="disabled"  />');

        //блокируем корпуса и строения
        blockCorpsControls(container);

        //блокируем помещение
        if (_mode == "flat") {
            blockFlatControls(container);
        }
    }

    function blockCorpsControls(container) {
        destroyWidget(container.find("#addr_obj_corps_nmb"));
        destroyWidget(container.find("#addr_stroy_type_id"));
        container.find("#addr_obj_corps_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="k-textbox k-state-disabled" id="addr_obj_corps_nmb" name="addr_obj_corps_nmb" type="text" disabled="disabled"  />');
        container.find("#addr_stroy_type_id").closest(".k-widget.brc-combobox").replaceWith('<input class="k-textbox k-state-disabled" id="addr_stroy_type_id" name="addr_stroy_type_id" type="text" disabled="disabled"  />');
        container.find("#addr_obj_stroy_nmb").replaceWith('<input class="k-textbox k-state-disabled" id="addr_obj_stroy_nmb" name="addr_obj_stroy_nmb" type="text" disabled="disabled"  />');
    }

    function blockFlatControls(container) {
        destroyWidget(container.find("#addr_obj_flat_nmb"));
        container.find("#addr_obj_flat_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="k-textbox k-state-disabled" id="addr_obj_flat_nmb" name="addr_obj_flat_nmb" type="text" disabled="disabled"  />');
    }

    function initParcelValue(addrElemId, container) {
        destroyWidget(container.find("#addr_obj_parcel_nmb"));

        if (container.find("#addr_obj_parcel_nmb").hasClass("k-textbox")) {
            container.find("#addr_obj_parcel_nmb").replaceWith('<input class="brc-combobox" data-minLength="1" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasParcelNmbList?addrElemId=' + addrElemId + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_parcel_nmb" name="addr_obj_parcel_nmb" type="text" />');
        }
        else {
            container.find("#addr_obj_parcel_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="brc-combobox" data-minLength="1" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasParcelNmbList?addrElemId=' + addrElemId + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_parcel_nmb" name="addr_obj_parcel_nmb" type="text" />');
        }
        
        initControls(container.find("#addr_obj_parcel_nmb").parent());
        container.find("#addr_obj_parcel_nmb").bind("change", function () {
            searchCountAddressObj(container, 0,false);
        });
    }

    function blockParcelControls(container) {
        //блокируем контрол номера ЗУ
        destroyWidget(container.find("#addr_obj_parcel_nmb"));
        container.find("#addr_obj_parcel_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="k-textbox k-state-disabled" id="addr_obj_parcel_nmb" name="addr_obj_parcel_nmb" type="text" disabled="disabled"  />');
    }

    function collectValueToJS(container, addrElemId) {
        if (addrElemId == 0) {
            //ищем ближайшее верхнее значение
            var upLevel = 0;
            container.find("input.searchAddressLevel[name=addr_elem_id]").each(function (item) {
                var tempValue = $(this).val();
                if (tempValue != undefined && tempValue != null && tempValue != "" && isNumeric(tempValue)) {
                    var level = Number($(this).attr("level"));
                    if (level > upLevel) {
                        upLevel = level;
                        addrElemId = tempValue;
                    }
                }
            });
        }

        var result;
        if (_mode == "parcel") {
            result = JSON.stringify({
                addr_elem_id: addrElemId,
                addr_obj_building_nmb: container.find("#addr_obj_building_nmb").val(),
                addr_obj_corps_nmb: container.find("#addr_obj_corps_nmb").val(),
                addr_stroy_type_id: container.find("#addr_stroy_type_id").val(),
                addr_obj_stroy_nmb: container.find("#addr_obj_stroy_nmb").val(),
                addr_obj_location: container.find("#addr_obj_location").val(),
                addr_obj_parcel_nmb: container.find("#addr_obj_parcel_nmb").val()
            });
        }
        else if (_mode == "building") {
            result = JSON.stringify({
                addr_elem_id: addrElemId,
                addr_obj_building_nmb: container.find("#addr_obj_building_nmb").val(),
                addr_obj_corps_nmb: container.find("#addr_obj_corps_nmb").val(),
                addr_stroy_type_id: container.find("#addr_stroy_type_id").val(),
                addr_obj_stroy_nmb: container.find("#addr_obj_stroy_nmb").val(),
                addr_obj_location: container.find("#addr_obj_location").val()
            });
        }
        else if (_mode == "flat") {
            result = JSON.stringify({
                addr_obj_parent: {
                    addr_elem_id: addrElemId,
                    addr_obj_building_nmb: container.find("#addr_obj_building_nmb").val(),
                    addr_obj_corps_nmb: container.find("#addr_obj_corps_nmb").val(),
                    addr_stroy_type_id: container.find("#addr_stroy_type_id").val(),
                    addr_obj_stroy_nmb: container.find("#addr_obj_stroy_nmb").val(),
                    addr_obj_location: container.find("#addr_obj_location").val()
                },
                addr_elem_id: addrElemId,
                addr_obj_building_nmb: container.find("#addr_obj_building_nmb").val(),
                addr_obj_corps_nmb: container.find("#addr_obj_corps_nmb").val(),
                addr_stroy_type_id: container.find("#addr_stroy_type_id").val(),
                addr_obj_stroy_nmb: container.find("#addr_obj_stroy_nmb").val(),
                addr_obj_location: container.find("#addr_obj_location").val(),
                addr_obj_flat_nmb: container.find("#addr_obj_flat_nmb").val()
            });
        }
        return result;
    }

    function searchCountAddressObj(container, addrElemId, initFlat) {

        var resValue = collectValueToJS(container, addrElemId);

        container.find("#addr_obj_prn").val("Загрузка...");

        BrcPreloader.load(container, true);

        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "address/fias/BuildFiasAddress",
            {
                data: resValue,
                mode: _mode
            },
            function (data) {
                BrcPreloader.unload(container, true);

                if (data.addr_elem_id == 0) {
                    container.find("#message").replaceWith("<span class='text-danger' id='message'><strong>не найден в ФИАС</strong></span>");
                    container.find("#addr_obj_prn").val(data.addr_elem_prn_name);
                }
                else if (data.addr_elem_id == 1) {
                    container.find("#message").replaceWith("<span class='text-success' id='message'><strong>найден в ФИАС</strong></span>");
                    container.find("#addr_obj_prn").val(data.addr_elem_prn_name);
                }
                else {
                    container.find("#message").replaceWith("<span class='text-danger' id='message'><strong>совпадений в ФИАС: " + data.addr_elem_id + "</strong></p>");
                    container.find("#addr_obj_prn").val(data.addr_elem_prn_name);
                }
                //инициализируем контрол с помещениями
                if (_mode == "flat" && initFlat && container.find("#addr_obj_building_nmb").val() != undefined && container.find("#addr_obj_building_nmb").val() != null && container.find("#addr_obj_building_nmb").val() != "") {
                    initFlatControl(container, data.houseguid);
                }

                $("#guid").val(data.elemguid);
                $("#guidname").html(data.elemguidName);
            },
            function (data) {
                BrcPreloader.unload(container, true);
                if (data.responseJSON && data.responseJSON.message) {
                    BrcNotification.error(data.responseJSON.message);
                }
                else {
                    BrcNotification.error("Произошла ошибка!");
                }
            }
        );
    }

    function initFlatControl(container, houseguid) {
        var val = container.find("#addr_obj_flat_nmb").val(); //сохраняем текущее значение 
        destroyWidget(container.find("#addr_obj_flat_nmb"));
        if (container.find("#addr_obj_flat_nmb").hasClass("k-textbox")) {
            container.find("#addr_obj_flat_nmb").replaceWith('<input class="brc-combobox" value="' + val + '" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasFlatNmbList?houseGuid=' + container.find("#houseguid").val() + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_flat_nmb" name="addr_obj_flat_nmb" type="text" />');
        }
        else {
            container.find("#addr_obj_flat_nmb").closest(".k-widget.brc-combobox").replaceWith('<input class="brc-combobox" value="' + val + '" data-cleanButton="true" data-dataSource="' + window["APPLICATION_CONFIG"].routes.root + 'odata/ODataSearchFiasFlatNmbList?houseGuid=' + container.find("#houseguid").val() + '" data-kendo-control="brc-combobox" data-placeholder="" data-textField="addr_elem_prn_name" data-val="true" data-valueField="addr_elem_prn_name" id="addr_obj_flat_nmb" name="addr_obj_flat_nmb" type="text" />');
        }
        initControls(container.find("#addr_obj_flat_nmb").parent());

        container.find("#addr_obj_flat_nmb").bind("change", function () {
            searchCountAddressObj(container, 0, false);
        });
    }

    function destroyWidget(widgetElem) {
        var destroyCombo = widgetElem.data("kendoComboBox");
        if (destroyCombo != undefined) {
            destroyCombo.destroy();
        }
    }
    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
})(jQuery);
