var BrcWindow = function (selector) {
    this.selector = selector;
    //this.itemId = itemId;
    this.windowHandler = null;
    this.hashCode = 0;
    this.options = {};
    this.uid = kendo.guid();
}
BrcWindow.prototype.init = function(config)
{
    var window = this;
    var defaultOptions = {
        visibility: true,
        width: 600,
        height: 'auto',
        title: "",
        position: 10,
        onrefresh: undefined,
        id: undefined,
        onclose: undefined,
        modal: false
    };
    for (var option in defaultOptions)
        this.options[option] = config && config[option] !== undefined ? config[option] : defaultOptions[option];
    this.windowHandler = $(this.selector).kendoWindow({
        visible: false,
        width: this.options.width,
        title: this.options.title,
        position: this.options.position,
        modal: this.options.modal,
        
        actions: [
            "Minimize",
            "Maximize",
            "Close"
        ],
        close: this.options.onclose,
        refresh: this.options.onrefresh,
        dragend: function() {
            window._checkModalInWindowPosition();
        },
        resize: function () {
            window._checkModalInWindowPosition();
        },
        error: function (e) {
            window.windowHandler.content('<div class="brc-window-layout"><div class="brc-window-layout__content"><div class="brc-window-layout__work-area"><h5><div class="brc-no-elements"><span class="brc-no-elements__text">Ошибка при получении данных. Возможно объект был удален, перезагрузите страницу.</span></div></h5></div></div<</div>')
        }
    }).data("kendoWindow");
    //this.windowHandler.center();
    $(this.windowHandler.wrapper).attr("data-uid", this.uid);
    $(this.windowHandler.wrapper).css('height', this.options.height);
  return this;
}
BrcWindow.prototype.Destroy = function () {
    var self = this;
    var window = self.windowHandler;
        destroyControls(self.selector, function() {
            window.destroy();
        });
}
BrcWindow.prototype._checkModalInWindowPosition = function () {
    var self = this.windowHandler;
    var offset = self.wrapper.offset();
    if (self.wrapper.outerHeight() < window.innerHeight) {
        if (offset.top + self.wrapper.outerHeight() > window.innerHeight) {
            self.wrapper.css({ top: (offset.top - ((offset.top + self.wrapper.outerHeight()) - window.innerHeight)) - 2 });
        }
    }
    if (offset.left < 0) {
        self.wrapper.css({ left: 0 });
    }
    if (self.wrapper.outerWidth() < window.innerWidth) {
        if (offset.left + self.wrapper.outerWidth() > window.innerWidth) {
            self.wrapper.css({ left: (offset.left - ((offset.left + self.wrapper.outerWidth()) - window.innerWidth)) - 2 });
        }
    }

    //OLD=======================
    //var self = this.windowHandler;
    //var offset = self.wrapper.offset();
    //if (self.wrapper.outerHeight() < $("body").outerHeight()) {
    //    if (offset.top + self.wrapper.outerHeight() > $("body").outerHeight()) {
    //        self.wrapper.css({ top: (offset.top - ((offset.top + self.wrapper.outerHeight()) - $("body").outerHeight())) - 2 });
    //    }
    //}
    //if (offset.left < 0) {
    //    self.wrapper.css({ left: 0 });
    //}
    //if (self.wrapper.outerWidth() < $("body").outerWidth()) {
    //    if (offset.left + self.wrapper.outerWidth() > $("body").outerWidth()) {
    //        self.wrapper.css({ left: (offset.left - ((offset.left + self.wrapper.outerWidth()) - $("body").outerWidth())) - 2 });
    //    }
    //}
}
