var initControls = function (selector, lazy) {
    if (lazy) {
        var tabStrip = $(selector).find("[data-role=tabstrip]").data('kendoTabStrip');
        if (!!tabStrip) {
            tabStrip.bind('show', onActivate);

            function onActivate(e) {
                $(e.contentElement).find("[data-role=grid]").kendoGrid("refresh");

            };
        }
    }
    if (!lazy) {
        var parentWindow = $(selector).data("kendoWindow");
        $(selector).find('[data-kendo-control]').each(function (counter, item) {
            if (this.getAttribute("data-kendo-control") === "kendoTabStrip") {
                var tabStrip = $(item).kendoTabStrip({ animation: false }).data('kendoTabStrip');
                tabStrip.bind('show', onActivate);

                function onActivate(e) {
                    $(e.contentElement).find("[data-role=grid]").kendoGrid("refresh");

                };
            }

            if (this.getAttribute('data-kendo-control') === "kendoNumericTextBox") {
                var txtBox = $(item).kendoNumericTextBox({
                    format: this.getAttribute("data-format") ? this.getAttribute("data-format") : "n",
                    decimals: this.getAttribute("data-decimals") ? this.getAttribute("data-decimals") : "2",
                    min: this.getAttribute("data-min") ? this.getAttribute("data-min") : null,
                    max: this.getAttribute("data-max") ? this.getAttribute("data-max") : null,
                    change: function (e) {
                        if (e.sender.value() == null || e.sender.value()==undefined || e.sender.value()==NaN)
                            e.sender.value(null);
                    }
                }).data("kendoNumericTextBox");
            }
            if (this.getAttribute('data-kendo-control') === "kendoMaskedTextBox") {
            if ($(item).attr("data-role")!="maskedtextbox")
                var masketTextBox = $(item).kendoMaskedTextBox({
                    mask: this.getAttribute("data-mask"),
                    clearPromptChar: true
                }).data("kendoMaskedTextBox");
                
            }
            if (this.getAttribute('data-kendo-control') === "kendoBrcDatePicker") {
                $(item).kendoBrcDateTimePicker({
                    format: "dd.MM.yyyy HH:mm:ss",
                    dateInput: true,
                   
                })
            }
            if (this.getAttribute('data-kendo-control') === "kendoTimePicker") {
                $(item).kendoBrcTimePicker({
                    format: "HH:mm:ss",
                    dateInput: true,
                    
                })
            }
            if (this.getAttribute('data-kendo-control') === "kendoDatePicker") {
                if (item.value === "01.01.01 0:00:00" || item.value === "01.01.0001 0:00:00")
                    $(item).val("");
                var min = undefined;
                var max = undefined;
                var datePicker = $(item);
                if (!!$(item).attr("min-date"))
                    min = kendo.parseDate($(item).attr("min-date"), format)
                if (!!$(item).attr("max-date"))
                    max = kendo.parseDate($(item).attr("max-date"), format)
                var format = this.getAttribute("data-format") ? this.getAttribute("data-format") : "dd.MM.yyyy";
                var dtPicker = datePicker.kendoDatePicker({
                    min: min,
                    max: max,
                    format: format,
                    parseFormats: ["dd.MM.yy"]
                }).data("kendoDatePicker");
                var maskFormat = format.replace(/[a-zA-Z]/g, "0").replace(/\./g, "\\.");
                datePicker.kendoMaskedTextBox({
                    mask: maskFormat,
                    change: function () {
                        dtPicker.value(this.value());
                    }
                });
                datePicker.closest(".k-datepicker").add(datePicker).removeClass("k-textbox");
            }
            if (this.getAttribute('data-kendo-control') === "kendoDateTimePicker") {
                if (item.value === "01.01.01 0:00:00" || item.value === "01.01.0001 0:00:00")
                    $(item).val("");
                var datePicker = $(item);
                var format = this.getAttribute("data-format") ? this.getAttribute("data-format") : "dd.MM.yy HH:mm:ss";
                var dtPicker = datePicker.kendoDateTimePicker({
                    format: format,
                    parseFormats: ['dd.MM.yy HH:mm:ss', 'dd.MM.yyyy HH:mm:ss']
                }).data("kendoDateTimePicker");
                var maskFormat = format.replace(/[a-zA-Z]/g, "0").replace(/\./g, "\\.");
                datePicker.kendoMaskedTextBox({
                    mask: maskFormat,
                    change: function () {
                        if (!dtPicker.value()) {
                            dtPicker.value(null);
                        }
                    }
                });
                datePicker.closest(".k-datepicker").add(datePicker).removeClass("k-textbox");
            }
            if (this.getAttribute('data-kendo-control') === "brc-combobox") {
                var tempCombobox = new BrcCombobox(this, this.getAttribute('data-dataSource')).init({
                    placeholder:this.getAttribute('data-placeholder'),
                    dataTextField:this.getAttribute('data-textField'),
                    dataValueField:this.getAttribute('data-valueField'),
                    cleaningButton:this.getAttribute('data-cleanButton'),
                    enabled:this.getAttribute('data-enable') === "false" ? false : true,
                    reqType:this.getAttribute('data-reqtype'),
                    minLength: this.getAttribute('data-minLength')
                });
            }
            if (this.getAttribute('data-kendo-control') === "kendoLocalComboBox") {
                var isClear = $(this).find("option[selected]").length === 0;
                var localComboBox = $(item).kendoComboBox().data("kendoComboBox");
                if (isClear) {
                    localComboBox.value("");
                }
                localComboBox.bind("change", function () {
                    if (!this.dataItem()) {
                        this.value(null);
                    }
                });
            }
            if (this.getAttribute('data-kendo-control') === "kendoLocalMultiSelect") {
                $(item).kendoMultiSelect({
                    autoClose: false
                });
            }
            if (this.getAttribute('data-kendo-control') === "kendoVirtualComboBox") {
                var tempCombobox = new BrcCombobox(this, this.getAttribute("data-source")).init({
                    placeholder:this.getAttribute('data-placeholder'),
                    dataTextField:this.getAttribute('data-textField'),
                    dataValueField:this.getAttribute('data-valueField'),
                    cleaningButton:this.getAttribute('data-cleanButton'),
                    enabled:this.getAttribute('data-enable') === "false" ? false : true,
                    reqType: this.getAttribute('data-reqtype'),
                    isVirtual:true,
                    
                });


            }
            if (this.getAttribute('data-kendo-control') === "kendoMultiBox") {
                var tempCombobox = $(item).kendoBrcMultiBox({
                    placeholder: this.getAttribute('data-placeholder'),
                    dataTextField: this.getAttribute('data-textField'),
                    dataValueField: this.getAttribute('data-valueField'),
                    cleaningButton: this.getAttribute('data-cleanButton'),
                    enabled: this.getAttribute('data-enable') === "false" ? false : true,
                    reqType: this.getAttribute('data-reqtype'),
                    canCreate: true,
                    isVirtual: this.getAttribute('data-isVirtual') == "true",
                    virtualUrl:!this.getAttribute('data-virtualUrl')?null:this.getAttribute('data-virtualUrl'),
                    mode: this.getAttribute('data-mode'),
                    selectModal: {
                        title: this.getAttribute('data-title'),
                    }
                });
            }
            if (this.getAttribute('data-kendo-control') === "kendoSimpleLinkEditor")
            {
                $(item).kendoSimpleLinkEditor();
            }
        });
    }

    if ($(selector).is("form"))
        var form = $(selector);
    else
        var form = $(selector).find("form")
    if (!$(form).data("kendoValidator")) {
        var validator = form.kendoValidator({
            errorTemplate: kendo.template('<span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg">#=message#</span>'),
            messages: {
                required: 'Поле не должно быть пустым',
            },
            validate: function (e) {
                e.preventDefault();
                var tabStrip = $(e.sender.element).find("[data-role=tabstrip]").data("kendoTabStrip");
                if (!!tabStrip) {
                    if (!e.valid) {

                        if (tabStrip)
                            tabsValidate(tabStrip);
                    } else {
                        var tabs = tabStrip.items();
                        for (var i = 0; i < tabs.length; i++) {
                            $(tabs[i]).find(".k-link").removeClass("tab-invalid");
                        }
                    }
                }

                function tabsValidate(tabStrip) {
                    if (tabStrip) {
                        var tabs = tabStrip.items();
                        for (var i = 0; i < tabs.length; i++) {
                            if ($(tabStrip.contentHolder(i)).find("input.k-invalid, select.k-invalid").length > 0) {
                                $(tabs[i]).find(".k-link").addClass("tab-invalid");
                            } else {
                                $(tabs[i]).find(".k-link").removeClass("tab-invalid");
                            }
                        }
                    }
                }

            }
        }).data("kendoValidator");
        return validator;
    }
};

var destroyControls = function (selector, callback) {
    setTimeout(function () {
        $(selector).find('[data-brc-dialog-select-control-guid]').each(function () {
            var windowObject = $('[data-brc-dialog-select-window-guid="' + $(this).attr('data-brc-dialog-select-control-guid') + '"]');
            var instance = kendo.widgetInstance(windowObject);
            if (instance)
                instance.destroy();
            windowObject.remove();
        });

        $(selector).find('.k-widget').each(function () {
            var instance = kendo.widgetInstance($(this));
            if (instance)
                instance.destroy();
        });
        if (callback)
            callback();
    }, 1);
    //$(selector).find('[data-kendo-control]').each(function (counter, item) {
    //    var obj = null;
    //    if (this.getAttribute('data-kendo-control') === "brc-combobox") {
    //        obj = $(this).data("kendoComboBox");
    //    }
    //    if (this.getAttribute('data-kendo-control') === "kendoLocalComboBox") {
    //        obj = $(this).data("kendoComboBox");
    //    }
    //    if (this.getAttribute('data-kendo-control') === "kendoDateTimePicker") {
    //        obj = $(this).data("kendoDateTimePicker");
    //    }
    //    if (this.getAttribute('data-kendo-control') === "kendoDatePicker") {
    //        obj = $(this).data("kendoDatePicker");
    //    }
    //    if (this.getAttribute('data-kendo-control') === "kendoNumericTextBox") {
    //        obj = $(this).data("kendoNumericTextBox");
    //    }
    //    if (this.getAttribute('data-kendo-control') === "kendoLocalMultiSelect") {
    //        obj = $(this).data("kendoMultiSelect");
    //    }
    //    if (this.getAttribute('data-kendo-control') === "kendoVirtualComboBox") {
    //        obj = $(this).data("kendoComboBox");
    //    }
    //    if (obj)
    //        obj.destroy();
    //});
    //$(selector).find('[data-kendo-control=kendoTabStrip]').each(function () {
    //    var obj = $(this).data("kendoTabStrip");
    //    if (obj)
    //        obj.destroy();
    //});
};
