var AssetContract1cModal = function () {
    var _contractId;
    var _onOKClose;
    var _1cCard;
    return {
        init: init
    };

    function init(config) {
        _contractId = config.contractId;
        _onOKClose = config.onOKClose;
        initWindow();
    }

    function initWindow(config) {
        var selector = "#contract1cWindow" + _contractId;

        var windowConfig = {
            id: selector,
            title: "Информация из 1С",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/AssetContractFrom1cCard",
                data: {
                    id: _contractId
                }
            },
            width: 600,
            height: 400,
            onrefresh: onRefreshWindow
        }

        _1cCard = WindowManager.openOrAddWindow(windowConfig);
    }


    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);
        var form = _context.find("form");
        var validator = initControls(_context);

        ReadonlyHelper(_context.find(".readonly"), true);

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                if ($("#move1cDataCheckbox").prop("checked")) {
                    BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/Sync1C",
                        $(event.target).serializeArray(),
                        function (id) {
                            BrcNotification.success("Выполнено");
                            _onOKClose();
                            WindowManager.closeWindow(true, _1cCard);
                        },
                        function (data) {
                            BrcNotification.error("Произошла ошибка!");
                        }
                    );
                }
            }
            WindowManager.closeWindow(true, _1cCard);
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _1cCard);
        })
    }
};