var AssetTaxModal = function () {
    var _assetTaxId;
    var _assetId;
    var _onOKClose;
    var _assetTaxCard;
    var _context;

    return {
        init: init
    };

    function init(config) {
        _assetTaxId = config.assetTaxId;
        _assetId = config.assetId;
        _onOKClose = config.onOKClose;

        initWindow();
    }

    function initWindow(config) {
        var selector = "#assetTaxWindow" + _assetTaxId;

        
            var windowConfig = {
                title: _assetTaxId > 0 ? "Налог на имущество" : "Новый налог на имущество",
                async: {
                    url: window["APPLICATION_CONFIG"].routes.root + "Estate/AssetTax/AssetTaxCard",
                    data: {
                        id: _assetTaxId,
                        assetId: _assetId,
                    }
                },
                width: 700,
                height: 400,
                onrefresh: onRefreshWindow,
                id: selector
            }
            _assetTaxCard = WindowManager.openOrAddWindow(windowConfig);
        
    }    
    
    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        var validator = initControls(_context);
        initRecalculation();
        BrcMetaModule.init(_context.find("#assetTaxAdditionallyBlock")[0]);

        if ((_context.find("#canUpdateAssetTax").val() === 'false' && _assetTaxId > 0) || (_context.find("#canCreateAssetTax").val() === 'false' && _assetTaxId == 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        DateRangeHelper.init(_context.find("#asset_tax_period_begin_date"), _context.find("#asset_tax_period_end_date"));

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetTax/SaveAssetTax",
                    $(event.target).serializeArray(),
                    function (data) {
                        BrcNotification.success("Выполнено");
                        _onOKClose();
                        WindowManager.closeWindow(true, _assetTaxCard);
                    },
                    function (data) {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _assetTaxCard);
        })
    }

    function initRecalculation() {
        var initTaxBaseTextBox = _context.find("#asset_tax_init_base").data("kendoNumericTextBox");
        var taxBenefitTextBox = _context.find("#asset_tax_benefit").data("kendoNumericTextBox");
        var taxBaseTextBox = _context.find("#asset_tax_base");
        var taxRateTextBox = _context.find("#asset_tax_rate").data("kendoNumericTextBox");
        var ownershipRatioTextBox = _context.find("#ownershipRatio");
        var taxSummTextBox = _context.find("#asset_tax_summ");
        var taxPeriodBeginDateDatePicker = _context.find("#asset_tax_period_begin_date").data("kendoDatePicker");
        var taxPeriodEndDateDatePicker = _context.find("#asset_tax_period_end_date").data("kendoDatePicker");

        taxPeriodBeginDateDatePicker.bind("change", recalculate);
        taxPeriodEndDateDatePicker.bind("change", recalculate);
        initTaxBaseTextBox.bind("change", recalculate);
        taxBenefitTextBox.bind("change", recalculate);
        taxRateTextBox.bind("change", recalculate);

        recalculate();

        function recalculate() {
            var taxBase = initTaxBaseTextBox.value() - taxBenefitTextBox.value();
            if (taxBase < 0) {
                taxBase = 0;
            }
            taxBaseTextBox.val(taxBase.toString().replace(".", ","));

            var ownershipRatio = 0;

            var beginDate = new Date(taxPeriodBeginDateDatePicker.value());
            var endDate = new Date(taxPeriodEndDateDatePicker.value());

            if (beginDate && endDate && beginDate >= new Date(1970,0,1) && endDate >= new Date(1970,0,1)) {
                if (beginDate > endDate) {
                    endDate = beginDate;
                    taxPeriodEndDateDatePicker.value(endDate);
                }

                if (beginDate.getYear() === endDate.getYear() && beginDate.getMonth() === endDate.getMonth()) {
                    if (beginDate.getDate() <= 15 && endDate.getDate() > 15) {
                        ownershipRatio++;
                    }
                }
                else {
                    var i = 1;
                    while (true) {
                        beginDate.setMonth(beginDate.getMonth() + 1);

                        if (beginDate.getYear() === endDate.getYear() && beginDate.getMonth() === endDate.getMonth())
                        {
                            if (endDate.getDate() > 15) {
                                i++;
                            }
                            break;
                        }
                        else {
                            i++;
                        }
                    }
                    ownershipRatio = ownershipRatio + i;
                }
            }

            ownershipRatioTextBox.val(ownershipRatio.toString().replace(".", ","));

            var taxSumm = taxBase * taxRateTextBox.value() * 0.01 * ownershipRatio / 12;
            taxSummTextBox.val(taxSumm.toFixed(2).toString().replace(".",","));
        }
    }
};