var EquipmentModal = function () {
    var _onOKClose;
    var _equipmentId;
    var _equipmentCard;
    var _assetId;
    var _assetComplexId;
    var _context;
    var _needAssetCostGridInit = true;
    var _needAssetTaxGridInit = true;
    var _needAssetInventGridInit = true;
    var _needAssetFixedGridInit = true;
    var _modeKind;

    return {
        init: init
    };

    function init (config) {
        _onOKClose = config.onOKClose;
        _equipmentId = config.equipmentId;
        _assetId = config.assetId;
        _assetComplexId = config.assetComplexId;
        _modeKind = config.modeKind;
        var selector = "#equipmentWindow" + _equipmentId;

        var windowConfig = {
               
            title: _equipmentId > 0 ? "Движимое имущество" : "Новое движимое имущество",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/Equipment/EquipmentCard",
                data: {
                    id: _equipmentId,
                    assetId: _assetId,
                    kindEnum: _modeKind,
                    assetComplexId: _assetComplexId,
                    groupId: config.groupId
                },
                
            },
            width: 950,
            height: 800,
            onrefresh: onRefreshWindow,
            id: selector
        }
            

        _equipmentCard = WindowManager.openOrAddWindow(windowConfig);
    }    
    
    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        if (_equipmentId > 0) {
            _context.parent().find(".k-window-title").html("Движимое имущество: " + _context.find("#asset_asset_name").val());
        }

        var dataSourceOkofTreeView = {
            //data: JSON.parse(_context.find("#okofData").val())
            odata: {
                url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyOKOF"
            }
        };

        var dataSourceEquipmentTypeTreeView = {
            //data: JSON.parse(_context.find("#equipmentTypeData").val())
            odata: {
                url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyEquipmentType"
            }
        };

        var okofTreeView = new BrcDialogSelect(_context.find("#equipment_asset_okof_id")[0], dataSourceOkofTreeView).init({
            multiple: false,
            dataTextField: "okof_sname",
            dataValueField: "okof_id",
            dataParentValueField: "parent_okof_id",
            hasChildren: "HasChildren",
            height: "600px"
        });

        var equipmentTypeTreeView = new BrcDialogSelect(_context.find("#equipment_equip_type_id")[0], dataSourceEquipmentTypeTreeView).init({
            multiple: false,
            dataTextField: "equip_type_name",
            dataValueField: "equip_type_id",
            dataParentValueField: "parent_equip_type_id",
            hasChildren: "hasChildren",
            height: "600px"
        });

        var validator = initControls(_context);

        BrcMetaModule.init(_context.find("#equipmentAdditionallyBlock")[0]);

        DateRangeHelper.init(_context.find("#equip_date_created"), _context.find("#equip_date_removed"));
        DateRangeHelper.init(_context.find("#equip_date_created"), _context.find("#equip_date_removed_doc"));

        if ((_context.find("#canUpdateEquipment").val() === 'false' && _equipmentId > 0) || (_context.find("#canCreateEquipment").val() === 'false' && _equipmentId === 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }
        ReadonlyHelper(".readonly", true);
        if (_equipmentId > 0) {
            var tabStrip = _context.find("#tabstrip").data("kendoTabStrip");
            tabStrip.bind("select", onTabStripSelect);
        }

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/Equipment/SaveEquipment",
                    $(event.target).serializeArray(),
                    function (id) {
                        BrcNotification.success("Выполнено");
                        if (_equipmentId === 0) {
                            _onOKClose();
                            WindowManager.closeWindow(true, _equipmentCard);
                            init({
                                equipmentId: id,
                                onOKClose: _onOKClose
                            });
                        }
                        else {
                            _onOKClose();
                            WindowManager.closeWindow(true, _equipmentCard);
                        }
                    },
                    function (data) {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _equipmentCard);
        });

    }

    function onTabStripSelect(e) {
        switch (e.item.id) {
            case "assetCosts":
                if (_needAssetCostGridInit) {
                    _needAssetCostGridInit = false;

                    var assetCostGrid = new AssetCostGrid();
                    assetCostGrid.init({
                        selector: _context.find("#assetCostGrid"),
                        toolbarSelector: _context.find("#assetCostToolbar"),
                        assetId: _equipmentId
                    });
                }
                break;
            case "assetFixeds":
                if (_needAssetFixedGridInit) {
                    _needAssetFixedGridInit = false;
                    var assetFixedGrid = new AssetFixedGrid();
                    assetFixedGrid.init({
                        selector: "#assetFixedGrid",
                        toolbarSelector: "#assetFixedToolbar",
                        assetId: _equipmentId
                    });
                }
                break;
            case "assetTaxes":
                if (_needAssetTaxGridInit) {
                    _needAssetTaxGridInit = false;

                    var assetTaxGrid = new AssetTaxGrid();
                    assetTaxGrid.init({
                        selector: _context.find("#assetTaxGrid"),
                        toolbarSelector: _context.find("#assetTaxToolbar"),
                        assetId: _equipmentId
                    });
                }
                break;
            case "assetInvents":
                if (_needAssetInventGridInit) {
                    _needAssetInventGridInit = false;

                    var assetInventGrid = new AssetInventGrid();
                    assetInventGrid.init({
                        selector: _context.find("#assetInventGrid"),
                        toolbarSelector: _context.find("#assetInventToolbar"),
                        mode: "equipment",
                        assetId: _equipmentId
                    });
                }
                break;
            case "documents":
                {
                    var selector = $(e.contentElement).find("#documentGridWrapper");
                    if (!kendo.widgetInstance($(selector).find(".brc-grid"))) {
                        var guid = kendo.guid();
                        selector.attr("guid", guid);

                        var documents = new DocumentGrid(selector, { entityName: "asset_id", entityId: _equipmentId });
                        documents.init();
                    }
                    break;
                }
                case "files":
               {
                if (!kendo.widgetInstance($(e.contentElement).find("#filesView"))) {
                    var itemView = $(e.contentElement).find("#filesView").kendoItemView({
                    files: true,
                    windowStyle: true,
                    title: "",
                    toolbar: $(e.contentElement).find("#filesToolbar"),
                    dataSource: {
                        filter: { field: "asset_id", operator: "eq", value: parseInt(_equipmentId) }
                           
                        },
                    upload: {
                        upload: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                            data: function () {
                                return {
                                    asset_id: _equipmentId, file_relation_id: 0, doc_file_id: 0
                                }
                            },
                           
                        },
                        destroy: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                            data: function (dataItems) {
                                var self = this;
                                var ids = [];
                                dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                })

                                return {
                                    ids: ids,
                                    relation: { asset_id: _equipmentId, file_relation_id: 0, doc_file_id: 0 }
                                }
                            },
                            
                        },
                        copy: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                            data: function (opt) {
                                var self = this;
                                var ids = [];
                                opt.dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                })
                                return {
                                    relation: { asset_id: _equipmentId, file_relation_id: 0, doc_file_id: 0 },
                                    ids: ids
                                }
                            }
                        },
                        move: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                            data: function (opt) {
                                var self = this;
                                var ids = [];
                                opt.dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                })
                                return {
                                    relation: { asset_id: _equipmentId, file_relation_id: 0, doc_file_id: 0 },
                                    old_relation: opt.moveFrom.options.relation,
                                    ids: ids
                                }
                            }
                        },
                        check: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                            data: function (dataItems) {
                                var self = this;
                                var names = [];
                                dataItems.forEach(function (item) {
                                    names.push(item.doc_file_name);
                                })
                                return {
                                    relation: { asset_id: _equipmentId, file_relation_id: 0, doc_file_id: 0 },
                                    fileNames: names
                                }
                            },
                            reject: function (error, dataItems) {
                                for (var i = 0; i < dataItems.length; i++) {
                                    if (dataItems[i].doc_file_name === error.fileName) {
                                        dataItems.splice(i, 1);
                                        break;
                                    }
                                }
                                return dataItems;
                            }
                        },

                    }
                }).data("kendoItemView");
                    itemView.filesToolbar({ asset_id: _equipmentId, file_relation_id: 0, doc_file_id: 0 });
                }
            }
        }
    }
};