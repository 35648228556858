function ContractPayPlanModal() {
    var _contractPayPlanId;
    var _contractPayId;
    var _onOKClose;
    var _window;
    var _context;
    var _paymentGrid;

    return {
        init: init
    };

    function init(config) {
        _contractPayPlanId = config.contractPayPlanId;
        _contractPayId = config.contractPayId;
        _onOKClose = config.onOKClose;

        initWindow();
    }

    function initWindow(config) {
        var selector = "#contractPayPlanWindow" + _contractPayPlanId;

        var windowConfig = {
            title: _contractPayPlanId > 0 ? "Начисление" : "Новое начисление",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/ContractPayPlanModal",
                data: {
                    id: _contractPayPlanId,
                    contractPayId: _contractPayId,
                }
            },
            width: 900,
            height: 600,
            onrefresh: onRefreshWindow,
            id: selector
        }
        _window = WindowManager.openOrAddWindow(windowConfig);
       
    }    
    
    function onRefreshWindow(e) {
        _context = $(e.sender.element.context);

        var validator = initControls(_context);

        if ((_context.find("#canUpdateContractPayPlan").val() === 'false' && _contractPayPlanId > 0) || (_context.find("#canCreateContractPayPlan").val() === 'false' && _contractPayPlanId == 0)) {
            _context.find("#saveButton").hide();
            ReadonlyHelper(e.sender.element.context, true);
        }

        DateRangeHelper.init(_context.find("#contract_pay_plan_beg_date"), _context.find("#contract_pay_plan_end_date"));

        if (_contractPayPlanId > 0) {
            _paymentGrid = new PaymentGrid();
            _paymentGrid.init({
                selector: "#paymentGrid"
            });
        }

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ContractPay/SaveContractPayPlan",
                    $(event.target).serializeArray(),
                    function (contractPayPlanId) {
                        BrcNotification.success("Выполнено");
                        _onOKClose();
                        WindowManager.closeWindow(true, _window);
                        if (_contractPayPlanId == 0) {
                            init({
                                contractPayPlanId: contractPayPlanId,
                                onOKClose: _onOKClose
                            });
                        }
                    },
                    function (data) {
                        if (data.responseJSON && data.responseJSON.message) {
                            BrcNotification.error(data.responseJSON.message);
                        }
                        else {
                            BrcNotification.error("Операция не была выполнена!");
                        }
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _window);
        });
    }
}