function DocumentGrid(selector, config, gridType, toolbarSelector) {
    this.selector = selector;
    this.config = config;
    this.gridType = gridType;
    this.toolbarSelector = toolbarSelector;
    this.tree;
}
DocumentGrid.prototype.init = function (isTree) {
    var self = this;
    var that = this;
    self._create = function (e, id) {
        openCardClick(e, id);
    }
    var docGridConfig = !!self.config.gridConfig ? self.config.gridConfig : Window["DOCUMENTS_CONFIG"].documentGridConfig
    var gridSelector;
    if ($(self.selector).find("#documentGrid").length > 0)
        gridSelector = $(self.selector).find("#documentGrid");
    else
        gridSelector = $(self.selector);
    function setToolBarPermissions(toolBarSelector) {
        if (!!Window["DOCUMENTS_CONFIG"]) {
            var p = Window["DOCUMENTS_CONFIG"].permissions;
            if (!p.canCreateDocument)
                kendo.widgetInstance($(toolBarSelector)).enable("#createDocument", false);
            if (!p.canDeleteDocument)
                kendo.widgetInstance($(toolBarSelector)).enable("#deleteDocument", false);
        }
    };
    function setCardPermission(cardSelector, action) {
        if (!!Window["DOCUMENTS_CONFIG"]) {
            var p = Window["DOCUMENTS_CONFIG"].permissions;
            if (action === "create") {
                ReadonlyHelper(cardSelector, !p.canCreateDocument);
                if (!p.canCreateDocument)
                    $(cardSelector).find("[type=submit]").css("display", "none");
            }
            else if (action === "update") {
                ReadonlyHelper(cardSelector, !p.canUpdateDocument);
                if (!p.canUpdateDocument)
                    $(cardSelector).find("[type=submit]").css("display", "none");
            }
        }
    }
    var entityName = self.config.entityName;
    var entityId = self.config.entityId;
    //var cardConfig = Window["DOCUMENTS_CONFIG"].documentCardConfig;
    if (!self.config.dataSource) {
        var dataSource = {
            type: self.gridType === 'odata' ? "odata" : "",
            url: Window["DOCUMENTS_CONFIG"].urls.documentGridData,
            data: {},
            dataType: self.gridType === 'odata' ? "json" : "",
            serverPaging: !Window["DOCUMENTS_CONFIG"].isCard,//false,
            serverFiltering: !Window["DOCUMENTS_CONFIG"].isCard,
            serverSorting: !Window["DOCUMENTS_CONFIG"].isCard,
            filter: { field: "doc_is_archive", operator: "eq", value: false },
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "estate/report/ExcelReportDocumentGrid"
        };
        dataSource.data[entityName] = entityId;
    }
    else {
        var dataSource = self.config.dataSource;
    }


    var bGrid = new BrcGrid(gridSelector, dataSource);

    var grid = bGrid.init(docGridConfig).kendoGrid;

    grid.dataSource.error = function (e) {
        BrcNotification.error(e.responseJSON);
    }
    grid.dataSource.change = function (e) {
        if (e !== undefined && e.action !== "sync")
            BrcNotification.success("Выполнено");
    }
    grid.resize();
    grid.__doc = self;
    if (!!self.toolbarSelector)
        var toolBarSelector = self.toolbarSelector;
    else
        var toolBarSelector = $(self.selector).closest(".brc-window-layout").find("#documentToolbar");
    if (toolBarSelector.length > 0) {
        toolBarInit(toolBarSelector)
    }
    else {
        toolBarInit($(self.selector).closest(".brc-page-layout").find("#documentToolbar"));
    }
    function toolBarInit(selector) {
        var items = [{
                type: "button",
                text: "Создать",
                click: openCardClick,
                id: "createDocument"
            },
            {
                type: "button",
                text: "Удалить",
                click: btnDelDocument,
                id: "deleteDocument"
        }];

        var entityName = self.config.entityName;
        if (entityName !== "realty_placement_asset_id" && entityName !== "realty_environment_asset_id" && entityName !== "realty_social_security_asset_id" && entityName !== "realty_public_service_asset_id") {
            items.push({
                type: "button",
                text: "Вырезать",
                click: function (e) { cutCardClick.call(self, false); },
                id: "cutDocument"
            });
            items.push({
                type: "button",
                text: "Вставить",
                click: function (e) { pasteCardClick.call(self) },
                id: "pasteDocument"
            });
            items.push({
                type: "button",
                text: "Экспорт MS Excel",
                click: function () { bGrid.exportExcel(); },
                id: 'excelExport'
            });
        }
        self.toolbar = $(selector).kendoToolBar({
            items: items
        }).data("kendoToolBar");
        if (!isTree && !Window["DOCUMENTS_CONFIG"].isCard) {

            self.toolbar.add({
                type: "button",
                text: '<i class="fa fa-sitemap" title="Дерево"></i>',
                id: "display",
                click: function (e) {
                    //_initTree($("#splitter"));
                    //self.toolbar.hide("#display");
                    location.href = window["APPLICATION_CONFIG"].routes.root + "Estate/Document/DocumentGrid?isTree=true";
                }
            })
        }
        if (selector.length > 0)
            setToolBarPermissions(selector);

        function cutCardClick(copy) {
            var self = this;
            self.buffer = [];
            bGrid.getSelectedKeys().forEach(function (item) {
                var dataItem = bGrid.kendoGrid.dataSource.get(item);
                self.buffer.push(dataItem);
            });
            if (!!copy)
                self.buffer["copy"] = true;
            else
                self.buffer["copy"] = false;
        }

        function pasteCardClick() {
            var self = this;
            if (self.hasOwnProperty("buffer") && self.buffer.length > 0) {
                BrcPreloader.load($(bGrid.selector));
                var items = [];
                //var selectedFolder = self.tree.brcList.dataItem(self.tree.getSelectedKey());
                var selectedFolderId = self.tree.getSelectedKey();
                if (!!selectedFolderId) {
                    self.buffer.forEach(function (dataItem) {
                        var flag = false;
                        var currItems = bGrid.kendoGrid.dataSource.view();
                        currItems.forEach(function (element) {
                            if (element.id == dataItem.id)
                                flag = true;
                        })
                        if (flag)
                            return;

                        items.push(dataItem.toJSON());
                        //dataItem[self.options.brcListConfig.key] = selectedFolder[self.options.brcListConfig.key];

                    });
                    if (items.length > 0) {
                        if (!self.buffer.copy) {
                            changeGroup(items, selectedFolderId)//selectedFolder[self.options.brcListConfig.key])
                                .done(function (e) {
                                    bGrid.kendoGrid.dataSource.pushCreate(items);
                                    bGrid.kendoGrid.dataSource.read();
                                }).fail(function (e) {

                                }).always(function () {
                                    BrcPreloader.unload($(bGrid.selector));
                                });
                        }
                        else {
                            self.options.brcGridConfig.addGroup(items, selectedFolderId)//selectedFolder[self.options.brcListConfig.key])
                               .done(function (e) {
                                   bGrid.kendoGrid.dataSource.pushCreate(items);
                                   bGrid.kendoGrid.dataSource.read();
                               }).fail(function (e) {

                               }).always(function () {
                                   BrcPreloader.unload($(bGrid.selector));


                               });
                        }
                    }
                    else {
                        BrcNotification.info("Нет записей доступных для вставки");
                        BrcPreloader.unload($(bGrid.selector));
                    }
                }
                else {
                    var selectedFolder = self.staticList.selectNodeByUid(self.staticList.selectNode());
                    switch (selectedFolder.id) {
                        case "all":
                            if (!!self.options.brcListConfig.virtualNode && !self.options.brcListConfig.virtualNode.all.dropTarget) {
                                BrcPreloader.unload($(bGrid.selector));
                                BrcNotification.info(self.options.brcListConfig.virtualNode.all.message)
                                return;
                            }
                            break;
                        case "ungrouped":
                            if (!!self.options.brcListConfig.virtualNode && !self.options.brcListConfig.virtualNode.ungrouped.dropTarget) {
                                BrcPreloader.unload($(bGrid.selector));
                                BrcNotification.info(self.options.brcListConfig.virtualNode.ungrouped.message)
                                return;
                            }
                            break;
                    }
                    if (!!selectedFolder) {
                        var items = [];
                        self.buffer.forEach(function (dataItem) {
                            var flag = false;
                            var currItems = bGrid.kendoGrid.dataSource.view();
                            currItems.forEach(function (element) {
                                if (element.id == dataItem.id && element[self.options.brcListConfig.linkedItemKey] == null)
                                    flag = true;
                            })
                            if (flag)
                                return;

                            items.push(dataItem.toJSON());
                            dataItem[self.options.brcListConfig.key] = null;

                        });
                        if (items.length > 0) {
                            if (!self.buffer.copy) {
                                self.options.brcGridConfig.changeGroup(items, null)
                               .done(function (e) {
                                   bGrid.kendoGrid.dataSource.pushCreate(items);
                                   bGrid.kendoGrid.dataSource.read();
                               }).fail(function (e) {

                               }).always(function () {
                                   BrcPreloader.unload($(bGrid.selector));
                               });
                            }
                            else {
                                BrcNotification.info("")
                            }
                        }
                        else {
                            BrcNotification.info("Нет выбранных записей");
                            BrcPreloader.unload($(bGrid.selector));
                        }
                    }
                    else {
                        BrcNotification.info("Необходимо выбрать группу назначения")
                    }
                }
            }
            self.buffer = [];
        }

        function changeGroup(items, newGroup) {
            var itemsIds = [];
            items.forEach(function (elem) {
                itemsIds.push(elem.doc_id);
            })
            return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Document/ChangeGroup", { docIds: itemsIds, newGroup: newGroup },
               function (d) {
                   BrcNotification.success("Выполнено")
               }, function (d) {
                   BrcNotification.error(d.error.message.value);
               });
        }

        function addGroup(items, newGroup) {
            var itemsIds = [];
            items.forEach(function (elem) {
                itemsIds.push(elem.doc_id);
            })
            return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Document/ChangeGroup", { docIds: itemsIds, newGroup: newGroup },
               function (d) {
                   BrcNotification.success("Выполнено")
               }, function (d) {
                   BrcNotification.error(d.responseJSON);
               });
        }
    }
    function btnDelDocument() {
        //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~временно!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        var rows = [];
        grid.select().each(function (index, element) {
            if (!$(element).parents(".k-grid-content-locked").length > 0)
                rows.push(element);
        })
        //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        if (rows.length > 0) {
            var selectedIds = [];
            for (var i = 0; i < rows.length; i++) {
                selectedIds.push(grid.dataItem(rows[i])["doc_id"]);
            };
            if (selectedIds.length == 1)
                var delMsgText = "Удалить документ?</div>";
            if (selectedIds.length > 1)
                var delMsgText = "Удалить документы?</div>";
            if (selectedIds.length > 0) {
                $(".page-content").append('<div id="metaGridConfirmation"></div>');
                $("#metaGridConfirmation").kendoPrompt({
                    messages: {
                        okText: "OK",
                        cancel: "Отмена"
                    },
                    width: 300,
                    buttonLayout: "normal",
                    title: "Подтверждение удаления",
                    close: function (e) {
                        e.sender.destroy();
                    },
                    content: delMsgText,
                    value: "Причина переноса в архив по умолчанию"
                }).data("kendoPrompt").result.done(function (e) {
                    deleteDocument(selectedIds, e);
                }).fail(function (e) {
                    return;
                });
            }
        } else {
            BrcNotification.info("Необходимо выбрать документ");
        }
    }
    function deleteDocument(docIds, reason) {
        //var selectedgrid = $("#documentGrid").data("kendoGrid");
        $.ajax({
            type: "post",
            url: Window["DOCUMENTS_CONFIG"].urls.urlDeleteDocument,
            data: { docIds: docIds, reason: reason },
            success: function () {
                BrcNotification.success("Выполнено");
                grid.dataSource.read();
               
                self.itemView.refresh();
                self.itemView.dataSource.read();
            },
            error: function (e) {
                BrcNotification.error(e.responseJSON);
            }
        });
    }
    grid.bind("dataBound", gridDataBound);
    function gridDataBound(e) {
        $(grid.wrapper).find('[data-toggle-window="documentCard"]').click(openCardClickFromGrid);
    }
    function openCardClick(e, folderId) {
        if (!!self.tree) {
            folderId = self.tree.getSelectedKey();
        }
        openCard.call(self, e, false, "create", folderId);
    }
    function openCardClickFromGrid(e, chkVal) {
        e.preventDefault();
        openCard.call(self, e, chkVal, "update");
    }
    function openCard(e, chkVal, action, folderId) {
        var self = this;
        var documentCard = WindowManager.getByUid($(e.target).attr("data-card-uid"));
        if (documentCard) {
            WindowManager.openWindow(documentCard);
            return;
        }
        var dataItem = grid.dataItem($(e.target).closest("tr"));
        if (!dataItem) {
            dataItem = {};
            dataItem["doc_relation"] = {};
            dataItem.doc_id = 0;
            dataItem["doc_relation"][entityName] = entityId;

        }
        var windowConfig = {
            windowSelector: "#docWindow" + dataItem.doc_id,
            title: "Документ",
            async: {
                url: Window["DOCUMENTS_CONFIG"].urls.urlEditDocumentCard,
                data: { id: dataItem.doc_id, chkVal: chkVal }
            },
            width: 700,
            height: 700,
            onrefresh: function (e) {
                refreshWindow.call(self, e);
            },
            onclose: closeWindow,
            //userData: dataItem
        }
        $.ajax({
            async: false,
            type: "post",
            url: Window["DOCUMENTS_CONFIG"].urls.urlLoadFilesData,
            data: { docId: dataItem.doc_id },
            success: function (data) {
                dataItem.DocFilesData = Array(data.length);
                for (var i = 0; i < data.length; i++) {
                    var m = data[i];
                    dataItem.DocFilesData[i] = { name: m.name, size: m.size, extension: m.extention, id: m.id }
                }
            }
        });
        //var w = WindowManager.openModalAsync(windowConfig);
        var documentCard = WindowManager.addWindow(windowConfig);
        $(e.target).attr("data-card-uid", documentCard.uid);
        function refreshWindow(e) {
            var metaContainer = $(e.sender.element.context).find("#documentsAdditionallyBlock")[0];
            if (!!metaContainer)
                BrcMetaModule.init(metaContainer);
            var self = this;
            var files = dataItem.DocFilesData;
            var doc = doc;
            var validator = initControls(e.sender.element.context);
            createUploader.call(self);
            if (!!folderId) {
                $(e.sender.element.context).find("#doc_folder_id").val(folderId);
            }
            /*function createUploader() {
                if (dataItem.doc_id !== 0) {
                    var wrapper = $(self.wrapper).find("#filesWrapper");
                    $('<input type="file" name="files" id="files" />').appendTo($(wrapper)).kendoUpload({
                        async:
                            {
                                saveUrl: Window["DOCUMENTS_CONFIG"].urls.saveDocFile + "?id=" + dataItem.doc_id,
                                removeUrl: Window["DOCUMENTS_CONFIG"].urls.removeDocFileEmpty + "?id=" + dataItem.doc_id,
                                autoUpload: true,
                                batch: true
                            },
                        enabled: !dataItem.doc_is_archive,
                        remove: onRemove,
                        multiple: true,
                        files: files,
                        success: onSuccess,
                        template: '<span class="k-progress"></span><span class="k-file-extension-wrapper"><span class="k-file-extension">#=files[0].extension#</span>' +
                                '<span class="k-file-state"></span></span><span class="k-file-name-size-wrapper"><span class="k-file-name" title="#=name#">' +
                                '<a href="' + Window["DOCUMENTS_CONFIG"].urls.urlLoadFile + '?id=#=files[0].id#">#=name#</a></span><span class="k-file-size">#=size#</span></span>' +
                                '<strong class="k-upload-status"><span class="k-upload-pct"></span><button type="button" class="k-button k-button-bare k-upload-action">' +
                                '<span class="k-icon k-i-close k-i-delete" title="Удалить" aria-label="Удалить"></span></button></strong>'
                    });
                }
                else {
                    var wrapper = $(self.wrapper).find("#filesWrapper");
                    $('<input type="file" name="files" id="files" />').appendTo($(wrapper)).kendoUpload({
                        multiple: true
                    });
                }
            }
            function onRemove(e) {
                // Array with information about the removed files
                var filesIds = new Array(e.files.length);
                for (var i = 0; i < e.files.length; i++) {
                    filesIds[i] = e.files[i].id;
                }
                $.ajax({
                    type: "post",
                    url: Window["DOCUMENTS_CONFIG"].urls.removeDocFile,
                    data: { ids: filesIds }
                });
            }
            function onSuccess(e) {
                var window = $(e.sender.wrapper).parents(".k-window-content").data("kendoWindow");
                var self = this;
                var upload = $("#files").data("kendoUpload");
                if (e.operation === "upload") {
                    BrcNotification.success("Выполнено");
                    files = e.response;
                    var wrapper = $(window.wrapper).find("#filesWrapper");
                    upload.destroy();
                    $(wrapper).empty();
                    createUploader();
                    //$.ajax({
                    //    async: false,
                    //    type: "post",
                    //    url: Window["DOCUMENTS_CONFIG"].urls.urlLoadFilesData,
                    //    data: { docId: window.userData.doc_id },
                    //    success: function (data) {
                    //        window.userData.DocFilesData = Array(data.length);
                    //        for (var i = 0; i < data.length; i++) {
                    //            var m = data[i];
                    //            window.userData.DocFilesData[i] = { name: m.file_name, size: m.size, extension: m.extention, id: m.id }
                    //        }
                    //    }
                    //});
                    //for (var i = 0; i < upload.options.files.length; i++) {
                    //    upload.options.files[i] = window.userData.DocFilesData[window.userData.DocFilesData.length - upload.options.files.length + i];
                    //}
                    //var filenames = upload.wrapper.find(".k-file-name");
                    //for (var i1 = 0; i1 < filenames.length; i1++) {
                    //    $(filenames[i1]).html("<a href='" + Window["DOCUMENTS_CONFIG"].urls.urlLoadFile + "?id=" + window.userData.DocFilesData[i1].id + "'>" + window.userData.DocFilesData[i1].name + "</a>");
                    //}
                }
            }*/


            function createUploader() {
                var self = this;
                var itemView = $(e.sender.element.context).find("#filesWrapper").kendoItemView({
                    windowStyle: true,
                    files: true,
                    title: "",
                    dataSource: {
                        filter: { field: "doc_id", operator: "eq", value: dataItem.doc_id }

                    },
                    upload: {
                        autoUpload: dataItem.doc_id > 0,
                        upload: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                            data: {
                                doc_id: dataItem.doc_id, file_relation_id: 0, doc_file_id: 0,
                                docId: dataItem.doc_id,
                                folderId: !!self.tree ? self.tree.getSelectedKey() : function () { }
                            },

                        },
                        destroy: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                            data: function (dataItems) {
                                var ids = [];
                                dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                });
                                return {
                                    relation: { doc_id: dataItem.doc_id, file_relation_id: 0, doc_file_id: 0 },
                                    ids: ids
                                }
                            },

                        },
                        move: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                            data: function (opt) {
                                var ids = [];
                                opt.dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                });
                                return {
                                    old_relation: opt.moveFrom.options.relation,
                                    relation: this.options.relation,
                                    ids: ids,

                                }
                            }

                        },
                        copy: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                            data: function (opt) {
                                var ids = [];
                                opt.dataItems.forEach(function (element) {
                                    ids.push(element.doc_file_id);
                                });
                                return {
                                    ids: ids,
                                    relation: this.options.relation,
                                }
                            }
                        },
                        check: {
                            url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                            data: function (dataItems) {
                                //var _self = this;
                                var names = [];
                                dataItems.forEach(function (item) {
                                    names.push(item.doc_file_name);
                                })
                                return {
                                    relation: dataItem.doc_id > 0 ? { doc_id: dataItem.doc_id, file_relation_id: 0, doc_file_id: 0 } : dataItem.doc_relation,
                                    fileNames: names,
                                    folderId: !!self.tree ? self.tree.getSelectedKey() : function () { }
                                }
                            },
                            reject: function (error, dataItems) {
                                for (var i = 0; i < dataItems.length; i++) {
                                    if (dataItems[i].doc_file_name == error.fileName) {
                                        dataItems.splice(i, 1);
                                        break;
                                    }
                                }
                                return dataItems;
                            }
                        }
                    }
                }).data("kendoItemView");
                self.itemView = itemView;
                itemView.filesToolbar({ doc_id: dataItem.doc_id });
            }
            $(e.sender.element.context).find("input,textarea").each(function (index, element) {
                if (element.id === 'doc_is_archive')
                    return;
                var widget = kendo.widgetInstance($(element));
                var isArch = $("#doc_is_archive").is(':checked');
                if (widget) {
                    widget.enable(!isArch);
                    //$(widget.input).removeAttr("disabled")
                    //$(widget.element).removeAttr("disabled")
                }
                $(element).prop("readonly", isArch)
                if (isArch) {
                    $(element).addClass("k-state-disabled")
                    $($(e.sender.element.context)).find("div[data-role=metaButtonGroup] .k-button").each(function (index, element) {
                        $(element).addClass("k-state-disabled");
                    });
                }
                else
                    $(element).removeClass("k-state-disabled")
            })
            $("#doc_is_archive").click(function (c) {
                //openCard(e, $("#doc_is_archive").is(':checked'));
                var isArch = $("#doc_is_archive").is(':checked');
                $(e.sender.element.context).find("input,textarea").each(function (index, element) {
                    if (element.id === 'doc_is_archive')
                        return;
                    var widget = kendo.widgetInstance($(element));
                    var isArch = $("#doc_is_archive").is(':checked');
                    if (widget) {
                        widget.enable(!isArch);
                        //$(widget.input).removeAttr("disabled")
                        //$(widget.element).removeAttr("disabled")
                    }
                    $(element).prop("readonly", isArch)
                    if (isArch) {
                        $(element).addClass("k-state-disabled")
                        $($(e.sender.element.context)).find("div[data-role=metaButtonGroup] .k-button").each(function (index, element) {
                            $(element).addClass("k-state-disabled");
                        });
                    }
                    else
                        $(element).removeClass("k-state-disabled")
                })
            });
            //var upload = $("#files").data("kendoUpload");
            //var filenames = upload.wrapper.find(".k-file-name");
            //for (var i = 0; i < filenames.length; i++) {
            //    $(filenames[i]).html("<a href='" + Window["DOCUMENTS_CONFIG"].urls.urlLoadFile + "?id=" + self.userData.DocFilesData[i].id + "'>" + self.userData.DocFilesData[i].name + "</a>");
            //}
            //var validator = $(e.sender.element.context).kendoValidator({
            //    errorTemplate: kendo.template('<span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg">#=message#</span>'),
            //    messages: {
            //        required: 'Поле не должно быть пустым'
            //    }
            //}).data("kendoValidator");
            $(e.sender.element.context).find("#documentcard-form").submit(function (event) {
                var self = this;
                var window = e.sender;
                event.preventDefault();
                //if ($("#doc_is_archive").is(':checked')) {
                //    $.ajax({
                //        url: Window["DOCUMENTS_CONFIG"].urls.saveDocumentIsArchive,
                //        type: 'POST',
                //        data: { doc_Id: dataItem.doc_id, is_doc_archive: true },
                //        async: false,
                //        success: function (data) {
                //            BrcNotification.success("Выполнено");
                //            grid.dataSource.read();
                //            WindowManager.closeWindow(true, documentCard);
                //        },
                //        error: function (e) {
                //            BrcNotification.error(e.responseJSON);
                //        }
                //    });
                //} else {
                if (validator.validate()) {
                    var d = new FormData();
                    //var d = $(this).serializeArray();
                    $(this).find("input").each(function (index, input) {
                        if ($(input).attr("type") == 'file') {

                        }
                        else {
                            if ($(input).attr("type") == "checkbox") {
                                d.append($(input).attr("name"), input.checked);
                            }
                            else {
                                d.append($(input).attr("name"), $(input).val());
                            }
                        }
                    })
                    if (dataItem.doc_id < 1) {
                        $(this).find("#filesUpload").data("kendoUpload").getFiles().forEach(function (file) {
                            d.append("files", file.rawFile, file.name);
                        })
                    }


                    //var d = $(this).serializeArray();
                    //var relation = {
                    //    asset_id: window.userData.doc_relation.asset_id,
                    //    doc_id: window.userData.doc_id,
                    //    asset_invent_id: window.userData.doc_relation.asset_invent_id,
                    //    right_id: window.userData.doc_relation.right_id,
                    //    asset_contract_id: window.userData.doc_relation.asset_contract_id,
                    //    encum_id: window.userData.doc_relation.encum_id,
                    //    parcel_insp_id: window.userData.doc_relation.parcel_insp_id,
                    //    subj_id: window.userData.doc_relation.subj_id,
                    //    asset_fixed_id: window.udserData.doc_relation.asset_fixed_id
                    //}
                    for (var prop in dataItem.doc_relation) {
                        d.append(prop, dataItem.doc_relation[prop])
                        //d.push({name:prop, value:dataItem.doc_relation[prop]})
                    }
                    BrcAjax.Post(parseInt(d["doc_id"]) > 0 ? Window["DOCUMENTS_CONFIG"].urls.urlUpdateDocumentCard : Window["DOCUMENTS_CONFIG"].urls.urlSaveDocumentCard, d,
                        function (data) {
                            BrcNotification.success("Выполнено");
                            grid.dataSource.read();
                            WindowManager.closeWindow(true, documentCard);
                            //that.itemView.dataSource.read();
                            document.dispatchEvent(new Event('itemViewChanged'));
                        },
                        function (e) {
                            if (!!e.responseJSON.errorCode && e.responseJSON.errorCode == 1) {
                                BrcNotification.error("Конфликт имен");
                                e.responseJSON.errors.forEach(function (e) {

                                })
                            }
                            else
                                BrcNotification.error(e.responseJSON);
                        },
                        false,
                        {
                        cache: false,
                        contentType: false,
                        processData: false,
                    })
                    
                }
                //}
            });
            $(e.sender.element.context).find("#cancelDocButton").click(function (event) {
                event.preventDefault();
                WindowManager.closeWindow(true, documentCard);
            });
            setCardPermission(documentCard.selector, action);
        }
        function closeWindow(e) {
            WindowManager.closeWindow(true, documentCard);
        }
    }

    
    bGrid.kendoGrid.dataSource.transport.update = function (e) {
        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/DocumentCard/Update", { doc: JSON.parse(kendo.stringify(e.data)) },
            function (d) {
                e.success(d);
            }, function (d) {
                e.error(d)
            });
    }
    if (isTree)
        self.tree = _initTree($("#splitter"));


    function _initTree(selector) {

        var g = $(selector).kendoGroupedGrid({
            tableViewLink: window["APPLICATION_CONFIG"].routes.root + "Estate/Document/DocumentGrid?isTree=false",
            brcListConfig: {
                title: "Каталоги",
                isTree: true,
                parentKey: "parent_folder_doc_folder_id",
                key: "doc_folder_id",
                textField: "doc_folder_name",
                pathSelector: "#docTitle",
                path: "Документы: ",
                virtualNode: {
                    all: {
                        dropTarget: true,

                    },
                    ungrouped: {
                        dropTarget: true
                    }
                },
                dataSource: {
                    type: "odata",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyDocumentFolder",
                    dataType: "json"
                },
                create: function (e) {
                    var entity = e;
                    entity.equip_type_cr_date = kendo.toString(new Date(), "dd.MM.yyyy HH:mm:ss");
                    entity.equip_type_is_visible = true;
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/file/CreateFolder", entity,
                    function (e) {
                        BrcNotification.success("Выполнено")
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON)
                    });
                },
                destroy: function (e) {
                    var data = e;
                    var req = $.Deferred();
                    BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/file/DeleteFolder", data,
                    function (e) {
                        BrcNotification.success("Выполнено");
                        req.resolve(e);
                    },
                    function (e) {
                        var state = e.responseJSON;
                        var message = "";
                        if (state.files && state.docs)
                            message = "Каталог содержит файлы и документы, при удалении каталога файлы будут удалены, а документы разгруппированы, продолжить?";
                        else if (state.files)
                            message = "Каталог содержит файлы, при удалении каталога файлы будут удалены, продолжить?";
                        else if (state.docs)
                            message = "Каталог содержит документы, при удалении каталога документы будут разгруппированы, продолжить?";
                        else {
                            BrcNotification.error(e.responseJSON);
                            return;
                        }
                        return BrcConfirm.show('', message).done(function () {
                            return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/file/DeleteFolder", { folder: data, force: true },
                    function (e) {
                        BrcNotification.success("Выполнено");
                        req.resolve(e);
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON);
                        req.reject(e);
                    });
                        })

                    });
                    return req;
                },
                update: function (e) {
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/file/UpdateFolder", e,
                    function (e) {
                        BrcNotification.success("Выполнено")
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON)
                    });
                },
            },
            brcGridConfig: {
                externalInit: {
                    grid: bGrid,
                    toolbar: self.toolbar
                },
                changeGroup: function (items, newGroup) {
                    var itemsIds = [];
                    items.forEach(function (elem) {
                        itemsIds.push(elem.doc_id);
                    })
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/Document/ChangeGroup", { docIds: itemsIds, newGroup: newGroup },
                       function (d) {
                           BrcNotification.success("Выполнено")
                       }, function (d) {
                           BrcNotification.error(d.responseJSON);
                       });
                },
                filters: [{ field: "doc_is_archive", operator: "eq", value: false }]

            },
            permissions: {
                canChangeGroup: Window["DOCUMENTS_CONFIG"].permissions.canUpdateFolder,
                canDeleteGroup: Window["DOCUMENTS_CONFIG"].permissions.canDeleteFolder,
                canCreateGroup: Window["DOCUMENTS_CONFIG"].permissions.canCreateFolder,
                canChangeObject: Window["DOCUMENTS_CONFIG"].permissions.canUpdateDocument,
            }
        }).data("kendoGroupedGrid");
        return g;

    }
    return bGrid;
}
