(function ($) {
    var ui = kendo.ui;
    var styleModificator = "page";
    var _options = {
        name: "BrcTreeView",
        windowStyle: false,
        permissions: {
            canCreateGroup: true,
            canDeleteGroup: true,
            canChangeGroup: true,
        },
        gridHeaderStyle: true
    }
    var BrcTreeView = ui.Widget.extend({
        options: _options,
        init: _init,
        createRootElement: _createRootElement,
        createNestedElement: _createNestedElement,
        deleteElement: _deleteElement,
        renameElement: _renameElement,
        getCurrentPath: _getPath,
        setPath: _setPath,
        getSelectedKey: _getSelectedKey,
        selectNode: _selectNode,
        selectNodeByUid: _selectNodeByUid,
        uid: kendo.guid(),
        _search: _search
    });
    function _init(element, options) {
        styleModificator = options.windowStyle ? "window" : "page";
        var that = this;
        options.linkedItemKey = options.linkedItemKey ? options.linkedItemKey : options.key;
        ui.Widget.fn.init.call(that, element, options);

        $(element).addClass("group-list");
        $(element).attr("uid", that.uid);
        $(element).append($("<div class='brc-" + styleModificator + "-layout'><div class='brc-" + styleModificator + "-layout__content'>" +
            (!!options.title ? '<div class="brc-' + styleModificator + '-layout__header"><div class="brс-' + styleModificator + '-layout__title"><h4 class="brc-card-title" data-role="treeViewHeader"></h4></div>' : '<div>') +
            ((!that.options.permissions.canCreateGroup && !that.options.permissions.canDeleteGroup && !that.options.permissions.canChangeGroup) ? "" : "<div class='brc-" + styleModificator + "-layout__toolbar'><div data-role='groupedListToolbar' class='brc-toolbar brc-toolbar-right brc-toolbar-border-none'>" +
            "</div></div>") + "</div><div class='brc-" + styleModificator + "-layout__work-area'>" +
            "<div class='search-disabled brc-tree-view__search'><div class='brc-search-input'><input type='text' placeholder='Поиск по наименованию узла' class='k-textbox'/><span class='k-select'><span class='k-icon k-i-search'></span></span></div></div>" +
            "<div class='brc-tree-view__custom-tools custom-tools-disabled'></div>" +
            "<div data-role='staticTree' class='grouped-grid-static-list' >" +
            "</div><div data-role='mainTree' class='grouped-grid-list'></div></div></div></div>"));
        that.mainList = _mainListInit.call(that, $(element).find("[data-role=mainTree]"));
        that.staticList = _staticTreeInit.call(that, $(element).find("[data-role=staticTree]"));
        if (that.options.permissions.canCreateGroup || that.options.permissions.canChangeGroup || that.options.permissions.canDeleteGroup) {
            that.listToolBar = _listToolbarInit.call(that);
        }
        if (!!options.groupable) {
            _dropTarget.call(that);
        }
        if (!!options.search) { //отключено до выяснений
            _initSearch.call(that);
        }
        if (!!options.customTools) {
            _initCustomTools.call(that);
        }
    }
    function _initCustomTools() {
        var self = this;
        var container = $(self.element).find(".custom-tools-disabled");
        container.removeClass("custom-tools-disabled");
        self.options.customTools.forEach(function (tool) {
            container.append(tool.detach());
            tool.wrap("<div class='custom-tool'></div>");
        });
    }
    function _initSearch() {
        var self = this;
        var searchContainer = $(self.element).find(".search-disabled");
        searchContainer.removeClass("search-disabled");
        self._searchInput = searchContainer.find("input");
        self._searchInput.on("change", function (e) {
            self._searchParams = { logic: "or", filters: [], expandPaths: {} };
            self._search.call(self);
        });
        searchContainer.find(".k-select").click(function (e) {
            self._searchParams = { logic: "or", filters: [], expandPaths: {} };
            self._search.call(self);
        })
    }
    function _search(filterExpression) {
        var self = this;
        if (!!self._searchInput.val()) {
            var startFilter = { filter: { logic: "or", filters: [{ field: "tolower(" + self.options.textField + ")", operator: "contains", value: self._searchInput.val().toLowerCase() }] } };
            if (!filterExpression) {
                filterExpression = _getFormattedFilter(startFilter);
            }
        }
        else {
            self.mainList.dataSource.filter({ field: self.options.parentKey, operator: "eq", value: null });
            return;
        }
        var parentId = [];
        BrcAjax.Get(self.options.dataSource.url, { $filter: filterExpression },
            function (e) {
                var hasParent = false;
                var filter = null;
                if (!!e && !!e.value && e.value.length > 0) {
                    filter = { logic: "or", filters: [] };
                    e.value.forEach(function (d) {
                        if (d.hasOwnProperty(self.options.parentKey) && parseInt(d[self.options.parentKey]) > 0) {
                            filter.filters.push({ field: self.options.key, operator: "eq", value: parseInt(d[self.options.parentKey]) });
                            if (d.hasChildren) {
                                if (!!self._searchParams.expandPaths[d[self.options.key]])
                                    self._searchParams.expandPaths[d[self.options.key]].push(d[self.options.parentKey]);
                                else
                                    self._searchParams.expandPaths[d[self.options.key]] = [d[self.options.key]];
                            }
                            else
                                self._searchParams.expandPaths[d[self.options.parentKey]] = [d[self.options.parentKey]];
                            hasParent = true;
                        }
                        else {
                            //var flag = false;
                            //for (var f in self._searchParams.filters)
                            self._searchParams.filters.push({ field: self.options.key, operator: "eq", value: parseInt(d[self.options.key]) })
                            if (!!self._searchParams.expandPaths[d[self.options.key]])
                                self._searchParams.expandPaths[d[self.options.key]].push(d[self.options.key]);
                            else
                                self._searchParams.expandPaths[d[self.options.key]] = [d[self.options.key]];
                        }
                    });
                    if (hasParent) {
                        var requestParameters = _getFormattedFilter({ filter: filter });
                        _search.call(self, requestParameters);
                    }
                    else {
                        BrcPreloader.load(self._searchInput.parent());
                        self._searchInput.blur();
                        var d = $.Deferred();
                        self.mainList.one("dataBound", function () {
                            function _expand() { //"рекурсивная" функция раскрытия
                                if (Object.keys(self._searchParams.expandPaths).length > 0) {
                                    for (var path in self._searchParams.expandPaths) { //берем перечислитель по коллекции путей раскрытия
                                        if (!self.mainList.dataSource.get(parseInt(path))._loaded) //если еще не раскрыто (для избежания цикличности)
                                        {
                                            self.mainList.expandPath(self._searchParams.expandPaths[path].reverse(), _expand); //раскрываем первый уровень пути, по окончании раскрытия (загрузки данных) рекурсим функцию
                                        }
                                        else {
                                            delete self._searchParams.expandPaths[path];
                                            _expand();

                                        }
                                        delete self._searchParams.expandPaths[path]; //удаляем текущий элемент 

                                        break; //т.к. всегда идем по порядку раскрытия и дожидаемся загрузки узлов - прерываем перечисление
                                    }

                                }
                                else
                                    d.resolve();
                            }
                            _expand(); //вызываем "рекурсивную" функцию.
                            d.always(function () {
                                BrcPreloader.unload(self._searchInput.parent());
                                self.selectNode("Все");
                            });
                        })
                        self.mainList.dataSource.filter(self._searchParams);

                    }
                }
                else {
                    self.mainList.dataSource.filter(startFilter);
                }
            },
            function (e) {
                BrcNotification.error("Ошибка при получении данных");
            })
    }
    function _getFormattedFilter(options) {
        return kendo.data.transports.odata.parameterMap(options, "read").$filter;
    }
    function _createRootElement(e) {
        var self = this;
        if (self.mainList.element.find("[state-changed=true]").length > 0) {
            return;
        }
        var obj = {};
        obj[self.options.textField] = "";
        var data = new kendo.data.Node(obj);
        node = self.mainList.append(data);
        _changeElement.call(self, data, node, true);
    }
    function _createNestedElement(e) {
        var self = this;
        if (self.mainList.element.find("[state-changed=true]").length > 0) {
            return;
        }
        if (!self.options.isTree) {
            var obj = {};
            obj[self.options.textField] = "";
            var data = new kendo.data.Node(obj);
            node = self.mainList.append(data);
            _changeElement.call(self, data, node, true);
        }
        else {
            var obj = {};
            obj[self.options.textField] = "";
            var data = new kendo.data.Node(obj);
            var selectedNode = self.mainList.dataItem(self.mainList.select());
            var node = null;
            if (!!selectedNode) {
                if (!selectedNode.loaded())
                    selectedNode.load().done(function (e) {
                        addNode();
                    })
                else {
                    addNode();
                }
                function addNode() {
                    data[self.options.parentKey] = selectedNode[self.options.key];
                    node = self.mainList.append(data, self.mainList.select());
                    _changeElement.call(self, data, node);
                }
            }
            else {
                BrcNotification.info("Не выбран узел");;
            }
        }
    }
    function _deleteElement(e) {
        var self = this;
        var node = self.mainList.select();
        var selected = self.mainList.dataItem(node);
        if (!selected) {
            BrcNotification.info("Не выбран узел");
            return;
        }
        else if (selected.hasChildren && self.options.confirmation)
            BrcConfirm.show('', "При выполнении действия будут удалены все вложенные объекты, продолжить?").done(function (e) {
                self.options.destroy.call(self, selected.toJSON(), true).done(function (e) {
                    self.mainList.remove(node);
                    self.brcGrid.kendoGrid.dataSource.read();
                }).fail(function (e) {
                });
            })
        else
            self.options.destroy.call(self, selected.toJSON()).done(function (e) {
                self.mainList.remove(node)
            }).fail(function (e) {
            });
    }
    function _renameElement(e) {
        var self = this;
        if (self.mainList.element.find("[state-changed=true]").length > 0) {
            return;
        }
        var node = self.mainList.select();
        var data = self.mainList.dataItem(node);
        if (!data) {
            BrcNotification.info("Не выбран узел");
            return;
        }
        if (!!node && !!data) {
            _changeElement.call(self, data, node, data.level() == 0);
        }
    }
    function _changeElement(data, node, root) {
        var self = this;
        if (!node) {
            BrcNotification.error("Неизвественая ошибка");
            return;
        }
        var htmlNode = node.find(".k-in").first();
        var expandPath = _getExpandPath.call(self, htmlNode, []);
        var nodeCopy = htmlNode.clone();
        htmlNode.html('');
        htmlNode.attr("state-changed", true);
        var clickPropagation = function (e) {
            e.stopPropagation();
        };
        node.on('click', clickPropagation)
        var form = $('<form ><div style="display:flex"><input style="align-self:stretch" type="text" class="k-textbox" name="' + self.options.textField + '" value="' + data[self.options.textField] + '" required="true"/><button type="submit" class="k-button k-button-icon"><span class="k-icon k-i-check"></span></button><button id="btnCancel" type="button" class="k-button k-button-icon"><span class="k-icon k-i-close"></span></button></div><span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg" role="alert" data-for="' + self.options.textField + '" /></form>').appendTo(htmlNode);
        initControls(node);
        form.submit(function (e) {
            e.preventDefault();
            var text = form.serializeArray()[0].value;
            data[self.options.textField] = text;
            if (data.isNew()) {
                self.options.create(data.toJSON()).done(function (e) {
                    data = e;
                    var newNode = new kendo.data.Node(data);
                    newNode.id = data[self.options.key];
                    if (self.mainList.select().length > 0 && !root)
                        self.mainList.append(newNode, self.mainList.select());
                    else
                        self.mainList.append(newNode);
                    self.mainList.remove(node);
                    self.mainList.dataSource.read().done(function () {
                        self.mainList.expandPath(expandPath)
                    });
                }).fail(function (e) {
                }).always(function () {
                    node.off("click", clickPropagation)
                });
            }
            else {
                self.options.update(data.toJSON()).done(function (e) {
                    var newNode = new kendo.data.Node(e);
                    newNode.id = e[self.options.key];
                    if (self.mainList.select().length > 0 && !root)
                        self.mainList.insertAfter(newNode, node);
                    else
                        self.mainList.append(newNode);
                    self.mainList.remove(node);
                    self.mainList.dataSource.read().done(function () {
                        self.mainList.expandPath(expandPath)
                    });
                }).fail(function (e) {
                }).always(function () {
                    node.off("click", clickPropagation)
                });
            }
        })
        form.find("#btnCancel").click(function (e) {
            e.preventDefault();
            if (!data.isNew()) {
                htmlNode.html(nodeCopy.html());
                htmlNode.attr("state-changed", false);
            }
            else
                htmlNode.remove();
            node.off("click", clickPropagation)
        })
    }
    function _getPath(node, path) {
        var self = this;
        if (node) {
            var parentnode = self.mainList.parent($(node));
            if (parentnode.is("li")) {
                path += _getPath.call(self, parentnode, path);
            }
            path += "\\" + self.mainList.dataItem(node)[self.options.textField];
            return path.replace(/^\\/, '');
        }
    }
    function _setPath(text, selector) {
        var self = this;
        $(selector).attr("title", text).text(text);
    }
    function _getExpandPath(node, path) {
        var self = this;
        if (node) {
            var parentnode = self.mainList.parent($(node));
            if (parentnode.is("li")) {
                path = _getExpandPath.call(self, parentnode, path);
            }
            path.push(self.mainList.dataItem(node)[self.options.key]);
            return path;
        }
    }
    function _mainListInit(selector) {
        var self = this;
        return $(selector).kendoTreeView({
            dataTextField: self.options.textField,
            template: self.options.template,
            dataSource: {
                serverSorting: true,
                serverPaging: true,
                transport: {
                    read: {
                        url: self.options.dataSource.url,
                        type: "get",
                        dataType: self.options.dataSource.dataType
                    },
                    parameterMap: function (a, type) {
                        if (type == "read" && !!a[self.options.key] && self.options.isTree) {
                            return {
                                $filter: kendo.format("{0} eq {1}", self.options.parentKey, a[self.options.key]),
                                $orderby: self.options.key,
                                $inlinecount: "allpages"
                            }
                        }
                        else if (type == "read" && self.options.isTree) {
                            return {
                                $filter: kendo.format("{0} eq {1}", self.options.parentKey, null),
                                $orderby: self.options.key,
                                $inlinecount: "allpages"
                            }
                        }
                        else if (type == "read" && !!a[self.options.key] && !self.options.isTree) {
                            return {
                                $filter: kendo.format("{0} eq {1}", self.options.key, a[self.options.key]),
                                $orderby: self.options.key,
                                $inlinecount: "allpages"
                            }
                        }
                        else if (type == "read")
                            return {
                                $orderby: self.options.key,
                                $inlinecount: "allpages"
                            }
                    }
                },
                schema: {
                    model: {
                        id: self.options.key,
                        parentId: self.options.isTree ? self.options.parentKey : "",
                        hasChildren: self.options.isTree ? "hasChildren" : false,
                    },
                    total: function (data) {
                        return !!data ? (!!data["odata.count"] ? data["odata.count"] : data["@odata.count"]) : 0;
                    },
                    data: "value"
                }
            },
            select: function (e) {
                self.options.select.call(self, this, e);
            },
            dragAndDrop: self.options.permissions.canChangeGroup,
            drag: function (e) {
                if ($(e.dropTarget).parents("[data-role=staticTree]").length > 0)
                    e.setStatusClass("k-denied");
                if (e.statusClass == "add") {
                    var destination = this.dataItem(e.dropTarget);

                    if (destination.level() == 3) {
                        e.setStatusClass("k-denied");
                    }
                }
            },
            drop: function (e) {

                if ($(e.dropTarget).parents("[data-role=staticTree]").length > 0) {
                    e.setValid(false);
                }
                //  else if ($(e.dropTarget) == e.)


            },
            dragend: function (e) {
                var source = self.mainList.dataItem(e.sourceNode);
                var target = self.mainList.dataItem(e.destinationNode);

                BrcPreloader.load(self.mainList.element);
                if (!target) {
                    source[self.options.parentKey] = null;
                }
                else if ((!target || target.level() === 0) && e.dropPosition != 'over') {
                    source[self.options.parentKey] = null;
                }
                else {
                    if (e.dropPosition == 'over') {
                        source[self.options.parentKey] = target[self.options.key];
                    }
                    else {
                        source[self.options.parentKey] = target[self.options.parentKey];
                    }

                }

                var model = source.toJSON();

                delete model.index;
                delete model.value;
                delete model.expanded;
                delete model.selected;
                self.options.update(model).done(function (e) {

                }).always(function () {
                    BrcPreloader.unload(self.mainList.element);
                })
            },
            dragstart: function (e) {
                e.sender.options.group = "main";
            },
        }).data("kendoTreeView")
    }
    function _staticTreeInit(selector) {
        var self = this;
        var treeView = $(selector).kendoTreeView({
            dataSource: {
                schema: {
                    model: {
                        hasChildren: false
                    }
                }
            },
            select: function (e) {
                self.options.staticListSelect.call(self, this, e);
            }
        }).data("kendoTreeView");
        if (!!self.options.virtualNode && !!self.options.virtualNode.all)
            treeView.append(new kendo.data.Node({
                text: !!self.options.virtualNode.all.text ? self.options.virtualNode.all.text : "Все",
                id: "all"
            }));
        if (!!self.options.virtualNode && !!self.options.virtualNode.ungroupped)
            treeView.append(new kendo.data.Node({
                text: !!self.options.virtualNode.ungroupped.text ? self.options.virtualNode.ungroupped.text : "Не сгруппированные",
                id: "ungroupped"
            }));
        return treeView;
    }
    function _getSelectedKey() {
        var self = this;
        var dataItem = self.mainList.dataItem(self.mainList.select());
        if (!!dataItem) {
            return dataItem[self.options.key];
        }
    }
    function _selectNode(nodeText) {
        var self = this;
        var node = self.mainList.findByText(nodeText);
        if (node.length < 1) {
            node = self.staticList.findByText(nodeText);
            self.staticList.select($(node));
            self.staticList.focus();
            self.staticList.trigger('select', { node: node });
            self.mainList.select($());
        }
        else {
            self.mainList.select($(node));
            self.mainList.focus();
            self.staticList.select($());
            self.mainList.trigger('select', { node: node });
        }

        return node;
    }
    function _selectNodeByUid(uid) {
        var self = this;
        var node = self.mainList.findByUid(uid);
        if (node.length < 1) {
            node = self.staticList.findByUid(uid);
            self.staticList.select($(node));
            self.staticList.focus();
            self.staticList.trigger('select', { node: node });
            self.mainList.select($());
        }
        else {
            self.mainList.select($(node));
            self.mainList.focus();
            self.staticList.select($());
            self.mainList.trigger('select', { node: node });
        }

        return node;
    }
    function _dropTarget() {
        var self = this;
        $(self.mainList.element).kendoDropTargetArea({
            group: "itemView",
            filter: ".k-in",
            drop: function (e) {
                var dest = e.dropTarget;
                var source = e.draggable.currentTarget;
                if ($(dest).is(".k-in") && !$(source).is(".k-in")) {
                    var destItem = self.mainList.dataItem(dest);
                    //dataRow[self.options.key] = destItem[self.options.key];
                    self.options.groupable.changeGroup.call(self, e, destItem);
                }
                $(e.dropTarget).removeClass("drop-target-selection");
            },
            dragenter: function (e) {
                $(e.dropTarget).addClass("drop-target-selection");
            },
            dragleave: function (e) {
                $(e.dropTarget).removeClass("drop-target-selection");
            }
        })
        $(self.staticList.element).kendoDropTargetArea({
            group: "itemView",
            filter: ".k-in",
            drop: function (e) {
                var dest = e.dropTarget;
                var source = e.draggable.currentTarget;
                if ($(dest).is(".k-in") && !$(source).is(".k-in")) {
                    var destItem = self.staticList.dataItem(dest);
                    //dataRow[self.options.key] = destItem[self.options.key];
                    self.options.groupable.changeGroup.call(self, e, destItem);
                }
                $(e.dropTarget).removeClass("drop-target-selection");
            },
            dragenter: function (e) {
                $(e.dropTarget).addClass("drop-target-selection");
            },
            dragleave: function (e) {
                $(e.dropTarget).removeClass("drop-target-selection");
            }
        })
    }
    function _listToolbarInit() {
        var self = this;
        var items = [];
        var tb = $(self.element).find("[data-role=groupedListToolbar]").kendoToolBar().data("kendoToolBar");
        var createGroup = {
            type: "buttonGroup",
            buttons: [
                 {
                     type: "button",
                     id: "create",
                     icon: "folder-more",
                     attributes: { "title": "Создать вложенную группу" },
                     text: "Создать вложенную группу",
                     showText: "overflow",
                     enable: self.options.permissions.canCreateGroup,
                     click: function () {
                         _createNestedElement.call(self);
                     }
                 },
            ]
        };

        createGroup.buttons.push(
        {
            type: "button",
            id: "create",
            icon: "folder-add",
            attributes: { "title": "Создать корневую группу" },
            text: "Создать корневую группу",
            showText: "overflow",
            enable: self.options.permissions.canCreateGroup,
            click: function () {
                _createRootElement.call(self);
            }
        });

        tb.add(createGroup);
        tb.add({
            type: "button",
            id: "delete",
            icon: "delete",
            attributes: { "title": "Удалить" },
            text: "Удалить",
            showText: "overflow",
            enable: self.options.permissions.canDeleteGroup,
            click: function () {
                _deleteElement.call(self);
            }
        });
        tb.add({
            type: "button",
            id: "rename",
            icon: "pencil",
            attributes: { "title": "Переименовать" },
            text: "Переименовать",
            showText: "overflow",
            enable: self.options.permissions.canChangeGroup,
            click: function () {
                _renameElement.call(self);
            }
        });
        tb.resize();
        return tb;
    }
    ui.plugin(BrcTreeView);
})(jQuery);