function AssetFixedGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;
    var _assetId;
    var _mode;
    var _pnsId;
    var _knsId;
    var _waterHoleId;
    var _idsString;

    return {
        init: init,
        read: read
    };

    function init(config) {
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);
        _assetId = config.assetId;
        _mode = config.mode;
        _pnsId = config.pnsId;
        _knsId = config.knsId;
        _waterHoleId = config.waterHoleId;
        _idsString = config.idsString;
        initGrid();
        if (_mode !== "acc_network")
            initToolbar();
    }
    function read() {
        _grid.kendoGrid.dataSource.read();
        _grid.kendoGrid.resize();
    }
    function initGrid() {
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + (_mode === "all" ? "odata/ODataRealtyAssetFixedAsset" : _mode === "pns" || _mode === "kns" ? "odata/ODataAssetFixedOnNsEquipment" : _mode === "waterhole" ? "odata/ODataAssetFixedOnWaterHole" : "odata/ODataRealtyAssetFixedEntity"),
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportAssetCostGrid?assetId=" + _assetId,
            filter: initFilter()
    };
        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());
        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);
    }

    function initFilter() {
        if (_mode === "acc_network") {
            var filters = [];
            arrId = _idsString.split(",");
            arrId.forEach(function (item) {
                filters.push({ field: "id", operator: "eq", value: parseInt(item) });
            })
            var filter = {
                logic: "or",
                filters: filters
            };
            return filter;
        } else {
            return _mode === "all" ? {} : _assetId > 0 ? { field: "asset_id", operator: "eq", value: parseInt(_assetId) } : _mode === "pns" ? { field: "pns_id", operator: "eq", value: parseInt(_pnsId) } : _mode === "waterhole" ? { field: "water_hole_id", operator: "eq", value: parseInt(_waterHoleId) } : {field: "kns_id", operator: "eq", value: parseInt(_knsId) };
        }
    }

    function initToolbar() {

        var items = [];

        if (_mode !== "waterhole") {
            items.push(
                        {
                            type: "button",
                            text: "Связать с карточкой ОС",
                            click: connectAssetToAssetFixed,
                            hidden: !hasPermission("canCreateAssetFixedRelation")
                        },
                        {
                            type: "button",
                            text: "Разорвать связь с карточкой ОС",
                            click: disconnectAssetToAssetFixed,
                            hidden: !hasPermission("canDeleteAssetFixedRelation")
                        },
                        { type: "separator" }
                    );
        }
            

        

        items.push({
            type: "splitButton",
            text: "Таблица",
            menuButtons: [
                _brcGrid.filters(),
                _brcGrid.columnVisibility(),
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ],
        });

        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");        
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="asset_fixed"]').click(onClick);
        function onClick(e) {
            e.preventDefault();

            var assetFixedModal = new AssetFixedModal();
            assetFixedModal.init({
                assetFixedId: this.getAttribute("number"),
                assetId: _assetId,
                mode: "readonly",
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }

        _grid.selector.find('[data-toggle-window="equipment"]').click(onClickEquipment);
        function onClickEquipment(e) {
            e.preventDefault();

            var equipmentModal = new EquipmentModal();
            equipmentModal.init({
                equipmentId: this.getAttribute("number"),
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }

        _grid.selector.find('[data-toggle-window="network"]').click(onClickNetwork);
        function onClickNetwork(e) {
            e.preventDefault();

            var networkModal = new NetworkModal();
            networkModal.init({
                assetId: this.getAttribute("number"),
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function connectAssetToAssetFixed() {
        _assetFixedSelectModal = new AssetFixedSelectModal();
        _assetFixedSelectModal.init({
            onSelect: function (assetFixedId) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetFixed/AddAssetFixedToAsset",
                    { assetId: _assetId, assetFixedId: assetFixedId },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                        _grid.kendoGrid.resize();
                    },
                    function (e) {
                        BrcNotification.error(e.responseJSON.message);
                    }
                );
            }
        });
        _assetFixedSelectModal.open();
    }

    function disconnectAssetToAssetFixed() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите основное средство");
            return false;
        }

        BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetFixed/DeleteAssetFixedFromAsset",
                    { assetId: _assetId, assetFixedId: selectedIds[0]
                    },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                        _grid.kendoGrid.resize();
                    },
                    function () {
                        BrcNotification.error("Произошла ошибка!");
                    }
                );
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
};