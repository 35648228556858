
var UrlHelper = function () {
    return {
        addOrReplaceParam: function (url, param, value, encodeUriValueInd) {
            param = encodeURIComponent(param);
            var r = "([&?]|&amp;)" + param + "\\b(?:=(?:[^&#]*))*";
            var a = document.createElement('a');
            var regex = new RegExp(r);
            if (encodeUriValueInd != undefined && encodeUriValueInd === true)
                value = encodeURIComponent(value);
            var str = param + (value ? "=" + value : "");
            a.href = url;
            var q = a.search.replace(regex, "$1" + str);
            try {
                if (q === a.search) {
                    a.search += (a.search ? "&" : "") + str;
                } else {
                    a.search = q;
                }
            } catch (ex) {
                //ignore (Специально для IE)
            }
            return a.href;
        },
        addParam: function (url, param, value, encodeUriValueInd) {
            param = encodeURIComponent(param);
            var a = document.createElement('a');
            if (encodeUriValueInd != undefined && encodeUriValueInd === true)
                value = encodeURIComponent(value);
            param += (value ? "=" + value : "");
            a.href = url;
            a.search += (a.search ? "&" : "") + param;
            return a.href;
        }
    };
}();