var AssetInventModal = function () {
    var _assetInventId;
    var _assetId;
    var _onOKClose;
    var _assetInventCard;
    var _context;

    return {
        init: init
    };

    function init(config) {
        _assetInventId = config.assetInventId;
        _assetId = config.assetId;
        _onOKClose = config.onOKClose;

        initWindow();
    }

    function initWindow() {
        var selector = "#assetInventWindow" + _assetInventId;
        
        var windowConfig = {
            id: selector,
            title: _assetInventId > 0 ? "Акт инвентаризации" : "Новый акт инвентаризации",
            async: {
                url: window["APPLICATION_CONFIG"].routes.root + "Estate/InventPlan/AssetInventModal",
                data: {
                    id: _assetInventId,
                    assetId: _assetId,
                }
            },
            width: 1100,
            height: 700,
            onrefresh: onRefreshWindow
        }
        _assetInventCard = WindowManager.openOrAddWindow(windowConfig);
    }

    function onRefreshWindow(e)
    {
        _context = $(e.sender.element.context);

        if (_context.find("#canUpdateAssetInvent").val() === 'false') {
            _context.find("#saveButton").hide();
            ReadonlyHelper(_context, true);
        }

        var validator = initControls(_context);
        BrcMetaModule.init(_context.find("#assetContractAdditionallyBlock")[0]);

        

        DateRangeHelper.init(_context.find("#asset_invent_begin_date"), _context.find("#asset_invent_end_date"));
        DateRangeHelper.init(_context.find("#asset_invent_field_begin_date"), _context.find("#asset_invent_field_end_date"));

        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
            _context.find("#asset_invent_end_note").attr("style", "min-height:230px;");
            _context.find("#asset_invent_end_note").parent().attr("style", "height:100%;");
            _context.find("#asset_invent_end_note").parent().parent().attr("style", "height:250px;");
        }

        if (_assetInventId > 0) {
            initTabStrip();
        }

        _context.find("form").submit(function (event) {
            event.preventDefault();

            if (validator.validate()) {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/InventPlan/SaveAssetInvent",
                    $(event.target).serializeArray(),
                    function (id) {
                        BrcNotification.success("Выполнено");
                        if (_assetInventId == 0) {
                            _onOKClose();
                            WindowManager.closeWindow(true, _assetInventCard);
                            init({
                                assetInventId: id,
                                onOKClose: _onOKClose,
                                assetId: _assetId
                            });
                        }
                        else {
                            _onOKClose();
                            WindowManager.closeWindow(true, _assetInventCard);
                        }
                    },
                    function (xhr) {
                        if (xhr.responseJSON.type == "error") {
                            BrcNotification.error(xhr.responseJSON.message);
                        }
                        else {
                            BrcNotification.error("Произошла ошибка!");
                        }
                    }
                );
            }
        });

        _context.find("#cancelButton").click(function (event) {
            event.preventDefault();
            WindowManager.closeWindow(true, _assetInventCard);
        })
    }

    function initTabStrip() {
        var tabStrip = _context.find("#tabstrip").data("kendoTabStrip");
        tabStrip.bind("select", onSelect);

        function onSelect(e) {
            if (e.item.id == "document") {
                var selector = $(e.contentElement).find("#documentGridWrapper");
                if (!kendo.widgetInstance($(selector).find(".brc-grid"))) {
                    var guid = kendo.guid();
                    selector.attr("guid", guid);

                    var documents = new DocumentGrid(selector, { entityName: "asset_invent_id", entityId: _assetInventId });
                    documents.init();
                }
            }
            if (e.item.id == "files") {
                if (!kendo.widgetInstance($(e.contentElement).find("#filesView"))) {
                    var itemView = $(e.contentElement).find("#filesView").kendoItemView({
                        files: true,
                        windowStyle: true,
                        title: "",
                        toolbar: $(e.contentElement).find("#filesToolbar"),
                        dataSource: {
                            filter: { field: "asset_invent_id", operator: "eq", value: parseInt(_assetInventId) }

                        },
                        upload: {
                            upload: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/uploadFile",
                                data: function () {
                                    return {
                                        asset_invent_id: _assetInventId, file_relation_id: 0, doc_file_id: 0
                                    }
                                },

                            },
                            destroy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/deleteFilesById",
                                data: function (dataItems) {
                                    var self = this;
                                    var ids = [];
                                    dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })

                                    return {
                                        ids: ids,
                                        relation: { asset_invent_id: _assetInventId, file_relation_id: 0, doc_file_id: 0 }
                                    }
                                },

                            },
                            copy: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/copyFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { asset_invent_id: _assetInventId, file_relation_id: 0, doc_file_id: 0 },
                                        ids: ids
                                    }
                                }
                            },
                            move: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/moveFileToObject",
                                data: function (opt) {
                                    var self = this;
                                    var ids = [];
                                    opt.dataItems.forEach(function (element) {
                                        ids.push(element.doc_file_id);
                                    })
                                    return {
                                        relation: { asset_invent_id: _assetInventId, file_relation_id: 0, doc_file_id: 0 },
                                        old_relation: opt.moveFrom.options.relation,
                                        ids: ids
                                    }
                                }
                            },
                            check: {
                                url: window["APPLICATION_CONFIG"].routes.root + "estate/file/checkFile",
                                data: function (dataItems) {
                                    var self = this;
                                    var names = [];
                                    dataItems.forEach(function (item) {
                                        names.push(item.doc_file_name);
                                    })
                                    return {
                                        relation: { asset_invent_id: _assetInventId, file_relation_id: 0, doc_file_id: 0 },
                                        fileNames: names
                                    }
                                },
                                reject: function (error, dataItems) {
                                    for (var i = 0; i < dataItems.length; i++) {
                                        if (dataItems[i].doc_file_name == error.fileName) {
                                            dataItems.splice(i, 1);
                                            break;
                                        }
                                    }
                                    return dataItems;
                                }
                            },

                        }
                    }).data("kendoItemView");
                    itemView.filesToolbar({ asset_invent_id: _assetInventId, file_relation_id: 0, doc_file_id: 0 });
                }
            }
        }
    }
};