(function ($) {
    ui = kendo.ui,
    Widget = ui.ComboBox
    var BrcMultiBox = Widget.extend({

        options: {
            name: "BrcMultiBox",
            selector: null,
            textField: null,
            valueField: null,
            selectGrid: null,
            subjectGridConfig: null,
            selectWindow: null,
            modalTemplate: '<div class="brc-window-layout brc-select-modal"><div class="brc-window-layout__header brc-window-layout_header-grid"><form class="form-inline brc-select-modal__form" ><div class="input-group brc-select-modal__form-search" ><span class="k-combobox k-widget  brc-select-modal__search"><span class="k-dropdown-wrap  brc-select-modal__wrapper"><input type="text" id="searchString" name="searchString"  class="k-input brc-select-modal__input " autocomplete="off" ><span class="k-select" unselectable="on" title="clear" role="button" tabindex="-1"><span class="k-icon k-i-close" ></span></span></span></span></div><div class="input-group brc-select-modal_form-create"><button class="k-button brc-action" type="button" id="createObjFl">Создать ФЛ</button><button class="k-button brc-action" type="button" id="createObjUl">Создать ЮЛ</button></div></form></div><div class="brc-window-layout__content"><div class="brc-window-layout__work-area"><div class="brc-grid-wrapper"><div class="brc-grid" id="selectGrid"></div></div></div></div><div class="brc-window-layout__footer"><div class="brc-form-action"><button id="okButton" class="brc-action k-button k-primary"> OK </button><button id="cancelButton" class="brc-action k-button k-default">Отмена</button></div></div></div>',
            input: null,
            _input: null,
            guid: kendo.guid(),
            mode: "modal",
            canCreate: true,
            isVirtual: false,
            suggest: true,
            filter: "contains",
            fallBackValue: false,
            syncValueAndText: false,
            filtering: function (e) {
                if (e.sender.options.classifierFilter) {
                    e.preventDefault();
                    if (!!e.filter)
                        e.sender.dataSource.filter(e.sender.options.classifierFilter.concat(e.filter));
                    else
                        e.sender.dataSource.filter(e.sender.options.classifierFilter);
                }
            },
            cascade: function (e) {
                if (this.select() == -1 && !this.value() && !this.text()) {
                    this.options.fallBackValue = false;
                }
            },
            change: function (e) {
                if (!this.dataItem()) {
                    if (!this.options.fallBackValue) {

                        this.value(null);
                    }
                    else {
                        this.value(this.options.fallBackValue[this.options.dataValueField]);
                        this.text(this.options.fallBackValue[this.options.dataTextField])

                    }
                }
            },
            syncValueAndText: false,
            selectModal: {
                title: "Выбор объекта",
                dataSource: null,
                refreshWindow: null,
                search: {
                    searchPlaceholder: "Наименование",
                    searchFilters: {
                        logic: "or",
                        filters: []
                    }
                },
                createObjectCallback: null,
                grid: {
                    gridConfig: null,
                    openObjectSelector: "[data-toggle-window]",
                    openObjectCallback: null,

                }

            },
            separateSubject: false
        },
        init: function (element, options) {
            var that = this;
            var inputRightPad = 5.7;
            if ((options.canCreate == null || options.canCreate == undefined) || !!options.permission) {
                if (options.permission.url != undefined) {
                    BrcAjax.Get(options.permission.url,
                        { obj: options.permission.rightObject, type: options.permission.rightType },
                        function (e) {
                            that.options.canCreate = e;
                    })
                }
                else{
                    BrcNotification.error("Не заданы права доступа!");
                }
                
            }
                

            var val = $(element).val();
            that.options.dataValueField = !options.dataValueField ? $(element).attr("data-valueField") : options.dataValueField;
            that.options.dataTextField = !options.dataTextField ? $(element).attr("data-textField") : options.dataTextField;
            if (!options.isVirtual) {
                options.dataSource = {
                    type: "odata",
                    transport: {
                        read: {
                            url: $(element).attr("data-datasource"),
                            dataType: "json",
                            type: !$(element).attr("data-reqType") ? "get" : $(element).attr("data-reqType")
                        },
                    },
                    serverFiltering: true,

                    schema: {
                        model: {
                            id: options.dataValueField
                        },
                        data: function (data) {
                            if (data.value) {
                                return data.value;
                            }
                            delete data["odata.metadata"];
                            return [data];
                        },
                        total: function (data) {
                            return !!data["odata.count"] ? data["odata.count"] : data["@odata.count"];
                        }
                    },
                }
            }
            else {
                options.dataSource = {
                    type: "odata",
                    transport: {
                        read: {
                            url: $(element).attr("data-datasource"),
                            dataType: "json",
                            type: "get"
                        }
                    },
                    schema: {
                        model: {
                            id: options.dataValueField
                        },
                        data: function (data) {
                            if (data.value) {
                                return data.value;
                            }
                            delete data["odata.metadata"];
                            return [data];
                        },
                        total: function (data) {
                            return !!data["odata.count"] ? data["odata.count"] : data["@odata.count"];
                        }
                    },
                    pageSize: 40,
                    serverPaging: true,
                    serverFiltering: true,

                };
                if ($(element).attr("data-classifier") == "true") {
                    options.dataSource.filter = [
                        { field: $(element).attr("data-visible"), operator: "eq", value: true },
                        { field: $(element).attr("data-archive"), operator: "eq", value: false }
                    ];
                    options.classifierFilter = options.dataSource.filter;
                }
                that.options.virtual = {
                    itemHeight: 26,
                    mapValueTo: "index",
                    valueMapper: function (options) {
                        if (!!that.options.fallBackValue)
                            return;
                        $.ajax({
                            url: !that.options.virtualUrl ? $(element).attr("data-dataSource") : that.options.virtualUrl,
                            data: {
                                key: (!!options.value ? options.value : 0),
                                "$filter": that.options.classifierFilter
                            },
                            type: $(element).attr("data-reqType") === null ? "get" : $(element).attr("data-reqType"),

                            success: function (data) {
                                if (data.value != undefined && data.value != null)
                                    options.success(data.value); //return the index number of the corresponding data item
                                else
                                    options.success(parseInt(data));
                            }
                        })
                    }
                }
            }

            //if (!element) {
            //    throw "element cannot be null";
            //}
            //if (!options.textField) {
            //    throw "textField cannot be null";
            //}
            //if (!options.valueField) {
            //    throw "valueField cannot be null";
            //}

            Widget.fn.init.call(that, element, options);

            that.one("dataBound", function (e) {
                if (!e.sender.dataItem() && !!e.sender.value()) {
                    BrcAjax.Get($(element).attr("data-datasource").split('?')[0], { '$filter': options.dataValueField + " eq " + parseInt(e.sender.value()) },
                        function (e) {
                            if (!!e.value && e.value.length > 0) {
                                that.options.fallBackValue = e.value[0];

                                that.trigger("change");
                                // that.text(e.value[0][options.dataTextField]);

                            }
                        },
                        function (e) {
                            that.value(null);
                        });

                }
                that.listView.setOptions({ placeholderTemplate: kendo.template("Загрузка...") });
                that.listView.element.addClass('brc-combobox-dropdownlist');
            })

            if (options.mode == "comboBox")
                return;

            $(that.wrapper).attr("aria-mode", that.options.mode);
            $(that.wrapper).addClass("brc-select-control");
            that.options.input = $(element)[0];
            that.options.wrapper = $(that.wrapper).find(".k-dropdown-wrap");// $(that.options.input).parent();
            that.options.wrapper.addClass("brc-select-control__wrapper");
            var searchButton = $('<span class="" unselectable="on" role="button" tabindex="-1" title="Выбрать"><span class="k-icon k-i-search"  ></span></span>');
            var showButton = $('<span class="" unselectable="on" role="button" title="Просмотр" tabindex="-1"><span class="fa fa-eye" ></span></span>');
            var openComboButton = $(that.wrapper).find("[aria-label=select]");

            that._controlButtons = [searchButton, showButton, openComboButton];

            $(that.input).after(searchButton);
            if (!!that.options.selectModal.grid.openObjectCallback)
                $(that.input).after(showButton);
            if (that.options.mode == "modal") {
                openComboButton.css("display", "none");
                that.open = that.selectModal;
                inputRightPad -= 1.9;
            }
            else
                openComboButton.detach().insertAfter(that.input);
            $(that.wrapper).find("[title=clear]").attr("title", "Очистить");
            $(that.wrapper).find(".k-i-close").click(function (e) {
                that.dataSource.filter(that.options.classifierFilter);
            })
            $(that.options.input).removeClass('k-textbox');
            $(that.options.input).addClass('k-input');
            $(that.options.input).addClass("brc-subject-select__input");

            $(that.wrapper).find("input").first().click(function (e) {
                if (!($(that.options.input).is(".k-state-disabled") || $(that.options.input).attr("readonly") != null || $(that.options.input).attr("disabled") != null))
                    that.open();
            });
            if (options.isVirtual)
                $(that.input).on("input change keyup", function (e) {
                    if (!!that.text()) {
                        that.dataSource.filter({
                            field: "tolower(" + that.options.dataTextField + ")",
                            operator: "contains",
                            value: that.text().toLowerCase()
                        });
                    }
                    else
                        that.dataSource.filter(null);
                })
            $(that.options.input).on("input change keyup", function (e) {
                e.preventDefault();
            })
            searchButton.click(function (e) {
                if (!($(that.options.input).is(".k-state-disabled") || $(that.options.input).attr("readonly") != null || $(that.options.input).attr("disabled") != null))
                    that.selectModal();
            })
            showButton.click(function (e) {
                e.preventDefault();
                var id = parseInt(that.value())
                if (id > 0)
                    that.options.selectModal.grid.openObjectCallback.call(this, id);
               
                //if (id > 0) {
                //    var modal = new SubjectModal();
                //    var config = {
                //        subjectId: id,
                //        onOKClose: function (e) {
                //            return false;
                //        }
                //    }
                //    modal.init(config);
                //}
            })
            $(that.wrapper).find("[role=combobox]").css("padding-right", inputRightPad+"em")
        },
        //enable: function (a, b) {
        //    var self = this.options;
        //    if (!a) {
        //        $(self.wrapper).addClass("k-state-disabled");
        //        $(self.input).addClass("k-state-disabled");
                
        //    }
        //    else {
        //        $(self.wrapper).removeClass("k-state-disabled");
        //        $(self.input).removeClass("k-state-disabled");
        //    }
        //    kendo.ui.ComboBox.fn.enable.call(self, a);
        //},

        //open:function(){
        //    var self = this;
        //    if (self.options.mode == "normal")
        //        self.selectModal();
        //    //else
        //    //    self.__proto__.open();
        //    return;
        //},

        selectModal: function () {
            var self = this;//.options;

            var subjSelModalCfg = {
                openImmidiate: true,
                height: 600,
                width: 900,
                title: self.options.selectModal.title,
                content: self.options.modalTemplate,
                onrefresh: (!self.options.selectModal.refreshWindow ? refreshWindow : self.options.selectModal.refreshWindow),
                modal: false
            }

            self.options.selectWindow = WindowManager.addWindow(subjSelModalCfg);
            refreshWindow.call(self.options.selectWindow.windowHandler);
            function refreshWindow(e) {
                var container = $(this.element);
                var form = container.find("form");
                var string = form.find("#searchString");//$(self.elem).val();
                string.attr("placeholder", self.options.selectModal.search.searchPlaceholder);
                var _dataSource = self.options.dataSource;
                //    {
                //    type: "odata",
                //    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtySubjectGrid",
                //    dataType: "json",
                //    excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportSubjectGrid",
                //};
                if (!self.options.separateSubject)
                    form.find("#createObjFl").hide();
                if (!!self.options.selectModal.grid.gridConfig)
                    self.options.selectGrid = new BrcGrid(container.find("#selectGrid"), { url: _dataSource.transport.read.url, type: "odata", dataType: "json" }).init(self.options.selectModal.grid.gridConfig).kendoGrid;
                else {
                    self.options.selectGrid = container.find("#selectGrid").kendoGrid({
                        dataSource: _dataSource,
                        columns: [
                            {
                                field: self.options.dataValueField,
                                title: "ID"
                            },
                            {
                                field: self.options.dataTextField,
                                title: "Наименование"
                            },
                        ],
                        selectable: "row",
                        sortable: true,
                        scrollable: true,
                        pageable: {
                            pageSize: 20
                        },
                        height: "100%"
                    }).data("kendoGrid")
                }
                self.options.selectGrid.bind("dataBound", function (e) {

                    self.options.selectGrid.table.find(self.options.selectModal.grid.openObjectSelector).click(onClick);
                    if (!!self.options.selectGrid.lockedTable)
                        self.options.selectGrid.lockedTable.find(self.options.selectModal.grid.openObjectSelector).click(onClick);
                    function onClick(e) {
                        e.preventDefault();
                        if (!!self.options.selectModal.grid.openObjectCallback) {
                            var dataItem = self.options.selectGrid.dataItem($(e.target).closest("tr"));
                            self.options.selectModal.grid.openObjectCallback.call(self, dataItem.id);
                        }
                    }

                })

                form.submit(function (e) {
                    e.preventDefault();
                    search(string.val());
                })
                form.find("#searchString").on("keyup input", function (e) {

                    search(string.val());
                })
                form.find("[title=clear]").click(function (e) {
                    string.val(null);
                    search(string.val());
                })
                if (!!self.options.canCreate && !!self.options.selectModal.createObjectCallback) {
                    if (self.options.separateSubject) {
                        form.find("#createObjFl").click(function (e) {
                            e.preventDefault();
                            self.options.selectModal.createObjectCallback.call(self, true);

                        });
                    }
                    form.find("#createObjUl").click(function (e) {
                        e.preventDefault();
                        self.options.selectModal.createObjectCallback.call(self);

                    });
                }
                else {
                    if (self.options.separateSubject) {
                        form.find("#createObjFl").addClass("k-state-disabled");
                    }
                    form.find("#createObjUl").addClass("k-state-disabled");
                }
                var validator = $(self.options.input).parents("form").data("kendoValidator");
                container.find("#okButton").click(function (e) {
                    var val = self.options.selectGrid.dataItem(self.options.selectGrid.select()[0]);

                    if (!!val) {
                        self.value(val[self.options.dataValueField]);
                    }
                    else {
                        self.select(null);
                    }
                    if (!!validator)
                        validator.validateInput($(self.options.input));
                    self.options.selectWindow.windowHandler.destroy();

                })
                container.find("#cancelButton").click(function (e) {
                    if (!!validator)
                        validator.validateInput($(self.options.input));
                    self.options.selectWindow.windowHandler.destroy();
                })
            }

            function search(searchString) {
                var _filters = [];
                if (self.options.selectModal.search.searchFilters.length > 0)
                    self.options.selectModal.search.searchFilters.forEach(function (f) {
                        var filter = createFilter(f, searchString);
                        if (!!filter)
                            _filters.push(
                                filter
                            );

                    });
                else
                    _filters.push({
                        field: "tolower(" + self.options.dataTextField + ")",
                        operator: "contains",
                        value: searchString.toLowerCase()
                    })
                self.options.selectGrid.dataSource.filter({
                    logic: self.options.selectModal.search.logic,
                    filters: !!self.options.classifierFilter ? _filters.concat(self.options.classifierFilter) : _filters
                })

            }
            function createFilter(opt, searchString) {
                if (!searchString)
                    return;
                var _field = opt.field;
                var _val = searchString;

                if (opt.type == "string") {
                    if (opt.caseSensitive) {
                        _field = opt.field;

                    }
                    else {
                        _field = "tolower(" + opt.field + ")";
                        _val = searchString.toLowerCase();
                    }
                }
                else if (opt.type == "number") {
                    var v = parseFloat(searchString);
                    if (!v)
                        return;
                    _val = v;
                }
                else
                    _field = opt.field;
                return {
                    field: _field,
                    operator: opt.operator,
                    value: _val
                }

            }
        },
    });

    ui.plugin(BrcMultiBox);

})(jQuery);
