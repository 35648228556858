var AssetContractAssetSelectModal = function () {
    var _grid;
    var _assetSelectWindow;
    var _needInit = true;
    var _wrapperId;

    var _assetContractId;
    var _onSelect;
    var _assetType;

    return {
        init: init,
        open: open
    };

    function init(config) {
        _assetContractId = config.assetContractId;
        _onSelect = config.onSelect;
        _assetType = config.assetType;
        _wrapperId = kendo.guid();
    }

    function initWindow() {
        var title = "";

        if (_assetType == "parcel") {
            title = "Выбор земельных участков для привязки к договору";
        }
        else if (_assetType == "building") {
            title = "Выбор зданий для привязки к договору";
        }
        else if (_assetType == "flat") {
            title = "Выбор помещений для привязки к договору";
        }
        else if (_assetType == "equipment") {
            title = "Выбор движимого имущества для привязки к договору";
        }

        var wrapper = $("#" + _wrapperId);

        _assetSelectWindow = wrapper.kendoWindow({
            width: "900px",
            height: "500px",
            resizable: true,
            modal: true,
            title: title,
            visible: false,
            actions: [
                "Minimize",
                "Maximize",
                "Close"
            ]
        }).data("kendoWindow");
        _assetSelectWindow.center();

        wrapper.find(".brc-button").kendoButton();

        wrapper.find("#okButton").on("click", function (e) {
            e.preventDefault();

            var selectedIds = _grid.getSelectedKeys();

            if (!(selectedIds.length > 0)) {
                var message = "";

                if (_assetType == "parcel") {
                    message = "Выберите земельные участки";
                }
                else if (_assetType == "building") {
                    message = "Выберите здания";
                }
                else if (_assetType == "flat") {
                    message = "Выберите помещения";
                }
                else if (_assetType == "equipment") {
                    message = "Выберите движимое имущество";
                }

                BrcNotification.error(message);
                return;
            }

            _assetSelectWindow.close();
            _onSelect(selectedIds);
        });

        wrapper.find("#cancelButton").click(function (e) {
            e.preventDefault();
            _assetSelectWindow.close();
        })
    }

    function initGrid() {
        var controller = "";

        if (_assetType == "parcel") {
            controller = "ODataRealtyParcel";
        }
        else if (_assetType == "building") {
            controller = "ODataRealtyBuilding";
        }
        else if (_assetType == "flat") {
            controller = "ODataRealtyFlat";
        }
        else if (_assetType == "equipment") {
            controller = "ODataRealtyEquipment";
        }

        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/" + controller,
            dataType: "json"
        };

        if (_assetType == "parcel") {
            var gridConfig = JSON.parse($("#" + _wrapperId).find("#parcelSelectGridConfig").val());
        }
        else if (_assetType == "building") {
            var gridConfig = JSON.parse($("#" + _wrapperId).find("#buildingSelectGridConfig").val());
        }
        else if (_assetType == "flat") {
            var gridConfig = JSON.parse($("#" + _wrapperId).find("#flatSelectGridConfig").val());
        }
        else if (_assetType == "equipment") {
            var gridConfig = JSON.parse($("#" + _wrapperId).find("#equipmentSelectGridConfig").val());
        }
        _grid = new BrcGrid($("#" + _wrapperId + " #assetGrid").selector, dataSource);
        _grid.init(gridConfig);
    }

    function open() {
        if (_needInit) {
            _needInit = false;
            BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/AssetContractAssetSelectModal",
                { assetType: _assetType },
                function (data) {
                    $("body").append("<div id=" + _wrapperId + ">" + data + "</div>");
                    initWindow();
                    initGrid();
                    _assetSelectWindow.open();
                },
                function (data) {
                    BrcNotification.error("Произошла ошибка!");
                }
            );
        }
        else {
            _grid.kendoGrid.dataSource.read();
            _assetSelectWindow.open();
        }
    }
}