function AssetTaxGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;
    var _assetId;

    return {
        init: init
    };

    function init(config) {
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);
        _assetId = config.assetId;

        initGrid();
        initToolbar();
    }

    function initGrid() {
        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyAssetTax",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + "Estate/Report/ExcelReportAssetTaxGrid?assetId=" + _assetId,
            filter: { field: "asset_id", operator: "eq", value: parseInt(_assetId) }
        };

        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());

        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);
    }
    
    function initToolbar() {
        var items = [];

        items.push({
                    type: "button",
                    text: "Создать",
                    click: onCreate,
                    hidden: !hasPermission("canCreateAssetTax")
                },
                {
                    type: "button",
                    text: "Удалить",
                    click: onDelete,
                    hidden: !hasPermission("canDeleteAssetTax")
                },
                { type: "separator" });

        items.push({
            type: "splitButton",
            text: "Таблица",
            menuButtons: [
                _brcGrid.filters(),
                _brcGrid.columnVisibility(),
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ],
        });

        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");   
    }

    function onCreate() {
        var assetTaxModal = new AssetTaxModal();
        assetTaxModal.init({
            assetTaxId: 0,
            assetId: _assetId,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
            }
        });
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите налог");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetTax/DeleteAssetTaxList",
                    { assetTaxIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="asset_tax"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var assetTaxModal = new AssetTaxModal();
            assetTaxModal.init({
                assetTaxId: this.getAttribute("number"),
                assetId: _assetId,
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
};