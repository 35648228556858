(function ($, kendo) {
    $.extend(true, kendo.ui.validator, {
        rules: {

            radio: function (input) {
                if (input.is("[type=radio]") && input.attr("data-val-required")) {
                    return $(input).parents("form").find("[type=radio]").is(":checked");
                }
                return true;
            },
            mvcrange: function (input) {
                if (input.filter("[data-val-numrange]").length) {
                    var minValue = kendo.parseFloat($(input).attr("data-val-numrange-min"));
                    var maxValue = kendo.parseFloat($(input).attr("data-val-numrange-max"));
                    var inputValue = kendo.parseFloat(input.val())
                    return inputValue > minValue && inputValue < maxValue;
                }
                return true;
            },
            mvcrequired: function (input) {

                var novalidate = $(input).attr("data-no-validate");
                if (!!novalidate && novalidate == 'true') {
                    return true;
                }

                if (input.filter("[data-val-required]").length) {
                    var value = input.val();
                    return !(value === "" || !value);
                }
                return true;
            },
            mvcvalidationmessagefor: function (input) {
                if (input.filter("[data-valmsg-for]").length) {
                    var value = input.val();
                    return !(value === "" || !value);
                }
                return true;
            }
        },
        messages: {
            mvcrequired: function (input) {
                return "Поле не может быть пустым"
            },
            mvcrange: function (input) {
                var minValue = kendo.parseFloat($(input).attr("data-val-numrange-min"));
                var maxValue = kendo.parseFloat($(input).attr("data-val-numrange-max"));
                var inputValue = kendo.parseFloat(input.val())
                if (inputValue <= minValue)
                    return kendo.format($(input).attr("data-val-numrange-minmessage"), minValue, maxValue);
                else if (inputValue >= maxValue)
                    return kendo.format($(input).attr("data-val-numrange-maxmessage"), minValue, maxValue);

            },
            radio: function (input) {
                return "Необходимо выбрать один из вариантов";
            }
        }
    });

})(jQuery, kendo);

function pairValueValidation(validator, firstInputSelector, secondInputSelector, ops, message) {
    for (var i = 0; i < ops.length; i++) {
        
        switch (ops[i]) {
            case ">":
                var newRule={
                    biggerRule: function (input) {
                        if (!!$(input).val()) {
                            if ($(input)[0].id == $(firstInputSelector)[0].id) {
                                if (!$(secondInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) > parseFloat($(secondInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else if ($(input)[0].id == $(secondInputSelector)[0].id) {
                                if (!$(firstInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) < parseFloat($(firstInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else
                                return true;
                        }
                        else
                            return true;
                    }
                }
                var validationMessages = {
                    biggerRule: message,
                }
                $.extend(true, validator.options.rules, newRule);
                $.extend(true, validator.options.messages, validationMessages);
                break;
            case ">=":
                var newRule = {
                    biggerRule: function (input) {
                        if (!!$(input).val()) {
                            if ($(input)[0].id == $(firstInputSelector)[0].id) {
                                if (!$(secondInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) >= parseFloat($(secondInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else if ($(input)[0].id == $(secondInputSelector)[0].id) {
                                if (!$(firstInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) <= parseFloat($(firstInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else
                                return true;
                        }
                        else
                            return true;
                    }
                }
                var validationMessages = {
                    biggerRule: message,
                }
                $.extend(true, validator.options.rules, newRule);
                $.extend(true, validator.options.messages, validationMessages);
                break;
            case "<":
                var newRule = {
                    biggerRule: function (input) {
                        if (!!$(input).val()) {
                            if ($(input)[0].id == $(firstInputSelector)[0].id) {
                                if (!$(secondInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) < parseFloat($(secondInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else if ($(input)[0].id == $(secondInputSelector)[0].id) {
                                if (!$(firstInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) > parseFloat($(firstInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else
                                return true;
                        }
                        else
                            return true;
                    }
                }
                var validationMessages = {
                    biggerRule: message,
                }
                $.extend(true, validator.options.rules, newRule);
                $.extend(true, validator.options.messages, validationMessages);
                break;
            case "<=":
                var newRule = {
                    biggerRule: function (input) {
                        if (!!$(input).val()) {
                            if ($(input)[0].id == $(firstInputSelector)[0].id) {
                                if (!$(secondInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) <= parseFloat($(secondInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else if ($(input)[0].id == $(secondInputSelector)[0].id) {
                                if (!$(firstInputSelector).val())
                                    return true;
                                var res = parseFloat($(input).val()) >= parseFloat($(firstInputSelector).val());
                                //if (res)
                                //    validator.hideMessages();
                                return res;
                            }
                            else
                                return true;
                        }
                        else
                            return true;
                    }
                }
                var validationMessages = {
                    biggerRule: message,
                }
                $.extend(true, validator.options.rules, newRule);
                $.extend(true, validator.options.messages, validationMessages);
                break;
        }
    }
}