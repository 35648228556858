var BrcCombobox = function (selector, dataSourceUrl) {
    this.selector = selector;
    this.dataSourceUrl = dataSourceUrl;
    this.kendoControl = null;
    this.isVirtual = false;
}


BrcCombobox.prototype.init = function (opt){//placeholder, dataTextField, dataValueField, cleaningButton, enabled, headerTemplate, template, reqType, isVirtual, minLength) {
    var combobox = this;
    var controlValue = $(combobox.selector).val();
    if (controlValue < 1) {
        $(combobox.selector).val("");
    }
    var dataSource = {};
    if (!opt.isVirtual)
    {
        dataSource={
            type: "odata",
            serverFiltering: false,
            serverPaging: false,
            transport: {
                read: {
                    url: $(combobox.selector).attr("data-classifier") == "true" ? $(combobox.selector).attr("data-datasource") + "/FilterVisibleAndArch?$inlinecount=allpages" : $(combobox.selector).attr("data-datasource"),
                    dataType: "json",
                    type: !$(combobox.selector).attr("data-reqType") ? ($(combobox.selector).attr("data-classifier") == "true" ? "post" : "get") : $(combobox.selector).attr("data-reqType")
                }
            },
            schema: {
                data: function (data) {
                    return data["value"];
                },
                total: function (data) {
                    return data["odata.count"];
                }
            },
            sort: { field: opt.dataTextField, dir: "asc" }
        };
    }
    else {
        dataSource= {
            type: "odata",
            transport: {
                read: {
                    url: $(combobox.selector).attr("data-classifier") == "true" ? $(combobox.selector).attr("data-datasource") + "/FilterVisibleAndArch?$inlinecount=allpages" : $(combobox.selector).attr("data-datasource"),
                    dataType: "json",
                    type: !$(combobox.selector).attr("data-reqType") ? ($(combobox.selector).attr("data-classifier") == "true" ? "post" : "get") : $(combobox.selector).attr("data-reqType")
                }
            },
            schema: {
                
                data: function (data) {
                    if (data.value) {
                        return data.value;
                    }
                    delete data["odata.metadata"];
                    return [data];
                },
                total: function (data) {
                    return data["odata.count"];
                }
            },
            pageSize: 40,
            serverPaging: true,
            serverFiltering: true
        };
    }
    this.kendoControl = $(combobox.selector).kendoComboBox({
        fallBackValue: false,
        //syncValueAndText: false,
        placeholder: opt.placeholder,
        dataTextField: opt.dataTextField,
        dataValueField: opt.dataValueField,
        enable: opt.enabled,
        clearButton: opt.cleaningButton ? opt.cleaningButton : true,
        headerTemplate: opt.headerTemplate,
        //noDataTemplate: "Нет доступных записей",
        template: opt.template,
        filter: "contains",
        minLength: !opt.minLength ? 2 : opt.minLength,
        dataSource: dataSource,
        height: !!opt.isVirtual ? 260 : 200,
        virtual: !!opt.isVirtual ? {
            itemHeight: 26,
            mapValueTo: "index",
            valueMapper: function (options) {
                $.ajax({
                    url: combobox.dataSourceUrl + "/EntityIndex?key=" + (!!options.value ? options.value : 0),
                    type: opt.reqType === null ? "get" : opt.reqType,
                    
                    success: function (data) {
                        options.success(data.value); //return the index number of the corresponding data item
                    }
                })
            }
        } : false,
        cascade: function (e) {
            if (this.select() == -1 && !this.value() && !this.text()) {
                this.options.fallBackValue = false;
            }
        },
        change: function (e) {
            if (!this.dataItem()) {
                if (!this.options.fallBackValue) {

                if(this.text() !== this.value())
                    this.value(null);
                }
                else {
                    this.value(this.options.fallBackValue[this.options.dataValueField]);
                    this.text(this.options.fallBackValue[this.options.dataTextField])
                }
            }
            this.wrapper.find("input").attr("title", this.text());
        },
        
    }).data("kendoComboBox");
    
    this.kendoControl.one("dataBound", function (e) {
        if (!e.sender.dataItem() && !!e.sender.value()) {
            BrcAjax.Get($(combobox.selector).attr("data-datasource") + "?$filter=" + e.sender.options.dataValueField + " eq " + parseInt(e.sender.value()), {},
                function (e) {
                    if (!!e.value && e.value.length > 0) {
                        combobox.kendoControl.options.fallBackValue = e.value[0];
                        combobox.kendoControl.trigger("change");
                        // that.text(e.value[0][options.dataTextField]);

                    }
                },
                function (e) {
                    combobox.kendoControl.value(null);
                });

        }
        combobox.kendoControl.listView.setOptions({ placeholderTemplate: kendo.template("Загрузка...") });
        combobox.kendoControl.listView.element.addClass('brc-combobox-dropdownlist');
    });
    
    this.kendoControl.listView.element.addClass('brc-combobox-dropdownlist');
    //if (!opt.isVirtual) {
    //    var changeValueBind = function (kendoControl) {
    //        kendoControl.bind("change", function () {
    //            if (!this.dataItem()) {
    //                this.value(null);
    //            }
    //        });
    //    };
    //    changeValueBind(this.kendoControl);
    //}
    return combobox;
}

