(function ($) {
    var BrcButtonList = kendo.toolbar.Item.extend({
        init: function (options, toolbar) {
            var self = this;
            this.options = options;
            this.toolbar = toolbar;
            //this.items=[];
            var e = $("<div class='brc-option-list'><div class='brc-option-list__button' data-role='customBtn'><span class='k-button' >" + options.text + " </span><a class='k-button'><span class='k-icon k-i-arrow-60-down'></span></a></div></div>");
            this.element = $(e);
            e.find('[data-role=customBtn]').click($.proxy(this._open, this));
            this._popup()
            this.attributes();
            this.addUidAttr();
            this.addOverflowAttr();
            this.enable(options.enable);
        },
        //enable:function(val){
        //    if (val)
        //        $(this.element).addClass(".k-state-disabled");
        //    else
        //        $(this.element).removeClass(".k-state-disabled");
        //},
        _open: function (e) {
            if (!$(this.element).is(".k-state-disabled"))
            {
                if ($(this.element).attr("data-state") == "open") {
                    this.popup.close();
                    $(this.element).attr("data-state", "closed");
                }
                else {
                    this.popup.open();
                    $(this.element).attr("data-state", "open");
                }
            }
        },
        _popup: function (anchor) {
            var self = this;
            var container = '<div class="brc-option-list"><div class="brc-option-list__popup"><ul class="k-overflow-container k-list-container k-popup k-group k-reset k-state-border-up">';
            container += '</ul></div>';
            this.popup = $(container).kendoPopup({
                anchor: $(this.element),
            //    appendTo: $(this.element),
                close: function (e) {
                    $(self.element).attr("data-state", "closed");
                }
            }).data("kendoPopup");
            var itemList = $(this.popup.element).find("ul");
            this.options.list.forEach(function (listItem) {
                var btn = new PopupButton(listItem, self);
                $(btn.element).wrap('<li class="k-overflow-button k-button"></li>');//btn.wrapper);
                btn.wrapper = $(btn.element).parent();
               // self.items.push(btn);
                itemList.append(btn.wrapper);
            });
        }
    });

    var BrcButtonOverflowList= kendo.toolbar.OverflowButton.extend({
        init: function (options, toolbar) {
            var self = this;
            kendo.toolbar.OverflowButton.fn.init.call(this, options, toolbar);
            this.options = options;
            this.toolbar = toolbar;
           // this.items = [];
            
            
            var e = $("<div class='brc-option-list'><div class='brc-option-list__button k-button' data-role='customBtn'><span >" + options.text + " </span><span class='k-icon k-i-arrow-60-down'></span></div></div>");
            this._popup();
            e.find('[data-role=customBtn]').click($.proxy(this._open, this));

            //this.dropDownList = dropDownList;
            $(this.element).html('').append(e);
        },
        enable:function(val){
            if (val)
                $(this.element).addClass(".k-state-disabled");
            else
                $(this.element).removeClass(".k-state-disabled");
        },
        _open: function (e) {
            if (!$(this.element).is(".k-state-disabled"))
            {
                if ($(this.element).attr("data-state") == "open") {
                    this.popup.close();
                    $(this.element).attr("data-state", "closed");
                }
                else {
                    this.popup.open();
                    $(this.element).attr("data-state", "open");
                }
            }
        },
        _popup: function () {
            var self = this;
            var container = '<div class="brc-option-list"><div class="brc-option-list__popup"><ul class="k-overflow-container k-list-container k-popup k-group k-reset k-state-border-up">';

            container += '</ul></div></div>';
            this.popup = $(container).kendoPopup({
                close: function (e) {
                    $(self.element).attr("data-state", "closed");
                },
                anchor: $(this.element),
                //appendTo: $(this.element)
            }).data("kendoPopup");
            var itemList = $(this.popup.element).find("ul");
            this.options.list.forEach(function (listItem) {
                var btn = new PopupButton(listItem, self);
                $(btn.element).wrap('<li class="k-overflow-button k-button"></li>');//btn.wrapper);
                btn.wrapper = $(btn.element).parent();
                //self.items.push(btn);
                itemList.append(btn.wrapper);
            });
        },

    });
    var PopupButton = kendo.toolbar.OverflowButton.extend({
        init: function (options, toolbar) {
            var self = this;
            kendo.toolbar.ToolBarButton.fn.init.call(this, options, toolbar);

            this.toolbar = toolbar;
            this.options = options;
            this.element.on("click", $.proxy(this._click, this))
            
        },
        _click: function (e) {
            e.preventDefault();
            if (!$(e.target).is(".k-state-disabled"))
                this.options.click.call(this.toolbar, e);
        },
        enable: function (val) {
            if (val)
                $(this.element).addClass(".k-state-disabled");
            else
                $(this.element).removeClass(".k-state-disabled");
        },
    });

    kendo.toolbar.registerComponent("brcButtonList", BrcButtonList, BrcButtonOverflowList);
})(jQuery);