(function ($) {
    var _options = {
        name: "GroupedGrid",
        brcGridConfig: {
            filters: [],
            draggable: false
        },
        brcGridDataSource: null,
        brcListConfig: {
            linkedItemKey: ""
        },
        tableViewLink: null,
        permissions: {},
        virtualNode: {},
        panes: {
            listPane: "25%",
            gridPane: "75%"
        },
        toolbar: null,

    }
    var GroupedGrid = kendo.ui.Widget.extend({
        options: _options,
        init: _init,
        deleteGroupFilters: _deleteGroupFilters
    })

    function _init(element, options) {
        var that = this;
        that.listPaneUid = kendo.guid();
        that.gridPaneUid = kendo.guid();
        kendo.ui.Widget.fn.init.call(that, element, options);
        // $(element).html('');
        $(element).addClass("brc-page-layout__splitter");
        //$(element).append($("<div uid='" + that.listPaneUid + "' ><div class='brc-page-layout__content'><div class='brc-page-layout__header'><div class='brd-page-layout__title'><h4 class='brc-card-title'>" + options.brcListConfig.title + "</h4></div><div class='brc-page-layout__toolbar'><div data-role='groupedListToolbar' class='brc-toolbar brc-toolbar-right brc-toolbar-border-none'></div></div></div><div class='brc-page-layout__work-area'><div data-role='groupedGridStaticList' style='border-bottom:1px solid #c9c9c9'></div><div data-role='groupedGridList'></div></div></div></div>"));
        //$(element).append($("<div uid='" + that.gridPaneUid + "' ><div class='brc-page-layout__content'><div class='brc-page-layout__header'><div class='brd-page-layout__title'><h4 class='brc-card-title'>" + options.brcGridConfig.title + "</h4></div><div class='brc-page-layout__toolbar'><div data-role='groupedGridToolbar' class='brc-toolbar brc-toolbar-right brc-toolbar-border-none'></div></div></div><div class='brc-page-layout__work-area'><div class='brc-grid-wrapper'> <div id='grid' class='brc-grid' data-role='groupedGridGrid'/></div></div></div></div>"));
        var grid = $(element).find(".brc-grid");
        _options.brcListConfig.linkedItemKey = options.brcListConfig.linkedItemKey ? options.brcListConfig.linkedItemKey : options.brcListConfig.key;
        grid.wrap("<div id='paneWrapper'><div class='pane-wrapper'><div class='brc-page-layout__content'><div class='brc-grid-wrapper'></div></div></div></div>")
        var w = $(element).find("#paneWrapper");
        var list = $("<div class='group-list' uid='" + that.listPaneUid + "'></div>");
        w.before(list);
        //$(divs[0]).attr("uid", that.listPaneUid);
        w.attr("uid", that.gridPaneUid);
        list.append($("<div class='brc-page-layout__content'>" + (!!options.brcListConfig.title ? '<div class="brc-page-layout__header"><div class="brc-page-layout__title"><h4 class="brc-card-title">' + options.brcListConfig.title + '</h4></div>' : '') + "<div class='brc-page-layout__toolbar'><div data-role='groupedListToolbar' class='brc-toolbar brc-toolbar-right brc-toolbar-border-none'></div></div></div><div class='brc-page-layout__work-area'><div data-role='groupedGridStaticList' class='grouped-grid-static-list' ></div><div data-role='groupedGridList' class='grouped-grid-list'></div></div></div>"));

        that.splitter = $(element).kendoSplitter({
            orientation: "horizontal",
            panes: [{ size: that.options.panes.listPane, collapsible: true }, { size: that.options.panes.gridPane }],
            resize: function (e) {
                if (!!that.brcGrid)
                    that.brcGrid.kendoGrid.resize();
                if (!!that.listToolBar)
                    that.listToolBar.resize();
                if (!!that.brcGridToolbar)
                    that.brcGridToolbar.resize();
            }
        }).data("kendoSplitter");

        that.brcList = _listInit.call(that, $("[uid=" + that.listPaneUid + "]"));
        that.staticList = _staticListInit.call(that, $("[uid=" + that.listPaneUid + "]"));
        if (!options.brcGridConfig.externalInit) {
            that.brcGridToolbar = that.options.toolbar;

            that.brcGrid = _gridInit.call(that, $("[uid=" + that.gridPaneUid + "]"));
            _gridToolbarInit.call(that);
            //_draggable.call(that);
        }
        else {
            that.brcGrid = options.brcGridConfig.externalInit.grid;
            that.brcGridToolbar = options.brcGridConfig.externalInit.toolbar;
            _gridToolbarInit.call(that);
        }
        that.getSelectedKey = function () {
            var data = that.brcList.dataItem(that.brcList.select());
            if (!!data) {
                return data[that.options.brcListConfig.key];
            }
            else
                return null;
        }
        if (that.options.brcGridConfig.draggable) {
            _draggable.call(that);
        }

        if (!!that.options.brcListConfig.virtualNode.all) {
            var node = that.staticList.findByText(that.staticList.dataSource.get("all").text);//($(that.staticList.element).find("#all").text());
            that.staticList.select(node);
            that.staticList.trigger("select", { node: node });
        }
        else if (that.options.brcListConfig.virtualNode.all && that.options.brcListConfig.virtualNode.ungrouped) {
            var node = that.staticList.findByText(that.staticList.dataSource.get("ungrouped").text);//($(that.staticList.element).find("#ungrouped").text());
            that.staticList.select(node);
            that.staticList.trigger("select", { node: node });
        }
        else {
            if (that.brcList.items().length > 0) {
                that.brcList.select(that.brcList.items()[0]);
                that.brcList.trigger("select", { node: that.brcList.items()[0] });
            }
        }
        document.addEventListener("LayoutSplitterResize", function (e) {
            that.splitter.resize();
        })
    }
    function _staticListInit(selector) {
        var self = this;
        var treeView = $(selector).find("[data-role=groupedGridStaticList]").kendoTreeView({
            dataSource: {
                schema: {
                    model: {
                        hasChildren: false
                    }
                }
            },
            select: function (e) {
                self.brcList.select($())
                e.sender.focus();
                var node = e.sender.dataItem(e.node);
                var path = (!!self.options.brcListConfig.path ? self.options.brcListConfig.path : "") + $(e.node).text();
                if (self.options.brcListConfig.pathSelector) {
                    $(self.options.brcListConfig.pathSelector).text(path);
                    $(self.options.brcListConfig.pathSelector).attr("title", path);
                }
                else
                    $("[uid=" + self.gridPaneUid + "]").find(".brc-card-title").text(path)
                switch (node.id) {
                    case "all":
                        //var filters = [];
                        //if (!!self.options.brcGridConfig.filters)
                        //    self.options.brcGridConfig.filters.forEach(function (f) {
                        //        filters.push(f);
                        //    })
                        //var customFilters = [];
                        //if (self.brcGrid.filterable)
                        //    customFilters = self.brcGrid.getCurrentFilters();
                        //filters = filters.concat(customFilters);
                        if (self.options.brcGridConfig.manyToMany) {
                            self.brcGrid.kendoGrid.dataSource.transport.options.read.url = self.options.brcGridConfig.manyToMany.url;
                            self.brcGrid.kendoGrid.dataSource.options.transport.read.url = self.options.brcGridConfig.manyToMany.url;
                            self.brcGrid.kendoGrid.options.excel.proxyURL = self.options.brcGridConfig.manyToMany.excelUrl;
                            //self.brcGrid.kendoGrid.dataSource.filter(filters);

                        }
                        self.deleteGroupFilters("groupedFilters");


                        break;
                    case "ungrouped":
                        //var filters = [];
                        //if (!!self.options.brcGridConfig.filters)
                        //    self.options.brcGridConfig.filters.forEach(function (f) {
                        //        filters.push(f);
                        //    })
                        //var customFilters = [];
                        //if (self.brcGrid.filterable)
                        //    customFilters = self.brcGrid.getCurrentFilters();
                        //filters = filters.concat(customFilters);
                        //if (!!self.options.brcListConfig.virtualNode.ungrouped.filters)
                        //    filters = filters.concat(self.options.brcListConfig.virtualNode.ungrouped.filters);
                        //else
                        //filters.push({id:"groupedFilters", field: self.options.brcListConfig.key, operator: "eq", value: null });
                        if (self.options.brcGridConfig.manyToMany) {
                            self.brcGrid.kendoGrid.dataSource.transport.options.read.url = self.options.brcGridConfig.manyToMany.alterUrl;
                            self.brcGrid.kendoGrid.dataSource.options.transport.read.url = self.options.brcGridConfig.manyToMany.alterUrl;
                            self.brcGrid.kendoGrid.options.excel.proxyURL = self.options.brcGridConfig.manyToMany.excelAlterUrl;
                            // self.brcGrid.kendoGrid.options.excel.proxyURL = self.options.brcGridConfig.manyToMany.excelAlterUrl + "?group=" + dataItem.id;


                        }
                        //else {
                        //    self.brcGrid.kendoGrid.dataSource.filter(filters);
                        //}
                        self.brcGrid.replaceFilter({ id: "groupedFilters", field: self.options.brcListConfig.key, operator: "eq", value: null });
                        break;
                }
            }
        }).data("kendoTreeView");
        if (!!self.options.brcListConfig.virtualNode && !!self.options.brcListConfig.virtualNode.all)
            treeView.append(new kendo.data.Node({
                text: !!self.options.brcListConfig.virtualNode.all.text ? self.options.brcListConfig.virtualNode.all.text : "Все",
                id: "all"
            }));
        if (!!self.options.brcListConfig.virtualNode && !!self.options.brcListConfig.virtualNode.ungrouped)
            treeView.append(new kendo.data.Node({
                text: !!self.options.brcListConfig.virtualNode.ungrouped.text ? self.options.brcListConfig.virtualNode.ungrouped.text : "Не сгруппированные",
                id: "ungrouped"
            }));
        return treeView;
    }
    function _listInit(selector) {
        var self = this;
        var opt = self.options.brcListConfig;

        var toolbar = $(selector).find("[data-role=groupedListToolbar]");//.append();
        var treeView = $(selector).find("[data-role=groupedGridList]");//.append();

        function createRootGroup(e) {
            if (self.brcList.element.find("[state-changed=true]").length > 0) {
                return;
            }
            var obj = {};
            obj[self.options.brcListConfig.textField] = "";
            var data = new kendo.data.Node(obj);
            node = self.brcList.append(data);
            changeNode(data, node, true);
        }
        function createNestedGroup(e) {
            if (self.brcList.element.find("[state-changed=true]").length > 0) {
                return;
            }
            if (!self.options.brcListConfig.isTree) {
                var obj = {};
                obj[self.options.brcListConfig.textField] = "";
                var data = new kendo.data.Node(obj);
                node = self.brcList.append(data);
                changeNode(data, node, true);
            }
            else {
                var obj = {};
                obj[self.options.brcListConfig.textField] = "";
                var data = new kendo.data.Node(obj);
                var selectedNode = self.brcList.dataItem(self.brcList.select());
                var node = null;
                if (!!selectedNode) {
                    if (!selectedNode.loaded())
                        selectedNode.load().done(function (e) {
                            addNode();
                        })
                    else {
                        addNode();
                    }
                    function addNode() {
                        data[self.options.brcListConfig.parentKey] = selectedNode[self.options.brcListConfig.key];
                        node = self.brcList.append(data, self.brcList.select());
                        changeNode(data, node);
                    }
                }
                else {
                    BrcNotification.info("Не выбран узел");;
                }
            }
        }
        function deleteNode(e) {

            //_createNode.call(self)
            var node = self.brcList.select();
            var selected = self.brcList.dataItem(node);
            if (!selected) {
                BrcNotification.info("Не выбран узел");
                return;
            }
            else if (selected.hasChildren && self.options.brcListConfig.confirmation)
                BrcConfirm.show('', "При выполнении действия будут удалены все вложенные группы, а объекты будут разгруппированы, продолжить?").done(function (e) {
                    self.options.brcListConfig.destroy.call(self, selected.toJSON(), true).done(function (e) {
                        if (e == undefined || e.responseJSON == undefined || e.responseJSON.error !== true) {
                            self.brcList.remove(node);
                            self.brcGrid.kendoGrid.dataSource.read();
                        }
                        else if (e != undefined && e.responseJSON != undefined) {
                            BrcNotification.error(e.responseJSON['odata.error'].message.value);
                        }
                        else {
                            BrcNotification.error("Произошла ошибка");
                        }

                        //BrcNotification.success("Выполнено");
                    }).fail(function (e) {
                        //BrcNotification.error(e.responseJSON);
                    });

                })
            else
                self.options.brcListConfig.destroy.call(self, selected.toJSON()).done(function (e) {
                    if (e == undefined || e.responseJSON == undefined || e.responseJSON.error !== true) {
                        self.brcList.remove(node)
                    }
                    else if (e != undefined && e.responseJSON != undefined) {
                        console.debug(e.responseJSON);
                        BrcNotification.error(e.responseJSON['odata.error'].message.value);
                    }
                    else {
                        BrcNotification.error("Произошла ошибка");
                    }
                    //BrcNotification.success("Выполнено");
                }).fail(function (e) {
                    //BrcNotification.error(e.responseJSON);
                });
        }
        function renameNode(e) {
            if (self.brcList.element.find("[state-changed=true]").length > 0) {
                return;
            }
            var node = self.brcList.select();
            var data = self.brcList.dataItem(node);
            if (!data) {
                BrcNotification.info("Не выбран узел");
                return;
            }
            if (!!node && !!data) {
                changeNode(data, node, data.level() == 0);
            }
        }
        function changeNode(data, node, root) {
            if (!node) {
                BrcNotification.error("Неизвественая ошибка");
                return;
            }

            var htmlNode = node.find(".k-in").first();
            var expandPath = getExpandPath.call(self, htmlNode, []);
            var nodeCopy = htmlNode.clone();
            htmlNode.html('');
            htmlNode.attr("state-changed", true);
            var clickPropagation = function (e) {
                e.stopPropagation();
            };
            node.on('click', clickPropagation)


            var form = $('<form ><div style="display:flex"><input style="align-self:stretch" type="text" class="k-textbox" name="' + self.options.brcListConfig.textField + '" value="' + data[self.options.brcListConfig.textField] + '" required="true"/><button type="submit" class="k-button k-button-icon"><span class="k-icon k-i-check"></span></button><button id="btnCancel" type="button" class="k-button k-button-icon"><span class="k-icon k-i-close"></span></button></div><span class="k-widget k-tooltip k-tooltip-validation k-invalid-msg" role="alert" data-for="' + self.options.brcListConfig.textField + '" /></form>').appendTo(htmlNode);

            initControls(node);
            form.submit(function (e) {

                e.preventDefault();

                var text = form.serializeArray()[0].value;
                data[self.options.brcListConfig.textField] = text;
                if (data.isNew()) {
                    self.options.brcListConfig.create(data.toJSON()).done(function (e) {
                        data = e;

                        var newNode = new kendo.data.Node(data);
                        newNode.id = data[self.options.brcListConfig.key];
                        if (self.brcList.select().length > 0 && !root)
                            self.brcList.append(newNode, self.brcList.select());
                        else
                            self.brcList.append(newNode);
                        self.brcList.remove(node);
                        //BrcNotification.success("Выполнено");
                        self.brcList.dataSource.read().done(function () {
                            self.brcList.expandPath(expandPath)
                        });
                    }).fail(function (e) {
                        //BrcNotification.error(e.responseJSON);
                    }).always(function () {
                        node.off("click", clickPropagation)
                    });
                }
                else {
                    self.options.brcListConfig.update(data.toJSON()).done(function (e) {
                        //data.loaded(false);
                        //data = e;
                        //e.hasChildren = true;
                        var newNode = new kendo.data.Node(e);

                        newNode.id = e[self.options.brcListConfig.key];
                        if (self.brcList.select().length > 0 && !root)
                            self.brcList.insertAfter(newNode, node);
                        else
                            self.brcList.append(newNode);
                        self.brcList.remove(node);
                        //newNode.children.sync();
                        // BrcNotification.success("Выполнено");
                        self.brcList.dataSource.read().done(function () {
                            self.brcList.expandPath(expandPath)
                        });

                    }).fail(function (e) {
                        //BrcNotification.error(e.responseJSON);
                    }).always(function () {
                        node.off("click", clickPropagation)
                    });
                }
            })
            form.find("#btnCancel").click(function (e) {
                e.preventDefault();
                if (!data.isNew()) {
                    htmlNode.html(nodeCopy.html());
                    htmlNode.attr("state-changed", false);
                }
                else
                    htmlNode.remove();
                node.off("click", clickPropagation)
            })
        }
        function getPath(node, path) {
            var self = this;
            if (node) {
                var parentnode = self.brcList.parent($(node));
                if (parentnode.is("li")) {
                    path += getPath.call(self, parentnode, path);

                }
                path += "\\" + self.brcList.dataItem(node)[self.options.brcListConfig.textField];
                return path.replace(/^\\/, '');
            }
        }
        function getExpandPath(node, path) {
            var self = this;
            if (node) {
                var parentnode = self.brcList.parent($(node));
                if (parentnode.is("li")) {
                    path = getExpandPath.call(self, parentnode, path);
                }
                path.push(self.brcList.dataItem(node)[self.options.brcListConfig.key]);
                return path;
            }
        }
        var items = [];
        var createGroup = {
            type: "buttonGroup",
            buttons: [
                 {
                     type: "button",
                     id: "create",
                     icon: "folder-more",
                     attributes: { "title": "Создать вложенную группу" },
                     text: "Создать вложенную группу",
                     showText: "overflow",
                     enable: self.options.permissions.canCreateGroup,
                     click: createNestedGroup
                 },
            ]
        };
        if (self.options.brcListConfig.isTree) {
            createGroup.buttons.push(
        {
            type: "button",
            id: "create",
            icon: "folder-add",
            attributes: { "title": "Создать корневую группу" },
            text: "Создать корневую группу",
            showText: "overflow",
            enable: self.options.permissions.canCreateGroup,
            click: createRootGroup
        });
        }

        self.listToolBar = toolbar.kendoToolBar().data("kendoToolBar")
        self.listToolBar.add(createGroup);
        self.listToolBar.add({
            type: "button",
            id: "delete",
            icon: "delete",
            attributes: { "title": "Удалить" },
            text: "Удалить",
            showText: "overflow",
            enable: self.options.permissions.canDeleteGroup,
            click: deleteNode
        });
        self.listToolBar.add({
            type: "button",
            id: "rename",
            icon: "pencil",
            attributes: { "title": "Переименовать" },
            text: "Переименовать",
            showText: "overflow",
            enable: self.options.permissions.canChangeGroup,
            click: renameNode
        });
        self.listToolBar.resize();
        var brcList = $(treeView).kendoTreeView({
            dataTextField: opt.textField,
            select: function (e) {
                self.staticList.select($())
                e.sender.focus();
                var dataItem = this.dataItem(e.node);
                var filters = [];


                //if (!!self.options.brcGridConfig.filters)
                //    self.options.brcGridConfig.filters.forEach(function (f) {
                //        filters.push(f);
                //    })
                //var customFilters = [];
                //if (self.brcGrid.filterable)
                //    customFilters = self.brcGrid.getCurrentFilters();
                //filters = filters.concat(customFilters);
                //filters.push({ id: "groupedFilters", field: opt.key, operator: "eq", value: dataItem[opt.key] });
                if (self.options.brcGridConfig.manyToMany) {
                    self.brcGrid.kendoGrid.dataSource.transport.options.read.url = self.options.brcGridConfig.manyToMany.alterUrl;
                    self.brcGrid.kendoGrid.dataSource.options.transport.read.url = self.options.brcGridConfig.manyToMany.alterUrl;
                    self.brcGrid.kendoGrid.options.excel.proxyURL = self.options.brcGridConfig.manyToMany.excelAlterUrl;
                    //self.brcGrid.kendoGrid.dataSource.filter(filters);
                }

                self.brcGrid.replaceFilter({ id: "groupedFilters", field: opt.key, operator: "eq", value: dataItem[opt.key] });

                var path = (!!self.options.brcListConfig.path ? self.options.brcListConfig.path : "") + getPath.call(self, e.node, "");
                if (self.options.brcListConfig.pathSelector) {
                    $(self.options.brcListConfig.pathSelector).text(path);
                    $(self.options.brcListConfig.pathSelector).attr("title", path);
                }
                else
                    $("[uid=" + self.gridPaneUid + "]").find(".brc-card-title").text(path)
            },
            dragAndDrop: self.options.brcListConfig.isTree && self.options.permissions.canChangeGroup,
            drag: function (e) {
                if ($(e.dropTarget).parents("[data-role=groupedGridStaticList]").length > 0)
                    e.setStatusClass("k-denied");
                if (e.statusClass == "add") {
                    var destination = this.dataItem(e.dropTarget);

                    if (destination.level() == 3) {
                        e.setStatusClass("k-denied");
                    }
                }
            },
            drop: function (e) {

                if ($(e.dropTarget).parents("[data-role=groupedGridStaticList]").length > 0) {
                    e.setValid(false);
                }
                //  else if ($(e.dropTarget) == e.)


            },
            dragend: function (e) {
                var source = self.brcList.dataItem(e.sourceNode);
                var target = self.brcList.dataItem(e.destinationNode);

                BrcPreloader.load(self.brcList.element);
                if (target.level() === 0 && e.dropPosition != 'over') {
                    source[self.options.brcListConfig.parentKey] = null;
                }
                else {
                    if (e.dropPosition == 'over') {
                        source[self.options.brcListConfig.parentKey] = target[self.options.brcListConfig.key];
                    }
                    else {
                        source[self.options.brcListConfig.parentKey] = target[self.options.brcListConfig.parentKey];
                    }

                }

                var model = source.toJSON();

                delete model.index;
                delete model.value;
                delete model.expanded;
                delete model.selected;
                self.options.brcListConfig.update(model).done(function (e) {

                }).always(function () {
                    BrcPreloader.unload(self.brcList.element);
                })
            },
            dragstart: function (e) {
                e.sender.options.group = "main";
            },
            dataSource: {
                serverSorting: true,
                serverPaging: true,

                transport: {
                    read: {
                        url: opt.dataSource.url,
                        type: opt.dataSource.reqType ? opt.dataSource.reqType : "get",
                        dataType: opt.dataSource.dataType,
                        //data: opt.dataSource.data?( typeof(opt.dataSource.data) == 'function'?opt.dataSource.data.call(self):opt.dataSource.data):null
                    },
                    parameterMap: function (a, type) {
                        if (type == "read" && !!a[opt.key] && opt.isTree) {
                            return {
                                $filter: kendo.format("{0} eq {1}", opt.parentKey, a[opt.key]),
                                $orderby: opt.key,
                                $inlinecount: "allpages"
                            }
                        }
                        else if (type == "read" && opt.isTree) {
                            return {
                                $filter: kendo.format("{0} eq {1}", opt.parentKey, null),
                                $orderby: opt.key,
                                $inlinecount: "allpages"
                            }
                        }
                        else if (type == "read" && !!a[opt.key] && !opt.isTree) {
                            return {
                                $filter: kendo.format("{0} eq {1}", opt.key, a[opt.key]),
                                $orderby: opt.key,
                                $inlinecount: "allpages"
                            }
                        }
                        else if (type == "read")
                            return {
                                $orderby: opt.key,
                                $inlinecount: "allpages"

                            }
                    }
                },
                schema: {
                    model: {
                        id: opt.key,
                        parentId: opt.isTree ? opt.parentKey : "",
                        hasChildren: opt.isTree ? "hasChildren" : false,
                    },
                    total: function (data) {
                        return !!data ? (!!data["odata.count"] ? data["odata.count"] : data["@odata.count"]) : 0;
                    },
                    data: "value"


                }
            },
        }).data("kendoTreeView");
        var draggable = $(treeView).data("kendoDraggable");
        if (!!draggable)
            $(treeView).data("kendoDraggable").options.container = $(treeView);
        return brcList;
    }
    function _gridToolbarInit(selector) {
        var self = this;
        //var tb = $(selector).find("[data-role=groupedGridToolbar]").kendoToolBar({
        if (self.brcGridToolbar.options.items.length > 0)
            self.brcGridToolbar.add({
                type: "separator"
            })
        var items = {};

        items =
            {
                type: "buttonGroup",
                buttons: [
                    {
                        type: "button",
                        text: "Вырезать",
                        id: "cut",
                        enable: self.options.permissions.canChangeObject,
                        click: function (e) {
                            _cut.call(self);
                        }
                    },

                ]
            };

        //self.brcGridToolbar.add({
        //})
        if (!!self.options.brcGridConfig.manyToMany || self.options.brcGridConfig.copyButton) {
            items.buttons.push({
                type: "button",
                text: "Копировать",
                id: "copy",
                enable: self.options.permissions.canChangeObject,
                click: function (e) {
                    _cut.call(self, true);
                }
            })
        }
        items.buttons.push({
            type: "button",
            text: "Вставить",
            id: "paste",
            enable: self.options.permissions.canChangeObject,
            click: function (e) {
                _paste.call(self);
            }
        })
        if (!!self.options.brcGridConfig.manyToMany) {
            items.buttons.push({
                type: "button",
                text: "Удалить из групп",
                id: "deleteFromGroup",
                enable: self.options.permissions.canChangeObject,
                click: function (e) {
                    BrcConfirm.show('', "Действие приведет к удалению выбранных объектов из всех групп, продолжить?").done(function (e) {
                        _deleteFromGroup.call(self);
                    })

                }
            })
        }
        if (self.options.tableViewLink)
            items.buttons.push({
                type: "button",
                text: '<i class="fa fa-table" title="Таблица"></i>',
                id: "table",
                click: function (e) {

                    location.href = self.options.tableViewLink;
                }
            })
        self.brcGridToolbar.add(items)
        self.brcGridToolbar.add({
            type: "separator"
        })
        //self.brcGridToolbar.resize();
        function _deleteFromGroup() {
            var self = this;
            var items = [];
            self.brcGrid.getSelectedKeys().forEach(function (item) {
                var dataItem = self.brcGrid.kendoGrid.dataSource.get(item);
                items.push(dataItem);
            });
            if (items.length > 0) {
                self.options.brcGridConfig.deleteFromGroups(items)
                                   .done(function (e) {
                                       self.brcGrid.kendoGrid.dataSource.pushDestroy(items);
                                   }).fail(function (e) {

                                   }).always(function () {
                                       BrcPreloader.unload($(self.brcGrid.selector));


                                   });
            }

        }
        // return tb;
        function _cut(copy) {
            var self = this;
            self.buffer = [];
            self.brcGrid.getSelectedKeys().forEach(function (item) {
                var dataItem = self.brcGrid.kendoGrid.dataSource.get(item);
                self.buffer.push(dataItem);
            });
            if (!!copy)
                self.buffer["copy"] = true;
            else
                self.buffer["copy"] = false;
        }
        function _paste() {
            var self = this;

            if (self.hasOwnProperty("buffer") && self.buffer.length > 0) {
                BrcPreloader.load($(self.brcGrid.selector));
                var items = [];
                var selectedFolder = self.brcList.dataItem(self.brcList.select());
                if (!!selectedFolder) {
                    self.buffer.forEach(function (dataItem) {
                        var flag = false;
                        var currItems = self.brcGrid.kendoGrid.dataSource.view();
                        currItems.forEach(function (element) {
                            if (element.id == dataItem.id)
                                flag = true;
                        })
                        if (flag)
                            return;

                        items.push(dataItem.toJSON());
                        dataItem[self.options.brcListConfig.key] = selectedFolder[self.options.brcListConfig.key];

                    });
                    if (items.length > 0) {
                        if (!self.buffer.copy) {
                            self.options.brcGridConfig.changeGroup(items, selectedFolder[self.options.brcListConfig.key])
                                .done(function (e) {
                                    self.brcGrid.kendoGrid.dataSource.pushCreate(items);
                                    self.brcGrid.kendoGrid.dataSource.read();
                                }).fail(function (e) {

                                }).always(function () {
                                    BrcPreloader.unload($(self.brcGrid.selector));
                                });
                        }
                        else {
                            self.options.brcGridConfig.addGroup(items, selectedFolder[self.options.brcListConfig.key])
                               .done(function (e) {
                                   self.brcGrid.kendoGrid.dataSource.pushCreate(items);
                                   self.brcGrid.kendoGrid.dataSource.read();
                               }).fail(function (e) {

                               }).always(function () {
                                   BrcPreloader.unload($(self.brcGrid.selector));


                               });
                        }
                    }
                    else {
                        BrcNotification.info("Нет записей доступных для вставки");
                        BrcPreloader.unload($(self.brcGrid.selector));
                    }
                }
                else {
                    var selectedFolder = self.staticList.dataItem(self.staticList.select());
                    switch (selectedFolder.id) {
                        case "all":
                            if (!!self.options.brcListConfig.virtualNode && !self.options.brcListConfig.virtualNode.all.dropTarget) {
                                BrcPreloader.unload($(self.brcGrid.selector));
                                BrcNotification.info(self.options.brcListConfig.virtualNode.all.message)
                                return;
                            }
                            break;
                        case "ungrouped":
                            if (!!self.options.brcListConfig.virtualNode && !self.options.brcListConfig.virtualNode.ungrouped.dropTarget) {
                                BrcPreloader.unload($(self.brcGrid.selector));
                                BrcNotification.info(self.options.brcListConfig.virtualNode.ungrouped.message)
                                return;
                            }
                            break;
                    }
                    if (!!selectedFolder) {
                        var items = [];
                        self.buffer.forEach(function (dataItem) {
                            var flag = false;
                            var currItems = self.brcGrid.kendoGrid.dataSource.view();
                            currItems.forEach(function (element) {
                                if (element.id == dataItem.id && element[self.options.brcListConfig.linkedItemKey] == null)
                                    flag = true;
                            })
                            if (flag)
                                return;

                            items.push(dataItem.toJSON());
                            dataItem[self.options.brcListConfig.key] = null;

                        });
                        if (items.length > 0) {
                            if (!self.buffer.copy) {
                                self.options.brcGridConfig.changeGroup(items, null)
                               .done(function (e) {
                                   self.brcGrid.kendoGrid.dataSource.pushCreate(items);
                                   self.brcGrid.kendoGrid.dataSource.read();
                               }).fail(function (e) {

                               }).always(function () {
                                   BrcPreloader.unload($(self.brcGrid.selector));
                               });
                            }
                            else {
                                BrcNotification.info("")
                            }
                        }
                        else {
                            BrcNotification.info("Нет выбранных записей");
                            BrcPreloader.unload($(self.brcGrid.selector));
                        }
                    }
                    else {
                        BrcNotification.info("Необходимо выбрать группу назначения")
                    }
                }
            }
            self.buffer = [];
        }
    }
    function _draggable() {
        var self = this;
        var gridDrag = $(self.brcGrid.kendoGrid.table).kendoDraggable({

            filter: "tbody>tr.k-state-selected",
            cursorOffset: {
                top: 5,
                left: 5
            },
            container: self.element,
            // ignore: 'td:not(.dragcontrol)',
            hint: function (e) {
                return $('<div class="k-grid k-widget"><table><tbody><tr>' + e.html() + '</tr></tbody></table></div>');
            },
            dragstart: function (e) {
                //if (!dragEnabled) {
                //    e.preventDefault();
                //    return false;
                //}
            },

        }).data("kendoDraggable");

        $(self.brcList.element).kendoDropTarget({

            drop: function (e) {
                var source = e.draggable.currentTarget;
                var dest = e.target;

                var dataRow = self.brcGrid.kendoGrid.dataItem(source);


                if ($(dest).is(".k-in") && !$(source).is(".k-in")) {
                    var destItem = self.brcList.dataItem(dest);
                    dataRow[self.options.brcListConfig.key] = destItem[self.options.brcListConfig.key];
                    self.options.brcGridConfig.changeGroup([dataRow], destItem[self.options.brcListConfig.key])
                                 .done(function (e) {
                                     self.brcGrid.kendoGrid.dataSource.pushDestroy(dataRow);
                                     self.brcGrid.kendoGrid.dataSource.read();
                                 }).fail(function (e) {

                                 }).always(function () {
                                     //BrcPreloader.unload($(self.brcGrid.selector));
                                 });

                }
            }
        })
        $(self.staticList.element).kendoDropTarget({

            drop: function (e) {
                var source = e.draggable.currentTarget;
                var dest = e.target;
                if ($(source).is(".k-in")) {
                    e.preventDefault();
                    e.setValid(false);
                    return;
                }
                var dataRow = self.brcGrid.kendoGrid.dataItem(source);
                var node = self.staticList.dataItem(dest);
                switch (node.id) {
                    case "all":
                        if (!self.options.brcListConfig.virtualNode && !!self.options.brcListConfig.virtualNode.all.dropTarget)
                            return;
                        break;
                    case "ungrouped":
                        if (!self.options.brcListConfig.virtualNode && !!self.options.brcListConfig.virtualNode.ungrouped.dropTarget)
                            return;
                        break;
                }

                if ($(dest).is(".k-in") && !$(source).is(".k-in")) {

                    dataRow.set(self.options.brcListConfig.key, null);
                    var sync = self.brcGrid.kendoGrid.dataSource.sync();
                    sync.done(function (e) {
                        self.brcGrid.kendoGrid.dataSource.read();

                    })

                }
                else {
                    return;
                }
            }
        })
    }
    function _deleteGroupFilters() {
        var self = this;
        self.brcGrid.kendoGrid.dataSource.filter(self.brcGrid.deleteFilterById("groupedFilters"));
    }
    function _gridInit(selector) {
        var self = this;
        var opt = self.options.brcGridConfig;

        var brcGrid = new BrcGrid(selector.find("[data-role=groupedGridGrid]"), opt.dataSource);
        brcGrid.init(opt.gridConfig);
        brcGrid.kendoGrid.dataSource.sort(opt.dataSource.sort)
        return brcGrid;
    }
    kendo.ui.plugin(GroupedGrid);
})(jQuery);