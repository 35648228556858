var DateRangeHelper = function () {
    return {
        init: function (startSelector, endSelector) {
            var start = $(startSelector).data("kendoDatePicker");          
            var end = $(endSelector).data("kendoDatePicker");
            if (!start && !end)
            {
                start = $(startSelector).data("kendoDateTimePicker");
                end = $(endSelector).data("kendoDateTimePicker");
            }
            start.min(new Date(1970, 0, 1));
            end.min(new Date(1970, 0, 1));
            if (start.value() || end.value()) {
                onStartChange();
                onEndChange();
            }

            start.bind("change", onStartChange);
            end.bind("change", onEndChange);

            function onStartChange() {
                var startDate = start.value();
                var endDate = end.value();

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.getDate());

                    end.min(startDate);
                    start.max(endDate?endDate:new Date(2099, 11, 31));
                    start.min(new Date(1970, 0, 1));
                    end.max(new Date(2099, 11, 31));
                } else if (endDate) {
                    start.max(new Date(endDate));
                    start.min(new Date(1970, 0, 1));
                    end.max(new Date(2099, 11, 31));
                    end.min(new Date(1970, 0, 1));
                } else {
                    start.max(new Date(2099, 11, 31));
                    start.min(new Date(1970, 0, 1));
                    end.max(new Date(2099, 11, 31));
                    end.min(new Date(1970, 0, 1));
                }
            }

            function onEndChange() {
                var endDate = end.value();
                var startDate = start.value();

                if (endDate) {
                    endDate = new Date(endDate);
                    endDate.setDate(endDate.getDate());

                    start.max(endDate);
                    start.min(new Date(1970, 0, 1));
                    end.max(new Date(2099, 11, 31));
                    end.min(startDate?startDate:new Date(1970, 0, 1));
                } else if (startDate) {
                    end.min(new Date(startDate));
                    start.max(new Date(2099, 11, 31));
                    start.min(new Date(1970, 0, 1));
                    end.max(new Date(2099, 11, 31));
                } else {
                    start.max(new Date(2099, 11, 31));
                    start.min(new Date(1970, 0, 1));
                    end.max(new Date(2099, 11, 31));
                    end.min(new Date(1970, 0, 1));
                }
            }
        }
    };
}();
