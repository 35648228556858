function AssetContractGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;
    var _assetId;
    var _subjId;
    var _assetComplexId;
    var _mode;
    var _tree;
    var _toolbar;
    var _config;
    return {
        init: init
    };

    function init(config) {
        _config = config;
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);
        _assetId = config.assetId;
        _assetComplexId = config.assetComplexId;
        _subjId = config.subjId;
        _mode = config.mode;

        initGrid();
        _toolbar = initToolbar(config.isTree);
        if (config.isTree)
            _tree = _initTree($("#splitter"));
        _toolbar.add({
            type: "brcButtonList",
            text: "Таблица",            
            list:[ //список кнопок
            _brcGrid.filters(),//стандартная кнопка 
            _brcGrid.columnVisibility(),//стандартная кнопка
            { text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }            
          ]

        })
        _toolbar.resize();
    }

    function initGrid() {
        var excelReportUrl= "";
        switch (_mode) {
            case "assetComplex":
                var filter = { field: "asset_complex_id", operator: "eq", value: parseInt(_assetComplexId) };
                var surl = "odata/ODataRealtyAssetContractByComplex";
                excelReportUrl = "Estate/Report/ExcelReportAssetContractByComplexGrid?assetComplexId=" + _assetComplexId;
                break;
            case "asset":
                var filter = { field: "asset_id", operator: "eq", value: parseInt(_assetId) };
                var surl = "odata/ODataRealtyAssetContractByAsset";
                excelReportUrl = "Estate/Report/ExcelReportAssetContractByAssetGrid?assetId=" + _assetId;
                break;
            case "subject":
                var filter = {
                    logic: "or",
                    filters: [
                        { field: "customer_subj_id", operator: "eq", value: parseInt(_subjId) },
                        { field: "executor_subj_id", operator: "eq", value: parseInt(_subjId) },
                        { field: "leasing_subj_id", operator: "eq", value: parseInt(_subjId) }
                    ]
                };
                var surl = "odata/ODataRealtyAssetContractByComplex";
                excelReportUrl = "Estate/Report/ExcelReportAssetContractByComplexGrid?subjId=" + _subjId;
                break;
            case "list":
                var surl = "odata/ODataRealtyAssetContract";
                excelReportUrl = "Estate/Report/ExcelReportAssetContractGrid";
                break;
        }

        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + surl,
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + excelReportUrl,
            filter: filter
        };

        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());
        if (_config.isModal)
            _selector.find("#grid").unwrap("div");


        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);
        
    }
    function _initTree(selector) {

        var g = $(selector).kendoGroupedGrid({
            tableViewLink: _config.tableViewLink,
            brcListConfig: {
                title: " ",
                isTree: true,
                parentKey: "parent_asset_contract_group_id",
                key: "asset_contract_group_id",
                textField: "asset_contract_group_name",
                path: "Группа договора: ",
                pathSelector: $(".brc-card-title")[0],
                confirmation:true,
                virtualNode: {
                    all: {
                        dropTarget: true,
                        //message: "Группа номенклатуры является обязательным полем"
                    },
                    ungrouped: {
                        dropTarget: true,
                        //message: "Группа номенклатуры является обязательным полем"
                    },
                },
                dataSource: {
                    type: "odata",
                    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyAssetContractGroup",
                    dataType: "json"
                },
                create: function (e) {
                    var entity = e;
                    
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/AssetContractGroup/CreateGroup", {group:e},
                    function (e) {
                        BrcNotification.success("Выполнено")
                    },
                    function (e) { BrcNotification.error(e.responseJSON) });
                },
                destroy: function (e) {
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/AssetContractGroup/DeleteGroup",{id: e.asset_contract_group_id},
                    function (e) {
                        //console.debug(e);
                        //alert("Del group");
                        BrcNotification.success("Выполнено");
                    },
                    function (e) { BrcNotification.error(e.responseJSON) });
                },
                update: function (e) {
                    
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/AssetContractGroup/UpdateGroup",{group:e},
                    function (e) { BrcNotification.success("Выполнено") },
                    function (e) { BrcNotification.error(e.responseJSON) });
                    //return $.ajax({
                    //    url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyContractType/UpdateEntity?key=" + e.contract_type_id,
                    //    data: kendo.stringify(e),
                    //    contentType: "application/json",
                    //    type: "post"
                    //}).done(function (e) {
                    //    BrcNotification.success("Выполнено")
                    //}).
                    //fail(function (e) {
                    //    BrcNotification.error(e.responseJSON["odata.error"].message.value)
                    //})
                },
            },
            brcGridConfig: {
                draggable:false,
                externalInit: {
                    grid: _grid,
                    toolbar: _toolbar
                },
                changeGroup: function (items, newGroup) {
                    var itemsIds = [];
                    items.forEach(function (elem) {
                        itemsIds.push(elem.asset_contract_id);
                    })
                    return BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "estate/AssetContract/ChangeGroup", { contractIds: itemsIds, newGroup: newGroup },
                       function (d) {
                           BrcNotification.success("Выполнено")
                       }, function (d) {
                           BrcNotification.error(d.responseJSON);
                       });
                },
                filters: !!_grid.kendoGrid.dataSource.filter() ? _grid.kendoGrid.dataSource.filter().filters : []
                //manyToMany: {
                //    url:"http://localhost:52396/estate/Document/GetDocumentsByRelation?=0"
                //}

            },
            permissions: {
                canChangeGroup: hasPermission("canChangeGroup"),
                canDeleteGroup: hasPermission("canDeleteGroup"),
                canCreateGroup: hasPermission("canCreateGroup"),
                canChangeObject: hasPermission("canUpdateAssetContract"),
            }
        }).data("kendoGroupedGrid");
        return g;
    }
    function initToolbar(isTree) {
        var items = [];

        if (_mode != "subject") {
            if (hasPermission("canCreateAssetContract")) {
                items.push({
                    type: "button",
                    text: "Создать",
                    click: onCreate
                });
            }

            if (hasPermission("canDeleteAssetContract")) {
                items.push({
                    type: "button",
                    text: "Удалить",
                    click: onDelete
                });
            }
               
            items.push({
                type: "button",
                text: "На карту",
                click: onMap
            });
        }

        items.push({
            type: "brcButtonList",
            text: "1С",
            list: [
                {
                    text: "Просмотреть данные",
                    click: show1C
                },
                {
                    text: "Синхронизировать",
                    click: sync1C,
                    hidden: !hasPermission("canUpdateAssetContract")
                }]
        });
        
               
        
        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");

        //toolbar.add(
        //    BrcReports.init(_brcGrid.config.EntityName, function (requestParameters) {
        //        var keys = _brcGrid.getSelectedKeys();
        //        if (keys.length < 1) {
        //            $("<div/>").kendoAlert({
        //                messages: {
        //                    okText: "OK"
        //                },
        //                buttonLayout: "normal",
        //                content: "Не выбраны объекты",
        //                modal: true,
        //                title: "Ошибка",
        //                width: "300px",
        //                close: function (e) {
        //                    e.sender.destroy();
        //                },
        //                actions: [{
        //                    text: "OK",
        //                    primary: true
        //                }]
        //            }).data("kendoAlert").open();
        //            //  BrcNotification.info("Не выбраны объекты");
        //            return false;
        //        }
        //        var data = [];
        //        data.push({ name: "asset_contract_id", value: keys[0] });
               

        //        //keys.forEach(function (k) {
        //        //    requestParameters.forEach(function (p) {
        //        //        data.push({ name: p.name, value: _brcGrid.kendoGrid.dataSource.get(k)[p.name] });
        //        //    })

        //        //})

        //        return data;
        //    })
        //    );

        if (!isTree && !_config.isModal) {

            toolbar.add({
                type: "button",
                text: '<i class="fa fa-sitemap" title="Дерево"></i>',
                id: "display",
                click: function (e) {
                    //_initTree($("#splitter"));
                    //self.toolbar.hide("#display");
                    location.href = _config.treeViewLink
                }
            })
        }
        return toolbar;
    }

    function onMap() {
        var selectedIds = _grid.getSelectedKeys();
        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите договор");
            return false;
        }
        
        BrcAjax.Get(window["APPLICATION_CONFIG"].routes.root + "AssetContract/CheckContour/" + selectedIds[0],
            null,
            function (data) {
                if (data.startsWith("parcel:")) {
                    location.href = window["APPLICATION_CONFIG"].routes.root + "Map/Main?view_menu=false&obj_name=parcel&obj_id=" + data.substring(0, data.indexOf(";")).replace("parcel:", "") + "&asset_complex_id=" + data.substring(data.indexOf(";") + 1).replace("asset_complex:", "");
                }
                if (data.startsWith("building:")) {
                    location.href = window["APPLICATION_CONFIG"].routes.root + "Map/Main?view_menu=false&obj_name=building&obj_id=" + data.substring(0, data.indexOf(";")).replace("building:", "") + "&asset_complex_id=" + data.substring(data.indexOf(";") + 1).replace("asset_complex:", "");
                }
            },
            function () {
                BrcNotification.error("Для объекта не задан контур");
            }
        );

    }
    function onCreate() {
        var assetContractModal = new AssetContractModal();
        if (!!_tree)
            var groupId = _tree.getSelectedKey();
        assetContractModal.init({
            assetContractId: 0,
            assetId: _assetId,
            assetComplexId: _assetComplexId,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
                
            },
            groupId: groupId
        });
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите договор");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/DeleteAssetContractList",
                    { assetContractIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="asset_contract"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var assetContractModal = new AssetContractModal();
            assetContractModal.init({
                assetContractId: this.getAttribute("number"),
                assetId: _assetId,
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }

        _grid.selector.find('[data-toggle-window="subject"]').click(onClickSubject);

        function onClickSubject(e) {
            e.preventDefault();

            var subjectModal = new SubjectModal();
            subjectModal.init({
                subjectId: this.getAttribute("number"),
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function show1C() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите договор");
            return false;
        }

        var contract1CModal = new AssetContract1cModal();
        contract1CModal.init({
            contractId: selectedIds[0],
            onOKClose: function () { _grid.kendoGrid.dataSource.read(); }
        });
    }
    function sync1C() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите договр");
            return false;
        }

        BrcConfirm.show("Подтверждение синхронизации", "Синхронизировать выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/AssetContract/SyncList1C",
                    { contractIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
};