function BrcEditor() {
    var _selector;
    var _readOnly = false;
    var _baseUrl;
    var _imageUploadUrl;
    var _customButtons;

    return {
        init: init,
        destroy: destroy,
        getContent: getContent,
        setContent: setContent,
        updateValue: updateValue,
        getEditor: getEditor
    };

    function init(config) {
        _selector = config.selector;
        _readOnly = config.readOnly;
        _baseUrl = config.baseUrl;
        _imageUploadUrl = config.imageUploadUrl;
        _customButtons = config.customButtons;

        var customToolbar = '';

        if (_customButtons) {
            for (var i = 0; i < _customButtons.length; i++) {
                customToolbar = customToolbar + ' ' + _customButtons[i].id;
            }
        }

        tinyMCE.baseURL = _baseUrl;
        tinymce.init({
            selector: _selector,
            menubar: false,
            resize: false,
            statusbar: false,
            paste_data_images: true,
            content_style: "h1, h3, h5 {color: #0072c6} a {cursor: pointer}",
            plugins: 'advlist autolink link image imagetools lists charmap print preview code textcolor colorpicker table autolink codesample hr searchreplace fullscreen paste',
            toolbar: 'undo redo styleselect bold italic alignleft aligncenter alignright bullist numlist outdent indent autolink link image lists charmap print preview code forecolor backcolor table codesample searchreplace fullscreen' + customToolbar,
            language: 'ru',
            readonly: _readOnly ? true : false,
            automatic_uploads: false,
            images_upload_url: _imageUploadUrl,
            images_upload_base_path: window["APPLICATION_CONFIG"].routes.root,
            relative_urls: false,
            convert_urls: true,
            document_base_url: window["APPLICATION_CONFIG"].routes.root,
            setup: function (editor) {
                if (_customButtons) {
                    for (var i = 0; i < _customButtons.length; i++) {
                        editor.addButton(_customButtons[i].id, _customButtons[i].config);
                    }
                }
            }
        });
    }

    function destroy() {
        tinymce.remove(_selector);
    }

    function getContent() {
        return getEditor().getContent();
    }

    function setContent(content) {
        return getEditor().setContent(content);
    }

    function updateValue(callback) {
        getEditor().uploadImages(function (success) {
            $(_selector).val(getContent());
            callback();
        });
    }

    function getEditor() {
        var index = _selector.lastIndexOf("#");
        var id = _selector.substring(index + 1);
        return tinymce.get(id);
    }
}