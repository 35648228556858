function ObjRightGrid() {
    var _grid;
    var _brcGrid;
    var _selector;
    var _toolbarSelector;
    var _assetId;
    var _subjId;
    var _assetComplexId;
    var _mode;
    var _showAll = true;
    var _excelReportUrl;

    return {
        init: init
    };

    function init(config) {
        _selector = $(config.selector);
        _toolbarSelector = $(config.toolbarSelector);
        _assetId = config.assetId;       
        _assetComplexId = config.assetComplexId;
        _subjId = config.subjId;
        _mode = config.mode;

        initGrid();
        initToolbar();
    }

    function initGrid() {
        var filter = initFilter();

        var dataSource = {
            type: "odata",
            url: window["APPLICATION_CONFIG"].routes.root + "odata/ODataRealtyObjRight",
            dataType: "json",
            excelProxyURL: window["APPLICATION_CONFIG"].routes.root + _excelReportUrl,
            filter: filter
        };

        var gridConfig = JSON.parse(_selector.find("#gridConfig").val());

        _brcGrid = new BrcGrid(_selector.find("#grid"), dataSource);
        _grid = _brcGrid.init(gridConfig);
        _grid.bind("dataBound", onDataBound);
    }
    
    function initFilter() {
        switch (_mode) {
            case "assetComplex":
                if (_showAll) {
                    var filter = { field: "asset_complex_id", operator: "eq", value: parseInt(_assetComplexId) };
                }
                else {
                    var filter = { field: "asset_id", operator: "eq", value: 0 };
                }
                break;
            case "asset":
                var filter = { field: "asset_id", operator: "eq", value: parseInt(_assetId) };
                break;
            case "subject":
                var filter = { field: "subj_id", operator: "eq", value: parseInt(_subjId) };
                break;
        }
        _excelReportUrl = "Estate/Report/ExcelReportObjRightGrid?assetComplexId=" + _assetComplexId + "&assetId=" + _assetId + "&subjId=" + _subjId;
        return filter;
    }

    function initToolbar() {
        var items = [];

        if (_mode == "asset") {
            if (hasPermission("canCreateObjRight")) {
                items.push({
                    type: "button",
                    text: "Создать",
                    click: onCreate
                });
            }

            if (hasPermission("canDeleteObjRight")) {
                items.push({
                    type: "button",
                    text: "Удалить",
                    click: onDelete
                });
            }

            items.push({
                type: "separator"
            });
        }
        
        items.push({
            type: "splitButton",
            text: "Таблица",
overflow:"never",
            menuButtons: [
                _brcGrid.filters(),
                _brcGrid.columnVisibility(),
                { type: "button", text: "Экспорт MS Excel", click: function () { _grid.exportExcel(); } }
            ],
        })

        var toolbar = _toolbarSelector.kendoToolBar({
            items: items
        }).data("kendoToolBar");
       
    }

    function onShowAll() {
        if (!_showAll) {
            _showAll = true;
            $("#btnViewParentAssetMode").text("Только для данного объекта");
        }
        else {
            _showAll = false;
            $("#btnViewParentAssetMode").text("Все записи");
        }
        var filter = initFilter();
        _grid.kendoGrid.dataSource.filter(filter);

        _grid.kendoGrid.options.excel.proxyURL = window["APPLICATION_CONFIG"].routes.root + _excelReportUrl + "&showAll=" + _showAll;
        _grid.dataSource.excelProxyURL = window["APPLICATION_CONFIG"].routes.root + _excelReportUrl + "&showAll=" + _showAll;

        _grid.kendoGrid.dataSource.read();
    }

    function onCreate() {
        var objRightModal = new ObjRightModal();
        objRightModal.init({
            objRightId: 0,
            assetId: _assetId,
            onOKClose: function () {
                _grid.kendoGrid.dataSource.read();
                _grid.kendoGrid.resize();
            }
        });
    }

    function onDelete() {
        var selectedIds = _grid.getSelectedKeys();

        if (selectedIds.length <= 0) {
            BrcNotification.error("Выберите право");
            return false;
        }

        BrcConfirm.show("Подтверждение удаления", "Удалить выбранные записи?")
            .done(function () {
                BrcAjax.Post(window["APPLICATION_CONFIG"].routes.root + "Estate/ObjRight/DeleteObjRightList",
                    { objRightIds: selectedIds },
                    function () {
                        BrcNotification.success("Выполнено");
                        _grid.kendoGrid.dataSource.read();
                    },
                    function () {
                        BrcNotification.error("Операция не была выполнена!");
                    }
                );
            });
    }

    function onDataBound() {
        _grid.selector.find('[data-toggle-window="obj_right"]').click(onClick);

        function onClick(e) {
            e.preventDefault();

            var objRightModal = new ObjRightModal();
            objRightModal.init({
                objRightId: this.getAttribute("number"),
                assetId: _assetId,
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }

        _grid.selector.find('[data-toggle-window="subject"]').click(onClickSubject);

        function onClickSubject(e) {
            e.preventDefault();

            var subjectModal = new SubjectModal();
            subjectModal.init({
                subjectId: this.getAttribute("number"),
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }

        _grid.selector.find('[data-toggle-window="encum"]').click(onClickEncum);

        function onClickEncum(e) {
            e.preventDefault();

            var encumbranceModal = new EncumbranceModal();
            encumbranceModal.init({
                encumbranceId: this.getAttribute("number"),
                assetId: _assetId,
                onOKClose: function () {
                    _grid.kendoGrid.dataSource.read();
                    _grid.kendoGrid.resize();
                }
            });
        }
    }

    function hasPermission(permissionName) {
        return _selector.find("#" + permissionName).val() === 'true';
    }
};